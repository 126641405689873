import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import { Tag }              from "Utils/API";



// The initial State
const initialState = {
    loading   : true,
    error     : false,
    edition   : 0,
    canCreate : false,
    canEdit   : false,
    canImport : false,
    canExport : false,
    list      : [],
    total     : 0,
    colors    : [],
    elem      : {},
    sort      : {
        orderBy  : "name",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "TAG_LOADING" });
    },

    /**
     * Fetches the Tag List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, params = {}) {
        Navigate.unsetParams(params);
        if (type === "CLIENT") {
            params.clientID = elemID;
        }
        const data = await Tag.getAll(params);
        data.sort  = params;
        dispatch({ type : "TAG_LIST", data });
    },

    /**
     * Fetches a single Tag
     * @param {Function} dispatch
     * @param {Number}   tagID
     * @returns {Promise}
     */
    async fetchElem(dispatch, tagID) {
        const data = await Tag.getOne({ tagID });
        dispatch({ type : "TAG_ELEM", data });
    },

    /**
     * Edits/Creates a Tag
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editTag(dispatch, data) {
        return Tag.edit(data);
    },

    /**
     * Deletes a Tag
     * @param {Function} dispatch
     * @param {Number}   tagID
     * @returns {Promise}
     */
    deleteTag(dispatch, tagID) {
        return Tag.delete({ tagID });
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "TAG_LIST", "TAG_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "TAG_LOADING":
        return {
            ...state,
            loading   : true,
        };

    case "TAG_LIST":
        return {
            ...state,
            loading   : false,
            error     : false,
            canCreate : action.data.canCreate,
            canEdit   : action.data.canEdit,
            canImport : action.data.canImport,
            canExport : action.data.canExport,
            list      : action.data.list,
            total     : action.data.total,
            colors    : action.data.colors,
            sort      : action.data.sort,
        };

    case "TAG_ELEM":
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            elem      : action.data.elem,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
