import React                from "react";
import Store                from "Dashboard/Core/Store";

// Components
import IntegrationCreate    from "../Dialogs/IntegrationCreate";

// Dashboard
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";



/**
 * The Integration Editor Dialogs
 * @returns {React.ReactElement}
 */
function IntegrationDialogs() {
    const { integrationID } = Store.useState("integrationEditor");
    const { deleteAction } = Store.useAction("integrationEditor");

    const {
        action, position, forTrigger,
        toSelectAction, selectedAction,
    } = Store.useState("integrationState");

    const {
        setAction, setHasChanges,
        setToSelectAction, setSelectedAction,
    } = Store.useAction("integrationState");


    // Handles the Action Create
    const handleCreate = () => {
        setAction();
    };

    // Handles the Action Deletes
    const handleDeleteAction = () => {
        if (selectedAction) {
            deleteAction(selectedAction);
            setSelectedAction(0);
            setHasChanges(false);
        }
        setAction();
    };

    // Handles the Discard
    const handleDiscard = () => {
        setSelectedAction(toSelectAction);
        setToSelectAction(0);
        setHasChanges(false);
        setAction();
    };


    // Do the Render
    return <>
        <IntegrationCreate
            open={action.isCreate}
            integrationID={integrationID}
            position={position}
            forTrigger={forTrigger}
            onSubmit={handleCreate}
            onClose={setAction}
        />
        <DeleteDialog
            open={action.isRemove}
            title="INTEGRATIONS_ACTIONS_DELETE_TITLE"
            message="INTEGRATIONS_ACTIONS_DELETE_TEXT"
            onSubmit={handleDeleteAction}
            onClose={setAction}
        />
        <ConfirmDialog
            open={action.isWarning}
            icon="status"
            title="INTEGRATIONS_ACTIONS_WARNING_TITLE"
            message="INTEGRATIONS_ACTIONS_WARNING_TEXT"
            primary="GENERAL_DISCARD"
            onSubmit={handleDiscard}
            onClose={setAction}
        />
    </>;
}

export default IntegrationDialogs;
