import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";

// Components
import DashboardChart       from "./DashboardChart";

// Dashboard
import Icon                 from "Dashboard/Components/Common/Icon";



// Styles
const Container = Styled.div.attrs(({ withNavigation }) => ({ withNavigation }))`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--main-padding);


    ${(props) => props.withNavigation ? `
        @media (max-width: 1600px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 1100px) {
            grid-template-columns: repeat(1, 1fr);
        }

        @media (max-width: 1000px) {
            grid-template-columns: repeat(2, 1fr);
        }
    ` : `
        @media (max-width: 1300px) {
            grid-template-columns: repeat(2, 1fr);
        }
    `}

    @media (max-width: 800px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

const Content = Styled.div.attrs(({ withTitle }) => ({ withTitle }))`
    display: flex;
    flex-direction: column;
    gap: 4px;
    box-sizing: border-box;

    ${(props) => props.withTitle && `
        padding: var(--main-padding);
        border: var(--border-width) solid var(--border-color-light);
        border-radius: var(--border-radius);
    `}
`;

const Header = Styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -8px;
    min-height: 30px;
`;

const Title = Styled.h3`
    margin: 0;
    display: flex;
    align-items: center;
    gap: 8px;
`;

const Help = Styled(Icon)`
    font-size: 18px;
    cursor: pointer;
`;

const Total = Styled.h3`
    margin: 0;
    font-size: 24px;
`;



/**
 * The Dashboard Reports
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function DashboardReports(props) {
    const { withNavigation } = props;

    const { reports, dayLabels } = Store.useState("dashboard");


    // Do the Render
    return <Container withNavigation={withNavigation}>
        {reports.map(({ id, withTitle, name, tooltip, total, type, ...chart }) => <Content
            key={id}
            withTitle={withTitle}
        >
            {withTitle && <Header>
                <Title>
                    {name}
                    {!!tooltip && <Help
                        icon="help"
                        tooltip={tooltip}
                    />}
                </Title>
                <Total>{total}</Total>
            </Header>}

            <DashboardChart
                type={type}
                isStacked={chart.isStacked}
                labels={chart.labels.length ? chart.labels : dayLabels}
                dataset={chart.dataset}
                extras={chart.extras}
                colors={chart.colors}
                urls={chart.urls}
                suffix={chart.suffix}
            />
        </Content>)}
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
DashboardReports.propTypes = {
    withNavigation : PropTypes.bool.isRequired,
};

export default DashboardReports;
