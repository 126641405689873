import React                from "react";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import useList              from "Dashboard/Hooks/List";

// Dashboard
import Filter               from "Dashboard/Components/Filter/Filter";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";



/**
 * The Integration Executions
 * @returns {React.ReactElement}
 */
function IntegrationExecutions() {
    const { load, loadFilter } = useList("integrationExec", "INTEGRATION");

    const data = Store.useState("integrationExec");
    const { loading, list, total, filters, sort, actionTypes, errorMessages } = data;


    // Handles the Filter
    const handleFilter = (filters) => {
        let   isFailed  = 0;
        let   isSuccess = 0;
        const types     = [];
        const errors    = [];

        if (filters.search) {
            const needle  = filters.search.toLocaleLowerCase();
            if (NLS.get("GENERAL_SUCCESS").toLocaleLowerCase().includes(needle)) {
                isSuccess = 1;
            }
            if (NLS.get("GENERAL_FAIL").toLocaleLowerCase().includes(needle)) {
                isFailed = 1;
            }

            for (const { key, message } of actionTypes) {
                if (NLS.get(message).toLocaleLowerCase().includes(needle)) {
                    types.push(key);
                }
            }

            for (const error of errorMessages) {
                if (NLS.get(error).toLocaleLowerCase().includes(needle)) {
                    errors.push(error);
                }
            }
        }

        loadFilter({
            ...filters, isFailed, isSuccess,
            actionTypes   : JSON.stringify(types),
            errorMessages : JSON.stringify(errors),
        });
    };



    // Do the Render
    return <>
        <Filter
            onFilter={handleFilter}
            values={filters}
            hasSearch
        />
        <Table
            fetch={load}
            sort={sort}
            none="INTEGRATIONS_EXECS_NONE_AVAILABLE"
            isLoading={loading}
            hasFilter
            hasTabs
        >
            <TableHead>
                <TableHeader field="createdTime" message="INTEGRATIONS_EXECS_DATE" isTitle maxWidth="160" />
                <TableHeader field="isFailed"    message="GENERAL_RESULT"          align="center" maxWidth="100" />
                <TableHeader field="failedStep"  message="GENERAL_ACTION"          />
                <TableHeader field="failedError" message="GENERAL_ONE_ERROR"       />
            </TableHead>
            <TableBody>
                {list.map((elem) => <TableRow key={elem.executionID} elemID={elem.executionID}>
                    <TableCell message={elem.createdDate}      />
                    <TableCell message={elem.isFailedText}     className={elem.isFailedClass} />
                    <TableCell message={elem.failedActionText} />
                    <TableCell message={elem.failedErrorText}  />
                </TableRow>)}
            </TableBody>
            <TablePaging total={total} />
        </Table>
    </>;
}

export default IntegrationExecutions;
