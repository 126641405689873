import React                from "react";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import Utils                from "Dashboard/Utils/Utils";

// Dashboard
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionItem           from "Dashboard/Components/Header/ActionItem";
import ActionOption         from "Dashboard/Components/Header/ActionOption";



/**
 * The Campaign Header
 * @returns {React.ReactElement}
 */
function CampaignHeader() {
    const parent = Navigate.useParent(2);

    const { canEdit, elem, activateErrors } = Store.useState("campaign");

    const { data } = Store.useState("campaignState");
    const { setAction, setInActivate } = Store.useAction("campaignState");

    const { publishErrors : flowPublishErrors } = Store.useState("flowEditor");
    const { setInPublish : setFlowInPublish } = Store.useAction("flowState");


    // Handles the Action
    const handleAction = (action) => {
        if ((action.isActivate || action.isPublish) && (!Utils.isEmpty(activateErrors) || flowPublishErrors.length)) {
            setInActivate(true);
            setFlowInPublish(true);
        } else {
            setAction(action);
        }
    };


    // Do the Render
    return <Header
        message={data.name || "CAMPAIGNS_SINGULAR"}
        icon="campaign"
        href={parent}
        subTitle={elem.statusName}
        subCircle={elem.statusColor}
    >
        <ActionList onAction={handleAction}>
            <ActionItem
                isHidden={elem.isActive || !canEdit}
                action="ACTIVATE"
            />
            <ActionItem
                isHidden={elem.isActive || !canEdit}
                action="DELETE"
            />

            <ActionItem
                isHidden={!elem.isActive || !canEdit}
                icon="more"
                message="GENERAL_ACTIONS"
            >
                <ActionOption
                    action="PAUSE"
                    message="CAMPAIGNS_PAUSE_TITLE"
                />
                <ActionOption
                    action="COMPLETE"
                    message="CAMPAIGNS_COMPLETE_TITLE"
                />
                <ActionOption
                    isHidden={!elem.needsPublish}
                    action="PUBLISH"
                />
                <ActionOption
                    isHidden={!elem.needsPublish}
                    action="CLEAR"
                />
                <ActionOption
                    action="DELETE"
                    message="CAMPAIGNS_DELETE_TITLE"
                />
            </ActionItem>
        </ActionList>
    </Header>;
}

export default CampaignHeader;
