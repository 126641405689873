import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import NLS                  from "Dashboard/Core/NLS";
import MediaType            from "Dashboard/Core/MediaType";
import Utils                from "Dashboard/Utils/Utils";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";
import Commons              from "Utils/Commons";

// Components
import MediaField           from "Components/Utils/Inputs/MediaField";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import DialogMessage        from "Dashboard/Components/Dialog/DialogMessage";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";
import ViewField            from "Dashboard/Components/Form/ViewField";



/**
 * The WABA Template Import Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function WabaTemplateImport(props) {
    const { open, clientID, onClose, onSubmit } = props;

    const { channels, wabaTemplates, variables } = Store.useState("wabaTemplate");
    const { fetchImportData, importWabaTemplate } = Store.useAction("wabaTemplate");


    // The Current State
    const [ isLoading, setLoading ] = React.useState(true);

    // The Initial Data
    const initialData = {
        clientID        : 0,
        channelID       : 0,
        wabaTemplate    : "",
        headerFile      : "",
        headerLatitude  : "",
        headerLongitude : "",
        headerLocation  : "",
        headerAddress   : "",
        headerVariable  : "",
        variableList    : [],
    };

    // Handles the Set
    const handleSet = (elem) => {
        const channelID = channels.length === 1 ? channels[0].key : 0;
        setElem({ ...elem, channelID });
        setLoading(!channelID);
    };

    // Handles the Edit
    const handleEdit = async (data) => {
        await importWabaTemplate({
            ...data,
            variableList : JSON.stringify(data.variableList),
        });
    };

    // Handles the Channel Change
    const handleChannel = async (name, value) => {
        handleChange(name, value);
        if (value) {
            setLoading(true);
            await fetchImportData(data.clientID, value, false);
            setLoading(false);
        }
    };

    // Handles the Variable
    const handleVariable = (index, value) => {
        const variableList = [ ...data.variableList ];
        variableList[Number(index)] = value;
        setData({ variableList });
    };


    // The Form State
    const {
        data, errors, setElem, setData, handleChange, handleSubmit,
    } = useForm("wabaTemplate", initialData, handleEdit, onSubmit);

    // Load the Data
    const { loading } = useDialog("wabaTemplate", open, 0, { clientID }, handleSet, fetchImportData);


    // Format the given Text
    const getMessage = (wabaTemplate) => {
        if (!wabaTemplate.bodyText) {
            return "";
        }
        return Commons.formatWabaText(wabaTemplate.bodyText).replace(/\n/g, "<br>");
    };


    // Variables
    const showTemplateLoading = Boolean(data.channelID && isLoading);
    const showTemplateList    = Boolean(data.channelID && !isLoading && wabaTemplates.length);
    const showTemplateError   = Boolean(data.channelID && !isLoading && !wabaTemplates.length);
    const wabaTemplate        = Utils.getValue(wabaTemplates, "key", data.templateID);
    const showTemplate        = Boolean(showTemplateList && data.templateID);
    const showHeader          = Boolean(showTemplate && wabaTemplate.headerType !== "NONE");
    const isImage             = wabaTemplate.headerType === "IMAGE";
    const isVideo             = wabaTemplate.headerType === "VIDEO";
    const isDocument          = wabaTemplate.headerType === "DOCUMENT";
    const showMedia           = Boolean(showHeader && (isImage || isVideo || isDocument));
    const showLocation        = Boolean(showHeader && wabaTemplate.headerType === "LOCATION");
    const showVariable        = Boolean(showTemplate && wabaTemplate.headerSample);
    const showFooter          = Boolean(showTemplate && (wabaTemplate.footerText || wabaTemplate.buttonList.length || wabaTemplate.buttonLinks.length));
    const sampleList          = showTemplate ? wabaTemplate.sampleList || [] : [];
    const buttonList          = showFooter ? wabaTemplate.buttonList.join("\n") : "";
    const buttonLinks         = showFooter ? wabaTemplate.buttonLinks.map((elem) => elem.text).join("\n") : "";


    // Do the Render
    return <EditDialog
        open={open}
        icon="import"
        title="WABA_TEMPLATES_IMPORT_TITLE"
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <DialogMessage
            variant="h4"
            message="WABA_TEMPLATES_IMPORT_TEXT"
            noSpace
        />
        <InputField
            isHidden={channels.length === 1}
            type="select"
            name="channelID"
            label="CHANNELS_SINGULAR"
            options={channels}
            value={data.channelID}
            error={errors.channelID}
            onChange={handleChannel}
            isRequired
        />

        <ViewField
            isHidden={!showTemplateLoading}
            label="WABA_TEMPLATES_TEMPLATE"
            message="GENERAL_LOADING_DOTS"
        />
        <InputField
            isHidden={!showTemplateList}
            type="select"
            name="templateID"
            label="WABA_TEMPLATES_TEMPLATE"
            options={wabaTemplates}
            value={data.templateID}
            error={errors.templateID}
            onChange={handleChange}
            isRequired
        />
        <ViewField
            isHidden={!showTemplateError}
            label="WABA_TEMPLATES_TEMPLATE"
            message="WABA_TEMPLATES_EMPTY_IMPORT"
        />

        <DialogMessage
            isHidden={!showHeader}
            message="WABA_TEMPLATES_HEADER_TITLE"
        />
        <ViewField
            isHidden={!showHeader}
            label="WABA_TEMPLATES_HEADER_TITLE"
            value={wabaTemplate.headerText}
        />
        <InputField
            isHidden={!showVariable}
            type="select"
            name="headerVariable"
            label={NLS.format("WABA_TEMPLATES_VALUE", "1")}
            options={variables}
            value={data.headerVariable}
            error={errors.headerVariable}
            onChange={handleChange}
            isRequired
        />
        <MediaField
            isHidden={!showMedia}
            clientID={clientID}
            name="headerFile"
            label="WABA_TEMPLATES_HEADER_FILE"
            value={data.headerFile}
            error={errors.headerFile}
            onChange={handleChange}
            placeholder={isImage ? "GENERAL_SELECT_IMAGE" : (isVideo ? "GENERAL_SELECT_VIDEO" : "GENERAL_SELECT_PDF")}
            mediaType={isImage ? MediaType.IMAGE : (isVideo ? MediaType.VIDEO : MediaType.PDF)}
            isRequired
        />
        <Columns isHidden={!showLocation}>
            <InputField
                name="headerLatitude"
                label="WABA_TEMPLATES_HEADER_LATITUDE"
                value={data.headerLatitude}
                error={errors.headerLatitude}
                onChange={handleChange}
                isRequired
            />
            <InputField
                name="headerLongitude"
                label="WABA_TEMPLATES_HEADER_LONGITUDE"
                value={data.headerLongitude}
                error={errors.headerLongitude}
                onChange={handleChange}
                isRequired
            />
            <InputField
                name="headerLocation"
                label="WABA_TEMPLATES_HEADER_LOCATION"
                value={data.headerLocation}
                error={errors.headerLocation}
                onChange={handleChange}
                isRequired
            />
            <InputField
                name="headerAddress"
                label="WABA_TEMPLATES_HEADER_ADDRESS"
                value={data.headerAddress}
                error={errors.headerAddress}
                onChange={handleChange}
                isRequired
            />
        </Columns>

        <DialogMessage
            isHidden={!showTemplate}
            message="WABA_TEMPLATES_BODY_TITLE"
        />
        <ViewField
            isHidden={!showTemplate}
            label="WABA_TEMPLATES_BODY_TITLE"
            value={getMessage(wabaTemplate)}
        />
        <>
            {sampleList.map((elem, index) => <InputField
                key={index}
                type="select"
                name={`variableList-${index}`}
                label={NLS.format("WABA_TEMPLATES_VALUE", String(index + 1))}
                options={variables}
                value={data.variableList[index] || 0}
                error={errors[`variableList-${index}`]}
                onChange={(name, value) => handleVariable(index, value)}
                isRequired
            />)}
        </>

        <DialogMessage
            isHidden={!showFooter}
            message="WABA_TEMPLATES_FOOTER_TITLE"
        />
        <ViewField
            isHidden={!showFooter}
            label="WABA_TEMPLATES_FOOTER_TITLE"
            value={wabaTemplate.footerText}
        />
        <ViewField
            isHidden={!showFooter}
            label="WABA_TEMPLATES_BUTTONS_TITLE"
            value={buttonList}
        />
        <ViewField
            isHidden={!showFooter}
            label="WABA_TEMPLATES_LINKS"
            value={buttonLinks}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
WabaTemplateImport.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    clientID : PropTypes.number,
};

export default WabaTemplateImport;
