import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";

// Dashboard
import HyperLink            from "Dashboard/Components/Link/HyperLink";
import PDFViewer            from "Dashboard/Components/Media/PDFViewer";



// Styles
const ImageLink = Styled(HyperLink)`
    display: block;
    width: 300px;
    max-width: 100%;
`;

const Image = Styled.img`
    display: block;
    max-width: 100%;
    border-radius: var(--border-radius);
    overflow: hidden;
`;

const Video = Styled.video`
    display: block;
    width: 100%;
    max-height: 400px;
    max-width: 400px;
    border-radius: var(--border-radius);
    overflow: hidden;
`;

const Audio = Styled.audio`
    display: block;
    max-width: 100%;
`;

const PDF = Styled.div`
    width: 100%;
    max-width: 300px;
    max-height: 100px;
    border-radius: var(--border-radius);
    overflow: hidden;
    cursor: pointer;
`;

const Error = Styled.div`
    text-decoration: underline;
    transition: all 0.2s ease-in-out;
    &:hover {
        opacity: 0.5;
    }
`;

const FileLink = Styled(HyperLink)`
    display: block;
    text-decoration: underline;
    overflow: hidden;
    text-overflow: ellipsis;
`;



/**
 * The Message Media
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function MessageMedia(props) {
    const { media : {
        hasImage, hasVideo, hasAudio, hasPDF, hasFile, hasUrl,
        fileUrl, fileText,
    } } = props;


    // Variables
    const extension = fileText.substring(fileText.lastIndexOf(".") + 1);
    const showImage = hasImage ?? [ "jpg", "jpeg", "gif", "png", "ico", "avif", "webp" ].includes(extension);
    const showVideo = hasVideo ?? [ "mov", "mpeg", "m4v", "mp4", "avi", "mpg", "wma", "flv", "webm" ].includes(extension);
    const showAudio = hasAudio ?? [ "mp3", "mpga", "m4a", "ac3", "aiff", "mid", "ogg", "wav" ].includes(extension);
    const showPDF   = hasPDF   ?? [ "pdf" ].includes(extension);
    const showFile  = hasFile  ?? (!showImage && !showVideo && !showAudio && !showPDF);
    const hasLink   = showFile || hasUrl;


    // Do the Render
    return <>
        {showImage && <ImageLink
            variant="opacity"
            href={fileUrl}
            target="_blank"
        >
            <Image
                className="bubble-media"
                src={fileUrl}
            />
        </ImageLink>}

        {showVideo && <Video
            className="bubble-media"
            src={fileUrl}
            controls
        />}

        {showAudio && <Audio
            className="bubble-media"
            src={fileUrl}
            controls
        />}

        {showPDF && <PDF
            className="bubble-media"
            onClick={() => window.open(fileUrl)}
        >
            <PDFViewer
                source={fileUrl}
                error={<Error>{fileText}</Error>}
                maxWidth={300}
            />
        </PDF>}

        {hasLink && <FileLink
            variant="opacity"
            href={fileUrl}
            target="_blank"
            preIcon="file"
            message={fileText}
        />}
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
MessageMedia.propTypes = {
    media : PropTypes.object,
};

/**
 * The Default Properties
 * @type {Object} defaultProps
 */
MessageMedia.defaultProps = {
    media : {},
};

export default MessageMedia;
