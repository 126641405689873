import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import Commons              from "Utils/Commons";

// Components
import StoreHeader          from "./Store/StoreHeader";
import StoreDetails         from "./Store/StoreDetails";
import StoreDialogs         from "./Store/StoreDialogs";
import OrderList            from "./Order/OrderList";
import ProductList          from "./Product/ProductList";
import CategoryList         from "./Category/CategoryList";
import BannerList           from "./Banner/BannerList";
import PersonalizePage      from "./Personalize/PersonalizePage";

// Dashboard
import Content              from "Dashboard/Components/Core/Content";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionItem           from "Dashboard/Components/Header/ActionItem";
import ActionOption         from "Dashboard/Components/Header/ActionOption";



// Styles
const Header = Styled.header`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 var(--main-padding);
`;



/**
 * The Store Page
 * @returns {React.ReactElement}
 */
function StorePage() {
    const { storeID, storeTab } = Navigate.useParams();
    const navigate = Commons.useGoto();

    const { charging, canEdit, elem } = Store.useState("store");
    const { fetchElem } = Store.useAction("store");
    const { tongues } = Store.useState("storeOption");

    const { withOrder, withAssistant, tongueID } = Store.useState("storeState");
    const { setFiltering, setCreating, setTongueID } = Store.useAction("storeState");


    // Load the Data
    React.useEffect(() => {
        handleLoad(storeID);
        setCreating(false);
    }, [ storeID ]);

    // Handle the Load
    const handleLoad = async (storeID) => {
        const { elem } = await fetchElem(storeID);
        if (!elem) {
            return;
        }

        if (!storeTab && elem.isDraft) {
            navigate("STORES", storeID, "CATEGORIES");
        } else if (!storeTab) {
            navigate("STORES", storeID, "ORDERS");
        }
    };

    // Handles the Tab
    const handleTab = (url) => {
        navigate("STORES", storeID, url);
        setCreating(false);
    };


    // Variables
    const isOrders      = Navigate.isUrl("ORDERS", storeTab);
    const isProducts    = Navigate.isUrl("PRODUCTS", storeTab);
    const isCategories  = Navigate.isUrl("CATEGORIES", storeTab);
    const isBanners     = Navigate.isUrl("BANNERS", storeTab);
    const isPersonalize = Navigate.isUrl("PERSONALIZE", storeTab);

    const canFilter     = isOrders;
    const canCreate     = canEdit && (isProducts || isCategories || isBanners);
    const hasLanguages  = isPersonalize && tongues.length > 1;


    // Do the Render
    return <>
        <StoreHeader />

        <Header>
            <TabList selected={storeTab} onClick={handleTab}>
                <TabItem
                    isHidden={elem.isDraft}
                    message="ORDERS_NAME"
                    url="ORDERS"
                />
                <TabItem
                    message="PRODUCTS_NAME"
                    url="PRODUCTS"
                />
                <TabItem
                    message="CATEGORIES_NAME"
                    url="CATEGORIES"
                />
                <TabItem
                    message="BANNERS_NAME"
                    url="BANNERS"
                />
                <TabItem
                    message="PERSONALIZE_NAME"
                    url="PERSONALIZE"
                />
            </TabList>

            <ActionList>
                <ActionItem
                    isHidden={!canFilter}
                    variant="outlined"
                    icon="filter"
                    message="GENERAL_FILTER"
                    onClick={() => setFiltering(true)}
                />
                <ActionItem
                    isHidden={!canCreate}
                    variant="outlined"
                    icon="add"
                    message="GENERAL_CREATE"
                    onClick={() => setCreating(true)}
                />
                <ActionItem
                    isHidden={!hasLanguages}
                    icon="language"
                    message="LANGUAGES_SINGULAR"
                    onClick={setTongueID}
                >
                    {tongues.map(({ key, value }) => <ActionOption
                        key={key}
                        value={key}
                        message={value}
                        icon={tongueID === key ? "checkedbox" : "checkbox"}
                    />)}
                </ActionItem>
            </ActionList>
        </Header>

        <Content
            isLoading={charging}
            withDetails={withOrder || withAssistant}
            wideDetails
        >
            {isOrders && <OrderList />}
            {isProducts && <ProductList />}
            {isCategories && <CategoryList />}
            {isBanners && <BannerList />}
            {isPersonalize && <PersonalizePage />}

            <StoreDetails />
        </Content>

        <StoreDialogs />
    </>;
}

export default StorePage;
