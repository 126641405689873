import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";
import { Profile }          from "Utils/API";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The User Create Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function UserCreate(props) {
    const { open, clientID, partnerID, onClose, onSubmit } = props;

    const { teams, colors, accesses, statuses } = Store.useState("user");
    const { createUser } = Store.useAction("user");


    // The Initial Data
    const initialData = {
        clientID       : 0,
        partnerID      : 0,
        access         : "",
        credentialID   : 0,
        credentialName : "",
        createNew      : 0,
        firstName      : "",
        lastName       : "",
        email          : "",
        phone          : "",
        password       : "",
        reqPassChange  : 0,
        color          : "#f2f2f2",
        teams          : [],
        observations   : "",
        status         : "Active",
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("user", initialData, createUser, onSubmit, false, open);

    // Load the Data
    const { loading } = useDialog("user", open, 0, { clientID, partnerID }, setElem);



    // Do the Render
    return <EditDialog
        open={open}
        icon="user"
        title="USERS_CREATE_TITLE"
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            type="select"
            name="access"
            label="GENERAL_ACCESS"
            options={accesses}
            value={data.access}
            error={errors.access}
            onChange={handleChange}
            isRequired
        />

        <InputField
            type="toggle"
            name="createNew"
            label="USERS_NEW"
            value={!!data.createNew}
            onChange={handleChange}
            withBorder
        />
        <InputField
            isHidden={!!data.createNew}
            type="suggest"
            name="credentialName"
            label="USERS_SEARCH"
            suggestID="credentialID"
            suggestFetch={Profile.search}
            suggestParams={{ onlyUsers : 1 }}
            value={data.credentialName}
            error={errors.credentialID}
            onChange={handleChange}
            isRequired
        />

        <InputField
            isHidden={!data.createNew}
            name="firstName"
            label="GENERAL_FIRST_NAME"
            value={data.firstName}
            error={errors.firstName}
            onChange={handleChange}
            isRequired
        />
        <InputField
            isHidden={!data.createNew}
            name="lastName"
            label="GENERAL_LAST_NAME"
            value={data.lastName}
            error={errors.lastName}
            onChange={handleChange}
            isRequired
        />
        <InputField
            isHidden={!data.createNew}
            type="email"
            name="email"
            label="GENERAL_EMAIL"
            value={data.email}
            error={errors.email}
            onChange={handleChange}
            isRequired
        />
        <InputField
            isHidden={!data.createNew}
            type="tel"
            name="phone"
            label="GENERAL_CELLPHONE"
            value={data.phone}
            onChange={handleChange}
            autoComplete="new-password"
        />
        <InputField
            isHidden={!data.createNew}
            type="password"
            name="password"
            label="GENERAL_PASSWORD"
            value={data.password}
            error={errors.password}
            onChange={handleChange}
            autoComplete="new-password"
            generateCode
            isRequired
        />
        <InputField
            isHidden={!data.createNew}
            type="toggle"
            name="reqPassChange"
            label="GENERAL_REQ_PASS_CHANGE"
            value={!!data.reqPassChange}
            onChange={handleChange}
            withBorder
        />

        <InputField
            isHidden={!teams.length}
            type="multiple"
            name="teams"
            label="TEAMS_NAME"
            options={teams}
            value={data.teams}
            error={errors.teams}
            onChange={handleChange}
        />
        <InputField
            type="color"
            name="color"
            label="GENERAL_COLOR"
            options={colors}
            value={data.color}
            error={errors.color}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="textarea"
            name="observations"
            label="GENERAL_OBSERVATIONS"
            value={data.observations}
            onChange={handleChange}
        />
        <InputField
            type="select"
            name="status"
            label="GENERAL_STATUS"
            options={statuses}
            value={data.status}
            error={errors.status}
            onChange={handleChange}
            isRequired
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
UserCreate.propTypes = {
    open      : PropTypes.bool.isRequired,
    onClose   : PropTypes.func.isRequired,
    onSubmit  : PropTypes.func.isRequired,
    clientID  : PropTypes.number,
    partnerID : PropTypes.number,
};

export default UserCreate;
