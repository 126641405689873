import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";

// Dashboard
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";



/**
 * The Contact Block Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ContactBlock(props) {
    const { open, elem, onClose, onSubmit } = props;

    const { blockContact, unblockContact } = Store.useAction("contact");


    // Handles the Contact Block
    const handleBlock = async () => {
        await blockContact(elem.id);
        onSubmit();
    };

    // Handles the Contact Unblock
    const handleUnblock = async () => {
        await unblockContact(elem.id);
        onSubmit();
    };



    // Do the Render
    if (!open) {
        return <React.Fragment />;
    }
    if (elem.isBlocked) {
        return <ConfirmDialog
            open={true}
            icon="block"
            title="CONTACTS_UNBLOCK_TITLE"
            message="CONTACTS_UNBLOCK_TEXT"
            content={elem.fullName}
            onSubmit={handleUnblock}
            onClose={onClose}
        />;
    }
    return <ConfirmDialog
        open={true}
        icon="block"
        title="CONTACTS_BLOCK_TITLE"
        message="CONTACTS_BLOCK_TEXT"
        content={elem.fullName}
        onSubmit={handleBlock}
        onClose={onClose}
    />;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ContactBlock.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elem     : PropTypes.object,
};

export default ContactBlock;
