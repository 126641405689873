import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Country Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CountryEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { editCountry } = Store.useAction("country");


    // The Initial Data
    const initialData = {
        countryID : 0,
        name      : "",
        isoCode   : "",
        idd       : "",
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("country", initialData, editCountry, onSubmit);

    // Load the Data
    const { loading } = useDialog("country", open, elemID, null, setElem);



    // Do the Render
    return <EditDialog
        open={open}
        icon="country"
        title="COUNTRIES_EDIT_TITLE"
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
            isRequired
        />
        <InputField
            name="isoCode"
            label="GENERAL_ISO_CODE"
            value={data.isoCode}
            error={errors.isoCode}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="number"
            name="idd"
            label="COUNTRIES_IDD"
            value={data.idd}
            error={errors.idd}
            onChange={handleChange}
            isRequired
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CountryEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default CountryEdit;
