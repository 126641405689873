import React                from "react";
import Store                from "Dashboard/Core/Store";
import useList              from "Dashboard/Hooks/List";

// Dashboard
import Filter               from "Dashboard/Components/Filter/Filter";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";



/**
 * The Campaign Executions
 * @returns {React.ReactElement}
 */
function CampaignExecutions() {
    const { load, loadFilter } = useList("campaignExecution", "CAMPAIGN");

    const data = Store.useState("campaignExecution");
    const { loading, list, total, filters, sort } = data;


    // Do the Render
    return <>
        <Filter
            onFilter={loadFilter}
            values={filters}
            hasSearch
        />
        <Table
            fetch={load}
            sort={sort}
            none="CAMPAIGNS_DISPATCHES_NONE_AVAILABLE"
            isLoading={loading}
            hasFilter
            hasTabs
        >
            <TableHead>
                <TableHeader field="createdTime" message="GENERAL_CREATED_FEM"        isTitle />
                <TableHeader field="sendTime"    message="GENERAL_SEND_AT"            />
                <TableHeader field="sentAmount"  message="CAMPAIGNS_DISPATCHES_DONE"  align="center" maxWidth="100" />
                <TableHeader field="totalAmount" message="CAMPAIGNS_DISPATCHES_TOTAL" align="center" maxWidth="100" />
            </TableHead>
            <TableBody>
                {list.map((elem) => <TableRow key={elem.executionID} elemID={elem.executionID}>
                    <TableCell message={elem.createdDate} />
                    <TableCell message={elem.sendDate}    />
                    <TableCell message={elem.sentAmount}  />
                    <TableCell message={elem.totalAmount} />
                </TableRow>)}
            </TableBody>
            <TablePaging total={total} />
        </Table>
    </>;
}

export default CampaignExecutions;
