import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Integration Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function IntegrationEdit(props) {
    const { open, isCopy, elemID, clientID, onClose, onSubmit } = props;

    const { integrationTemps } = Store.useState("integration");
    const { editIntegration, copyIntegration } = Store.useAction("integration");


    // The Initial Data
    const initialData = {
        clientID          : 0,
        integrationID     : 0,
        integrationTempID : 0,
        name              : "",
    };

    // Handles the Edit
    const handleEdit = (data) => {
        if (isCopy) {
            return copyIntegration(data);
        }
        return editIntegration(data);
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("integration", initialData, handleEdit, onSubmit);

    // Load the Data
    const { loading } = useDialog("integration", open, elemID, { clientID }, setElem);


    // Generates the Title
    const title = React.useMemo(() => {
        if (isCopy) {
            return "INTEGRATIONS_COPY_TITLE";
        }
        if (elemID) {
            return "INTEGRATIONS_EDIT_TITLE";
        }
        return "INTEGRATIONS_CREATE_TITLE";
    }, [ isCopy, elemID ]);



    // Do the Render
    const showTemplates = Boolean(!elemID && integrationTemps && integrationTemps.length);

    return <EditDialog
        open={open}
        icon="integration"
        title={title}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
            isRequired
        />
        <InputField
            isHidden={!showTemplates}
            type="select"
            name="integrationTempID"
            label="INTEGRATIONS_TEMPLATE"
            value={data.integrationTempID}
            error={errors.integrationTempID}
            options={integrationTemps}
            onChange={handleChange}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
IntegrationEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    isCopy   : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
    clientID : PropTypes.number,
};

export default IntegrationEdit;
