import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Action               from "Dashboard/Core/Action";
import Navigate             from "Dashboard/Core/Navigate";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";
import { Subscription }     from "Utils/API";

// Components
import SubscriptionFilter   from "./SubscriptionFilter";
import SubscriptionView     from "./SubscriptionView";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import StatList             from "Dashboard/Components/Stats/StatList";
import StatItem             from "Dashboard/Components/Stats/StatItem";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Subscription List
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function SubscriptionList(props) {
    const { type } = props;
    const { fetch, load, loadFilter } = useList("subscription", type);

    const { clientID, partnerID } = Navigate.useParams();
    const navigate = Navigate.useGoto();

    const data = Store.useState("subscription");
    const { loading, canEdit, list, total, stats, filters, sort } = data;

    const { deleteSubscription } = Store.useAction("subscription");

    // The Current State
    const [ exporting, setExporting ] = React.useState(false);
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Action
    const handleAction = async (action, elemID) => {
        if (action.isClient) {
            endAction();
            const clientID = Utils.getValue(list, "subscriptionID", elemID, "clientID");
            navigate("CLIENTS", clientID, "SUBSCRIPTIONS");
        } else if (action.isExport) {
            setExporting(true);
            endAction();
            const source = Subscription.export({ ...filters, clientID });
            await Utils.download(source, "subscriptions.xlsx");
            setExporting(false);
        } else {
            startAction(action, elemID);
        }
    };

    // Handles the Filter Submit
    const handleFilter = (filters) => {
        endAction();
        loadFilter(filters);
    };

    // Handles the Close
    const handleClose = (update) => {
        endAction();
        if (update) {
            fetch();
        }
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        handleClose();
        const result = await deleteSubscription(elemID);
        if (result.success) {
            fetch();
        }
    };


    // Variables
    const forAdmin    = type === "ADMIN";
    const forPartner  = type === "PARTNER";
    const forClient   = type === "CLIENT";
    const showClient  = !clientID;
    const showPartner = forAdmin;


    // Do the Render
    return <Main>
        <Header message="SUBSCRIPTIONS_NAME" icon="subscription">
            <ActionList data={data} onAction={handleAction} isExporting={exporting} />
        </Header>
        <Content>
            <StatList isHidden={!forClient} variant="outlined">
                <StatItem
                    message="PLANS_SINGULAR"
                    value={stats.planName}
                />
                <StatItem
                    message="GENERAL_AMOUNT"
                    value={stats.amountText}
                />
                <StatItem
                    message="GENERAL_PRICE"
                    value={stats.basePrice}
                    symbol={stats.currencySymbol}
                    isPrice
                />
                <StatItem
                    message="ADDONS_NAME"
                    value={stats.addonPrice}
                    symbol={stats.currencySymbol}
                    isPrice
                />
                <StatItem
                    message="CONNECTORS_NAME"
                    value={stats.connectorPrice}
                    symbol={stats.currencySymbol}
                    isPrice
                />
                <StatItem
                    message="GENERAL_TOTAL"
                    value={stats.totalPrice}
                    symbol={stats.currencySymbol}
                    isPrice
                />
            </StatList>

            <Table
                fetch={load}
                sort={sort}
                none="SUBSCRIPTIONS_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="createdTime"    message="PERIOD_NAME"       maxWidth="90" />
                    <TableHeader field="clientName"     message="CLIENTS_SINGULAR"  grow="2" isHidden={!showClient} />
                    <TableHeader field="partnerName"    message="PARTNERS_SINGULAR" grow="2" isHidden={!showPartner} />
                    <TableHeader field="planName"       message="PLANS_SINGULAR"    />
                    <TableHeader field="finalAmount"    message="GENERAL_AMOUNT"    maxWidth="120" />
                    <TableHeader field="basePrice"      message="GENERAL_PRICE"     maxWidth="180" />
                    <TableHeader field="addonPrice"     message="ADDONS_NAME"       maxWidth="100" align="right" noSorting />
                    <TableHeader field="connectorPrice" message="CONNECTORS_NAME"   maxWidth="100" align="right" noSorting />
                    <TableHeader field="totalPrice"     message="GENERAL_TOTAL"     maxWidth="100" align="right" />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.subscriptionID} elemID={elem.subscriptionID}>
                        <TableCell message={elem.createdPeriod}      />
                        <TableCell message={elem.clientName}         circle={elem.statusColor} />
                        <TableCell message={elem.partnerName}        />
                        <TableCell message={elem.planName}           />
                        <TableCell message={elem.amountText}         />
                        <TableCell message={elem.basePriceText}      />
                        <TableCell message={elem.addonPriceText}     />
                        <TableCell message={elem.connectorPriceText} />
                        <TableCell message={elem.totalPriceText}     />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={handleAction} canEdit={canEdit}>
                    <TableAction action="VIEW"   message="SUBSCRIPTIONS_VIEW_TITLE"   />
                    <TableAction action="CLIENT" message="CLIENTS_VIEW_TITLE"         isHidden={!showClient} />
                    <TableAction action="DELETE" message="SUBSCRIPTIONS_DELETE_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <SubscriptionFilter
            open={action.isFilter}
            partnerID={partnerID}
            forAdmin={forAdmin}
            forPartner={forPartner}
            filters={filters}
            onSubmit={handleFilter}
            onClose={endAction}
        />
        <SubscriptionView
            open={action.isVCE}
            forAdmin={forAdmin}
            elemID={elemID}
            onClose={handleClose}
        />
        <DeleteDialog
            open={action.isDelete}
            title="SUBSCRIPTIONS_DELETE_TITLE"
            message="SUBSCRIPTIONS_DELETE_TEXT"
            onSubmit={handleDelete}
            onClose={handleClose}
        />
    </Main>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
SubscriptionList.propTypes = {
    type : PropTypes.string.isRequired,
};

export default SubscriptionList;
