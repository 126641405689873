import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import AvatarList           from "Dashboard/Components/Avatar/AvatarList";
import AvatarItem           from "Dashboard/Components/Avatar/AvatarItem";



/**
 * The Ticket Assign Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function TicketAssign(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { admins       } = Store.useState("ticket");
    const { assignTicket } = Store.useAction("ticket");


    // The Initial Data
    const initialData = {
        ticketID          : 0,
        adminCredentialID : 0,
    };

    // The Current State
    const [ data, setData ] = React.useState({ ...initialData });

    // Load the Data
    const {
        loading, startLoading, endLoading,
    } = useDialog("ticket", open, elemID, null, setData);


    // Handles the User Change
    const handleUser = (adminCredentialID) => {
        setData({ ...data, adminCredentialID });
    };

    // Handles the Submit
    const handleSubmit = async () => {
        if (!loading) {
            startLoading();
            await assignTicket(data);
            endLoading();
            onSubmit();
        }
    };


    // Do the Render
    return <EditDialog
        open={open}
        icon="assign"
        title="TICKETS_ASSIGN_TITLE"
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        withSpacing={false}
        isNarrow
    >
        <AvatarList>
            {admins.map((elem) => <AvatarItem
                key={elem.credentialID}
                isSelected={data.adminCredentialID === elem.credentialID}
                name={elem.credentialName}
                email={elem.email}
                avatar={elem.avatar}
                onClick={() => handleUser(elem.credentialID)}
            />)}
        </AvatarList>
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
TicketAssign.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default TicketAssign;
