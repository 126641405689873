import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import KeyCode              from "Dashboard/Utils/KeyCode";
import Utils                from "Dashboard/Utils/Utils";

// Components
import FlowNode             from "./FlowNode";
import FlowEdge             from "./FlowEdge";



// Constants
const HEADER  = 22;
const WIDTH   = 160;
const HEIGHT  = 50;
const SPACING = 8;
const SQUARE  = 6;

// Styles
const Container = Styled.main`
    position: relative;
    width: 10000px;
    height: 2000px;
    background-image: radial-gradient(var(--grid-color) 1px, transparent 0);
    background-size: var(--grid-size) var(--grid-size);
    transform-origin: top left;
    touch-action: none;
`;

const Selector = Styled.div`
    position: absolute;
    border: 1px dashed black;
    background-color: rgba(250, 250, 250, 0.3);
    z-index: 10;
`;



/**
 * The Flow Canvas
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function FlowCanvas(props) {
    const { containerRef } = props;

    const { flowID, nodes, edges } = Store.useState("flowEditor");
    const { createNode, dragNodes, createEdge, editEdge } = Store.useAction("flowEditor");

    const {
        hasChanges, zoom, nodeCreating, selectedNodes,
        isEditingEdge, selectedEdge,
    } = Store.useState("flowState");
    const {
        setAction, setInPublish, setDetailsTab, setNodeCreating, setEdgeEditing,
        setToSelectNodes, setSelectedNodes, setSelectedEdge,
    } = Store.useAction("flowState");


    // The References
    const canvasRef = React.useRef(null);

    // The Current State
    const initialState = {
        forSelect       : false,
        forScroll       : false,
        nodeIDs         : [],
        edgeID          : 0,
        edgeNode        : null,
        edgeOut         : "",
        startX          : 0,
        startY          : 0,
        diffX           : 0,
        diffY           : 0,
        posX            : 0,
        posY            : 0,
        scrollX         : 0,
        scrollY         : 0,
        canvasBounds    : {},
        containerBounds : {},
        timer           : null,
    };
    const stateRef = React.useRef({ ...initialState });
    const { forSelect, forScroll, nodeIDs, edgeID, edgeNode, edgeOut } = stateRef.current;

    const [ isMoving,   setMoving   ] = React.useState(false);
    const [ isDragging, setDragging ] = React.useState(false);
    const [ startX,     setStartX   ] = React.useState(0);
    const [ startY,     setStartY   ] = React.useState(0);
    const [ posX,       setPosX     ] = React.useState(0);
    const [ posY,       setPosY     ] = React.useState(0);

    const moveX = startX - posX;
    const moveY = startY - posY;
    const mult  = zoom / 100;


    // Start the Node create
    React.useEffect(() => {
        if (nodeCreating) {
            const canvasBounds = canvasRef.current.getBoundingClientRect();
            const startX       = (nodeCreating.startX - canvasBounds.left) / mult;
            const startY       = (nodeCreating.startY - canvasBounds.top) / mult;
            startDrag({ startX, startY, diffX : 20, diffY : 20 });
        }
    }, [ nodeCreating ]);

    // Start the Edge edit
    React.useEffect(() => {
        if (isEditingEdge && selectedEdge) {
            startDrag({ edgeID : selectedEdge.id });
        }
    }, [ isEditingEdge, selectedEdge ]);

    // Selects the Nodes
    const setSelectNodes = (nodeIDs) => {
        if (hasChanges) {
            setAction("WARNING");
            setToSelectNodes(nodeIDs);
        } else {
            setSelectedNodes(nodeIDs);
            if (nodeIDs.length === 1) {
                setDetailsTab("editor");
            }
        }
    };


    // Starts the Drag
    const startDrag = ({
        forSelect = false, forScroll = false,
        nodeIDs = [], edgeID = 0, edgeNode = null, edgeOut = "",
        startX = 0, startY = 0, diffX = 0, diffY = 0,
    }) => {
        const posX = startX - diffX;
        const posY = startY - diffY;

        stateRef.current = {
            forSelect, forScroll,
            nodeIDs, edgeID, edgeNode, edgeOut,
            diffX, diffY, posX, posY,
            startX          : posX,
            startY          : posY,
            scrollX         : containerRef.current.scrollLeft,
            scrollY         : containerRef.current.scrollTop,
            canvasBounds    : canvasRef.current.getBoundingClientRect(),
            containerBounds : containerRef.current.getBoundingClientRect(),
            timer           : null,
        };

        setMoving(true);
        setDragging(false);
        setStartX(posX);
        setStartY(posY);
        setPosX(posX);
        setPosY(posY);
    };

    // Returns the Target with an Action
    const getTarget = (e) => {
        let element = e.target;
        while (element.parentElement && !element.dataset.action) {
            element = element.parentElement;
        }
        const action = element.dataset.action;
        const id     = Number(element.dataset.id);
        return { element, action, id };
    };

    // Returns the Pointer Position
    const getPointerPosition = (e) => {
        if (e.changedTouches) {
            const touch = e.changedTouches[0];
            return { x : touch.pageX, y : touch.pageY };
        }
        return { x : e.clientX, y : e.clientY };
    };

    // Handles the Select
    const handleSelect = (e, nodeIDs) => {
        let selectNodeIDs = nodeIDs;
        if (e.metaKey || e.ctrlKey || e.shiftKey) {
            const oldNodeIDs = selectedNodes.filter((nodeID) => !nodeIDs.includes(nodeID));
            const newNodeIDs = nodeIDs.filter((nodeID) => !selectedNodes.includes(nodeID));
            selectNodeIDs = [ ...oldNodeIDs, ...newNodeIDs ];
        }

        setSelectNodes(selectNodeIDs);
        Utils.unselectAll();
    };

    // Handles the Pointer Down
    const handlePointerDown = (e) => {
        if (e.button === 2) {
            return;
        }

        const { action, id } = getTarget(e);
        const { x, y } = getPointerPosition(e);

        const canvasBounds = canvasRef.current.getBoundingClientRect();
        const startX       = (x - canvasBounds.left) / mult;
        const startY       = (y - canvasBounds.top) / mult;

        // Handle the Node Pick
        if (action === "grabNode") {
            const node = Utils.getValue(nodes, "id", id);
            if (Utils.isEmpty(node)) {
                return;
            }
            if (hasChanges) {
                setSelectNodes([ node.id ]);
                return;
            }

            const nodeIDs = selectedNodes.includes(node.id) ? selectedNodes : [ node.id ];
            startDrag({ nodeIDs, startX, startY });
            return;
        }

        // Handle the Scroll
        if (e.type === "touchstart") {
            startDrag({ forScroll : true, startX : x, startY : y });

        // Handle the Multi selection
        } else if (!isMoving) {
            startDrag({ forSelect : true, startX, startY });
        }
    };

    // Handles the Pointer Move
    const handlePointerMove = (e) => {
        const { startX, startY, diffX, diffY, posX, posY } = stateRef.current;
        if (!isMoving) {
            return;
        }

        Utils.unselectAll();
        const canvasBounds = endTimeout();
        const { x, y } = getPointerPosition(e);

        const pointerX = x - diffX * mult;
        const pointerY = y - diffY * mult;
        let   newPosX  = posX;
        let   newPosY  = posY;

        if (pointerX >= canvasBounds.left && pointerX <= canvasBounds.right) {
            newPosX = (pointerX - canvasBounds.left) / mult;
            stateRef.current.posX = newPosX;
            setPosX(newPosX);
        }
        if (pointerY >= canvasBounds.top && pointerY <= canvasBounds.bottom) {
            newPosY = (pointerY - canvasBounds.top) / mult;
            stateRef.current.posY = newPosY;
            setPosY(newPosY);
        }

        if (!isDragging) {
            const xDist = newPosX - startX;
            const yDist = newPosY - startY;
            const dist  = xDist * xDist + yDist * yDist;
            if (dist < 10) {
                return;
            }
            setDragging(true);
        }

        if (forScroll) {
            const { scrollX, scrollY, containerBounds } = stateRef.current;

            const diffX      = Utils.mapValue(pointerX - startX, 0, containerBounds.width, 0, canvasBounds.width / 5);
            const diffY      = Utils.mapValue(pointerY - startY, 0, containerBounds.height, 0, canvasBounds.height);
            const newScrollX = scrollX + diffX;
            const newScrollY = scrollY + diffY;
            containerRef.current.scrollTo(newScrollX, newScrollY);
            return;
        }

        handleAutoScroll(x, y);
    };


    // Handles the Scroll
    const handleAutoScroll = (x, y) => {
        const { containerBounds, scrollX, scrollY } = stateRef.current;

        let scrollDeltaX = 0;
        if (scrollX > 30 && x < containerBounds.left + 30) {
            scrollDeltaX = -1;
        } else if (x > containerBounds.right - 30) {
            scrollDeltaX = 1;
        }

        let scrollDeltaY = 0;
        if (scrollY > 30 && y < containerBounds.top + 30) {
            scrollDeltaY = -1;
        } else if (y > containerBounds.bottom - 30) {
            scrollDeltaY = 1;
        }

        if (scrollDeltaX || scrollDeltaY) {
            stateRef.current.timer = window.setInterval(() => {
                autoScroll(scrollDeltaX, scrollDeltaY, 5);
            }, 10);
        }
    };

    // Ends the Timeout
    const endTimeout = () => {
        const { canvasBounds, timer } = stateRef.current;
        if (!timer) {
            return canvasBounds;
        }
        window.clearTimeout(timer);
        stateRef.current.timer        = null;
        stateRef.current.canvasBounds = canvasRef.current.getBoundingClientRect();
        return stateRef.current.canvasBounds;
    };

    // Auto-scroll the container
    const autoScroll = (scrollDeltaX, scrollDeltaY, scrollAmount) => {
        const { scrollX, scrollY, posX, posY } = stateRef.current;

        const newScrollX = scrollX + scrollDeltaX * scrollAmount;
        const newScrollY = scrollY + scrollDeltaY * scrollAmount;
        containerRef.current.scrollTo(newScrollX, newScrollY);

        const newPosX = posX + scrollDeltaX * scrollAmount;
        const newPosY = posY + scrollDeltaY * scrollAmount;
        setPosX(newPosX);
        setPosY(newPosY);

        stateRef.current.posX    = newPosX;
        stateRef.current.posY    = newPosY;
        stateRef.current.scrollX = newScrollX;
        stateRef.current.scrollY = newScrollY;
    };


    // Handles the Pointer Up
    const handlePointerUp = async (e) => {
        if (forScroll && isDragging) {
            setMoving(false);
            setDragging(false);
            return;
        }

        const { element, action, id } = getTarget(e);
        endTimeout();

        // Handle the Drop
        if (isDragging) {
            if (!edgeNode && nodeIDs.length) {
                const moves = {};
                for (const nodeID of nodeIDs) {
                    const node = Utils.getValue(nodes, "id", nodeID);
                    moves[nodeID] = {
                        posX : Math.max(node.posX - moveX, 1),
                        posY : Math.max(node.posY - moveY, 1),
                    };
                }
                await dragNodes(flowID, JSON.stringify(moves));
            } else if (nodeCreating) {
                await createNode(flowID, nodeCreating.key, posX, posY);
                setNodeCreating(null);
                setInPublish(false);
            } else if (edgeNode || edgeID) {
                if (action === "dropEdge") {
                    if (edgeNode) {
                        await createEdge(edgeNode.id, edgeOut, id);
                    } else {
                        await editEdge(edgeID, id);
                        selectedEdge.toNodeID = id;
                    }
                }
                setEdgeEditing(false);
            } else if (forSelect) {
                const selected = [];
                const fromX    = Math.min(posX, startX);
                const fromY    = Math.min(posY, startY);
                const toX      = Math.max(posX, startX);
                const toY      = Math.max(posY, startY);
                for (const node of nodes) {
                    if (
                        (node.posX > fromX && node.posY > fromY) &&
                        (node.posX + WIDTH < toX && node.posY + HEIGHT < toY)
                    ) {
                        selected.push(node.id);
                    }
                }
                handleSelect(e, selected);
            }
            setMoving(false);
            setDragging(false);
        }

        // Keep the Movement
        if (isMoving) {
            stateRef.current = { ...initialState };

            if (isDragging) {
                return;
            }
        }

        // Handle the Edge Grab
        if (action === "grabEdge") {
            const edgeNode = Utils.getValue(nodes, "id", id);
            if (!Utils.isEmpty(edgeNode)) {
                const canvasBounds = canvasRef.current.getBoundingClientRect();
                const elemBounds   = element.getBoundingClientRect();
                const edgeOut      = element.dataset.type;
                const startX       = (elemBounds.right - canvasBounds.left) / mult;
                const startY       = (elemBounds.top + elemBounds.height / 2 - canvasBounds.top) / mult;
                startDrag({ edgeNode, edgeOut, startX, startY });
            }
            return;
        }

        // Handle the Node selection
        if (action === "selectNode" && id) {
            handleSelect(e, [ id ]);
        } else if (action === "grabNode") {
            handleSelect(e, nodeIDs);
        } else if (action !== "grabNode" && selectedNodes.length) {
            setSelectNodes([]);
        }

        // Handle the Edge selection
        if (action === "selectEdge" && id) {
            const edge = Utils.getValue(edges, "id", id);
            if (!Utils.isEmpty(edge)) {
                setSelectedEdge(edge);
            }
        } else if (selectedEdge) {
            setSelectedEdge(null);
        }
    };


    // Add the Key Up handler
    React.useEffect(() => {
        window.addEventListener("keyup", handleKeyUp);
        return () => {
            window.removeEventListener("keyup", handleKeyUp);
        };
    }, [ nodeCreating ]);

    // Handles the Key Up
    const handleKeyUp = (e) => {
        if (e.which === KeyCode.DOM_VK_ESCAPE && nodeCreating) {
            stateRef.current = { ...initialState };
            setNodeCreating(null);
        }
    };


    // Calculates the Node Position
    const getNodePosition = (node) => {
        const { startX, startY, posX, posY } = stateRef.current;
        const inDragMode = nodeIDs.includes(node.id);
        return {
            inDragMode,
            posX : node.posX - (inDragMode ? (startX - posX) : 0),
            posY : node.posY - (inDragMode ? (startY - posY) : 0),
        };
    };

    // Calculates the Edge Positions
    const getEdgePosition = (edge) => {
        const { startX, startY, posX, posY } = stateRef.current;

        const fromNode = Utils.getValue(nodes, "id", edge.fromNodeID);
        const toNode   = Utils.getValue(nodes, "id", edge.toNodeID);
        const dragFrom = nodeIDs.includes(edge.fromNodeID);
        const dragTo   = nodeIDs.includes(edge.toNodeID);
        const moveX    = startX - posX;
        const moveY    = startY - posY;
        const fromX    = fromNode.posX - (dragFrom ? moveX : 0);
        const fromY    = fromNode.posY - (dragFrom ? moveY : 0);
        const toX      = toNode.posX - (dragTo ? moveX : 0);
        const toY      = toNode.posY - (dragTo ? moveY : 0);

        let index = 0;
        if (fromNode.fromNodeOuts?.length) {
            const foundIndex = fromNode.fromNodeOuts.findIndex(({ key }) => key === edge.fromNodeOut);
            if (foundIndex > -1) {
                index = foundIndex;
            }
        }

        if (!fromX || !fromY || !toX || !toY) {
            return null;
        }
        return {
            startX : fromX + WIDTH + SQUARE,
            startY : fromY + HEADER + SPACING + (SQUARE * 2 + SPACING) * index + SQUARE - 1,
            endX   : toX - SQUARE,
            endY   : toY + HEADER + SPACING + SQUARE - 1,
        };
    };



    // Do the Render
    return <Container
        ref={canvasRef}
        onMouseDown={handlePointerDown}
        onMouseMove={handlePointerMove}
        onMouseUp={handlePointerUp}
        onMouseLeave={endTimeout}
        onTouchStart={handlePointerDown}
        onTouchMove={handlePointerMove}
        onTouchEnd={handlePointerUp}
        style={{ transform : `scale(${mult})` }}
    >
        {nodeCreating && <FlowNode
            elem={nodeCreating}
            posX={posX}
            posY={posY}
            isCreating
        />}

        {nodes.map((item) => {
            const { posX, posY, inDragMode } = getNodePosition(item);
            return <FlowNode
                key={item.id}
                elem={item}
                posX={posX}
                posY={posY}
                isDragging={inDragMode}
            />;
        })}

        {edgeNode && <FlowEdge
            startX={startX}
            startY={startY}
            endX={posX}
            endY={posY}
            isCreating
        />}

        {edges.map((item) => {
            const inDragMode = edgeID === item.id;
            const position   = getEdgePosition(item);
            if (!position) {
                return <React.Fragment key={item.id} />;
            }
            return <FlowEdge
                key={item.id}
                elem={item}
                startX={position.startX}
                startY={position.startY}
                endX={inDragMode ? posX : position.endX}
                endY={inDragMode ? posY : position.endY}
            />;
        })}

        {isDragging && forSelect && <Selector style={{
            top    : `${Math.min(posY, startY)}px`,
            left   : `${Math.min(posX, startX)}px`,
            width  : `${Math.abs(posX - startX)}px`,
            height : `${Math.abs(posY - startY)}px`,
        }} />}
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
FlowCanvas.propTypes = {
    containerRef : PropTypes.object.isRequired,
};

export default FlowCanvas;
