import React                from "react";
import PropTypes            from "prop-types";

// Components
import FieldsItems          from "./FieldsItems";

// Dashboard
import DetailList           from "Dashboard/Components/Details/DetailList";
import DetailItem           from "Dashboard/Components/Details/DetailItem";



/**
 * The Account Detail Item
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function AccountItem(props) {
    const { elem, collapsible, action, onAction, canEdit } = props;

    const hasAccount = Boolean(elem.id);


    // Do the Render
    return <DetailList
        isHidden={!hasAccount}
        message="ACCOUNTS_SINGULAR"
        collapsible={collapsible}
        action={action}
        onAction={onAction}
        canEdit={canEdit}
    >
        <DetailItem
            icon="name"
            message={elem.name}
            tooltip="GENERAL_NAME"
        />
        <DetailItem
            icon="id"
            message={elem.externalID}
            tooltip="GENERAL_EXTERNAL_ID"
            prefix="GENERAL_CODE"
        />
        <FieldsItems
            fields={elem.visibleFields}
        />
        <DetailItem
            icon="time"
            message={elem.createdDate}
            tooltip="GENERAL_CREATED_TIME"
        />
    </DetailList>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
AccountItem.propTypes = {
    elem        : PropTypes.object.isRequired,
    collapsible : PropTypes.string,
    action      : PropTypes.string,
    canEdit     : PropTypes.bool,
    onAction    : PropTypes.func,
};

export default AccountItem;
