import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";

// Components
import IconLink             from "Dashboard/Components/Link/IconLink";



// Styles
const Container = Styled.div.attrs(({ isLast }) => ({ isLast }))`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: var(--inters-action);
    max-width: 100%;
    margin: 4px auto;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: calc(50% - 1px);
        border-left: 2px solid var(--border-color-light);
    }

    ${(props) => props.isLast && `
        height: 40px;
        align-items: flex-end;
    `}
`;

const Create = Styled(IconLink)`
    position: relative;
    background-color: white;
    z-index: 2;
`;



/**
 * The Integration Edge
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function IntegrationEdge(props) {
    const { isLast, onClick } = props;


    // Do the Render
    return <Container isLast={isLast}>
        <Create
            variant="black"
            icon="add"
            onClick={onClick}
        />
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
IntegrationEdge.propTypes = {
    position : PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
    isLast   : PropTypes.bool,
    onClick  : PropTypes.func.isRequired,
};

export default IntegrationEdge;
