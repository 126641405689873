import React                from "react";
import PropTypes            from "prop-types";
import Action               from "Dashboard/Core/Action";
import Store                from "Dashboard/Core/Store";
import useList              from "Dashboard/Hooks/List";
import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";

// Components
import DashboardReports     from "./View/DashboardReports";
import DashboardUsers       from "./View/DashboardUsers";
import DashboardFilter      from "./Edit/DashboardFilter";
import DashboardPersonalize from "./Edit/DashboardPersonalize";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionItem           from "Dashboard/Components/Header/ActionItem";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";



/**
 * The Dashboard Page
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function DashboardPage(props) {
    const { type } = props;

    const { dashboardTab } = Navigate.useParams();
    const navigate = Commons.useGoto();
    const clientID = Commons.useClientID();

    const { isAgent } = Store.useState("auth");
    const { loading, update, filters, initialFilter } = Store.useState("dashboard");
    const { fetch, loadTab } = useList("dashboard", type, false);


    // The References
    const timerRef = React.useRef(0);

    // The Current State
    const [ action, setAction ] = Action.useAction();


    // Handle the Auto-update
    React.useEffect(() => {
        Utils.setTimeout(timerRef, fetch, 20 * 1000);
        return () => {
            Utils.clearTimeout(timerRef);
        };
    }, [ update ]);

    // Handle the Tab
    React.useEffect(() => {
        if (!dashboardTab) {
            navigate("DASHBOARD", "REPORTS");
        } else {
            const tab = Navigate.isUrl("REPORTS", dashboardTab) ? "reports" : "users";
            loadTab(tab, 50, false);
        }
    }, [ dashboardTab ]);

    // Handles the Tab
    const handleTab = (url) => {
        navigate("DASHBOARD", url);
    };


    // Variables
    const withNavigation = type === "CLIENT";
    const isReports      = Navigate.isUrl("REPORTS", dashboardTab);
    const isUsers        = Navigate.isUrl("USERS", dashboardTab);
    const hasFilter      = !loading && !Utils.isEmpty(filters) && !Utils.areObjectsEqual(initialFilter, filters);


    // Do the Render
    return <Main withNavigation={withNavigation}>
        <Header message="DASHBOARD_NAME" icon="dashboard">
            <ActionList onAction={setAction}>
                <ActionItem action="FILTER" withMark={hasFilter} />
                <ActionItem action="PERSONALIZE" isHidden={isUsers} />
            </ActionList>
        </Header>

        <Content isLoading={loading} className="light-scrollbars">
            <TabList
                isHidden={isAgent}
                selected={dashboardTab || NLS.url("REPORTS")}
                onClick={handleTab}
            >
                <TabItem
                    message="DASHBOARD_REPORTS"
                    url="REPORTS"
                />
                <TabItem
                    message="USERS_NAME"
                    url="USERS"
                />
            </TabList>

            {isReports && <DashboardReports
                withNavigation={withNavigation}
            />}
            {isUsers && <DashboardUsers />}
        </Content>

        <DashboardFilter
            open={action.isFilter}
            initialData={initialFilter}
            clientID={clientID}
            onClose={setAction}
        />
        <DashboardPersonalize
            open={action.isPersonalize}
            clientID={clientID}
            onClose={setAction}
        />
    </Main>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
DashboardPage.propTypes = {
    type : PropTypes.string.isRequired,
};

export default DashboardPage;
