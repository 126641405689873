import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Dashboard/Core/NLS";

// Components
import ProviderButton       from "Components/Utils/Common/ProviderButton";

// Dashboard
import Html                 from "Dashboard/Components/Common/Html";
import InputField           from "Dashboard/Components/Form/InputField";
import Button               from "Dashboard/Components/Form/Button";



/**
 * The Telegram Button
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function TelegramButton(props) {
    const { isHidden, provider, onInstall } = props;


    // The Current State
    const [ token, setToken ] = React.useState("");


    // Handles the Install
    const handleContinue = () => {
        if (token) {
            onInstall(token);
        }
    };


    // Do the Render
    if (isHidden) {
        return <React.Fragment />;
    }
    return <>
        <ProviderButton
            logo={provider.logo}
            color={provider.color}
            message={provider.fullName}
        />

        <section>
            <p>{NLS.get("CHANNELS_TELEGRAM_BEFORE")}</p>
            <ul>
                <Html
                    variant="li"
                    message="CHANNELS_TELEGRAM_INFO1"
                />
                <Html
                    variant="li"
                    message="CHANNELS_TELEGRAM_INFO2"
                />
            </ul>
            <Html
                variant="p"
                message="CHANNELS_TELEGRAM_START"
            />
        </section>

        <InputField
            name="accessToken"
            label="CHANNELS_TELEGRAM_LABEL"
            value={token}
            onChange={(name, value) => setToken(value)}
            isRequired
        />
        <Button
            className="top-space"
            variant="outlined"
            message="GENERAL_CONTINUE"
            onClick={handleContinue}
            isDisabled={!token}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
TelegramButton.propTypes = {
    isHidden  : PropTypes.bool.isRequired,
    provider  : PropTypes.object.isRequired,
    onInstall : PropTypes.func.isRequired,
};

export default TelegramButton;
