import NLS                  from "Dashboard/Core/NLS";
import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";

// The API
import {
    IntegrationExecution,
} from "Utils/API";



// The initial State
const initialState = {
    loading       : true,
    error         : false,
    edition       : 0,
    canCreate     : false,
    canEdit       : false,
    canImport     : false,
    canExport     : false,
    list          : [],
    total         : 0,
    actionTypes   : [],
    errorMessages : [],
    filters       : {
        search   : "",
        fromDate : "",
        toDate   : "",
    },
    sort          : {
        orderBy  : "createdTime",
        orderAsc : 0,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "INTEGRATION_EXECUTION_LOADING" });
    },

    /**
     * Fetches the Integration Execution List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  filters
     * @param {Object=}  sort
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, filters = {}, sort = {}) {
        const params = { ...filters, ...sort };
        Navigate.unsetParams(params);
        if (type === "INTEGRATION") {
            params.integrationID = elemID;
        }
        const data = await IntegrationExecution.getAll(params);
        data.filters = filters;
        data.sort    = sort;
        dispatch({ type : "INTEGRATION_EXECUTION_LIST", data });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdDate     = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.isFailedText    = elem.isFailed ? NLS.get("GENERAL_FAIL") : NLS.get("GENERAL_SUCCESS");
    elem.isFailedClass   = elem.isFailed ? "text-red" : "text-green";
    elem.failedErrorText = NLS.get(elem.failedError);

    if (elem.failedAction) {
        elem.failedActionText = `${elem.failedStep}- ${NLS.get(elem.actionName)}`;
    }
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "INTEGRATION_EXECUTION_LIST")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "INTEGRATION_EXECUTION_LOADING":
        return {
            ...state,
            loading       : true,
        };

    case "INTEGRATION_EXECUTION_LIST":
        return {
            ...state,
            loading       : false,
            error         : false,
            canCreate     : action.data.canCreate,
            canEdit       : action.data.canEdit,
            canImport     : action.data.canImport,
            canExport     : action.data.canExport,
            list          : Utils.parseList(action.data.list, parseElem),
            total         : action.data.total,
            actionTypes   : action.data.actionTypes,
            errorMessages : action.data.errorMessages,
            filters       : action.data.filters,
            sort          : action.data.sort,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
