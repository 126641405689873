import React                from "react";
import PropTypes            from "prop-types";

// Dashboard
import InputField           from "Dashboard/Components/Form/InputField";
import Columns              from "Dashboard/Components/Form/Columns";



/**
 * The Campaign Main
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CampaignMain(props) {
    const { data, getOptionError, onChange } = props;


    // Do the Render
    return <Columns>
        <InputField
            className="columns-double"
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={getOptionError("name")}
            onChange={onChange}
            onInput={onChange}
            isRequired
        />
        <InputField
            className="columns-double"
            type="textarea"
            name="description"
            label="GENERAL_DESCRIPTION"
            value={data.description}
            error={getOptionError("description")}
            onChange={onChange}
            onInput={onChange}
            rows="2"
        />

        <InputField
            type="date"
            name="startTime"
            label="CAMPAIGNS_START_TIME"
            value={data.startTime}
            error={getOptionError("startTime")}
            onChange={onChange}
            isRequired
        />
        <InputField
            type="date"
            name="endTime"
            label="CAMPAIGNS_END_TIME"
            value={data.endTime}
            error={getOptionError("endTime")}
            onChange={onChange}
            hasClear
        />
    </Columns>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CampaignMain.propTypes = {
    data           : PropTypes.object.isRequired,
    getOptionError : PropTypes.func.isRequired,
    onChange       : PropTypes.func.isRequired,
};

export default CampaignMain;
