import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";

// Dashboard
import ViewDialog           from "Dashboard/Components/Dialogs/ViewDialog";
import ViewField            from "Dashboard/Components/Form/ViewField";



/**
 * The Widget Code Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function WidgetCode(props) {
    const { open, onClose } = props;

    const { elem } = Store.useState("widget");


    // Variables
    const link   = `${process.env.REACT_APP_CHAT_URL}${elem.hash}`;
    const url    = `${process.env.REACT_APP_CHAT}chat.js?${elem.hash}`;
    const script = `<script async src="${url}" id="conversana"></script>`;


    // Do the Render
    return <ViewDialog
        open={open}
        icon="code"
        title="GENERAL_GET_CODE"
        onClose={onClose}
        withSpacing
    >
        <ViewField
            label="GENERAL_LINK"
            value={link}
            hasCopy
        />
        <ViewField
            label="GENERAL_CODE"
            value={script}
            hasCopy
        />
    </ViewDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
WidgetCode.propTypes = {
    open    : PropTypes.bool.isRequired,
    onClose : PropTypes.func.isRequired,
};

export default WidgetCode;
