// The Provider Colors
const WHATSAPP = "rgb(37, 211, 102)";
const SMS      = "rgb(12, 157, 88)";
const EMAIL    = "rgb(23, 109, 194)";



// The Provider Colors
const colors = {
    WHATSAPP,
    SMS,
    EMAIL,
};

export default colors;
