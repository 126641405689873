import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";

// Components
import ColorItem            from "./ColorItem";



// Styles
const List = Styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
`;



/**
 * The Color List
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ColorList(props) {
    const { list } = props;


    // Do the Render
    return <List>
        {list.map((elem, key) => <ColorItem
            key={key}
            color={elem.color}
            message={elem.name}
        />)}
    </List>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ColorList.propTypes = {
    list : PropTypes.array,
};

export default ColorList;
