import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Colors               from "Utils/Colors";

// Components
import ProviderButton       from "Components/Utils/Common/ProviderButton";
import CampaignEmail        from "./CampaignEmail";
import CampaignSMS          from "./CampaignSMS";
import CampaignWhatsApp     from "./CampaignWhatsApp";

// Dashboard
import Alert                from "Dashboard/Components/Form/Alert";



// Styles
const Container = Styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

const Channels = Styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: var(--main-gap);
    margin-bottom: 12px;
`;



/**
 * The Campaign Channels
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CampaignChannels(props) {
    const { data, getSectionError, getOptionError, onChange } = props;

    const { hasPhoneNumbers } = Store.useState("permission");

    const { channel } = Store.useState("campaignState");
    const { setChannel } = Store.useAction("campaignState");


    // Variables
    const showEmail    = Boolean(channel === "email");
    const showSMS      = Boolean(channel === "sms"      && hasPhoneNumbers);
    const showWhatsApp = Boolean(channel === "whatsApp");


    // Do the Render
    return <Container>
        <Alert variant="error" message={getOptionError("channelsForm")} />

        <Channels>
            <ProviderButton
                onClick={() => setChannel("email")}
                color={Colors.EMAIL}
                logo="Email"
                message="CAMPAIGNS_EMAIL_TITLE"
                isSelected={showEmail}
                errorCount={getSectionError("email")}
            />
            <ProviderButton
                isHidden={!hasPhoneNumbers}
                onClick={() => setChannel("sms")}
                color={Colors.SMS}
                logo="SMS"
                message="CAMPAIGNS_SMS_TITLE"
                isSelected={showSMS}
                errorCount={getSectionError("sms")}
            />
            <ProviderButton
                onClick={() => setChannel("whatsApp")}
                color={Colors.WHATSAPP}
                logo="WhatsApp"
                message="CAMPAIGNS_WHATSAPP_TITLE"
                isSelected={showWhatsApp}
                errorCount={getSectionError("whatsApp")}
            />
        </Channels>

        {showEmail && <CampaignEmail
            data={data}
            getOptionError={getOptionError}
            onChange={onChange}
        />}
        {showSMS && <CampaignSMS
            data={data}
            getOptionError={getOptionError}
            onChange={onChange}
        />}
        {showWhatsApp && <CampaignWhatsApp
            data={data}
            getOptionError={getOptionError}
            onChange={onChange}
        />}
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CampaignChannels.propTypes = {
    data            : PropTypes.object.isRequired,
    getSectionError : PropTypes.func.isRequired,
    getOptionError  : PropTypes.func.isRequired,
    onChange        : PropTypes.func.isRequired,
};

export default CampaignChannels;
