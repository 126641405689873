import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import { TicketType }       from "Utils/API";



// The initial State
const initialState = {
    loading     : true,
    error       : false,
    edition     : 0,
    canCreate   : false,
    canEdit     : false,
    canImport   : false,
    canExport   : false,
    list        : [],
    total       : 0,
    elem        : {},
    credentials : [],
    sort        : {
        orderBy  : "position",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "TICKET_TYPE_LOADING" });
    },

    /**
     * Fetches the Ticket Type List
     * @param {Function} dispatch
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, params = {}) {
        const data = await TicketType.getAll(params);
        data.sort = params;
        dispatch({ type : "TICKET_TYPE_LIST", data });
    },

    /**
     * Fetches a single Ticket Type
     * @param {Function} dispatch
     * @param {Number}   ticketTypeID
     * @returns {Promise}
     */
    async fetchElem(dispatch, ticketTypeID) {
        const data = await TicketType.getOne({ ticketTypeID });
        dispatch({ type : "TICKET_TYPE_ELEM", data });
    },

    /**
     * Fetches the Ticket Type Edit data
     * @param {Function} dispatch
     * @returns {Promise}
     */
    async fetchEditData(dispatch) {
        const data = await TicketType.getEditData();
        dispatch({ type : "TICKET_TYPE_EDIT", data });
    },

    /**
     * Edits a Ticket Type
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editTicketType(dispatch, data) {
        return TicketType.edit(data);
    },

    /**
     * Deletes a Ticket Type
     * @param {Function} dispatch
     * @param {Number}   ticketTypeID
     * @returns {Promise}
     */
    deleteTicketType(dispatch, ticketTypeID) {
        return TicketType.delete({ ticketTypeID });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.credentialName = elem.credentialName || NLS.get("GENERAL_NOBODY");
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "TICKET_TYPE_LIST", "TICKET_TYPE_ELEM", "TICKET_TYPE_EDIT")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "TICKET_TYPE_LOADING":
        return {
            ...state,
            loading     : true,
        };

    case "TICKET_TYPE_LIST":
        return {
            ...state,
            loading     : false,
            error       : false,
            canCreate   : action.data.canCreate,
            canEdit     : action.data.canEdit,
            canImport   : action.data.canImport,
            canExport   : action.data.canExport,
            list        : Utils.parseList(action.data.list, parseElem),
            total       : action.data.total,
            sort        : action.data.sort,
        };

    case "TICKET_TYPE_ELEM":
        return {
            ...state,
            error       : false,
            edition     : state.edition + 1,
            elem        : parseElem(action.data.elem),
            credentials : action.data.credentials,
        };

    case "TICKET_TYPE_EDIT":
        return {
            ...state,
            error       : false,
            edition     : state.edition + 1,
            credentials : action.data.credentials,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
