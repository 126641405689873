import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";

// Dashboard
import Columns              from "Dashboard/Components/Form/Columns";



// Styles
const Title = Styled.h3`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 16px 0;
`;

const Content = Styled(Columns)`
    @media screen and (max-width: 1200px) {
        grid-template-columns: 1fr;

        .columns-double {
            grid-column-end: span 1;
        }
    }
`;



/**
 * The Widget Section
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function WidgetSection(props) {
    const { isHidden, title, button, withColumns, lastDouble, children } = props;


    // Do the Render
    if (isHidden) {
        return <React.Fragment />;
    }
    return <section>
        <Title>
            {NLS.get(title)}
            {button}
        </Title>
        {withColumns ? <Content lastDouble={lastDouble}>
            {children}
        </Content> : children}
    </section>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
WidgetSection.propTypes = {
    isHidden    : PropTypes.bool,
    title       : PropTypes.string.isRequired,
    button      : PropTypes.any,
    withColumns : PropTypes.bool,
    lastDouble  : PropTypes.bool,
    children    : PropTypes.any,
};

/**
 * The Default Properties
 * @type {Object} defaultProps
 */
WidgetSection.defaultProps = {
    withColumns : true,
};

export default WidgetSection;
