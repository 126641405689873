import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";

// Components
import MessageReply         from "./MessageReply";
import MessageMedia         from "./MessageMedia";
import MessageStatus        from "./MessageStatus";

// Dashboard
import IconLink             from "Dashboard/Components/Link/IconLink";
import Html                 from "Dashboard/Components/Common/Html";



// Styles
const Container = Styled.div.attrs(({ isMine, padding }) => ({ isMine, padding }))`
    --bubble-padding: ${(props) => props.padding + 12}px;
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
    max-width: min(calc(680px - var(--bubble-padding)), calc(100% - var(--bubble-padding)));
    margin-bottom: 4px;

    ${(props) => props.isMine ? `
        --bubble-background: var(--bubble-mine-bg, #1f85ff);
        --bubble-color: var(--bubble-mine-color, white);

        flex-direction: row-reverse;
        padding-left: var(--bubble-padding);

        &:last-child .bubble::before {
            content: "";
            position: absolute;
            z-index: 0;
            bottom: 0;
            right: -8px;
            height: 20px;
            width: 20px;
            background: var(--bubble-background);
            border-bottom-left-radius: 15px;
        }
        &:last-child .bubble::after {
            content: "";
            position: absolute;
            z-index: 1;
            bottom: 0;
            right: -10px;
            width: 10px;
            height: 21px;
            background: white;
            border-bottom-left-radius: 10px;
        }
    ` : `
        --bubble-background: var(--bubble-your-bg, #e2e3e4);
        --bubble-color: var(--bubble-your-color, var(--black-color));

        flex-direction: row;
        padding-right: var(--bubble-padding);

        &:last-child .bubble::before {
            content: "";
            position: absolute;
            z-index: 0;
            bottom: 0;
            left: -7px;
            height: 20px;
            width: 20px;
            background: var(--bubble-background);
            border-bottom-right-radius: 15px;
        }
        &:last-child .bubble::after {
            content: "";
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: -10px;
            width: 10px;
            height: 20px;
            background: white;
            border-bottom-right-radius: 10px;
        }
    `}

    &:first-child {
        margin-top: 0;
    }
    &:last-child {
        margin-bottom: 0;
    }

    &:hover {
        --bubble-padding: 12px;
    }
    &:hover .bubble-action {
        display: block;
    }
`;

const Content = Styled.div.attrs(({ isMine }) => ({ isMine }))`
    overflow: hidden;
    ${(props) => props.isMine ? "padding-right: 12px" : "padding-left: 12px"};
`;

const Inner = Styled.div.attrs(({ withSpacing }) => ({ withSpacing }))`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px 12px;
    border-radius: 16px;
    min-width: ${(props) => props.withSpacing ? "80px" : "40px"};;
    min-height: 17px;
    background-color: var(--bubble-background);
    color: var(--bubble-color);
`;

const Text = Styled(Html).attrs(({ hasIcon, withSpacing, useBigFont }) => ({ hasIcon, withSpacing, useBigFont }))`
    --text-space: ${(props) => props.hasIcon ? "48px" : "32px"};
    ${(props) => props.withSpacing && "margin-right: var(--text-space)"};
    ${(props) => props.useBigFont && "font-size: 32px;"};

    overflow: hidden;
    text-overflow: ellipsis;

    a {
        color: var(--bubble-color);
    }
`;

const Footer = Styled.footer.attrs(({ isMine, withSpacing }) => ({ isMine, withSpacing }))`
    font-size: 11px;
    color: ${(props) => props.isMine ? "rgba(255, 255, 255, 0.8)" : "rgba(0, 0, 0, 0.5)"};

    ${(props) => props.withSpacing ? `
        position: absolute;
        bottom: 4px;
        right: 8px;
    ` : `
        text-align: right;
        margin-top: -10px;
        transform: translate(2px, 4px);
    `}
`;

const Options = Styled.ol`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2px;
    list-style: none;
    margin: 2px 0 0 0;
    padding: 0;
`;

const Option = Styled.li.attrs(({ isMine }) => ({ isMine }))`
    box-sizing: border-box;
    display: block;
    padding: 8px 12px;
    border-radius: 16px;
    min-width: calc(50% - 2px);
    flex-grow: 2;
    background-color: var(--bubble-background);
    color: var(--bubble-color);
    line-height: 1;
    text-align: center;
    text-decoration: underline;

    ${(props) => props.isMine ? `
        filter: brightness(115%);
    ` : `
        transition: all 0.2s;
        filter: brightness(105%);
        cursor: pointer;
        &:hover {
            filter: brightness(95%);
        }
    `}
`;

const Reactions = Styled.ul.attrs(({ isMine, amount }) => ({ isMine, amount }))`
    position: relative;
    top: -4px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    list-style: none;
    width: ${(props) => props.amount * 20 + (props.amount - 1) * 4 + 8}px;
    min-width: 20px;
    height: 26px;
    margin: 0 auto;
    padding: 0 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 9999px;
    font-size: 16px;
    background-color: var(--light-gray);
    ${(props) => props.isMine ? "margin-right: 12px;" : "margin-left: 12px;"}
`;

const ActionIcon = Styled(IconLink)`
    display: none;
    background-color: var(--light-gray);
`;



/**
 * The Message Bubble
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function MessageBubble(props) {
    const {
        className, item, id, isMine, message, file, options,
        time, isDelivered, hideStatus,
        canReplyTo, onReply, canForwardTo, onForward,
        canReactTo, onReaction, onOption,
    } = props;


    // Variables
    const text         = message || item.message || "";
    const date         = time    || DateTime.formatDate(item.createdTime, "time");

    const hasText      = !!text;
    const hasReactions = Boolean(item.reactions && item.reactions.length);
    const buttons      = options || item.buttons || [];
    const hasButtons   = Boolean(buttons && buttons.length);
    const files        = file ? [ file ] : (item.files || []);
    const reactionRef  = React.useRef(null);
    const canForward   = canForwardTo && !!text;
    const padding      = ((canReplyTo ? 1 : 0) + (canForward ? 1 : 0) + (canReactTo ? 1 : 0)) * (24 + 8);
    const useBigFont   = Boolean(!files.length && hasText && Utils.isEmojiOnly(text) && text.length <= 8);
    const withSpacing  = Boolean(!useBigFont && !item.replyToID && !files.length && text.length < 35);


    // Handles the Reply
    const handleReply = () => {
        if (onReply) {
            if (!text && item.file.fileText) {
                onReply(item.id, item.file.fileText);
            } else {
                onReply(item.id, text);
            }
        }
    };

    // Handles the Forward
    const handleForward = () => {
        if (onForward && text) {
            onForward(item.id, text);
        }
    };

    // Handles the Reaction
    const handleReaction = () => {
        if (onReaction && reactionRef.current) {
            onReaction(item.id, Utils.getBounds(reactionRef));
        }
    };

    // Handles the Option
    const handleOption = (payload, message) => {
        if (onOption) {
            onOption(payload, message, item.messageID);
        }
    };



    // Do the Render
    return <Container
        id={id}
        className={className}
        isMine={isMine}
        padding={padding}
    >
        <Content className="bubble-content" isMine={isMine}>
            <Inner className="bubble" withSpacing={withSpacing}>
                <MessageReply item={item} />

                {files.map((file) => <MessageMedia
                    key={file.fileID}
                    media={file}
                />)}
                {hasText && <Text
                    hasIcon={isMine}
                    withSpacing={withSpacing}
                    useBigFont={useBigFont}
                    content={text}
                    addLinks
                    addBreaks
                    formatText
                />}

                <Footer isMine={isMine} withSpacing={withSpacing}>
                    <span>{date}</span>
                    <MessageStatus
                        isHidden={!isMine || hideStatus}
                        isDelivered={isDelivered}
                        item={item}
                    />
                </Footer>
            </Inner>

            {hasReactions && <Reactions
                isMine={isMine}
                amount={item.reactions.length}
            >
                {item.reactions.map(({ id, reaction }) => <li key={id}>
                    {reaction}
                </li>)}
            </Reactions>}

            {hasButtons && <Options>
                {buttons.map(({ payload, message }) => <Option
                    key={payload}
                    isMine={isMine}
                    onClick={() => handleOption(payload, message)}
                >
                    {message}
                </Option>)}
            </Options>}
        </Content>

        {canReplyTo && <ActionIcon
            className="bubble-action"
            variant="black"
            icon="reply"
            tooltip="CONVERSATIONS_REPLY_TO"
            onClick={handleReply}
            isSmall
        />}
        {canForward && <ActionIcon
            passedRef={reactionRef}
            className="bubble-action"
            variant="black"
            icon="forward"
            tooltip="CONVERSATIONS_FORWARD_TO"
            onClick={handleForward}
            isSmall
        />}
        {canReactTo && <ActionIcon
            passedRef={reactionRef}
            className="bubble-action"
            variant="black"
            icon="emoji"
            tooltip="CONVERSATIONS_REACT_TO"
            onClick={handleReaction}
            isSmall
        />}
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
MessageBubble.propTypes = {
    className    : PropTypes.string,
    item         : PropTypes.object,
    id           : PropTypes.string,
    isMine       : PropTypes.bool,
    message      : PropTypes.string,
    options      : PropTypes.array,
    file         : PropTypes.object,
    time         : PropTypes.string,
    isDelivered  : PropTypes.bool,
    hideStatus   : PropTypes.bool,
    canReplyTo   : PropTypes.bool,
    onReply      : PropTypes.func,
    canForwardTo : PropTypes.bool,
    onForward    : PropTypes.func,
    canReactTo   : PropTypes.bool,
    onReaction   : PropTypes.func,
    onOption     : PropTypes.func,
};

/**
 * The Default Properties
 * @type {Object} defaultProps
 */
MessageBubble.defaultProps = {
    item        : {},
    isDelivered : false,
    isMine      : false,
    hideStatus  : false,
};

export default MessageBubble;
