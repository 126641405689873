import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Custom Field Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CustomFieldEdit(props) {
    const { open, elemID, clientID, module, onClose, onSubmit } = props;

    const { editField } = Store.useAction("customField");


    // The Initial Data
    const initialData = {
        customFieldID  : 0,
        clientID       : 0,
        module         : "",
        name           : "",
        inputType      : "",
        options        : [ "" ],
        fileTypes      : "",
        minValue       : "",
        maxValue       : "",
        minuteInterval : "",
        isHidden       : 0,
        isRequired     : 0,
        position       : 0,
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("customField", initialData, editField, onSubmit);

    // Load the Data
    const { loading } = useDialog("customField", open, elemID, { clientID, module }, setElem);



    // Do the Render
    return <EditDialog
        open={open}
        icon="field"
        title={elemID ? "CUSTOM_FIELDS_EDIT_TITLE" : "CUSTOM_FIELDS_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="select"
            name="inputType"
            label="GENERAL_TYPE"
            options="SELECT_INPUT_TYPES"
            value={data.inputType}
            error={errors.inputType}
            onChange={handleChange}
            isRequired
        />

        <InputField
            isHidden={data.inputType !== "select"}
            type="list"
            name="options"
            label="GENERAL_OPTIONS"
            addButton="GENERAL_ADD_OPTION"
            value={data.options}
            error={errors.options}
            onChange={handleChange}
            isSortable
            isRequired
        />
        <InputField
            isHidden={data.inputType !== "file"}
            type="chooser"
            name="fileTypes"
            label="CUSTOM_FIELDS_FILE_TYPES"
            options="SELECT_FILE_TYPES"
            value={data.fileTypes}
            error={errors.fileTypes}
            onChange={handleChange}
        />
        <InputField
            isHidden={data.inputType !== "time"}
            type="number"
            name="minuteInterval"
            label="CUSTOM_FIELDS_MINUTE_INTERVAL"
            value={data.minuteInterval}
            error={errors.minuteInterval}
            onChange={handleChange}
        />

        <Columns>
            <InputField
                isHidden={data.inputType !== "number"}
                type="number"
                name="minValue"
                label="CUSTOM_FIELDS_MIN_VALUE"
                value={data.minValue}
                error={errors.minValue}
                onChange={handleChange}
            />
            <InputField
                isHidden={data.inputType !== "number"}
                type="number"
                name="maxValue"
                label="CUSTOM_FIELDS_MAX_VALUE"
                value={data.maxValue}
                error={errors.maxValue}
                onChange={handleChange}
            />

            <InputField
                isHidden={data.inputType !== "time"}
                type="number"
                name="minValue"
                label="CUSTOM_FIELDS_MIN_HOUR"
                value={data.minValue}
                error={errors.minValue}
                onChange={handleChange}
            />
            <InputField
                isHidden={data.inputType !== "time"}
                type="number"
                name="maxValue"
                label="CUSTOM_FIELDS_MAX_HOUR"
                value={data.maxValue}
                error={errors.maxValue}
                onChange={handleChange}
            />

            <InputField
                type="toggle"
                name="isHidden"
                label="CUSTOM_FIELDS_IS_HIDDEN"
                value={data.isHidden}
                error={errors.isHidden}
                onChange={handleChange}
                withBorder
            />
            <InputField
                type="toggle"
                name="isRequired"
                label="CUSTOM_FIELDS_IS_REQUIRED"
                value={data.isRequired}
                error={errors.isRequired}
                onChange={handleChange}
                withBorder
            />
        </Columns>
        <InputField
            type="number"
            name="position"
            label="GENERAL_POSITION"
            value={data.position}
            error={errors.position}
            onChange={handleChange}
            minValue={0}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CustomFieldEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
    clientID : PropTypes.number,
    module   : PropTypes.string.isRequired,
};

export default CustomFieldEdit;
