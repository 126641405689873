import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import { NotificationLog }  from "Utils/API";



// The initial State
const initialState = {
    loading   : false,
    error     : false,
    edition   : 0,
    canCreate : false,
    canEdit   : false,
    canImport : false,
    canExport : false,
    list      : [],
    total     : 0,
    elem      : {},
    filters   : {
        search   : "",
        fromDate : "",
        toDate   : "",
    },
    sort      : {
        orderBy  : "createdTime",
        orderAsc : 0,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "NOTIFICATION_LOADING" });
    },

    /**
     * Fetches the Notification List
     * @param {Function} dispatch
     * @param {Object=}  filters
     * @param {Object=}  sort
     * @returns {Promise}
     */
    async fetchList(dispatch, filters = {}, sort = {}) {
        const data   = await NotificationLog.getAll({ ...filters, ...sort });
        data.filters = filters;
        data.sort    = sort;
        dispatch({ type : "NOTIFICATION_LIST", data });
    },

    /**
     * Fetches a single Notification Element
     * @param {Function} dispatch
     * @param {Number}   notificationID
     * @returns {Promise}
     */
    async fetchElem(dispatch, notificationID) {
        const data = await NotificationLog.getOne({ notificationID });
        dispatch({ type : "NOTIFICATION_ELEM", data });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdDate     = DateTime.formatDate(elem.createdTime, "dashes");
    elem.createdDateTime = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.sentDate        = DateTime.formatIf(elem.sentTime, "dashes");
    elem.sentDateTime    = DateTime.formatIf(elem.sentTime, "dashesTime");
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "NOTIFICATION_LIST", "NOTIFICATION_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "NOTIFICATION_LOADING":
        return {
            ...state,
            loading   : true,
        };

    case "NOTIFICATION_LIST":
        return {
            ...state,
            loading   : false,
            error     : false,
            canCreate : action.data.canCreate,
            canEdit   : action.data.canEdit,
            canImport : action.data.canImport,
            canExport : action.data.canExport,
            list      : Utils.parseList(action.data.list, parseElem),
            total     : action.data.total,
            filters   : action.data.filters,
            sort      : action.data.sort,
        };

    case "NOTIFICATION_ELEM":
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            elem      : parseElem(action.data.elem),
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
