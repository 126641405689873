import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";

// Dashboard
import PageContainer        from "Dashboard/Components/Page/PageContainer";
import PageAccordion        from "Dashboard/Components/Page/PageAccordion";
import PageButtons          from "Dashboard/Components/Page/PageButtons";
import AccordionItem        from "Dashboard/Components/Accordion/AccordionItem";
import InputField           from "Dashboard/Components/Form/InputField";
import InputItem            from "Dashboard/Components/Form/InputItem";



/**
 * The Schedule Editor
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ScheduleEditor(props) {
    const {
        loading, isEdit, data, errors, getErrorCount,
        onChange, onSubmit, onDelete, onClose,
    } = props;

    const { teams } = Store.useState("schedule");


    // Do the Render
    return <PageContainer error={errors.form}>
        <PageAccordion initial="main" noClose>
            <AccordionItem
                value="main"
                message="GENERAL_MAIN"
                description="SCHEDULES_MAIN_TEXT"
                errorCount={getErrorCount("main")}
                withGap
            >
                <InputField
                    name="name"
                    label="GENERAL_NAME"
                    value={data.name}
                    error={errors.name}
                    onChange={onChange}
                    isRequired
                />
                <InputField
                    type="select"
                    name="timeZone"
                    label="GENERAL_TIME_ZONE"
                    options={NLS.get("DATE_TIME_ZONES")}
                    value={data.timeZone}
                    error={errors.timeZone}
                    onChange={onChange}
                    isRequired
                />
                <InputField
                    type="toggle"
                    name="showTimeZone"
                    label="SCHEDULES_SHOW_TIME_ZONE"
                    value={data.showTimeZone}
                    onChange={onChange}
                    withBorder
                />
                <InputField
                    type="toggle"
                    name="isDefault"
                    label="GENERAL_IS_DEFAULT"
                    value={data.isDefault}
                    onChange={onChange}
                    withBorder
                />
            </AccordionItem>

            <AccordionItem
                value="periods"
                message="SCHEDULES_PERIODS_TITLE"
                description="SCHEDULES_PERIODS_TEXT"
                errorCount={getErrorCount("periods")}
                withGap
            >
                <InputField
                    type="fields"
                    name="periods"
                    title="SCHEDULES_PERIOD_TITLE"
                    addButton="SCHEDULES_ADD_PERIOD"
                    value={data.periods}
                    errors={errors}
                    onChange={onChange}
                    columns="2"
                    isRequired
                >
                    <InputItem
                        type="chooser"
                        name="days"
                        label="GENERAL_DAYS"
                        options="DATE_DAY_NAMES"
                        columns="2"
                        isRequired
                    />
                    <InputItem
                        type="time"
                        name="from"
                        label="GENERAL_FROM"
                        isRequired
                    />
                    <InputItem
                        type="time"
                        name="to"
                        label="GENERAL_TO"
                        isRequired
                    />
                </InputField>
            </AccordionItem>

            <AccordionItem
                value="assigns"
                message="SCHEDULES_ASSIGNS_TITLE"
                description="SCHEDULES_ASSIGNS_TEXT"
                errorCount={getErrorCount("assigns")}
                withGap
            >
                <InputField
                    type="multiple"
                    name="teams"
                    label="TEAMS_NAME"
                    options={teams}
                    value={data.teams}
                    error={errors.teams}
                    onChange={onChange}
                />
            </AccordionItem>
        </PageAccordion>

        <PageButtons
            isEdit={isEdit}
            isDisabled={loading}
            onSubmit={onSubmit}
            onDelete={onDelete}
            onClose={onClose}
        />
    </PageContainer>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ScheduleEditor.propTypes = {
    loading       : PropTypes.bool.isRequired,
    isEdit        : PropTypes.bool.isRequired,
    data          : PropTypes.object.isRequired,
    errors        : PropTypes.object.isRequired,
    getErrorCount : PropTypes.func.isRequired,
    onChange      : PropTypes.func.isRequired,
    onSubmit      : PropTypes.func.isRequired,
    onDelete      : PropTypes.func.isRequired,
    onClose       : PropTypes.func.isRequired,
};

export default ScheduleEditor;
