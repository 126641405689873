import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";
import DateTime             from "Dashboard/Utils/DateTime";

// Components
import NoteEdit             from "./NoteEdit";
import MessageMedia         from "../Message/MessageMedia";

// Dashboard
import Html                 from "Dashboard/Components/Common/Html";
import IconLink             from "Dashboard/Components/Link/IconLink";



// Styles
const Container = Styled.div`
    position: relative;
    margin: 0 16px;

    :hover .note-actions {
        display: block;
    }
`;

const Author = Styled.h4`
    margin: 0;
    padding: 2px 12px;
    font-weight: normal;
`;

const Note = Styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px 16px;
    border: 1px solid var(--border-color-light);
    border-radius: var(--border-radius);
`;

const Date = Styled.p`
    position: absolute;
    bottom: 6px;
    right: 6px;
    margin: 0;
    font-size: 11px;
    color: rgba(0, 0, 0, 0.5);
`;

const ActionIcon = Styled(IconLink)`
    display: none;
    position: absolute;
    top: 2px;
    right: 2px;
    background-color: var(--light-gray);
`;



/**
 * The Note List
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function NoteList(props) {
    const { list } = props;

    // The Current State
    const [ showEdit, setShowEdit ] = React.useState(false);
    const [ noteID,   setNoteID   ] = React.useState(0);

    // Handles the Edit Open
    const handleOpen = (noteID) => {
        setShowEdit(true);
        setNoteID(noteID);
    };

    // Handles the Edit Close
    const handleClose = () => {
        setShowEdit(false);
        setNoteID(0);
    };



    // Do the Render
    return <>
        {list.map((elem) => <Container key={elem.id}>
            <Author>{elem.credentialName || NLS.get("CONVERSATIONS_BOT")}</Author>
            <Note>
                <Html
                    content={elem.message}
                    addBreaks
                    formatText
                />
                {!!elem.media && <MessageMedia
                    media={elem.media}
                />}
                <Date>{DateTime.formatDate(elem.createdTime, "time")}</Date>
                <ActionIcon
                    className="note-actions"
                    variant="black"
                    icon="edit"
                    onClick={() => handleOpen(elem.id)}
                    isSmall
                />
            </Note>
        </Container>)}

        <NoteEdit
            open={showEdit}
            elemID={noteID}
            onClose={handleClose}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
NoteList.propTypes = {
    list : PropTypes.array.isRequired,
};

export default NoteList;
