import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";



// Styles
const Item = Styled.div.attrs(({ color, useBlack }) => ({ color, useBlack }))`
    padding: 3px 6px;
    color: ${(props) => props.useBlack ? "var(--color-black)" : "white"};
    font-size: 12px;
    border-radius: var(--border-radius-small);
    background-color: ${(props) => props.color};
`;



/**
 * The Color item
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ColorItem(props) {
    const { color, message } = props;

    const finalColor = color || "#f2f2f2";
    const useBlack   = [ "#f2f2f2" ].includes(finalColor);


    // Do the Render
    return <Item
        color={finalColor}
        useBlack={useBlack}
    >{NLS.get(message)}</Item>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ColorItem.propTypes = {
    color   : PropTypes.string,
    message : PropTypes.string,
};

export default ColorItem;
