import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";
import Plans                from "Utils/Plans";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Plan Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function PlanEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { currencies } = Store.useState("plan");
    const { editPlan } = Store.useAction("plan");


    // The Initial Data
    const initialData = {
        planID        : 0,
        planType      : "",
        name          : "",
        description   : "",
        contactAmount : "",
        prices        : {},
        position      : 0,
        observations  : "",
        isDefault     : 0,
    };

    // Handles the Edit
    const handleEdit = (data) => {
        return editPlan({ ...data, prices : JSON.stringify(data.prices) });
    };

    // Handles the Price
    const handlePrice = (currencyID, name, value) => {
        const elem   = { ...data.prices[currencyID], [name] : value };
        const prices = { ...data.prices, [currencyID] : elem };
        handleChange("prices", prices);
    };


    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("plan", initialData, handleEdit, onSubmit);

    // Load the Data
    const { loading } = useDialog("plan", open, elemID, null, setElem);


    // Variables
    const isContact = Plans.isContact(data.planType);
    const prices    = Plans.getPriceData(data.planType);


    // Returns the Price Items
    const items = React.useMemo(() => {
        const result = [];
        for (const price of prices) {
            for (const currency of currencies) {
                result.push({ ...price, ...currency });
            }
        }
        return result;
    }, [ JSON.stringify(prices), JSON.stringify(currencies) ]);



    // Do the Render
    return <EditDialog
        open={open}
        icon="plan"
        title={elemID ? "PLANS_EDIT_TITLE" : "PLANS_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            onChange={handleChange}
            error={errors.name}
            isRequired
        />
        <InputField
            type="select"
            name="planType"
            label="GENERAL_TYPE"
            options="SELECT_PLAN_TYPES"
            value={data.planType}
            onChange={handleChange}
            error={errors.planType}
            isRequired
        />
        <InputField
            type="textarea"
            name="description"
            label="GENERAL_DESCRIPTION"
            value={data.description}
            error={errors.description}
            onChange={handleChange}
        />
        <InputField
            isHidden={!isContact}
            type="number"
            name="contactAmount"
            label="PLANS_CONTACT_AMOUNT"
            value={data.contactAmount}
            error={errors.contactAmount}
            onChange={handleChange}
            isRequired
        />

        <Columns lastDouble>
            {items.map((item) => <InputField
                key={`${item.priceKey}-${item.key}`}
                isHidden={item.isHidden}
                type="number"
                name={`${item.priceKey}-${item.key}`}
                icon="money"
                label={NLS.format(item.message, item.value)}
                value={data.prices[item.key]?.[item.priceKey] || 0}
                error={errors[`${item.priceKey}-${item.key}`]}
                onChange={(name, value) => handlePrice(item.key, item.priceKey, value)}
            />)}
        </Columns>

        <InputField
            type="textarea"
            name="observations"
            label="GENERAL_OBSERVATIONS"
            value={data.observations}
            onChange={handleChange}
        />
        <InputField
            type="toggle"
            name="isDefault"
            label="GENERAL_IS_DEFAULT"
            value={data.isDefault}
            onChange={handleChange}
            withBorder
        />
        <InputField
            type="number"
            name="position"
            label="GENERAL_POSITION"
            value={data.position}
            error={errors.position}
            onChange={handleChange}
            minValue={0}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
PlanEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default PlanEdit;
