import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Plans                from "Utils/Plans";

// Dashboard
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";
import InputItem            from "Dashboard/Components/Form/InputItem";



/**
 * The Client Edit Plan
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ClientEditPlan(props) {
    const { isHidden, data, errors, onChange } = props;

    const { plans, currencies } = Store.useState("client");


    // Variables
    const planType  = Utils.getValue(plans, "key", data.planID, "planType");
    const isHotel   = Plans.isHotel(planType);
    const isContact = Plans.isContact(planType);


    // Do the Render
    return <Columns isHidden={isHidden}>
        <InputField
            type="select"
            name="planID"
            label="CLIENTS_PLAN"
            options={plans}
            value={data.planID}
            error={errors.planID}
            onChange={onChange}
            isRequired
        />
        <InputField
            isHidden={currencies.length < 2}
            type="select"
            name="currencyID"
            label="CURRENCIES_SINGULAR"
            options={currencies}
            value={data.currencyID}
            error={errors.currencyID}
            onChange={onChange}
            isRequired
        />

        <InputField
            isHidden={!isHotel}
            type="number"
            name="roomAmount"
            label="PLANS_ROOM_AMOUNT"
            value={data.roomAmount}
            error={errors.roomAmount}
            onChange={onChange}
        />
        <InputField
            isHidden={!isHotel}
            type="double"
            label="PLANS_ROOM_PRICE"
            helperText="CLIENTS_PLAN_VALUE_TIP"
            error={errors.roomPrice}
            onChange={onChange}
        >
            <InputItem
                type="checkbox"
                name="roomPriceFree"
                label="GENERAL_FREE"
                value={data.roomPriceFree}
            />
            <InputItem
                type="number"
                name="roomPrice"
                icon="money"
                value={data.roomPrice}
                minValue={0}
            />
        </InputField>

        <InputField
            isHidden={!isContact}
            type="number"
            name="contactAmount"
            label="PLANS_CONTACT_AMOUNT"
            helperText="CLIENTS_PLAN_VALUE_TIP"
            value={data.contactAmount}
            error={errors.contactAmount}
            onChange={onChange}
        />
        <InputField
            isHidden={!isContact}
            type="double"
            label="PLANS_PRICE"
            helperText="CLIENTS_PLAN_VALUE_TIP"
            error={errors.planPrice}
            onChange={onChange}
        >
            <InputItem
                type="checkbox"
                name="planPriceFree"
                label="GENERAL_FREE"
                value={data.planPriceFree}
            />
            <InputItem
                type="number"
                name="planPrice"
                icon="money"
                value={data.planPrice}
                minValue={0}
            />
        </InputField>

        <InputField
            isHidden={!isContact}
            type="double"
            label="PLANS_CONTACT_PRICE"
            helperText="CLIENTS_PLAN_VALUE_TIP"
            error={errors.contactPrice}
            onChange={onChange}
        >
            <InputItem
                type="checkbox"
                name="contactPriceFree"
                label="GENERAL_FREE"
                value={data.contactPriceFree}
            />
            <InputItem
                type="number"
                name="contactPrice"
                icon="money"
                value={data.contactPrice}
                minValue={0}
            />
        </InputField>

        <InputField
            type="double"
            label="PLANS_SETUP_PRICE"
            helperText="CLIENTS_PLAN_VALUE_TIP"
            error={errors.setupPrice}
            onChange={onChange}
        >
            <InputItem
                type="checkbox"
                name="setupPriceFree"
                label="GENERAL_FREE"
                value={data.setupPriceFree}
            />
            <InputItem
                type="number"
                name="setupPrice"
                icon="money"
                value={data.setupPrice}
                minValue={0}
            />
        </InputField>

        <InputField
            type="number"
            name="discount"
            label="CLIENTS_DISCOUNT_PERCENT"
            value={data.discount}
            error={errors.discount}
            onChange={onChange}
            minValue={0}
        />

        <InputField
            type="toggle"
            name="dontCharge"
            label="CLIENTS_DONT_CHARGE"
            value={data.dontCharge}
            error={errors.dontCharge}
            onChange={onChange}
            withBorder
        />
        <InputField
            isHidden={!!data.dontCharge}
            type="date"
            name="chargeStartDate"
            label="CLIENTS_CHARGE_START_DATE"
            value={data.chargeStartDate}
            error={errors.chargeStartDate}
            onChange={onChange}
        />
    </Columns>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ClientEditPlan.propTypes = {
    isHidden : PropTypes.bool.isRequired,
    data     : PropTypes.object.isRequired,
    errors   : PropTypes.object.isRequired,
    onChange : PropTypes.func.isRequired,
};

export default ClientEditPlan;
