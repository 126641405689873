import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import { StoreCategory }    from "Utils/API";



// The initial State
const initialState = {
    loading   : true,
    error     : false,
    edition   : 0,
    canCreate : false,
    canEdit   : false,
    canImport : false,
    canExport : false,
    list      : [],
    total     : 0,
    elem      : {},
    tongues   : [],
    statuses  : [],
    sort      : {
        orderBy  : "position",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "STORE_CATEGORY_LOADING" });
    },

    /**
     * Fetches the Store Category List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, params = {}) {
        Navigate.unsetParams(params);
        if (type === "STORE") {
            params.storeID = elemID;
        }
        const data = await StoreCategory.getAll(params);
        data.sort  = params;
        dispatch({ type : "STORE_CATEGORY_LIST", data });
    },

    /**
     * Fetches a single Store Category
     * @param {Function} dispatch
     * @param {Number}   categoryID
     * @returns {Promise}
     */
    async fetchElem(dispatch, categoryID) {
        const data = await StoreCategory.getOne({ categoryID });
        dispatch({ type : "STORE_CATEGORY_ELEM", data });
        return data;
    },

    /**
     * Fetches the Store Category Edit data
     * @param {Function} dispatch
     * @param {Number}   storeID
     * @returns {Promise}
     */
    async fetchEditData(dispatch, storeID) {
        const data = await StoreCategory.getEditData({ storeID });
        dispatch({ type : "STORE_CATEGORY_EDIT", data });
    },

    /**
     * Edits/Creates a Store Category
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editCategory(dispatch, data) {
        return StoreCategory.edit(data);
    },

    /**
     * Deletes the Store Category
     * @param {Function} dispatch
     * @param {Number}   categoryID
     * @returns {Promise}
     */
    deleteCategory(dispatch, categoryID) {
        return StoreCategory.delete({ categoryID });
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "STORE_CATEGORY_LIST", "STORE_CATEGORY_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "STORE_CATEGORY_LOADING":
        return {
            ...state,
            loading   : true,
        };

    case "STORE_CATEGORY_LIST":
        return {
            ...state,
            loading   : false,
            error     : false,
            canCreate : action.data.canCreate,
            canEdit   : action.data.canEdit,
            canImport : action.data.canImport,
            canExport : action.data.canExport,
            list      : action.data.list,
            total     : action.data.total,
            sort      : action.data.sort,
        };

    case "STORE_CATEGORY_ELEM":
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            elem      : action.data.elem,
            tongues   : action.data.tongues,
            statuses  : action.data.statuses,
        };

    case "STORE_CATEGORY_EDIT":
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            tongues   : action.data.tongues,
            statuses  : action.data.statuses,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
