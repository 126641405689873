import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Dashboard/Core/NLS";
import Utils                from "Dashboard/Utils/Utils";
import Facebook             from "Utils/Facebook";

// Components
import ProviderButton       from "Components/Utils/Common/ProviderButton";

// Dashboard
import CircularLoader       from "Dashboard/Components/Loader/CircularLoader";
import Html                 from "Dashboard/Components/Common/Html";
import Button               from "Dashboard/Components/Form/Button";
import InputField           from "Dashboard/Components/Form/InputField";
import HyperLink            from "Dashboard/Components/Link/HyperLink";



/**
 * The Messenger Install
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function MessengerInstall(props) {
    const { isHidden, provider, onInstall } = props;


    // The Current State
    const [ isLoading,   setLoading     ] = React.useState(false);
    const [ accessToken, setAccessToken ] = React.useState("");
    const [ pageID,      setPageID      ] = React.useState("");
    const [ pages,       setPages       ] = React.useState([]);


    // Load the Data
    React.useEffect(() => {
        if (!isHidden) {
            handleLoad();
        }
    }, [ isHidden ]);

    // Handles the Load
    const handleLoad = async () => {
        if (process.env.NODE_ENV === "development") {
            return;
        }

        setLoading(true);
        await Facebook.initialize();
        const accessToken = await Facebook.getLoginStatus();
        if (accessToken) {
            await getPages(accessToken);
        }
        setLoading(false);
    };

    // Handles the Login
    const handleLogin = async () => {
        if (process.env.NODE_ENV === "development") {
            setAccessToken("development");
            setPages([]);
            return;
        }

        setLoading(true);
        const accessToken = await Facebook.login({
            scope : "public_profile,pages_show_list,pages_manage_metadata,pages_messaging",
        });
        const pages = await getPages(accessToken);
        setLoading(false);

        if (pages.length === 1) {
            setPageID(String(pages[0].key));
            handleInstall(accessToken, pages[0].key);
        }
    };

    // Gets the Pages
    const getPages = async (accessToken) => {
        setAccessToken(accessToken);
        setPages([]);
        if (!accessToken) {
            return [];
        }

        const response = await Facebook.api(`/me/accounts?access_token=${accessToken}`);
        if (!response.data || !response.data.length) {
            return [];
        }

        const pages = Utils.toSelect(response.data);
        setPages(pages);
        return pages;
    };

    // Handles the Continue
    const handleContinue = () => {
        if (accessToken && pageID) {
            handleInstall(accessToken, pageID);
        }
    };

    // Handles the Install
    const handleInstall = (accessToken, pageID) => {
        const token = JSON.stringify({ accessToken, pageID });
        onInstall(token, pageID);
    };



    // Do the Render
    const hasToken   = Boolean(accessToken);
    const showPages  = Boolean(hasToken && pages.length);
    const showPageID = Boolean(hasToken && !pages.length);

    if (isHidden) {
        return <React.Fragment />;
    }

    if (isLoading) {
        return <>
            <ProviderButton
                logo={provider.logo}
                color={provider.color}
                message={provider.fullName}
            />
            <CircularLoader top={24} />
        </>;
    }

    return <>
        <ProviderButton
            logo={provider.logo}
            color={provider.color}
            message={provider.fullName}
        />

        <section>
            <p>{NLS.get("CHANNELS_MESSENGER_BEFORE")}</p>
            <ul>
                <li>{NLS.get("CHANNELS_MESSENGER_INFO1")}</li>
                <li>{NLS.get("CHANNELS_MESSENGER_INFO2")}</li>
            </ul>

            <Html
                isHidden={hasToken}
                variant="p"
                message="CHANNELS_MESSENGER_START"
            />
            <Html
                isHidden={!showPages}
                variant="p"
                message="CHANNELS_MESSENGER_SELECT"
            />
            <Html
                isHidden={!hasToken || showPages}
                variant="p"
                message="CHANNELS_MESSENGER_NONE"
            />
        </section>

        <Button
            isHidden={hasToken}
            variant="outlined"
            message="CHANNELS_MESSENGER_LOGIN"
            onClick={handleLogin}
        />

        <section>
            <InputField
                isHidden={!showPages}
                type="select"
                name="pageID"
                label="CHANNELS_MESSENGER_PAGE"
                options={pages}
                value={pageID}
                onChange={(name, value) => setPageID(value)}
                isRequired
            />
            <InputField
                isHidden={!showPageID}
                type="number"
                name="pageID"
                label="CHANNELS_MESSENGER_PAGE_ID"
                value={pageID}
                onChange={(name, value) => setPageID(value)}
                isRequired
            />
            <Button
                isHidden={!hasToken}
                className="top-space"
                variant="outlined"
                message="GENERAL_CONTINUE"
                onClick={handleContinue}
                isDisabled={!pageID}
            />
        </section>

        <HyperLink
            isHidden={!hasToken}
            className="user-link"
            message="CHANNELS_OTHER_USER"
            onClick={handleLogin}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
MessengerInstall.propTypes = {
    isHidden  : PropTypes.bool.isRequired,
    provider  : PropTypes.object.isRequired,
    onInstall : PropTypes.func.isRequired,
};

export default MessengerInstall;
