import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Action               from "Dashboard/Core/Action";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";
import Commons              from "Utils/Commons";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Assistant List
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function AssistantList(props) {
    const { type } = props;
    const { fetch, load } = useList("assistant", type);

    const navigate = Commons.useGoto();

    const data = Store.useState("assistant");
    const { canEdit, list, total, sort, loading } = data;

    const { deleteAssistant } = Store.useAction("assistant");

    // The Current State
    const [ deleting, setDeleting ] = React.useState(false);
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Action
    const handleAction = (action, elemID) => {
        if (action.isCreate) {
            navigate("SETTINGS", "ASSISTANTS", "CREATE");
        } else {
            startAction(action, elemID);
        }
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        setDeleting(true);
        const result = await deleteAssistant(elemID);
        if (result.success) {
            endAction();
            setDeleting(false);
            fetch();
        }
    };


    // Do the Render
    const elemName = Utils.getValue(list, "assistantID", elemID, "name");

    return <Main>
        <Header message="ASSISTANTS_NAME" icon="assistant">
            <ActionList data={data} onAction={handleAction} />
        </Header>
        <Content>
            <Table
                fetch={load}
                sort={sort}
                none="ASSISTANTS_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="name"         message="GENERAL_NAME"              isTitle />
                    <TableHeader field="modelName"    message="ASSISTANTS_MODELS_MODEL"   />
                    <TableHeader field="description"  message="GENERAL_DESCRIPTION"       />
                    <TableHeader field="useByDefault" message="ASSISTANTS_USE_BY_DEFAULT" maxWidth="110" align="center" />
                    <TableHeader field="fileCount"    message="MEDIA_NAME"                maxWidth="100" />
                    <TableHeader field="status"       message="GENERAL_STATUS"            maxWidth="80" />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.assistantID} elemID={elem.assistantID}>
                        <TableCell message={elem.name}             />
                        <TableCell message={elem.modelName}        />
                        <TableCell message={elem.description}      />
                        <TableCell message={elem.useByDefaultText} />
                        <TableCell message={elem.filesText}        />
                        <TableCell message={elem.statusName}       textColor={elem.statusColor} />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={handleAction} canEdit={canEdit}>
                    <TableAction action="EDIT"   message="ASSISTANTS_EDIT_TITLE"   navigate />
                    <TableAction action="DELETE" message="ASSISTANTS_DELETE_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <DeleteDialog
            open={action.isDelete}
            isLoading={deleting}
            title="ASSISTANTS_DELETE_TITLE"
            message="ASSISTANTS_DELETE_TEXT"
            content={elemName}
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </Main>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
AssistantList.propTypes = {
    type : PropTypes.string.isRequired,
};

export default AssistantList;
