import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";

// The API
import {
    ConversationMessage, ConversationReaction,
} from "Utils/API";



// The initial State
const initialState = {
    loading    : true,
    error      : false,
    update     : 0,
    edition    : 0,
    items      : [],
    messages   : [],
    notes      : [],
    actions    : [],
    hasMore    : false,
    lastUpdate : 0,
};



// The Actions
const actions = {
    /**
     * Fetches old Messages
     * @param {Function} dispatch
     * @param {Number}   conversationID
     * @param {Number}   time
     * @returns {Promise}
     */
    async fetchOldMessages(dispatch, conversationID, time) {
        const data = await ConversationMessage.getAllOld({ conversationID, time });
        if (!data.error) {
            dispatch({ type : "CONVERSATION_OLD_MESSAGES", data });
        }
        return data;
    },

    /**
     * Fetches new Messages
     * @param {Function} dispatch
     * @param {Number}   conversationID
     * @param {Number}   clientID
     * @param {Number}   lastUpdate
     * @returns {Promise}
     */
    async fetchNewMessages(dispatch, conversationID, clientID, lastUpdate) {
        const data = await ConversationMessage.getAllNew({ conversationID, clientID, lastUpdate });
        if (!data.error) {
            dispatch({ type : "CONVERSATION_NEW_MESSAGES", data });
        }
    },

    /**
     * Adds a Message to a Conversation
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    async addMessage(dispatch, params) {
        const data = await ConversationMessage.add(params);
        if (!data.error) {
            dispatch({ type : "CONVERSATION_NEW_MESSAGES", data });
        }
        return data;
    },

    /**
     * Sends a Message in a Conversation
     * @param {Function} dispatch
     * @param {Number}   messageID
     * @param {Number}   lastUpdate
     * @returns {Promise}
     */
    async sendMessage(dispatch, messageID, lastUpdate) {
        const data = await ConversationMessage.send({ messageID, lastUpdate });
        if (!data.error) {
            dispatch({ type : "CONVERSATION_NEW_MESSAGES", data });
        }
        return data;
    },

    /**
     * Sends a Template to a Conversation
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    async sendTemplate(dispatch, params) {
        const data = await ConversationMessage.sendTemplate(params);
        if (!data.error) {
            dispatch({ type : "CONVERSATION_NEW_MESSAGES", data });
        }
        return data;
    },

    /**
     * Forwards a Conversation Message
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    forwardMessage(dispatch, params) {
        return ConversationMessage.forward(params);
    },

    /**
     * Adds a Reaction to a Conversation Message
     * @param {Function} dispatch
     * @param {Number}   messageID
     * @param {String}   reaction
     * @param {Number}   lastUpdate
     * @returns {Promise}
     */
    async addReaction(dispatch, messageID, reaction, lastUpdate) {
        const data = await ConversationReaction.add({ messageID, reaction, lastUpdate });
        if (!data.error) {
            dispatch({ type : "CONVERSATION_NEW_MESSAGES", data });
        }
        return data;
    },

    /**
     * Removes a Reaction in a Conversation Message
     * @param {Function} dispatch
     * @param {Number}   messageID
     * @param {Number}   lastUpdate
     * @returns {Promise}
     */
    async removeReaction(dispatch, messageID, lastUpdate) {
        const data = await ConversationReaction.remove({ messageID, lastUpdate });
        if (!data.error) {
            dispatch({ type : "CONVERSATION_NEW_MESSAGES", data });
        }
        return data;
    },
};



/**
 * Parses the Messages
 * @param {Object}   conversation
 * @param {Object[]} messages
 * @param {Object[]} notes
 * @param {Object[]} actions
 * @returns {Object[]}
 */
function parseItems(conversation, messages, notes, actions) {
    const result   = [];
    const list     = [ ...messages, ...notes, ...actions ].sort((a, b) => a.createdTime - b.createdTime);
    let showUnread = conversation.readTime > 0;
    let unread     = 0;
    let lastDay    = "";
    let lastType   = "";
    let lastID     = 0;
    let index      = -1;

    for (const message of messages) {
        if (!message.fromFlow && message.createdTime > conversation.readTime) {
            unread += 1;
        }
    }

    for (const item of list) {
        const { id, createdTime, fromFlow, credentialID, contactID } = item;
        const thisDay    = DateTime.formatDate(createdTime, "dashes");
        const isMine     = Boolean(fromFlow || credentialID);
        const thisType   = item.noteID ? "note" : (item.actionID ? "action" : (isMine ? "mine" : "yours"));
        const thisID     = fromFlow ? -1 : (credentialID ? credentialID : contactID);
        const isNewDay   = thisDay !== lastDay;
        const isNewUser  = thisType !== lastType || thisID !== lastID;
        const newMessage = showUnread && createdTime > conversation.readTime;

        if (isNewDay || isNewUser || newMessage) {
            index        += 1;
            result[index] = {
                key      : item.messageID ? `message-${id}` : `action-${id}`,
                dayName  : isNewDay ? DateTime.formatDay(createdTime) : "",
                unread   : newMessage ? unread : 0,
                isMine   : isMine,
                userName : item.userName,
                messages : [],
                notes    : [],
                actions  : [],
            };
            if (newMessage) {
                showUnread = false;
            }
        }

        if (item.messageID) {
            result[index].messages.push(item);
        } else if (item.noteID) {
            result[index].notes.push(item);
        } else if (item.actionID) {
            result[index].actions.push(item);
        }

        lastDay  = thisDay;
        lastType = thisType;
        lastID   = thisID;
    }
    return result;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "CONVERSATION_ELEM", "CONVERSATION_ACTION",
        "CONVERSATION_OLD_MESSAGES", "CONVERSATION_NEW_MESSAGES"
    )) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "CONVERSATION_ELEM": {
        return {
            ...state,
            items      : parseItems(action.data.elem, action.data.messages, action.data.notes, action.data.actions),
            messages   : action.data.messages,
            notes      : action.data.notes,
            actions    : action.data.actions,
            hasMore    : action.data.hasMore,
            lastUpdate : action.data.lastUpdate,
        };
    }

    case "CONVERSATION_ACTION": {
        const actions = Commons.updateList("actionID", state.actions, action.data.actions);
        return {
            ...state,
            error      : false,
            items      : parseItems(action.data.elem, state.messages, state.notes, actions),
            actions    : actions,
        };
    }

    case "CONVERSATION_OLD_MESSAGES": {
        const messages = Commons.updateList("messageID", state.messages, action.data.messages, action.data.deletedMessages);
        const notes    = Commons.updateList("noteID", state.notes, action.data.notes, action.data.deletedNotes);
        const actions  = Commons.updateList("actionID", state.actions, action.data.actions);
        return {
            ...state,
            error      : false,
            items      : parseItems(action.data.elem, messages, notes, actions),
            messages   : messages,
            actions    : actions,
            notes      : notes,
            hasMore    : action.data.hasMore,
        };
    }

    case "CONVERSATION_NOTE_UPDATE":
    case "CONVERSATION_NEW_MESSAGES": {
        const messages = Commons.updateList("messageID", state.messages, action.data.messages, action.data.deletedMessages);
        const notes    = Commons.updateList("noteID", state.notes, action.data.notes, action.data.deletedNotes);
        const actions  = Commons.updateList("actionID", state.actions, action.data.actions);
        return {
            ...state,
            error      : false,
            items      : parseItems(action.data.elem, messages, notes, actions),
            messages   : messages,
            notes      : notes,
            actions    : actions,
            lastUpdate : action.data.lastUpdate,
        };
    }

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
