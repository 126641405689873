import React                from "react";
import Store                from "Dashboard/Core/Store";

// Components
import ClientList           from "Components/App/Admin/Client/ClientList";
import ClientPage           from "Components/App/Admin/Client/ClientPage";
import PartnerList          from "Components/App/Admin/Partner/PartnerList";
import PartnerPage          from "Components/App/Admin/Partner/PartnerPage";
import SubscriptionList     from "Components/App/Admin/Subscription/SubscriptionList";
import TicketList           from "Components/App/Support/Ticket/TicketList";
import DocumentPage         from "Components/App/Support/Document/DocumentPage";
import StoreContainer       from "Components/App/Client/Store/StoreContainer";
import ConfigContainer      from "Components/App/Config/ConfigContainer";
import SetupContainer       from "Components/App/Setup/SetupContainer";
import LogContainer         from "Components/App/Log/LogContainer";
import ProfileContainer     from "Components/App/Profile/ProfileContainer";

// Dashboard
import Router               from "Dashboard/Components/Route/Router";
import AdminRoute           from "Dashboard/Components/Route/AdminRoute";



/**
 * The Admin Router
 * @returns {React.ReactElement}
 */
function AdminRouter() {
    const { isAnyAdmin, isSupport } = Store.useState("auth");


    // Do the Render
    if (!isAnyAdmin) {
        return <React.Fragment />;
    }
    return <Router type="ADMIN">
        <AdminRoute
            url="CLIENTS"
            component={ClientList}
        />
        <AdminRoute
            url="CLIENT"
            component={ClientPage}
        />
        <AdminRoute
            url="CLIENT_STORES"
            component={StoreContainer}
        />
        <AdminRoute
            url="CLIENT_SETTINGS"
            component={ConfigContainer}
        />

        <AdminRoute
            url="PARTNERS"
            component={PartnerList}
        />
        <AdminRoute
            url="PARTNER"
            component={PartnerPage}
        />
        <AdminRoute
            url="PARTNER_CLIENT"
            component={ClientPage}
        />
        <AdminRoute
            url="PARTNER_SETTINGS"
            component={ConfigContainer}
        />

        <AdminRoute
            url="SUBSCRIPTIONS"
            component={SubscriptionList}
        />
        <AdminRoute
            url="TICKETS"
            component={TicketList}
        />
        <AdminRoute
            url="TICKET"
            component={TicketList}
        />
        <AdminRoute
            url="DOCUMENTS"
            component={DocumentPage}
        />
        <AdminRoute
            url="DOCUMENTS_TYPE"
            component={DocumentPage}
        />
        <AdminRoute
            url="DOCUMENT"
            component={DocumentPage}
        />

        <AdminRoute
            isHidden={isSupport}
            url="SETTINGS"
            component={SetupContainer}
        />
        <AdminRoute
            isHidden={isSupport}
            url="LOGS"
            component={LogContainer}
        />
        <AdminRoute
            url="PROFILE"
            component={ProfileContainer}
        />
    </Router>;
}

export default AdminRouter;
