import React                from "react";
import PropTypes            from "prop-types";
import MediaType            from "Dashboard/Core/MediaType";
import Store                from "Dashboard/Core/Store";

// Components
import MediaDialog          from "Components/App/Setup/Media/MediaDialog";

// Dashboard
import PageAccordion        from "Dashboard/Components/Page/PageAccordion";
import PageButtons          from "Dashboard/Components/Page/PageButtons";
import PageContainer        from "Dashboard/Components/Page/PageContainer";
import AccordionItem        from "Dashboard/Components/Accordion/AccordionItem";
import InputField           from "Dashboard/Components/Form/InputField";
import InputItem            from "Dashboard/Components/Form/InputItem";
import PromptDialog         from "Dashboard/Components/Dialogs/PromptDialog";



/**
 * The Assistant Editor
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function AssistantEditor(props) {
    const {
        loading, isEdit, clientID, data, errors, getErrorCount,
        onChange, onSubmit, onDelete, onClose,
    } = props;

    const { models, maxFiles, statuses } = Store.useState("assistant");

    // The Current State
    const [ showMedia,   setShowMedia   ] = React.useState(false);
    const [ showContent, setShowContent ] = React.useState(false);
    const [ fileIndex,   setFileIndex   ] = React.useState(0);
    const [ fileValue,   setFileValue   ] = React.useState("");


    // Handles the Media open
    const handleMediaOpen = (index) => {
        setShowMedia(true);
        handleFileSet(index, "file");
    };

    // Handles the Media change
    const handleMediaChange = (value) => {
        handleFilesChange("file", value);
        setShowMedia(false);
    };

    // Handles the Content open
    const handleContentOpen = (index) => {
        setShowContent(true);
        handleFileSet(index, "content");
    };

    // Handles the Content change
    const handleContentChange = (value) => {
        handleFilesChange("content", value);
        setShowContent(false);
    };

    // Handles the File select
    const handleFileSet = (index, name) => {
        const fileData = JSON.parse(data.files);
        setFileIndex(index);
        setFileValue(fileData[index][name]);
    };

    // Handles the File select
    const handleFilesChange = (name, value) => {
        const fileData = JSON.parse(data.files);
        fileData[fileIndex][name] = value;
        onChange("files", JSON.stringify(fileData));
    };


    // Do the Render
    return <PageContainer error={errors.form}>
        <PageAccordion initial="main" noClose>
            <AccordionItem
                value="main"
                message="GENERAL_MAIN"
                description="ASSISTANTS_MAIN_TEXT"
                errorCount={getErrorCount("main")}
                withGap
            >
                <InputField
                    name="name"
                    label="GENERAL_NAME"
                    value={data.name}
                    error={errors.name}
                    onChange={onChange}
                    isRequired
                />
                <InputField
                    type="select"
                    name="assistantModelID"
                    label="ASSISTANTS_MODELS_MODEL"
                    options={models}
                    value={data.assistantModelID}
                    error={errors.assistantModelID}
                    onChange={onChange}
                    isRequired
                />
                <InputField
                    name="description"
                    label="GENERAL_DESCRIPTION"
                    value={data.description}
                    error={errors.description}
                    onChange={onChange}
                />
                <InputField
                    type="textarea"
                    name="instructions"
                    label="ASSISTANTS_INSTRUCTIONS"
                    helperText="ASSISTANTS_INSTRUCTIONS_TIP"
                    value={data.instructions}
                    error={errors.instructions}
                    onChange={onChange}
                />
                <InputField
                    type="select"
                    name="status"
                    label="GENERAL_STATUS"
                    options={statuses}
                    value={data.status}
                    error={errors.status}
                    onChange={onChange}
                    isRequired
                />
                <InputField
                    type="toggle"
                    name="useByDefault"
                    label="ASSISTANTS_USE_BY_DEFAULT"
                    value={data.useByDefault}
                    onChange={onChange}
                    withBorder
                />
            </AccordionItem>

            <AccordionItem
                value="files"
                message="MEDIA_NAME"
                description="ASSISTANTS_FILES_TEXT"
                errorCount={getErrorCount("files")}
            >
                <InputField
                    type="fields"
                    name="files"
                    title="ASSISTANTS_FILE_TITLE"
                    addButton="ASSISTANTS_ADD_FILE"
                    value={data.files}
                    errors={errors}
                    onChange={onChange}
                    columns="2"
                    maxAmount={maxFiles}
                >
                    <InputItem
                        type="toggle"
                        name="isContent"
                        label="ASSISTANTS_IS_CONTENT"
                        withBorder
                    />
                    <InputItem
                        type="text"
                        name="name"
                        label="GENERAL_NAME"
                        isRequired
                    />
                    <InputItem
                        hide={(elem) => !!elem.isContent}
                        type="media"
                        name="file"
                        label="MEDIA_SINGULAR"
                        onMedia={(index) => handleMediaOpen(index)}
                        columns="2"
                        isRequired
                    />
                    <InputItem
                        hide={(elem) => !elem.isContent}
                        type="media"
                        name="content"
                        label="ASSISTANTS_CONTENT"
                        placeholder="ASSISTANTS_CONTENT"
                        onMedia={(index) => handleContentOpen(index)}
                        columns="2"
                        isRequired
                    />
                </InputField>
            </AccordionItem>
        </PageAccordion>

        <PageButtons
            isEdit={isEdit}
            isDisabled={loading}
            onSubmit={onSubmit}
            onDelete={onDelete}
            onClose={onClose}
        />

        <MediaDialog
            open={showMedia}
            clientID={clientID}
            mediaType={MediaType.TEXT}
            initialSelect={fileValue}
            onSubmit={handleMediaChange}
            onClose={() => setShowMedia(false)}
            isRequired
        />
        <PromptDialog
            open={showContent}
            icon="file"
            title="ASSISTANTS_CONTENT"
            inputType="textarea"
            inputLabel="ASSISTANTS_CONTENT"
            initialValue={fileValue}
            rows="5"
            maxRows="20"
            onSubmit={handleContentChange}
            onClose={() => setShowContent(false)}
            isNarrow={false}
        />
    </PageContainer>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
AssistantEditor.propTypes = {
    loading       : PropTypes.bool.isRequired,
    isEdit        : PropTypes.bool.isRequired,
    clientID      : PropTypes.number.isRequired,
    data          : PropTypes.object.isRequired,
    errors        : PropTypes.object.isRequired,
    getErrorCount : PropTypes.func.isRequired,
    onChange      : PropTypes.func.isRequired,
    onFieldChange : PropTypes.func.isRequired,
    onSubmit      : PropTypes.func.isRequired,
    onDelete      : PropTypes.func.isRequired,
    onClose       : PropTypes.func.isRequired,
};

export default AssistantEditor;
