import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import NLS                  from "Dashboard/Core/NLS";
import Commons              from "Utils/Commons";

// Components
import ProviderButton       from "Components/Utils/Common/ProviderButton";

// Dashboard
import Html                 from "Dashboard/Components/Common/Html";
import Button               from "Dashboard/Components/Form/Button";
import InputField           from "Dashboard/Components/Form/InputField";
import HyperLink            from "Dashboard/Components/Link/HyperLink";



/**
 * The 360 Dialog Install
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function Dialog360Install(props) {
    const { isHidden, provider, onInstall } = props;

    const clientID = Commons.useClientID();

    const { credential } = Store.useState("auth");


    // The Current State
    const [ accessToken, setAccessToken ] = React.useState("");
    const [ channelID,   setChannelID   ] = React.useState("");
    const [ accounts,    setAccounts    ] = React.useState([]);


    // Handles the Login
    const handleLogin = () => {
        const url = Commons.createUrl("https://hub.360dialog.com/dashboard/app/fvxvCjPA/permissions", {
            plan_selection : "basic",
            redirect_url   : `${process.env.REACT_APP_HOOK}m/dialog`,
            state          : "fvxvCjPA",
            email          : credential.email,
            name           : credential.name,
            partner        : clientID,
        });
        Commons.openWindow(url, handleMessage, 900);
    };

    // Handles the Message
    const handleMessage = (event) => {
        if (typeof event.data === "object") {
            return;
        }

        const data = JSON.parse(event.data);
        setAccessToken(event.data);
        setAccounts(data.channels);

        if (data.channels.length === 1) {
            setChannelID(data.channels[0].key);
            handleInstall(event.data, data.channels[0].key);
        }
    };

    // Handles the Continue
    const handleContinue = () => {
        if (accessToken && channelID) {
            handleInstall(accessToken, channelID);
        }
    };

    // Handles the Install
    const handleInstall = (accessToken, channelID) => {
        const data  = JSON.parse(accessToken);
        const token = JSON.stringify({
            partnerID : data.partnerID,
            clientID  : data.clientID,
            channelID,
        });
        onInstall(token, channelID);
    };



    // Do the Render
    const hasToken    = Boolean(accessToken);
    const hasAccounts = Boolean(hasToken && accounts.length);

    if (isHidden) {
        return <React.Fragment />;
    }
    return <>
        <ProviderButton
            logo={provider.logo}
            color={provider.color}
            message={provider.fullName}
        />

        <section>
            <p>{NLS.get("CHANNELS_WHATSAPP_BEFORE")}</p>
            <ul>
                <li>{NLS.get("CHANNELS_WHATSAPP_INFO1")}</li>
                <li>{NLS.get("CHANNELS_WHATSAPP_INFO2")}</li>
                <li>{NLS.get("CHANNELS_WHATSAPP_INFO3")}</li>
            </ul>
            <Html
                isHidden={hasToken}
                variant="p"
                message="CHANNELS_WHATSAPP_START"
            />
            <Html
                isHidden={!hasAccounts}
                variant="p"
                message="CHANNELS_WHATSAPP_SELECT"
            />
            <Html
                isHidden={!hasToken || hasAccounts}
                variant="p"
                message="CHANNELS_WHATSAPP_NONE"
            />
        </section>

        <Button
            isHidden={hasToken}
            variant="primary"
            message="CHANNELS_WHATSAPP_LOGIN"
            onClick={handleLogin}
        />

        <section>
            <InputField
                isHidden={!hasAccounts}
                type="select"
                name="elemID"
                label="CHANNELS_WHATSAPP_ACCOUNT"
                options={accounts}
                value={channelID}
                onChange={(name, value) => setChannelID(value)}
                isRequired
            />
            <Button
                isHidden={!hasToken}
                className="top-space"
                variant="outlined"
                message="GENERAL_CONTINUE"
                onClick={handleContinue}
                isDisabled={!channelID}
            />
        </section>

        <HyperLink
            isHidden={!hasToken}
            className="user-link"
            message="CHANNELS_OTHER_USER"
            onClick={handleLogin}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
Dialog360Install.propTypes = {
    isHidden  : PropTypes.bool.isRequired,
    provider  : PropTypes.object.isRequired,
    onInstall : PropTypes.func.isRequired,
};

export default Dialog360Install;
