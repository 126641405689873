import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import NLS                  from "Dashboard/Core/NLS";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import ViewField            from "Dashboard/Components/Form/ViewField";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Connector Type Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ConnectorTypeEdit(props) {
    const { open, elemCode, onClose, onSubmit } = props;

    const { currencies } = Store.useState("connectorType");
    const { editConnector } = Store.useAction("connectorType");


    // The Initial Data
    const initialData = {
        connectorCode : "",
        name          : "",
        prices        : {},
        position      : 0,
    };

    // Handles the Edit
    const handleEdit = (data) => {
        return editConnector({ ...data, prices : JSON.stringify(data.prices) });
    };

    // Handles the Set
    const handleSet = (elem) => {
        const prices = {};
        for (const [ currencyID, price ] of Object.entries(elem.prices || {})) {
            prices[currencyID] = price.price;
        }
        setElem({ ...elem, prices });
    };

    // Handles the Price
    const handlePrice = (currencyID, value) => {
        const prices = { ...data.prices, [currencyID] : value };
        handleChange("prices", prices);
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("connectorType", initialData, handleEdit, onSubmit);

    // Load the Data
    const { loading } = useDialog("connectorType", open, elemCode, null, handleSet);



    // Do the Render
    const amount = currencies.length;

    return <EditDialog
        open={open}
        icon="connector"
        title="CONNECTORS_EDIT_TITLE"
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <ViewField
            label="GENERAL_NAME"
            value={data.name}
        />

        <Columns>
            <>
                {currencies.map(({ key, value }, index) => <InputField
                    key={key}
                    className={(amount % 2 === 1 && index === amount - 1) ? "columns-double" : ""}
                    type="number"
                    name={`price-${key}`}
                    icon="money"
                    label={NLS.format("GENERAL_PRICE_IN", value)}
                    value={data.prices[key] || 0}
                    error={errors[`price-${key}`]}
                    onChange={(name, value) => handlePrice(key, value)}
                />)}
            </>
        </Columns>

        <InputField
            type="number"
            name="position"
            label="GENERAL_POSITION"
            value={data.position}
            error={errors.position}
            onChange={handleChange}
            minValue={0}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ConnectorTypeEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemCode : PropTypes.string,
};

export default ConnectorTypeEdit;
