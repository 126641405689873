import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";
import { StoreProduct }     from "Utils/API";



// The initial State
const initialState = {
    loading      : true,
    error        : false,
    edition      : 0,
    canCreate    : false,
    canEdit      : false,
    canImport    : false,
    canExport    : false,
    list         : [],
    total        : 0,
    elem         : {},
    categories   : [],
    customFields : [],
    tongues      : [],
    statuses     : [],
    sort         : {
        orderBy  : "finalPosition",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "STORE_PRODUCT_LOADING" });
    },

    /**
     * Fetches the Store Product List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, params = {}) {
        Navigate.unsetParams(params);
        if (type === "STORE") {
            params.storeID = elemID;
        }
        const data = await StoreProduct.getAll(params);
        data.sort  = params;
        dispatch({ type : "STORE_PRODUCT_LIST", data });
    },

    /**
     * Fetches a single Store Product
     * @param {Function} dispatch
     * @param {Number}   productID
     * @returns {Promise}
     */
    async fetchElem(dispatch, productID) {
        const data = await StoreProduct.getOne({ productID });
        dispatch({ type : "STORE_PRODUCT_ELEM", data });
        return data;
    },

    /**
     * Fetches the Store Product Edit data
     * @param {Function} dispatch
     * @param {Number}   storeID
     * @returns {Promise}
     */
    async fetchEditData(dispatch, storeID) {
        const data = await StoreProduct.getEditData({ storeID });
        dispatch({ type : "STORE_PRODUCT_EDIT", data });
    },

    /**
     * Edits/Creates a Store Product
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editProduct(dispatch, data) {
        return StoreProduct.edit(data);
    },

    /**
     * Deletes the Store Product
     * @param {Function} dispatch
     * @param {Number}   productID
     * @returns {Promise}
     */
    deleteProduct(dispatch, productID) {
        return StoreProduct.delete({ productID });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.priceText = Commons.formatPrice(elem.price);
    elem.stockText = elem.unlimited ? "∞" : elem.stock;

    Commons.parseFields(elem);
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "STORE_PRODUCT_LIST", "STORE_PRODUCT_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "STORE_PRODUCT_LOADING":
        return {
            ...state,
            loading      : true,
        };

    case "STORE_PRODUCT_LIST":
        return {
            ...state,
            loading      : false,
            error        : false,
            canCreate    : action.data.canCreate,
            canEdit      : action.data.canEdit,
            canImport    : action.data.canImport,
            canExport    : action.data.canExport,
            list         : Utils.parseList(action.data.list, parseElem),
            total        : action.data.total,
            sort         : action.data.sort,
        };

    case "STORE_PRODUCT_ELEM":
        return {
            ...state,
            error        : false,
            edition      : state.edition + 1,
            elem         : parseElem(action.data.elem),
            categories   : action.data.categories,
            customFields : action.data.customFields,
            tongues      : action.data.tongues,
            statuses     : action.data.statuses,
        };

    case "STORE_PRODUCT_EDIT":
        return {
            ...state,
            error        : false,
            edition      : state.edition + 1,
            categories   : action.data.categories,
            customFields : action.data.customFields,
            tongues      : action.data.tongues,
            statuses     : action.data.statuses,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
