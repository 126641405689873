import React                from "react";
import Auth                 from "Dashboard/Core/Auth";
import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";

// Dashboard
import MenuItem             from "Dashboard/Components/Menu/MenuItem";



/**
 * The Help Menu
 * @returns {React.ReactElement[]}
 */
function HelpMenu() {
    const navigate     = Navigate.useGoto();
    const path         = Navigate.usePath();
    const pathKey      = NLS.getCurrentLang() + path.replace(/\/[0-9]+/g, "/{}");
    const refreshToken = Auth.getRefreshToken();

    const { hasTickets } = Store.useState("permission");
    const { isLoggedIn } = Store.useState("auth");
    const { urls } = Store.useState("document");
    const { fetchUrls } = Store.useAction("document");


    // Loads the Document Urls
    React.useEffect(() => {
        if (isLoggedIn && refreshToken) {
            fetchUrls();
        }
    }, [ isLoggedIn, refreshToken ]);


    // Do the Render
    if (!isLoggedIn) {
        return [];
    }

    const result = [];
    if (hasTickets) {
        result.push(<MenuItem
            key="ticket"
            icon="ticket"
            message="TICKETS_NAME"
            url="TICKETS"
        />);
    }

    result.push(<MenuItem
        key="document"
        icon="document"
        message="DOCUMENTS_NAME"
        url="DOCUMENTS"
    />);

    if (urls && urls[pathKey]) {
        result.push(<MenuItem
            key="document-url"
            icon="document-url"
            message="DOCUMENTS_HELP"
            onClick={() => navigate("DOCUMENTS", urls[pathKey])}
        />);
    }

    return result;
}

export default HelpMenu;
