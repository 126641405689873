import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";

// Components
import CampaignCondition    from "./CampaignCondition";

// Dashboard
import NoneAvailable        from "Dashboard/Components/Common/NoneAvailable";
import InputField           from "Dashboard/Components/Form/InputField";
import Button               from "Dashboard/Components/Form/Button";
import IconLink             from "Dashboard/Components/Link/IconLink";



// Styles
const Container = Styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

const Header = Styled.div`
    display: flex;
    gap: 12px;
`;

const Input = Styled(InputField)`
    width: 100%;
`;

const Content = Styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 12px;
    margin-left: var(--campaign-cond-width);
`;



/**
 * The Campaign Group
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CampaignGroup(props) {
    const { item, getGlobalError } = props;

    const { conditionsDraft } = Store.useState("campaign");
    const { deleteGroup, editGroup } = Store.useAction("campaign");


    // The Current State
    const [ isCreating, setCreating ] = React.useState(false);


    // Do the Render
    const conditions = conditionsDraft.filter((elem) => elem.groupID === item.groupID);
    const showNone   = Boolean(!isCreating && !conditions.length);

    return <Container>
        <Header className="condition-square">
            <Input
                type="select"
                name="match"
                options="SELECT_CONDITION_MATCHES"
                value={item.match}
                onChange={(name, value) => editGroup(item.groupID, value)}
                isSmall
            />
            <IconLink
                variant="black"
                icon="delete"
                onClick={() => deleteGroup(item.groupID)}
            />
        </Header>

        <Content className="condition-line">
            <NoneAvailable
                isHidden={!showNone}
                message="CAMPAIGNS_CONDITIONS_NONE_AVAILABLE"
            />

            {conditions.map((item) => <CampaignCondition
                key={item.conditionID}
                item={item}
                setCreating={setCreating}
                getGlobalError={getGlobalError}
                forGroup
            />)}

            {isCreating && <CampaignCondition
                groupID={item.groupID}
                setCreating={setCreating}
                getGlobalError={getGlobalError}
                forGroup
            />}

            {!isCreating && <div className="condition-square">
                <Button
                    variant="outlined"
                    message="GENERAL_ADD_CONDITION"
                    onClick={() => setCreating(true)}
                />
            </div>}
        </Content>
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CampaignGroup.propTypes = {
    item           : PropTypes.object.isRequired,
    getGlobalError : PropTypes.func.isRequired,
};

export default CampaignGroup;
