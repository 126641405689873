import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import { StoreBanner }      from "Utils/API";



// The initial State
const initialState = {
    loading    : true,
    error      : false,
    edition    : 0,
    canCreate  : false,
    canEdit    : false,
    canImport  : false,
    canExport  : false,
    list       : [],
    total      : 0,
    elem       : {},
    categories : [],
    statuses   : [],
    sort       : {
        orderBy  : "position",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "STORE_BANNER_LOADING" });
    },

    /**
     * Fetches the Store Banner List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, params = {}) {
        Navigate.unsetParams(params);
        if (type === "STORE") {
            params.storeID = elemID;
        }
        const data = await StoreBanner.getAll(params);
        data.sort  = params;
        dispatch({ type : "STORE_BANNER_LIST", data });
    },

    /**
     * Fetches a single Store Banner
     * @param {Function} dispatch
     * @param {Number}   bannerID
     * @returns {Promise}
     */
    async fetchElem(dispatch, bannerID) {
        const data = await StoreBanner.getOne({ bannerID });
        dispatch({ type : "STORE_BANNER_ELEM", data });
        return data;
    },

    /**
     * Fetches the Store Banner Edit data
     * @param {Function} dispatch
     * @param {Number}   storeID
     * @returns {Promise}
     */
    async fetchEditData(dispatch, storeID) {
        const data = await StoreBanner.getEditData({ storeID });
        dispatch({ type : "STORE_BANNER_EDIT", data });
    },

    /**
     * Edits/Creates a Store Banner
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editBanner(dispatch, data) {
        return StoreBanner.edit(data);
    },

    /**
     * Deletes the Store Banner
     * @param {Function} dispatch
     * @param {Number}   bannerID
     * @returns {Promise}
     */
    deleteBanner(dispatch, bannerID) {
        return StoreBanner.delete({ bannerID });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.linkTypeText = NLS.get("SELECT_LINK_TYPES", elem.linkType);
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "STORE_BANNER_LIST", "STORE_BANNER_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "STORE_BANNER_LOADING":
        return {
            ...state,
            loading    : true,
        };

    case "STORE_BANNER_LIST":
        return {
            ...state,
            loading    : false,
            error      : false,
            canCreate  : action.data.canCreate,
            canEdit    : action.data.canEdit,
            canImport  : action.data.canImport,
            canExport  : action.data.canExport,
            list       : Utils.parseList(action.data.list, parseElem),
            total      : action.data.total,
            sort       : action.data.sort,
        };

    case "STORE_BANNER_ELEM":
        return {
            ...state,
            error      : false,
            edition    : state.edition + 1,
            elem       : parseElem(action.data.elem),
            categories : action.data.categories,
            statuses   : action.data.statuses,
        };

    case "STORE_BANNER_EDIT":
        return {
            ...state,
            error      : false,
            edition    : state.edition + 1,
            categories : action.data.categories,
            statuses   : action.data.statuses,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
