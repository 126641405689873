import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Flow Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function FlowEdit(props) {
    const { open, isCopy, elemID, clientID, onClose, onSubmit } = props;

    const { flowTemplates, parentFlows } = Store.useState("flow");
    const { editFlow, copyFlow } = Store.useAction("flow");


    // The Initial Data
    const initialData = {
        clientID       : 0,
        flowID         : 0,
        flowTemplateID : 0,
        parentFlowID   : 0,
        name           : "",
    };

    // Handles the Edit
    const handleEdit = (data) => {
        if (isCopy) {
            return copyFlow(data);
        }
        return editFlow(data);
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("flow", initialData, handleEdit, onSubmit);

    // Load the Data
    const { loading } = useDialog("flow", open, elemID, { clientID }, setElem);


    // Generates the Title
    const title = React.useMemo(() => {
        if (isCopy) {
            return "FLOWS_COPY_TITLE";
        }
        if (elemID) {
            return "FLOWS_EDIT_TITLE";
        }
        return "FLOWS_CREATE_TITLE";
    }, [ isCopy, elemID ]);



    // Do the Render
    return <EditDialog
        open={open}
        icon="flow"
        title={title}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
            isRequired
        />
        <InputField
            isHidden={!!elemID}
            type="select"
            name="flowTemplateID"
            label="FLOWS_TEMPLATE"
            value={data.flowTemplateID}
            error={errors.flowTemplateID}
            options={flowTemplates}
            onChange={handleChange}
        />
        <InputField
            type="select"
            name="parentFlowID"
            label="FLOWS_PARENT_FLOW"
            value={data.parentFlowID}
            error={errors.parentFlowID}
            options={parentFlows}
            onChange={handleChange}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
FlowEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    isCopy   : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
    clientID : PropTypes.number,
};

export default FlowEdit;
