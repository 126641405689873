import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";
import { ContactIO }        from "Utils/API";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import DialogMessage        from "Dashboard/Components/Dialog/DialogMessage";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";
import ViewField            from "Dashboard/Components/Form/ViewField";



/**
 * The Contact Import Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ContactImport(props) {
    const { open, clientID, onClose, onSubmit } = props;

    const { uploadImport, importContacts } = Store.useAction("contact");


    // The Current State
    const [ step,       setStep       ] = React.useState("file");
    const [ importText, setImportText ] = React.useState("");
    const [ fields,     setFields     ] = React.useState([]);
    const [ columns,    setColumns    ] = React.useState([]);
    const [ warnings,   setWarnings   ] = React.useState([]);

    // The Initial Data
    const initialData = {
        file     : null,
        fileName : "",
        filePath : "",
        columns  : {},
    };


    // Restore the State
    React.useEffect(() => {
        if (open) {
            setElem({ ...initialData });
            setStep("file");
        }
    }, [ open ]);

    // Handles the Edit
    const handleEdit = (data) => {
        if (step === "file") {
            return uploadImport({ ...data, clientID });
        }
        if (step === "columns") {
            return importContacts({
                filePath : data.filePath,
                columns  : JSON.stringify(data.columns),
                clientID,
            });
        }

        const source = ContactIO.downloadImport({
            filePath : data.filePath,
            warnings : JSON.stringify(warnings),
        });
        Utils.download(source, "contacts.xlsx");
        onSubmit();
        return null;
    };

    // Handles the Column Change
    const handleColumnChange = (name, value) => {
        const columns = { ...data.columns, [name] : value };
        handleChange("columns", columns);
    };

    // Handles the After Submit
    const handleAfterSubmit = (data) => {
        if (step === "columns" && data.success) {
            onSubmit();
            return;
        }
        if (step === "columns") {
            setWarnings(data.warnings);
            setStep("warnings");
            return;
        }

        setElem({
            filePath : data.filePath,
            columns  : Commons.getImportColumns(data.columns, data.fields),
        });
        setFields(data.fields);
        setColumns(data.columns);
        setImportText(NLS.format("GENERAL_IMPORT_LINES", data.first, data.last));
        setStep("columns");
    };

    // Handles the Close
    const handleClose = () => {
        if (step === "warnings") {
            onSubmit();
        } else {
            onClose();
        }
    };


    // The Form State
    const {
        data, errors, setElem, setError, handleChange, handleSubmit,
    } = useForm("contact", initialData, handleEdit, handleAfterSubmit, false, open);



    // Do the Render
    const showFile     = step === "file";
    const showColumns  = step === "columns";
    const showWarnings = step === "warnings";

    return <EditDialog
        open={open}
        icon="import"
        title="CONTACTS_IMPORT_TITLE"
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={handleClose}
        primary={showWarnings ? "GENERAL_DOWNLOAD" : "GENERAL_SAVE"}
    >
        <DialogMessage
            isHidden={!showFile}
            message="CONTACTS_IMPORT_FILE"
        />
        <InputField
            isHidden={!showFile}
            type="file"
            name="file"
            label="MEDIA_SINGULAR"
            value={data.fileName}
            error={errors.file}
            maxSize={3}
            onChange={handleChange}
            onError={setError}
        />

        <DialogMessage
            isHidden={!showColumns}
            message={importText}
        />
        <DialogMessage
            isHidden={!showColumns}
            message="CONTACTS_IMPORT_COLUMNS"
        />
        <Columns isHidden={!showColumns}>
            <>
                {fields.map(({ key, value }) => <InputField
                    key={key}
                    type="select"
                    name={String(key)}
                    label={value}
                    value={data.columns[key]}
                    onChange={handleColumnChange}
                    options={columns}
                />)}
            </>
        </Columns>

        <DialogMessage
            isHidden={!showWarnings}
            className="text-red"
            message="CONTACTS_WARNING_IMPORT"
        />
        {showWarnings && <>
            {warnings.map(({ index, name }) => <ViewField
                key={index}
                label="CONTACTS_SINGULAR"
                value={name}
            />)}
        </>}
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ContactImport.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    clientID : PropTypes.number,
};

export default ContactImport;
