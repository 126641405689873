import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import NLS                  from "Dashboard/Core/NLS";
import useForm              from "Dashboard/Hooks/Form";
import Commons              from "Utils/Commons";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import DialogMessage        from "Dashboard/Components/Dialog/DialogMessage";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Account Import Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function AccountImport(props) {
    const { open, clientID, onClose, onSubmit } = props;

    const { uploadImport, importAccounts } = Store.useAction("account");


    // The Current State
    const [ step,       setStep       ] = React.useState("file");
    const [ importText, setImportText ] = React.useState("");
    const [ fields,     setFields     ] = React.useState([]);
    const [ columns,    setColumns    ] = React.useState([]);

    // The Initial Data
    const initialData = {
        file     : null,
        fileName : "",
        filePath : "",
        columns  : {},
    };


    // Restore the State
    React.useEffect(() => {
        if (open) {
            setElem({ ...initialData });
            setStep("file");
        }
    }, [ open ]);

    // Handles the Edit
    const handleEdit = (data) => {
        if (step === "file") {
            return uploadImport({ ...data, clientID });
        }
        return importAccounts({
            filePath : data.filePath,
            columns  : JSON.stringify(data.columns),
            clientID,
        });
    };

    // Handles the Column Change
    const handleColumnChange = (name, value) => {
        const columns = { ...data.columns, [name] : value };
        handleChange("columns", columns);
    };

    // Handles the After Submit
    const handleAfterSubmit = (data) => {
        if (step === "columns" && data.success) {
            onSubmit();
            return;
        }

        setElem({
            filePath : data.filePath,
            columns  : Commons.getImportColumns(data.columns, data.fields),
        });
        setFields(data.fields);
        setColumns(data.columns);
        setImportText(NLS.format("GENERAL_IMPORT_LINES", data.first, data.last));
        setStep("columns");
    };


    // The Form State
    const {
        data, errors, setElem, setError, handleChange, handleSubmit,
    } = useForm("account", initialData, handleEdit, handleAfterSubmit, false, open);



    // Do the Render
    const showFile    = step === "file";
    const showColumns = step === "columns";

    return <EditDialog
        open={open}
        icon="import"
        title="ACCOUNTS_IMPORT_TITLE"
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        primary="GENERAL_SAVE"
    >
        <DialogMessage
            isHidden={!showFile}
            message="ACCOUNTS_IMPORT_FILE"
        />
        <InputField
            isHidden={!showFile}
            type="file"
            name="file"
            label="MEDIA_SINGULAR"
            value={data.fileName}
            error={errors.file}
            maxSize={3}
            onChange={handleChange}
            onError={setError}
        />

        <DialogMessage
            isHidden={!showColumns}
            message={importText}
        />
        <DialogMessage
            isHidden={!showColumns}
            message="ACCOUNTS_IMPORT_COLUMNS"
        />
        <Columns isHidden={!showColumns}>
            <>
                {fields.map(({ key, value }) => <InputField
                    key={key}
                    type="select"
                    name={String(key)}
                    label={value}
                    value={data.columns[key]}
                    onChange={handleColumnChange}
                    options={columns}
                />)}
            </>
        </Columns>
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
AccountImport.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    clientID : PropTypes.number,
};

export default AccountImport;
