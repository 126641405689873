import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";

// Dashboard
import ViewDialog           from "Dashboard/Components/Dialogs/ViewDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import ViewField            from "Dashboard/Components/Form/ViewField";



/**
 * The Flow Conversation Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function FlowConversation(props) {
    const { open, elem, onClose } = props;

    const { returnConversation, closeConversation } = Store.useAction("flowConversation");


    // Returns the Conversation
    const handleReturn = async () => {
        const response = await returnConversation(elem.conversationID);
        if (response.success) {
            onClose(true);
        }
    };

    // Closes the Conversation
    const handleClose = async () => {
        const response = await closeConversation(elem.conversationID);
        if (response.success) {
            onClose(true);
        }
    };

    // Handles the Dialog Close
    const handleDialogClose = () => {
        onClose(false);
    };


    // Do the Render
    return <ViewDialog
        open={open}
        icon="conversation"
        title="CONVERSATIONS_VIEW_TITLE"
        secondary="CONVERSATIONS_RETURN"
        onSecondary={handleReturn}
        tertiary="CONVERSATIONS_RESOLVE"
        onTertiary={handleClose}
        onClose={handleDialogClose}
        withSpacing
    >
        <Columns>
            <ViewField
                label="FLOWS_NODES_SINGULAR"
                value={elem.nodeName}
            />
            <ViewField
                label="GENERAL_CREATED_FEM"
                value={elem.createdDate}
            />
            <ViewField
                label="WIDGETS_PLATFORMS_SINGULAR"
                value={elem.providerName}
            />
            <ViewField
                label="CHANNELS_SINGULAR"
                value={elem.channelName}
            />
            <ViewField
                label="CONTACTS_SINGULAR"
                value={elem.contactName}
            />
            <ViewField
                label="LANGUAGES_SINGULAR"
                value={elem.languageName}
                showEmpty
            />

            <ViewField
                label="CONVERSATIONS_LAST_CONTACT_MESSAGE"
                value={elem.lastContactSentDate}
                showEmpty
            />
            <ViewField
                label="CONVERSATIONS_LAST_CONTACT_MESSAGE"
                value={elem.lastContactMessage}
                showEmpty
            />
            <ViewField
                label="CONVERSATIONS_LAST_USER_MESSAGE"
                value={elem.lastUserSentDate}
                showEmpty
            />
            <ViewField
                label="CONVERSATIONS_LAST_USER_MESSAGE"
                value={elem.lastUserMessage}
                showEmpty
            />
            <ViewField
                label="CONVERSATIONS_LAST_MESSAGE"
                value={elem.lastMessageDate}
                showEmpty
            />
            <ViewField
                label="CONVERSATIONS_LAST_MESSAGE"
                value={elem.lastMessageText}
                showEmpty
            />
        </Columns>
    </ViewDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
FlowConversation.propTypes = {
    open    : PropTypes.bool.isRequired,
    onClose : PropTypes.func.isRequired,
    elem    : PropTypes.object,
};

export default FlowConversation;
