import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Components
import TextField            from "Components/Utils/Inputs/TextField";
import TextareaField        from "Components/Utils/Inputs/TextareaField";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import DialogMessage        from "Dashboard/Components/Dialog/DialogMessage";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Chat Forward Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ChatForward(props) {
    const { open, clientID, messageID, title, onClose } = props;

    const { variables, channelLinks } = Store.useState("conversation");
    const { forwardMessage } = Store.useAction("conversationMessage");


    // The Initial Data
    const initialData = {
        messageID    : 0,
        sendTo       : "",
        title        : "",
        extraMessage : "",
    };

    // The Form State
    const {
        loading, data, errors, setElem, handleChange, handleSubmit,
    } = useForm("conversation", initialData, forwardMessage, onClose);

    // Load the Data
    useDialog("conversation", open, 0, {
        clientID, messageID, title,
    }, setElem);



    // Do the Render
    return <EditDialog
        open={open}
        icon="forward"
        title="CONVERSATIONS_FORWARD_TITLE"
        primary="GENERAL_SEND"
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <DialogMessage
            variant="h3"
            message="CONVERSATIONS_FORWARD_TEXT"
            noSpace
        />
        <InputField
            type="list"
            name="sendTo"
            label="GENERAL_SEND_TO_EMAILS"
            addButton="GENERAL_ADD_EMAIL"
            value={data.sendTo}
            error={errors.sendTo}
            onChange={handleChange}
            isRequired
        />
        <TextField
            name="title"
            label="GENERAL_SUBJECT"
            value={data.title}
            error={errors.title}
            onChange={handleChange}
            variables={variables}
            withEmoji
            isRequired
        />
        <TextareaField
            name="extraMessage"
            label="CONVERSATIONS_FORWARD_MESSAGE"
            value={data.extraMessage}
            error={errors.extraMessage}
            variables={variables}
            channelLinks={channelLinks}
            onChange={handleChange}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ChatForward.propTypes = {
    open      : PropTypes.bool.isRequired,
    messageID : PropTypes.number.isRequired,
    clientID  : PropTypes.number,
    title     : PropTypes.string,
    onClose   : PropTypes.func.isRequired,
};

export default ChatForward;
