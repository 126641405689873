import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import Utils                from "Dashboard/Utils/Utils";

// Components
import PersonalizeOptions   from "./PersonalizeOptions";

// Dashboard
import CircularLoader       from "Dashboard/Components/Loader/CircularLoader";



// Styles
const Container = Styled.main`
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: var(--main-gap);

    @media screen and (max-width: 700px) {
        display: block;
    }
`;

const Options = Styled(PersonalizeOptions)`
    min-width: 360px;
`;

const Preview = Styled.iframe`
    box-sizing: border-box;
    width: 100%;
    height: var(--page-height-tabs);
    border: var(--border-width) solid var(--border-color-light);
    border-radius: var(--border-radius);
    overflow: auto;

    @media screen and (max-width: 700px) {
        display: none;
    }
`;



/**
 * The Personalize Page
 * @returns {React.ReactElement}
 */
function PersonalizePage() {
    const { storeID } = Navigate.useParams();

    const { elem : store } = Store.useState("store");

    const { loading, options, tongues, data } = Store.useState("storeOption");
    const { fetchData, edit, setInitialErrors, setData, setErrors } = Store.useAction("storeOption");

    const { tongueID } = Store.useState("storeState");
    const { setTongueID } = Store.useAction("storeState");


    // The References
    const iframeRef = React.useRef(null);
    const timerRef  = React.useRef(null);


    // Load the Data
    React.useEffect(() => {
        handleLoad(storeID);
    }, [ storeID ]);

    // Handle the Load
    const handleLoad = async (storeID) => {
        const data = await fetchData(storeID);
        if (data.tongues && data.tongues.length > 0) {
            setTongueID(data.tongues[0].key);
        }
    };

    // Handle the Tongue Update
    React.useEffect(() => {
        if (iframeRef.current) {
            const isoCode = Utils.getValue(tongues, "key", tongueID, "isoCode");
            iframeRef.current.contentWindow.postMessage(`tongue-${isoCode}`, process.env.REACT_APP_STORE);
        }
    }, [ tongueID ]);


    // Handles the Change
    const handleChange = (name, value) => {
        const newTongueID = Utils.isObject(data[name]) ? tongueID : 0;
        setData(name, value, newTongueID);

        Utils.setTimeout(timerRef, () => {
            handleSave(name, value, newTongueID);
        }, 1000);
    };

    // Handles the Clear
    const handleClear = (name) => {
        if (Utils.isObject(data[name])) {
            handleChange(name, options[name][tongueID] || "");
        } else {
            handleChange(name, options[name] || "");
        }
    };

    // Returns true if the given Option has Clear
    const hasClear = (name) => {
        if (Utils.isObject(data[name])) {
            return data[name] && options[name] && data[name][tongueID] !== options[name][tongueID];
        }
        return data[name] !== options[name];
    };

    // Handles the Save
    const handleSave = async (name, value, newTongueID = 0) => {
        try {
            setInitialErrors();
            await edit(storeID, name, value, newTongueID);
            iframeRef.current.contentWindow.postMessage("update", process.env.REACT_APP_STORE);
            timerRef.current = null;
        } catch (errors) {
            setErrors(errors);
        }
    };

    // Returns the Text for the given Option
    const getText = (name) => {
        if (data[name] && Utils.isObject(data[name])) {
            return data[name][tongueID] || "";
        }
        return "";
    };



    // Do the Render
    if (loading) {
        return <CircularLoader />;
    }
    return <Container>
        <Options
            onChange={handleChange}
            onClear={handleClear}
            hasClear={hasClear}
            getText={getText}
        />
        <Preview
            ref={iframeRef}
            src={`${process.env.REACT_APP_STORE}preview/${store.hash}`}
        />
    </Container>;
}

export default PersonalizePage;
