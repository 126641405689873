import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";

// Dashboard
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Personalize Category
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function PersonalizeCategory(props) {
    const { onChange } = props;

    const { data } = Store.useState("storeOption");


    // Do the Render
    return <>
        <InputField
            type="toggle"
            name="categoriesInHome"
            label="PERSONALIZE_CATEGORIES_IN_HOME"
            value={Number(data.categoriesInHome)}
            onChange={onChange}
            withBorder
        />
        <InputField
            type="toggle"
            name="categoriesInProducts"
            label="PERSONALIZE_CATEGORIES_IN_PRODUCTS"
            value={Number(data.categoriesInProducts)}
            onChange={onChange}
            withBorder
        />

        <InputField
            type="toggle"
            name="categoriesAsSquares"
            label="PERSONALIZE_CATEGORIES_AS_SQUARES"
            value={Number(data.categoriesAsSquares)}
            onChange={onChange}
            withBorder
        />
        <InputField
            type="toggle"
            name="categoriesTwoRows"
            label="PERSONALIZE_CATEGORIES_TWO_ROWS"
            value={Number(data.categoriesTwoRows)}
            onChange={onChange}
            withBorder
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
PersonalizeCategory.propTypes = {
    onChange : PropTypes.func.isRequired,
};

export default PersonalizeCategory;
