import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import { Connector }        from "Utils/API";



// The initial State
const initialState = {
    loading    : true,
    charging   : false,
    error      : false,
    edition    : 0,
    canCreate  : false,
    canEdit    : false,
    canImport  : false,
    canExport  : false,
    list       : [],
    total      : 0,
    elem       : {},
    connectors : [],
    statuses   : [],
    sort       : {
        orderBy  : "connectorCode",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "CONNECTOR_LOADING" });
    },

    /**
     * Fetches the Connector List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, params = {}) {
        Navigate.unsetParams(params);
        if (type === "CLIENT") {
            params.clientID = elemID;
        }
        const data = await Connector.getAll(params);
        data.sort  = params;
        dispatch({ type : "CONNECTOR_LIST", data });
    },

    /**
     * Fetches a single Connector
     * @param {Function} dispatch
     * @param {Number}   connectorID
     * @returns {Promise}
     */
    async fetchElem(dispatch, connectorID) {
        dispatch({ type : "CONNECTOR_CHARGING" });
        const data = await Connector.getOne({ connectorID });
        dispatch({ type : "CONNECTOR_ELEM", data });
    },

    /**
     * Fetches the Connector Edit data
     * @param {Function} dispatch
     * @param {Number}   clientID
     * @returns {Promise}
     */
    async fetchEditData(dispatch, clientID) {
        dispatch({ type : "CONNECTOR_CHARGING" });
        const data = await Connector.getEditData({ clientID });
        dispatch({ type : "CONNECTOR_EDIT", data });
    },

    /**
     * Edits a Connector
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editConnector(dispatch, data) {
        return Connector.edit(data);
    },

    /**
     * Deletes a Connector
     * @param {Function} dispatch
     * @param {Number}   connectorID
     * @returns {Promise}
     */
    deleteConnector(dispatch, connectorID) {
        return Connector.delete({ connectorID });
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "CONNECTOR_LIST", "CONNECTOR_ELEM", "CONNECTOR_PROFILE", "CONNECTOR_EDIT")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "CONNECTOR_LOADING":
        return {
            ...state,
            loading    : true,
        };

    case "CONNECTOR_CHARGING":
        return {
            ...state,
            charging   : true,
        };

    case "CONNECTOR_LIST":
        return {
            ...state,
            loading    : false,
            error      : false,
            canCreate  : action.data.canCreate,
            canEdit    : action.data.canEdit,
            canImport  : action.data.canImport,
            canExport  : action.data.canExport,
            list       : action.data.list,
            total      : action.data.total,
            sort       : action.data.sort,
        };

    case "CONNECTOR_ELEM":
        return {
            ...state,
            error      : false,
            charging   : false,
            edition    : state.edition + 1,
            elem       : action.data.elem,
            connectors : action.data.connectors,
            statuses   : action.data.statuses,
        };

    case "CONNECTOR_EDIT":
        return {
            ...state,
            error      : false,
            charging   : false,
            edition    : state.edition + 1,
            connectors : action.data.connectors,
            statuses   : action.data.statuses,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
