import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";

// Dashboard
import DetailList           from "Dashboard/Components/Details/DetailList";
import DetailItem           from "Dashboard/Components/Details/DetailItem";



/**
 * The Notes Details
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function NoteDetails(props) {
    const { collapsible, onClick } = props;

    const { recentNotes } = Store.useState("conversation");

    // Returns the Note Message
    const getMessage = (elem) => {
        const name = `<b>${elem.name}</b>`;
        const date = `<i>${DateTime.formatDate(elem.createdTime, "dashesTime")}</i>`;
        return `${name} - ${date}`;
    };


    // Do the Render
    return <DetailList
        isHidden={!recentNotes.length}
        message="CONVERSATIONS_NOTES_NAME"
        collapsible={collapsible}
    >
        {recentNotes.map((elem) => <DetailItem
            key={elem.id}
            icon="note"
            message={getMessage(elem)}
            tooltip="CONVERSATIONS_NOTES_SINGULAR"
            onClick={() => onClick(elem.id)}
        />)}
    </DetailList>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
NoteDetails.propTypes = {
    collapsible : PropTypes.string,
    onClick     : PropTypes.func,
};

export default NoteDetails;
