import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";



// The initial State
const initialState = {
    hasSubscriptions  : true,
    hasPhoneNumbers   : true,
    hasStore          : true,
    hasAnyStore       : true,
    hasHotel          : true,
    hasHospitality    : true,
    hasBookingEngine  : true,
    hasMarketing      : true,
    hasIntegrations   : true,
    hasAssistantAI    : true,
    hasAccount        : true,
    hasPremiumSupport : true,
    hasConnectors     : true,
    hasTickets        : true,
    hasClientTickets  : true,
};
const disabledState = {
    hasSubscriptions  : false,
    hasPhoneNumbers   : false,
    hasStore          : false,
    hasAnyStore       : false,
    hasHotel          : false,
    hasHospitality    : false,
    hasBookingEngine  : false,
    hasMarketing      : false,
    hasIntegrations   : false,
    hasAssistantAI    : false,
    hasAccount        : false,
    hasPremiumSupport : false,
    hasConnectors     : false,
    hasTickets        : false,
    hasClientTickets  : false,
};



// The Actions
const actions = {};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
    case "AUTH_CURRENT_USER": {
        const credential = action.credential;
        if (Utils.isEmpty(credential)) {
            return { ...disabledState };
        }
        if ([ "Support", "Admin", "SuperAdmin" ].includes(credential.accessName)) {
            return { ...state };
        }
        if (!credential.clientID && !credential.partnerID) {
            return { ...disabledState };
        }

        if (credential.partnerID) {
            return {
                ...state,
                hasPremiumSupport : credential.partnerPremiumSupport,
                hasTickets        : credential.partnerShowTickets,
            };
        }

        const addons     = credential.addons     || [];
        const connectors = credential.connectors || [];
        return {
            hasSubscriptions  : !credential.clientDontCharge,
            hasPhoneNumbers   : addons.includes("PhoneNumbers"),
            hasStore          : addons.includes("Store"),
            hasAnyStore       : credential.storeCount > 0,
            hasHotel          : addons.includes("Hospitality") || addons.includes("BookingEngine"),
            hasHospitality    : addons.includes("Hospitality"),
            hasBookingEngine  : addons.includes("BookingEngine"),
            hasMarketing      : addons.includes("Marketing"),
            hasIntegrations   : addons.includes("Integrations"),
            hasAssistantAI    : addons.includes("AssistantAI"),
            hasAccount        : addons.includes("Account"),
            hasPremiumSupport : addons.includes("PremiumSupport"),
            hasConnectors     : connectors.length > 0,
            hasTickets        : !credential.partnerID || credential.partnerShowTickets,
            hasClientTickets  : false,
        };
    }

    case "CLIENT_ELEM": {
        const client = action.data.elem;
        if (Utils.isEmpty(client)) {
            return state;
        }

        return {
            hasSubscriptions  : !client.dontCharge,
            hasPhoneNumbers   : client.addons.PhoneNumbers?.isActive,
            hasStore          : client.addons.Store?.isActive,
            hasAnyStore       : true,
            hasHotel          : client.addons.Hospitality?.isActive || client.addons.BookingEngine?.isActive,
            hasHospitality    : client.addons.Hospitality?.isActive,
            hasBookingEngine  : client.addons.BookingEngine?.isActive,
            hasMarketing      : client.addons.Marketing?.isActive,
            hasIntegrations   : client.addons.Integrations?.isActive,
            hasAssistantAI    : client.addons.AssistantAI?.isActive,
            hasAccount        : client.addons.Account?.isActive,
            hasPremiumSupport : client.addons.PremiumSupport?.isActive,
            hasConnectors     : Object.values(client.connectors).filter((elem) => elem.isActive).length > 0,
            hasTickets        : true,
            hasClientTickets  : !client.partnerID || client.partnerShowTickets,
        };
    }

    case "PARTNER_ELEM": {
        const partner = action.data.elem;
        if (Utils.isEmpty(partner)) {
            return state;
        }

        return {
            ...state,
            hasClientTickets : partner.showTickets,
        };
    }

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
