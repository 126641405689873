import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";

// Components
import ProviderIcon         from "./ProviderIcon";



// Styles
const Button = Styled.div.attrs(({ color, isSelected, hasHover }) => ({ color, isSelected, hasHover }))`
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
    border: 1px solid var(--border-color-medium);
    border-radius: var(--border-radius);
    padding: 8px;
    font-size: 16px;

    ${(props) => props.hasHover && `
        transition: all 0.2s;
        cursor: pointer;

        &:hover {
            color: white;
            background-color: ${props.color};
            border-color: ${props.color};
        }
    `}

    ${(props) => props.isSelected && `
        color: white;
        background-color: ${props.color};
        border-color: ${props.color};
    `}
`;

const Errors = Styled.div`
    position: absolute;
    top: 0;
    right: 0;
    padding: 4px 8px;
    font-size: 12px;
    color: white;
    background-color: var(--error-color);
    border-radius: var(--border-radius);
    transform: translate(25%, -25%);
`;



/**
 * The Provider Button
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ProviderButton(props) {
    const {
        isHidden, message, logo, icon, color, isSelected,
        errorCount, onClick,
    } = props;


    // Do the Render
    if (isHidden) {
        return <React.Fragment />;
    }
    return <Button
        onClick={onClick}
        color={color}
        isSelected={isSelected}
        hasHover={!!onClick}
    >
        <ProviderIcon
            logo={logo}
            icon={icon}
            color={color}
            message={message}
            size={48}
            padding={8}
        />
        {NLS.get(message)}
        {!!errorCount && <Errors>{errorCount}</Errors>}
    </Button>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ProviderButton.propTypes = {
    isHidden   : PropTypes.bool,
    logo       : PropTypes.string,
    icon       : PropTypes.string,
    color      : PropTypes.string.isRequired,
    message    : PropTypes.string.isRequired,
    onClick    : PropTypes.func,
    isSelected : PropTypes.bool,
    errorCount : PropTypes.number,
};

export default ProviderButton;
