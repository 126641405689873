import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";

// Components
import MessageDay           from "../Message/MessageDay";
import MessageList          from "../Message/MessageList";
import MessageActions       from "../Message/MessageActions";
import NoteList             from "../Note/NoteList";



// Styles
const Unread = Styled.header`
    background-color: var(--primary-color);
    text-transform: uppercase;
    text-align: center;
    padding: 6px 0;
    margin-top: 24px;
`;

const UnreadTitle = Styled.h3`
    margin: 0;
    font-size: 12px;
    color: white;
    font-family: var(--font-main);
`;



/**
 * The Chat Messages
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ChatMessages(props) {
    const {
        elem, unreadRef,
        canReplyTo, onReply,
        canForwardTo, onForward,
        canReactTo, onReaction,
    } = props;


    // Do the Render
    return <>
        <MessageDay dayName={elem.dayName} />

        {!!elem.unread && <Unread ref={unreadRef}>
            <UnreadTitle>{NLS.pluralize("CONVERSATIONS_UNREAD", elem.unread)}</UnreadTitle>
        </Unread>}

        <MessageList
            list={elem.messages}
            userName={elem.userName}
            isMine={elem.isMine}
            canReplyTo={canReplyTo}
            onReply={onReply}
            canForwardTo={canForwardTo}
            onForward={onForward}
            canReactTo={canReactTo}
            onReaction={onReaction}
        />
        <NoteList
            list={elem.notes}
        />
        <MessageActions
            list={elem.actions}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ChatMessages.propTypes = {
    elem         : PropTypes.object.isRequired,
    unreadRef    : PropTypes.any.isRequired,
    canReplyTo   : PropTypes.bool,
    onReply      : PropTypes.func,
    canReactTo   : PropTypes.bool,
    onReaction   : PropTypes.func,
    canForwardTo : PropTypes.bool,
    onForward    : PropTypes.func,
};

export default ChatMessages;
