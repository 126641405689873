import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import MediaType            from "Dashboard/Core/MediaType";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Components
import MediaField           from "Components/Utils/Inputs/MediaField";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import Alert                from "Dashboard/Components/Form/Alert";
import InputField           from "Dashboard/Components/Form/InputField";
import DialogMessage        from "Dashboard/Components/Dialog/DialogMessage";



// Styles
const Message = Styled(DialogMessage)`
    margin-bottom: 16px;
    text-align: center;
    font-weight: bold;
`;
const Text = Styled.p`
    margin-top: 0;
    margin-bottom: 8px;
`;
const Image = Styled.img`
    max-width: 180px;
    display: block;
    margin: 0 auto;
`;



/**
 * The Channel Profile Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ChannelProfile(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { canProfile, profile, verticals } = Store.useState("channel");
    const { fetchProfile, editProfile } = Store.useAction("channel");


    // The Initial Data
    const initialData = {
        clientID    : 0,
        channelID   : 0,
        image       : "",
        imageUrl    : "",
        vertical    : "",
        name        : "",
        about       : "",
        description : "",
        address     : "",
        email       : "",
        websites    : [ "" ],
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("channel", initialData, editProfile, onSubmit);

    // Load the Data
    const { loading } = useDialog("channel", open, elemID, profile, setElem, fetchProfile);



    // Do the Render
    return <EditDialog
        open={open}
        icon="profile"
        title="CHANNELS_PROFILE_TITLE"
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <Alert
            isHidden={canProfile}
            variant="error"
            message="CHANNELS_PROFILE_PROVIDER_ERROR"
        />
        <Message isHidden={!data.imageUrl}>
            <Text>{NLS.get("CHANNELS_PROFILE_CURRENT_IMAGE")}</Text>
            <Image src={data.imageUrl} />
        </Message>

        <MediaField
            isHidden={!profile.hasImageUrl}
            name="image"
            label="CHANNELS_PROFILE_NEW_IMAGE"
            helperText="CHANNELS_PROFILE_IMAGE_HELP"
            mediaType={MediaType.IMAGE}
            value={data.image}
            error={errors.image}
            clientID={data.clientID}
            onChange={handleChange}
        />
        <InputField
            isHidden={!profile.hasVertical}
            type="select"
            name="vertical"
            label="CHANNELS_PROFILE_CATEGORY"
            options={verticals}
            value={data.vertical}
            error={errors.vertical}
            onChange={handleChange}
            isRequired
        />
        <InputField
            isHidden={!profile.hasName}
            name="name"
            label="CHANNELS_PROFILE_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
        />
        <InputField
            isHidden={!profile.hasAbout}
            name="about"
            label="CHANNELS_PROFILE_ABOUT"
            value={data.about}
            error={errors.about}
            onChange={handleChange}
        />
        <InputField
            isHidden={!profile.hasDescription}
            type="textarea"
            name="description"
            label="CHANNELS_PROFILE_DESCRIPTION"
            value={data.description}
            error={errors.description}
            onChange={handleChange}
            maxLength={512}
        />
        <InputField
            isHidden={!profile.hasAddress}
            name="address"
            label="CHANNELS_PROFILE_ADDRESS"
            value={data.address}
            error={errors.address}
            maxLength={256}
            onChange={handleChange}
        />
        <InputField
            isHidden={!profile.hasEmail}
            name="email"
            label="CHANNELS_PROFILE_EMAIL"
            value={data.email}
            error={errors.email}
            maxLength={256}
            onChange={handleChange}
        />
        <InputField
            isHidden={!profile.hasWebsites}
            type="list"
            name="websites"
            label="CHANNELS_PROFILE_WEBSITES"
            addButton="CHANNELS_PROFILE_ADD_WEBSITE"
            value={data.websites}
            error={errors.websites}
            onChange={handleChange}
            maxLength={256}
            isSortable
            isSmall
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ChannelProfile.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default ChannelProfile;
