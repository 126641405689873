import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Period               from "Dashboard/Utils/Period";
import useFilter            from "Dashboard/Hooks/Filter";
import Commons              from "Utils/Commons";

// Dashboard
import FilterDialog         from "Dashboard/Components/Dialogs/FilterDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Dashboard Filter Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function DashboardFilter(props) {
    const { open, initialData, clientID, onClose } = props;
    const { isAgent, isAnyAdmin } = Store.useState("auth");

    const { filters, channels, hotels, users, sort } = Store.useState("dashboard");
    const { saveFilters } = Store.useAction("dashboard");


    // Handles the Filter Submit
    const onSubmit = (filters) => {
        saveFilters({
            clientID : clientID,
            filter   : sort.filter,
            filters  : JSON.stringify({ ...initialData, ...filters }),
        });
        onClose();
    };

    // The Filter State
    const {
        data, handleChange, handleSubmit,
    } = useFilter("dashboard", open, initialData, filters, onSubmit);



    // Do the Render
    const isReport = sort.filter === "reports";

    return <FilterDialog
        open={open}
        title="DASHBOARD_FILTER_TITLE"
        initialData={initialData}
        data={data}
        onSubmit={handleSubmit}
        onClose={onClose}
    >
        <InputField
            type="select"
            name="period"
            label="PERIOD_NAME"
            options={isReport ? Period.getLastSelect(true) : Period.getSimpleSelect()}
            value={data.period}
            onChange={handleChange}
        />
        <InputField
            type="chooser"
            name="providerName"
            label="GENERAL_PLATFORM"
            options={Commons.getPlatformSelect(channels)}
            value={data.providerName}
            onChange={handleChange}
            hasClear
        />
        <InputField
            type="chooser"
            name="channelID"
            label="CHANNELS_SINGULAR"
            options={Commons.getChannelSelect(channels)}
            value={data.channelID}
            onChange={handleChange}
            hasClear
        />
        <InputField
            isHidden={!hotels.length}
            type="chooser"
            name="hotelID"
            label="HOTELS_SINGULAR"
            options={hotels}
            value={data.hotelID}
            onChange={handleChange}
            noneText="GENERAL_NONE"
            hasClear
        />
        <InputField
            isHidden={isAgent || !!data.onlyMine}
            type="chooser"
            name="userID"
            label="USERS_NAME"
            options={users}
            value={data.userID}
            onChange={handleChange}
            noneText="GENERAL_NONE"
            hasClear
        />
        <InputField
            isHidden={isAgent || isAnyAdmin}
            type="toggle"
            name="onlyMine"
            label="GENERAL_ONLY_MINE"
            value={!!data.onlyMine}
            onChange={handleChange}
            withBorder
        />
    </FilterDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
DashboardFilter.propTypes = {
    open        : PropTypes.bool.isRequired,
    onClose     : PropTypes.func.isRequired,
    clientID    : PropTypes.number,
    initialData : PropTypes.object,
};

export default DashboardFilter;
