import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Widget Create Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function WidgetCreate(props) {
    const { open, isCopy, elemID, clientID, onClose, onSubmit } = props;

    const { statuses } = Store.useState("widget");
    const { createWidget, copyWidget } = Store.useAction("widget");


    // The Initial Data
    const initialData = {
        clientID : 0,
        widgetID : 0,
        name     : "",
        status   : "Active",
    };

    // Handles the Edit
    const handleEdit = (data) => {
        if (isCopy) {
            return copyWidget(data);
        }
        return createWidget(data);
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("widget", initialData, handleEdit, onSubmit);

    // Load the Data
    const { loading } = useDialog("widget", open, elemID, { clientID }, setElem);


    // Generates the Title
    const title = React.useMemo(() => {
        if (isCopy) {
            return "WIDGETS_COPY_TITLE";
        }
        return "WIDGETS_CREATE_TITLE";
    }, [ isCopy ]);



    // Do the Render
    return <EditDialog
        open={open}
        icon="widget"
        title={title}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="select"
            name="status"
            label="GENERAL_STATUS"
            options={statuses}
            value={data.status}
            error={errors.status}
            onChange={handleChange}
            isRequired
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
WidgetCreate.propTypes = {
    open     : PropTypes.bool.isRequired,
    isCopy   : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
    clientID : PropTypes.number,
};

export default WidgetCreate;
