import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Components
import FieldsInputs         from "Components/Utils/Inputs/FieldsInputs";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import Columns              from "Dashboard/Components/Form/Columns";



/**
 * The Conversation Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ConversationEdit(props) {
    const { open, onClose, onSubmit } = props;

    const { elem, customFields } = Store.useState("conversation");
    const { fetchEditData, editConversation } = Store.useAction("conversation");


    // The Initial Data
    const initialData = {
        conversationID : 0,
        clientID       : 0,
    };
    for (const customField of customFields) {
        initialData[customField.id] = "";
    }


    // Gets the Edit Data
    const getElem = () => {
        fetchEditData(elem.clientID);
    };

    // The Form State
    const {
        loading, data, errors, setElem, handleChange, handleSubmit,
    } = useForm("conversation", initialData, editConversation, onSubmit);

    // Load the Data
    useDialog("conversation", open, 0, elem, setElem, getElem);



    // Do the Render
    return <EditDialog
        open={open}
        icon="conversation"
        title="CONVERSATIONS_EDIT_TITLE"
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <Columns>
            <FieldsInputs
                fields={customFields}
                data={data}
                errors={errors}
                onChange={handleChange}
                withRequired
            />
        </Columns>
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ConversationEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
};

export default ConversationEdit;
