import React                from "react";
import Store                from "Dashboard/Core/Store";

// Components
import DashboardPage        from "Components/App/Client/Dashboard/DashboardPage";
import ConversationPage     from "Components/App/Client/Conversation/ConversationPage";
import ContactList          from "Components/App/Client/Contact/ContactList";
import HospitalityList      from "Components/App/Client/Hospitality/HospitalityList";
import StoreContainer       from "Components/App/Client/Store/StoreContainer";
import AccountList          from "Components/App/Client/Account/AccountList";
import CampaignList         from "Components/App/Client/Campaign/CampaignList";
import CampaignPage         from "Components/App/Client/Campaign/CampaignPage";
import UserList             from "Components/App/Client/User/UserList";
import TeamList             from "Components/App/Client/Team/TeamList";
import SubscriptionList     from "Components/App/Admin/Subscription/SubscriptionList";
import ConfigContainer      from "Components/App/Config/ConfigContainer";
import TicketList           from "Components/App/Support/Ticket/TicketList";
import DocumentPage         from "Components/App/Support/Document/DocumentPage";
import ProfileContainer     from "Components/App/Profile/ProfileContainer";

// Dashboard
import Router               from "Dashboard/Components/Route/Router";
import UserRoute            from "Dashboard/Components/Route/UserRoute";



/**
 * The User Router
 * @returns {React.ReactElement}
 */
function UserRouter() {
    const { isClientUser, isAgent, isOwner } = Store.useState("auth");
    const {
        hasSubscriptions, hasHospitality, hasStore, hasAnyStore,
        hasAccount, hasMarketing, hasTickets,
    } = Store.useState("permission");


    // Variables
    const showStore         = Boolean(hasStore && (!isAgent || hasAnyStore));
    const showMarketing     = Boolean(hasMarketing && !isAgent);
    const showSubscriptions = Boolean(hasSubscriptions && isOwner);


    // Do the Render
    if (!isClientUser) {
        return <React.Fragment />;
    }

    return <Router type="USER">
        <UserRoute
            url="DASHBOARD"
            component={DashboardPage}
        />
        <UserRoute
            url="DASHBOARD_TAB"
            component={DashboardPage}
        />

        <UserRoute
            url="CONVERSATIONS"
            component={ConversationPage}
        />
        <UserRoute
            url="CONVERSATION_TAB"
            component={ConversationPage}
        />
        <UserRoute
            url="CONVERSATION"
            component={ConversationPage}
        />

        <UserRoute
            isHidden={!showStore}
            url="STORES"
            component={StoreContainer}
        />

        <UserRoute
            url="CONTACTS"
            component={ContactList}
        />
        <UserRoute
            url="CONTACT"
            component={ContactList}
        />

        <UserRoute
            isHidden={!hasHospitality}
            url="HOSPITALITIES"
            component={HospitalityList}
        />
        <UserRoute
            isHidden={!hasHospitality}
            url="HOSPITALITY"
            component={HospitalityList}
        />

        <UserRoute
            isHidden={!hasAccount}
            url="ACCOUNTS"
            component={AccountList}
        />
        <UserRoute
            isHidden={!hasAccount}
            url="ACCOUNT"
            component={AccountList}
        />

        <UserRoute
            isHidden={!showMarketing}
            url="CAMPAIGNS"
            component={CampaignList}
        />
        <UserRoute
            isHidden={!showMarketing}
            url="CAMPAIGN"
            component={CampaignPage}
        />
        <UserRoute
            isHidden={!showMarketing}
            url="CAMPAIGN_TAB"
            component={CampaignPage}
        />

        <UserRoute
            isHidden={isAgent}
            url="USERS"
            component={UserList}
        />
        <UserRoute
            isHidden={isAgent}
            url="USER"
            component={UserList}
        />

        <UserRoute
            isHidden={isAgent}
            url="TEAMS"
            component={TeamList}
        />
        <UserRoute
            isHidden={!showSubscriptions}
            url="SUBSCRIPTIONS"
            component={SubscriptionList}
        />
        <UserRoute
            isHidden={isAgent}
            url="SETTINGS"
            component={ConfigContainer}
        />
        <UserRoute
            isHidden={!hasTickets}
            url="TICKETS"
            component={TicketList}
        />
        <UserRoute
            isHidden={!hasTickets}
            url="TICKET"
            component={TicketList}
        />
        <UserRoute
            url="DOCUMENTS"
            component={DocumentPage}
        />
        <UserRoute
            url="DOCUMENTS_TYPE"
            component={DocumentPage}
        />
        <UserRoute
            url="DOCUMENT"
            component={DocumentPage}
        />
        <UserRoute
            url="PROFILE"
            component={ProfileContainer}
        />
    </Router>;
}

export default UserRouter;
