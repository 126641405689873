import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";

// Dashboard
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionItem           from "Dashboard/Components/Header/ActionItem";
import ActionOption         from "Dashboard/Components/Header/ActionOption";
import InputField           from "Dashboard/Components/Form/InputField";



// Styles
const Input = Styled(InputField)`
    width: 160px;
    .input-content {
        padding: 5px 10px;
    }
    a {
        margin-bottom: -4px;
    }

    @media (max-width: 700px) {
        display: none;
    }
`;



/**
 * The Flow Header
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function FlowHeader(props) {
    const { levels, isEdit } = props;

    const { flowID, flowTab } = Navigate.useParams();
    const parent   = Navigate.useParent(levels);
    const navigate = Commons.useGoto();

    const { openDetails } = Store.useAction("core");

    const { elem } = Store.useState("flow");
    const { nodes, publishErrors } = Store.useState("flowEditor");
    const { selectedNode } = Store.useState("flowState");
    const { hasSimulation } = Store.useState("flowSimulation");

    const { search } = Store.useState("flowState");
    const { setAction, setInPublish, setSearch, setDetailsTab } = Store.useAction("flowState");


    // The Current Node
    const node = selectedNode ? Utils.getValue(nodes, "id", selectedNode) : {};


    // Handles the Action
    const handleAction = (action) => {
        if (action.isPublish && publishErrors.length) {
            setInPublish(true);
        } else if (action.isSimulate && hasSimulation) {
            if (!Navigate.isUrl("EDIT", flowTab)) {
                navigate("SETTINGS", "FLOWS", flowID, "EDIT");
            }
            setDetailsTab("simulation");
            openDetails();
        } else {
            setAction(action);
        }
    };

    // Handles the Search
    const handleSearch = (name, value) => {
        setSearch(value);
        setDetailsTab("editor");
    };

    // Handles the Goto Parent
    const handleGotoParent = () => {
        navigate("SETTINGS", "FLOWS", elem.parentFlowID, "EDIT");
    };

    // Handles the Goto Child
    const handleGotoChild = () => {
        navigate("SETTINGS", "FLOWS", node.options.flowID, "EDIT");
    };


    // Do the Render
    const showGotoChild  = Boolean(node.isJump && node.options && node.options.flowID);
    const showGotoParent = Boolean(!showGotoChild && elem.parentFlowID);

    return <Header
        message={elem.name || "GENERAL_LOADING"}
        icon="flow"
        href={parent}
        subTitle={elem.statusName}
        subCircle={elem.statusColor}
    >
        <ActionList onAction={handleAction}>
            <Input
                isHidden={!isEdit}
                name="search"
                icon="search"
                placeholder="GENERAL_SEARCH"
                autoComplete="new-password"
                value={search}
                onChange={handleSearch}
                onClear={() => setSearch("")}
                hasClear={search.length > 2}
                isSmall
            />

            <ActionItem
                isHidden={!showGotoParent}
                icon="flow"
                message="FLOWS_NODES_GOTO_PARENT"
                onClick={handleGotoParent}
            />
            <ActionItem
                isHidden={!showGotoChild}
                icon="flow"
                message="FLOWS_NODES_GOTO_FLOW"
                onClick={handleGotoChild}
            />

            <ActionItem
                icon="more"
                message="GENERAL_ACTIONS"
            >
                <ActionOption
                    action="SIMULATE"
                    message="FLOWS_SIMULATION_START_TITLE"
                />
                <ActionOption
                    action="EDIT"
                    message="FLOWS_EDIT_TITLE"
                />
                <ActionOption
                    isHidden={!elem.isDraft}
                    action="COPY"
                    message="FLOWS_COPY_TITLE"
                />
                <ActionOption
                    isHidden={!elem.needsPublish}
                    action="PUBLISH"
                />
                <ActionOption
                    isHidden={!elem.needsPublish}
                    action="CLEAR"
                />
                <ActionOption
                    action="DELETE"
                    message="FLOWS_DELETE_TITLE"
                />
            </ActionItem>
        </ActionList>
    </Header>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
FlowHeader.propTypes = {
    levels : PropTypes.number.isRequired,
    isEdit : PropTypes.bool.isRequired,
};

export default FlowHeader;
