import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Period               from "Dashboard/Utils/Period";
import Utils                from "Dashboard/Utils/Utils";
import { Dashboard }        from "Utils/API";



// The initial Filter
const initialFilter = {
    period       : Period.LAST_15_DAYS,
    providerName : [],
    channelID    : [],
    hotelID      : [],
    userID       : [],
    onlyMine     : 0,
};

// The initial State
const initialState = {
    loading       : true,
    error         : false,
    update        : 0,
    canCreate     : false,
    canEdit       : false,
    canImport     : false,
    canExport     : false,
    reports       : [],
    dayLabels     : [],
    list          : [],
    channels      : [],
    hotels        : [],
    users         : [],
    reportTypes   : [],
    filters       : { ...initialFilter },
    initialFilter : { ...initialFilter },
    sort          : {
        filter : "reports",
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "DASHBOARD_LOADING" });
    },

    /**
     * Fetches the Dashboard List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  filters
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, filters = {}, params = {}) {
        Navigate.unsetParams(params);
        if (type === "CLIENT") {
            params.clientID = elemID;
        }
        const data = await Dashboard.getAll(params);
        data.sort  = params;
        dispatch({ type : "DASHBOARD_LIST", data });
    },

    /**
     * Saves the Dashboard Filters
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    async saveFilters(dispatch, params) {
        const data = await Dashboard.saveFilters(params);
        data.sort = {
            filter : params.filter,
        };
        dispatch({ type : "DASHBOARD_LIST", data });
        return data;
    },

    /**
     * Saves the Dashboard Reports
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    async saveReports(dispatch, params) {
        const data = await Dashboard.saveReports(params);
        data.sort = {
            filter : params.filter,
        };
        dispatch({ type : "DASHBOARD_LIST", data });
        return data;
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.loginDateTime = DateTime.formatDate(elem.lastLogin, "dashesTime");
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "DASHBOARD_LIST")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "DASHBOARD_LOADING":
        return {
            ...state,
            loading   : true,
        };

    case "DASHBOARD_LIST":
        return {
            ...state,
            loading     : false,
            error       : false,
            update      : state.update + 1,
            canCreate   : action.data.canCreate,
            canEdit     : action.data.canEdit,
            canImport   : action.data.canImport,
            canExport   : action.data.canExport,
            reports     : action.data.reports,
            dayLabels   : action.data.dayLabels,
            list        : Utils.parseList(action.data.list, parseElem),
            channels    : action.data.channels,
            hotels      : action.data.hotels,
            users       : action.data.users,
            reportTypes : action.data.reportTypes,
            filters     : { ...initialFilter, ...(action.data.filters ?? {}) },
            sort        : action.data.sort,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
