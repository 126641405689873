import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// Components
import FlowInput            from "./FlowInput";



/**
 * The Flow Inputs
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function FlowInputs(props) {
    const { data, errors, language, onChange } = props;

    const { nodes } = Store.useState("flowEditor");
    const { selectedNodes } = Store.useState("flowState");


    // Do the Render
    const nodeID    = selectedNodes.length === 1 ? selectedNodes[0] : 0;
    const node      = nodeID ? Utils.getValue(nodes, "id", nodeID) : {};
    const hasInputs = Boolean(nodeID && node.inputs.length);

    return <>
        {hasInputs && node.inputs.map((item) => <FlowInput
            key={`${nodeID}-${item.key || item.name}`}
            clientID={node.clientID}
            item={item}
            data={data}
            errors={errors}
            language={language}
            onChange={onChange}
        />)}
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
FlowInputs.propTypes = {
    data     : PropTypes.object.isRequired,
    errors   : PropTypes.object.isRequired,
    language : PropTypes.string.isRequired,
    onChange : PropTypes.func.isRequired,
};

export default FlowInputs;
