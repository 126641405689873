import React                from "react";
import Store                from "Dashboard/Core/Store";
import Action               from "Dashboard/Core/Action";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";

// Components
import LanguageEdit         from "./LanguageEdit";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Language List
 * @returns {React.ReactElement}
 */
function LanguageList() {
    const { fetch, load } = useList("language");

    const data = Store.useState("language");
    const { loading, canEdit, list, total, sort } = data;

    const { deleteLanguage } = Store.useAction("language");

    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Edit Submit
    const handleEdit = () => {
        endAction();
        fetch();
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        endAction();
        const result = await deleteLanguage(elemID);
        if (result.success) {
            fetch();
        }
    };


    // Do the Render
    const elemName = Utils.getValue(list, "languageID", elemID, "name");

    return <Main>
        <Header message="LANGUAGES_NAME" icon="language">
            <ActionList data={data} onAction={startAction} />
        </Header>
        <Content>
            <Table
                fetch={load}
                sort={sort}
                none="LANGUAGES_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="name"            message="GENERAL_NAME"                      isTitle />
                    <TableHeader field="englishName"     message="LANGUAGES_ENGLISH_NAME"            />
                    <TableHeader field="isoCode"         message="GENERAL_ISO_CODE"                  align="center" maxWidth="80" />
                    <TableHeader field="createByDefault" message="LANGUAGES_CREATE_BY_DEFAULT_SHORT" align="center" maxWidth="120" hideMobile />
                    <TableHeader field="position"        message="GENERAL_POSITION"                  align="center" maxWidth="80" hideMobile />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.languageID} elemID={elem.languageID}>
                        <TableCell message={elem.name}                />
                        <TableCell message={elem.englishName}         />
                        <TableCell message={elem.isoCode}             />
                        <TableCell message={elem.createByDefaultText} />
                        <TableCell message={elem.position}            />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={startAction} canEdit={canEdit}>
                    <TableAction action="EDIT"   message="LANGUAGES_EDIT_TITLE"   />
                    <TableAction action="DELETE" message="LANGUAGES_DELETE_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <LanguageEdit
            open={action.isVCE}
            elemID={elemID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="LANGUAGES_DELETE_TITLE"
            message="LANGUAGES_DELETE_TEXT"
            content={elemName}
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </Main>;
}

export default LanguageList;
