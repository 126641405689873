import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";
import { AssistantThread }  from "Utils/API";



// The initial State
const initialState = {
    error    : false,
    submits  : 0,
    messages : [],
    items    : [],
};



// The Actions
const actions = {
    /**
     * Fetches the messages of an Assistant Thread for a Store
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    async fetchMessages(dispatch, params) {
        const data = await AssistantThread.getMessages(params);
        if (!data.error) {
            dispatch({ type : "ASSISTANT_THREAD_MESSAGES", data });
        }
    },

    /**
     * Sends a message to the Assistant Thread
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    async sendMessage(dispatch, params) {
        const data = await AssistantThread.sendMessage(params);
        if (!data.error) {
            dispatch({ type : "ASSISTANT_THREAD_SEND", data });
        }
    },

    /**
     * Restarts the messages of the Assistant Thread
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    async restartMessages(dispatch, params) {
        const data = await AssistantThread.restartMessages(params);
        if (!data.error) {
            dispatch({ type : "ASSISTANT_THREAD_RESTART" });
        }
    },
};



/**
 * Parses the Messages
 * @param {Object[]} messages
 * @returns {Object[]}
 */
function parseMessages(messages) {
    const list   = messages.sort((a, b) => a.createdTime - b.createdTime);
    const result = [];

    let lastDay  = "";
    let index    = -1;

    for (const item of list) {
        const thisDay  = new Date(item.createdTime * 1000).toDateString();
        const isNewDay = thisDay !== lastDay;

        if (isNewDay) {
            index       += 1;
            result[index] = {
                dayName : isNewDay ? DateTime.formatDay(item.createdTime) : "",
                list    : [],
            };
        }

        item.time = DateTime.formatDate(item.createdTime, "time");
        result[index].list.push(item);

        lastDay  = thisDay;
    }
    return result;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "ASSISTANT_THREAD_MESSAGES")) {
        return { ...state, error : true };
    }

    switch (action.type) {
    case "ASSISTANT_THREAD_MESSAGES":
        return {
            ...state,
            error    : false,
            messages : action.data.messages,
            items    : parseMessages(action.data.messages),
        };

    case "ASSISTANT_THREAD_SEND": {
        const messages = Commons.updateList("assistantMessageID", state.messages, [ action.data.message ]);
        return {
            ...state,
            submits  : state.submits + 1,
            messages : messages,
            items    : parseMessages(messages),
        };
    }

    case "ASSISTANT_THREAD_RESTART":
        return {
            ...state,
            submits  : 0,
            messages : [],
            items    : [],
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
