import React                from "react";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import useForm              from "Dashboard/Hooks/Form";

// Components
import AuthContainer        from "Components/Utils/Auth/AuthContainer";
import AuthHeader           from "Components/Utils/Auth/AuthHeader";
import AuthForm             from "Components/Utils/Auth/AuthForm";
import AuthSocial           from "Components/Utils/Auth/AuthSocial";
import GoogleLogin          from "Components/Utils/Login/GoogleLogin";
import MicrosoftLogin       from "Components/Utils/Login/MicrosoftLogin";
import FacebookLogin        from "Components/Utils/Login/FacebookLogin";

// Dashboard
import IconField            from "Dashboard/Components/Form/IconField";



/**
 * The Login Page
 * @returns {React.ReactElement}
 */
function LoginPage() {
    const { redirect } = Store.useState("core");
    const { login, googleLogin, microsoftLogin, facebookLogin } = Store.useAction("auth");

    const navigate = Navigate.useGoto();


    // The Initial Data
    const initialData = {
        email    : "",
        password : "",
    };

    // Handles the Edit
    const handleEdit = async (params) => {
        try {
            const response = await login(params);
            resolveLogin(response);
        } catch (errors) {
            setErrors(errors);
        }
    };

    // Handles the Google Login
    const handleGoogle = async (params) => {
        try {
            const response = await googleLogin(params.access_token);
            resolveLogin(response);
        } catch (errors) {
            setErrors(errors);
        }
    };

    // Handles the Microsoft Login
    const handleMicrosoft = async (params) => {
        try {
            const response = await microsoftLogin(params.idToken);
            resolveLogin(response);
        } catch (errors) {
            setErrors(errors);
        }
    };

    // Handles the Facebook Login
    const handleFacebook = async (accessToken) => {
        try {
            const response = await facebookLogin(accessToken);
            resolveLogin(response);
        } catch (errors) {
            setErrors(errors);
        }
    };

    // Resolves the Login
    const resolveLogin = (result) => {
        if (result.reqPassChange) {
            navigate("PASS_CHANGE");
        } else {
            navigate(redirect);
        }
    };

    // The Form State
    const {
        loading, data, errors, handleChange, handleSubmit, setErrors,
    } = useForm("login", initialData, handleEdit, null, false);



    // Do the Render
    return <AuthContainer>
        <AuthHeader title="LOGIN_TITLE" />
        <AuthForm
            url="PASS_RECOVER"
            link="LOGIN_RECOVER"
            button="LOGIN_BUTTON"
            error={errors.form}
            onSubmit={handleSubmit}
            isLoading={loading}
        >
            <IconField
                type="email"
                name="email"
                icon="email"
                label="GENERAL_EMAIL"
                value={data.email}
                error={errors.email}
                onChange={handleChange}
                isRequired
                withLabel
            />
            <IconField
                type="password"
                name="password"
                icon="password"
                label="GENERAL_PASSWORD"
                value={data.password}
                error={errors.password}
                onChange={handleChange}
                isRequired
                withLabel
            />
        </AuthForm>

        <AuthSocial message="LOGIN_SOCIAL">
            <GoogleLogin onSuccess={handleGoogle} />
            <MicrosoftLogin onSuccess={handleMicrosoft} />
            <FacebookLogin onSuccess={handleFacebook} />
        </AuthSocial>
    </AuthContainer>;
}

export default LoginPage;
