import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// The API
import {
    Flow, FlowNode, FlowEdge,
} from "Utils/API";



// The initial State
const initialState = {
    readying      : false,
    error         : false,
    edition       : 0,
    canEdit       : false,
    flowID        : 0,
    nodes         : [],
    edges         : [],
    publishErrors : [],
    languages     : [],
    variables     : [],
    channelLinks  : [],
    nodeTypes     : [],
    selects       : {},
};



// The Actions
const actions = {
    /**
     * Fetches a single Flow
     * @param {Function} dispatch
     * @param {Number}   flowID
     * @returns {Promise}
     */
    async fetchEditor(dispatch, flowID) {
        dispatch({ type : "FLOW_EDITOR_READYING" });
        const data = await Flow.getEditor({ flowID });
        dispatch({ type : "FLOW_STATE_RESET" });
        dispatch({ type : "FLOW_EDITOR_READY" });
        dispatch({ type : "FLOW_EDITOR_ELEM", data });
    },

    /**
     * Fetches a single Flow
     * @param {Function} dispatch
     * @param {Number}   flowID
     * @returns {Promise}
     */
    async fetchUpdate(dispatch, flowID) {
        const data = await Flow.getEditor({ flowID });
        dispatch({ type : "FLOW_EDITOR_UPDATE", data });
    },


    /**
     * Creates a Flow Node
     * @param {Function} dispatch
     * @param {Number}   flowID
     * @param {String}   nodeType
     * @param {Number}   posX
     * @param {Number}   posY
     * @returns {Promise}
     */
    async createNode(dispatch, flowID, nodeType, posX, posY) {
        const data = await FlowNode.create({ flowID, nodeType, posX, posY });
        if (!data.error) {
            dispatch({ type : "FLOW_EDITOR_ELEM", data });
            dispatch({ type : "FLOW_SELECTED_NODE", selectedNode : data.nodeIDs[0] });
        }
    },

    /**
     * Edits a Flow Node
     * @param {Function} dispatch
     * @param {Number}   nodeID
     * @param {String}   name
     * @param {String}   options
     * @returns {Promise}
     */
    async editNode(dispatch, nodeID, name, options) {
        const data = await FlowNode.edit({ nodeID, name, options });
        dispatch({ type : "FLOW_EDITOR_ELEM", data });
    },

    /**
     * Drags multiple Flow Nodes
     * @param {Function} dispatch
     * @param {Number}   flowID
     * @param {String}   nodes
     * @returns {Promise}
     */
    async dragNodes(dispatch, flowID, nodes) {
        const data = await FlowNode.drag({ flowID, nodes });
        if (!data.error) {
            dispatch({ type : "FLOW_EDITOR_ELEM", data });
        }
    },

    /**
     * Copies multiple Flow Nodes
     * @param {Function} dispatch
     * @param {Number}   flowID
     * @param {String}   nodes
     * @returns {Promise}
     */
    async copyNodes(dispatch, flowID, nodes) {
        const data = await FlowNode.copy({ flowID, nodes });
        if (!data.error) {
            dispatch({ type : "FLOW_EDITOR_ELEM", data });
            dispatch({ type : "FLOW_SELECTED_NODES", selectedNodes : data.nodeIDs });
        }
    },

    /**
     * Moves multiple Flow Nodes
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    async moveNodes(dispatch, params) {
        const data = await FlowNode.move(params);
        if (data.error) {
            return 0;
        }
        if (data.newFlowID) {
            return data.newFlowID;
        }
        dispatch({ type : "FLOW_EDITOR_ELEM", data });
        if (data.nodeIDs) {
            dispatch({ type : "FLOW_SELECTED_NODES", selectedNodes : data.nodeIDs });
        }
        return 0;
    },

    /**
     * Deletes multiple Flow Nodes
     * @param {Function} dispatch
     * @param {Number}   clientID
     * @param {Number}   flowID
     * @param {String}   nodes
     * @returns {Promise}
     */
    async deleteNodes(dispatch, clientID, flowID, nodes) {
        const data = await FlowNode.delete({ clientID, flowID, nodes });
        if (!data.error) {
            dispatch({ type : "FLOW_SELECTED_NODES", selectedNodes : [] });
            dispatch({ type : "FLOW_EDITOR_ELEM", data });
        }
    },


    /**
     * Creates a Flow Edge
     * @param {Function} dispatch
     * @param {Number}   fromNodeID
     * @param {String}   fromNodeOut
     * @param {Number}   toNodeID
     * @returns {Promise}
     */
    async createEdge(dispatch, fromNodeID, fromNodeOut, toNodeID) {
        const data = await FlowEdge.create({ fromNodeID, fromNodeOut, toNodeID });
        if (!data.error) {
            dispatch({ type : "FLOW_EDITOR_ELEM", data });
        }
    },

    /**
     * Edits a Flow Edge
     * @param {Function} dispatch
     * @param {Number}   edgeID
     * @param {Number}   toNodeID
     * @returns {Promise}
     */
    async editEdge(dispatch, edgeID, toNodeID) {
        const data = await FlowEdge.edit({ edgeID, toNodeID });
        if (!data.error) {
            dispatch({ type : "FLOW_EDITOR_ELEM", data });
        }
    },

    /**
     * Deletes a Flow Edge
     * @param {Function} dispatch
     * @param {Number}   edgeID
     * @returns {Promise}
     */
    async deleteEdge(dispatch, edgeID) {
        const data = await FlowEdge.delete({ edgeID });
        if (!data.error) {
            dispatch({ type : "FLOW_SELECTED_EDGE", selectedEdge : null });
            dispatch({ type : "FLOW_EDITOR_ELEM", data });
        }
    },
};



/**
 * Parses the Nodes
 * @param {Object[]} nodes
 * @param {Object}   nodeTypes
 * @returns {Object[]}
 */
function parseNodes(nodes, nodeTypes) {
    return Utils.parseList(nodes, (elem) => {
        const nodeType = nodeTypes[elem.nodeType] ?? nodeTypes.NodeInvalid;
        for (const [ key, value ] of Object.entries(nodeType)) {
            elem[key] = value;
        }
    });
}

/**
 * Parses the Nodes
 * @param {Object} nodeTypes
 * @returns {Object}
 */
function parseNodeTypes(nodeTypes) {
    for (const elem of Object.values(nodeTypes)) {
        elem.fromNodeOuts = [];
    }
    return nodeTypes;
}

/**
 * Parses the Node Select
 * @param {Object[]} nodes
 * @param {Object}   nodeTypes
 * @returns {Object[]}
 */
function parseNodeSelect(nodes, nodeTypes) {
    return Utils.parseList(nodes, (elem) => {
        if (!elem.value) {
            const nodeType = nodeTypes[elem.nodeType] ?? nodeTypes.Invalid;
            if (nodeType) {
                elem.value = NLS.get(nodeType.message);
            }
        }
    });
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "FLOW_EDITOR")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "FLOW_EDITOR_READYING":
        return {
            ...state,
            readying      : true,
        };

    case "FLOW_EDITOR_READY":
        return {
            ...state,
            readying      : false,
            error         : false,
            edition       : state.edition + 1,
        };

    case "FLOW_EDITOR_ELEM":
        return {
            ...state,
            canEdit       : action.data.canEdit,
            flowID        : action.data.flowID,
            nodes         : parseNodes(action.data.nodes, action.data.nodeTypes),
            edges         : action.data.edges,
            languages     : action.data.languages,
            publishErrors : action.data.publishErrors,
            variables     : action.data.variables,
            channelLinks  : action.data.channelLinks,
            nodeTypes     : parseNodeTypes(action.data.nodeTypes),
            selects       : {
                ...action.data.selects,
                nodes : parseNodeSelect(action.data.selects.nodes, action.data.nodeTypes),
            },
        };

    case "FLOW_EDITOR_PUBLISH":
        return {
            ...state,
            publishErrors : action.data.publishErrors,
        };

    case "FLOW_EDITOR_CAMPAIGN":
    case "FLOW_EDITOR_INTEGRATION":
        return {
            ...state,
            publishErrors : action.data.flowPublishErrors,
        };


    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
