import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";
import { Contact }          from "Utils/API";

// Components
import ContactEdit          from "Components/App/Client/Contact/Dialogs/ContactEdit";
import TextareaField        from "Components/Utils/Inputs/TextareaField";
import MediaField           from "Components/Utils/Inputs/MediaField";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";
import ViewField            from "Dashboard/Components/Form/ViewField";



/**
 * The Conversation Create Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ConversationCreate(props) {
    const { open, clientID, contact, onSubmit, onClose } = props;
    const currentContact = contact || {};

    const {
        channels, msgTemplates, wabaTemplates, variables,
        channelLinks, smsCost, messageLength,
    } = Store.useState("conversation");

    const { lastUpdate } = Store.useState("conversationMessage");
    const { createConversation } = Store.useAction("conversation");


    // The Current State
    const [ contactData,      setContactData      ] = React.useState({ values : [], providers : [], email : "", cellphone : "" });
    const [ channelList,      setChannelList      ] = React.useState([]);
    const [ msgTemplateList,  setMsgTemplateList  ] = React.useState([]);
    const [ wabaTemplateList, setWabaTemplateList ] = React.useState([]);
    const [ externalIDLabel,  setExternalIDLabel  ] = React.useState("");
    const [ externalIDList,   setExternalIDList   ] = React.useState([]);
    const [ isWhatsApp,       setIsWhatsApp       ] = React.useState(false);
    const [ isSMS,            setIsSMS            ] = React.useState(false);
    const [ isEmail,          setIsEmail          ] = React.useState(false);
    const [ openContact,      setOpenContact      ] = React.useState(false);

    // The Initial Data
    const initialData = {
        clientID       : 0,
        contactID      : 0,
        contactName    : "",
        channelID      : 0,
        externalID     : "",
        title          : "",
        msgTemplateID  : 0,
        message        : "",
        isNewFile      : 0,
        file           : "",
        fileName       : "",
        wabaTemplateID : 0,
        lastUpdate     : 0,
    };

    // The Form State
    const {
        data, errors, setElem, setData, setError, handleChange, handleSubmit,
    } = useForm("conversation", initialData, createConversation, onSubmit);

    // Load the Data
    const { loading } = useDialog("conversation", open, 0, { clientID, lastUpdate }, setElem);


    // Sets the initial Contact
    React.useEffect(() => {
        if (!loading && currentContact.id && currentContact.id !== data.contactID) {
            setData({
                ...initialData, clientID,
                contactID   : currentContact.id,
                contactName : currentContact.fullName,
            });
            handleContactData(currentContact);
        }
    }, [ loading, currentContact.id ]);

    // Handles the Contact
    const handleContact = (name, newValue, id, newID, data) => {
        handleChange(name, newValue, id, newID);
        if (!data) {
            return;
        }
        handleContactData(data.data);
    };

    // Handles the Contact Data
    const handleContactData = (contact) => {
        const msgTemplateList = msgTemplates[contact.tongueID] || [];
        const channelList     = [];

        for (const channel of (channels || [])) {
            const isWhatsApp = channel.providerName === "WhatsApp";
            const isSMS      = channel.providerName === "SMS";
            const isEmail    = channel.providerName === "Email";

            if (isWhatsApp && contact.cellphone) {
                channelList.push({
                    key        : channel.id,
                    value      : channel.fullName,
                    isWhatsApp : true,
                    isSMS      : false,
                    isEmail    : false,
                });
            } else if (isSMS && contact.cellphone) {
                channelList.push({
                    key        : channel.id,
                    value      : channel.fullName,
                    isWhatsApp : false,
                    isSMS      : true,
                    isEmail    : false,
                });
            } else if (isEmail && contact.email) {
                channelList.push({
                    key        : channel.id,
                    value      : channel.fullName,
                    isWhatsApp : false,
                    isSMS      : false,
                    isEmail    : true,
                });
            } else if (contact.providers) {
                for (const provider of contact.providers) {
                    if (provider.channelID === channel.channelID) {
                        channelList.push({
                            key        : channel.id,
                            value      : channel.fullName,
                            isWhatsApp : false,
                            isSMS      : false,
                            isEmail    : false,
                        });
                    }
                }
            }
        }

        setContactData(contact);
        setChannelList(channelList);
        setMsgTemplateList(msgTemplateList);
        setIsWhatsApp(false);
        setIsSMS(false);
        setIsEmail(false);
    };

    // Handles the Contact Create
    const handleContactCreate = (contactID, response) => {
        setOpenContact(false);
        setData({
            contactID   : response.contact.id,
            contactName : response.contact.fullName,
        });
        handleContactData(response.contact);
    };

    // Handles the Channel
    const handleChannel = (name, channelID) => {
        const isWhatsApp    = Utils.getValue(channelList, "key", channelID, "isWhatsApp");
        const isSMS         = Utils.getValue(channelList, "key", channelID, "isSMS");
        const isEmail       = Utils.getValue(channelList, "key", channelID, "isEmail");
        let   wabaList      = [];
        let   externalLabel = "";
        let   externalList  = [];
        let   externalID    = "";

        if (isWhatsApp || isSMS) {
            externalLabel = "GENERAL_CELLPHONE";
            externalList  = Utils.getValues(contactData.values, "defaultField", "cellphone", "fullValue");
            externalID    = contactData.cellphone;
            wabaList      = isWhatsApp ? Commons.getWabaTemplates(wabaTemplates, channelID) : [];
            if (externalID[0] === "+") {
                externalID = externalID.substring(1);
            }
        } else if (isEmail) {
            externalLabel = "GENERAL_EMAIL";
            externalList  = Utils.getValues(contactData.values, "defaultField", "email", "fullValue");
            externalID    = contactData.email;
        } else {
            externalID = Utils.getValue(contactData.providers, "channelID", channelID, "externalID");
        }

        const externalIDList = [];
        for (const value of externalList) {
            externalIDList.push({ key : value, value });
        }

        setWabaTemplateList(wabaList);
        setIsWhatsApp(isWhatsApp);
        setIsSMS(isSMS);
        setIsEmail(isEmail);
        setExternalIDLabel(externalLabel);
        setExternalIDList(externalIDList);

        setData({ channelID, externalID });
    };

    // Handles the Message Template
    const handleMsgTemplate = (name, msgTemplateID) => {
        const msgTemplate = Utils.getValue(msgTemplateList, "key", msgTemplateID);
        const { message, file : fileName } = msgTemplate;
        if (fileName) {
            setData({ msgTemplateID, message, isNewFile : 0, fileName });
        } else {
            setData({ msgTemplateID, message });
        }
    };



    // Variables
    const showChannel       = Boolean(data.contactID && channelList.length);
    const showChannelError  = Boolean(data.contactID && !channelList.length);
    const hasChannel        = Boolean(showChannel && data.channelID);
    const showSMSInfo       = Boolean(hasChannel && isSMS);
    const showExternalID    = Boolean(hasChannel && externalIDList.length > 1);
    const showTitle         = Boolean(hasChannel && isEmail);
    const showMessage       = Boolean(hasChannel && !isWhatsApp);
    const showMsgTemplate   = Boolean(showMessage && msgTemplateList.length > 0);
    const showFile          = Boolean(showMessage);
    const showWabaTemp      = Boolean(hasChannel && isWhatsApp);
    const showWabaTempList  = Boolean(showWabaTemp && wabaTemplateList.length);
    const showWabaTempError = Boolean(showWabaTemp && !wabaTemplateList.length);
    const showPreview       = Boolean(showWabaTemp && data.wabaTemplateID);
    const wabaTemplate      = Utils.getValue(wabaTemplates, "id", data.wabaTemplateID);
    const wabaMessage       = data.wabaTemplateID ? Commons.getWabaMessage(wabaTemplate, data.isoCode) : "";


    // Do the Render
    return <>
        <EditDialog
            open={open}
            icon="conversation"
            title="CONVERSATIONS_CREATE_TITLE"
            primary="GENERAL_SEND"
            error={errors.form}
            onSubmit={handleSubmit}
            onClose={onClose}
            isLoading={loading}
            noOverflow
        >
            <InputField
                type="suggest"
                name="contactName"
                label="CONTACTS_SINGULAR"
                suggestID="contactID"
                suggestFetch={Contact.search}
                suggestParams={{ clientID, notBlocked : 1 }}
                value={data.contactName}
                error={errors.contactID}
                onChange={handleContact}
                button="GENERAL_CREATE"
                onClick={() => setOpenContact(true)}
                isRequired
            />
            <InputField
                isHidden={!showChannel}
                type="select"
                name="channelID"
                label="CHANNELS_SINGULAR"
                options={channelList}
                value={data.channelID}
                error={errors.channelID}
                onChange={handleChannel}
                isRequired
            />
            <ViewField
                isHidden={!showChannelError}
                label="CHANNELS_SINGULAR"
                message="CONVERSATIONS_CHANNEL_ERROR"
            />
            <ViewField
                isHidden={!showSMSInfo}
                label="CHANNELS_SMS"
                message="CONVERSATIONS_SMS_WARNING"
            />
            <InputField
                isHidden={!showExternalID}
                type="select"
                name="externalID"
                label={externalIDLabel}
                options={externalIDList}
                value={data.externalID}
                error={errors.externalID}
                onChange={handleChange}
                isRequired
            />

            <InputField
                isHidden={!showTitle}
                name="title"
                label="GENERAL_SUBJECT"
                options={wabaTemplateList}
                value={data.title}
                error={errors.title}
                onChange={handleChange}
                isRequired
            />
            <InputField
                isHidden={!showMsgTemplate}
                type="select"
                name="msgTemplateID"
                label="MSG_TEMPLATES_SINGULAR"
                options={msgTemplateList}
                value={data.msgTemplateID}
                error={errors.msgTemplateID}
                onChange={handleMsgTemplate}
            />
            <TextareaField
                isHidden={!showMessage}
                name="message"
                label="GENERAL_MESSAGE"
                helperText={showSMSInfo ? "CONVERSATIONS_SMS_COST_INFO" : ""}
                messageCost={showSMSInfo ? smsCost : 0}
                value={data.message}
                error={errors.message}
                variables={variables}
                channelLinks={channelLinks}
                onChange={handleChange}
                withoutFormat={isSMS}
                maxLength={messageLength}
                isRequired
            />

            <Columns isHidden={!showFile}>
                <InputField
                    type="toggle"
                    name="isNewFile"
                    label="CONVERSATIONS_NOTES_USE_FILE"
                    value={data.isNewFile}
                    onChange={handleChange}
                    withBorder
                />
                <MediaField
                    isHidden={!!data.isNewFile}
                    name="fileName"
                    label="CONVERSATIONS_OLD_FILE"
                    value={data.fileName}
                    error={errors.fileName}
                    clientID={clientID}
                    onChange={handleChange}
                />
                <InputField
                    isHidden={!data.isNewFile}
                    type="file"
                    name="file"
                    label="CONVERSATIONS_NEW_FILE"
                    value={data.file}
                    error={errors.file}
                    maxSize={3}
                    onChange={handleChange}
                    onError={setError}
                />
            </Columns>

            <ViewField
                isHidden={!showWabaTempError}
                label="GENERAL_TEMPLATE"
                message="WABA_TEMPLATES_EMPTY_SEND"
            />
            <InputField
                isHidden={!showWabaTempList}
                type="select"
                name="wabaTemplateID"
                label="GENERAL_TEMPLATE"
                options={wabaTemplateList}
                value={data.wabaTemplateID}
                error={errors.wabaTemplateID}
                onChange={handleChange}
                isRequired
            />
            <ViewField
                isHidden={!showPreview}
                label="GENERAL_MESSAGE"
                value={wabaMessage}
            />
        </EditDialog>

        <ContactEdit
            open={openContact}
            clientID={clientID}
            onSubmit={handleContactCreate}
            onClose={() => setOpenContact(false)}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ConversationCreate.propTypes = {
    open     : PropTypes.bool.isRequired,
    clientID : PropTypes.number.isRequired,
    onSubmit : PropTypes.func.isRequired,
    onClose  : PropTypes.func.isRequired,
    contact  : PropTypes.object,
};

export default ConversationCreate;
