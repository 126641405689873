import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Dashboard/Core/NLS";

// Dashboard
import EditorFieldBase      from "Dashboard/Components/Form/EditorField";



/**
 * The Editor Field
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function EditorField(props) {
    const {
        isHidden, clientID, name, value, error, height,
        contentStyle, variables, channelLinks, onChange, onMedia,
    } = props;


    // Handles the Editor Setup
    const handleSetup = (editor) => {
        for (const { key, value, extra } of variables) {
            editor.ui.registry.addMenuItem(key, {
                text     : `${key} ${extra} - ${NLS.get(value)}`,
                onAction : () => editor.insertContent(key),
            });
        }
        for (const { key, value, extra } of channelLinks) {
            editor.ui.registry.addMenuItem(key, {
                text     : `${NLS.get(value)} - ${extra}`,
                onAction : () => editor.insertContent(key),
            });
        }
    };


    // Do the Render
    if (isHidden) {
        return <React.Fragment />;
    }

    const variableItem = variables.length    ? "variable" : "";
    const channelItem  = channelLinks.length ? "channel"  : "";

    return <EditorFieldBase
        name={name}
        value={value}
        error={error}
        height={height}
        clientID={clientID}
        contentStyle={contentStyle}
        menubar={`${variableItem} ${channelItem}`}
        menu={{
            variable : {
                title : NLS.get("GENERAL_VARIABLES"),
                items : variables.map(({ key }) => key).join(" "),
            },
            channel : {
                title : NLS.get("CHANNELS_SINGULAR"),
                items : channelLinks.map(({ key }) => key).join(" "),
            },
        }}
        onChange={onChange}
        onMedia={onMedia}
        onSetup={handleSetup}
    />;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
EditorField.propTypes = {
    isHidden     : PropTypes.bool,
    clientID     : PropTypes.number,
    name         : PropTypes.string.isRequired,
    value        : PropTypes.string,
    height       : PropTypes.number,
    contentStyle : PropTypes.string,
    error        : PropTypes.string,
    variables    : PropTypes.array,
    channelLinks : PropTypes.array,
    onChange     : PropTypes.func,
    onMedia      : PropTypes.func,
};

/**
 * The Default Properties
 * @typedef {Object} defaultProps
 */
EditorField.defaultProps = {
    isHidden     : false,
    clientID     : 0,
    value        : "",
    height       : 600,
    contentStyle : "",
    variables    : [],
    channelLinks : [],
};

export default EditorField;
