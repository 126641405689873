import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import { Admin }            from "Utils/API";



// The initial State
const initialState = {
    loading   : true,
    error     : false,
    edition   : 0,
    canCreate : false,
    canEdit   : false,
    canImport : false,
    canExport : false,
    list      : [],
    total     : 0,
    elem      : {},
    accesses  : [],
    statuses  : [],
    sort      : {
        orderBy  : "fullName",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "ADMIN_LOADING" });
    },

    /**
     * Fetches the Admin List
     * @param {Function} dispatch
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, params = {}) {
        const data = await Admin.getAll(params);
        data.sort = params;
        dispatch({ type : "ADMIN_LIST", data });
    },

    /**
     * Fetches a single Admin
     * @param {Function} dispatch
     * @param {Number}   credentialID
     * @returns {Promise}
     */
    async fetchElem(dispatch, credentialID) {
        const data = await Admin.getOne({ credentialID });
        dispatch({ type : "ADMIN_ELEM", data });
    },

    /**
     * Edits/Creates a Admin
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editAdmin(dispatch, data) {
        return Admin.edit(data);
    },

    /**
     * Deletes a Admin
     * @param {Function} dispatch
     * @param {Number}   credentialID
     * @returns {Promise}
     */
    deleteAdmin(dispatch, credentialID) {
        return Admin.delete({ credentialID });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdDate     = DateTime.formatDate(elem.createdTime, "dashes");
    elem.createdDateTime = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.loginDate       = DateTime.formatDate(elem.lastLogin,   "dashes");
    elem.loginDateTime   = DateTime.formatDate(elem.lastLogin,   "dashesTime");
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "ADMIN_LIST", "ADMIN_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "ADMIN_LOADING":
        return {
            ...state,
            loading   : true,
        };

    case "ADMIN_LIST":
        return {
            ...state,
            loading   : false,
            error     : false,
            canCreate : action.data.canCreate,
            canEdit   : action.data.canEdit,
            canImport : action.data.canImport,
            canExport : action.data.canExport,
            list      : Utils.parseList(action.data.list, parseElem),
            total     : action.data.total,
            sort      : action.data.sort,
            accesses  : action.data.accesses,
            statuses  : action.data.statuses,
        };

    case "ADMIN_ELEM":
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            elem      : parseElem(action.data.elem),
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
