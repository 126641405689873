import React                from "react";
import PropTypes            from "prop-types";
import Utils                from "Dashboard/Utils/Utils";

// Components
import FormatMenu           from "../Menus/FormatMenu";
import VariableMenu         from "../Menus/VariableMenu";
import ChannelMenu          from "../Menus/ChannelMenu";

// Dashboard
import IconLink             from "Dashboard/Components/Link/IconLink";
import EmojiPopup           from "Dashboard/Components/Form/EmojiPopup";




/**
 * The Text Item
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function TextItem(props) {
    const {
        hasFormat, withEmoji, variables, channelLinks,
        isDisabled, inputRef, value, onChange,
    } = props;


    // The References
    const formatRef   = React.useRef(null);
    const emojiRef    = React.useRef(null);
    const variableRef = React.useRef(null);
    const channelRef  = React.useRef(null);

    // The Current State
    const [ menu, setMenu ] = React.useState("");


    // Handles the Insert Click
    const handleInsertClick = (insert) => {
        if (inputRef && value) {
            const text = Utils.insertText(inputRef, value, insert);
            inputRef.current.focus();
            onChange(text);
        } else if (value) {
            onChange(value + insert);
        } else {
            onChange(insert);
        }
        setMenu("");
    };


    // Do the Render
    const withVariable = Boolean(variables && variables.length > 0);
    const withChannel  = Boolean(channelLinks && channelLinks.length > 0);

    return <>
        <IconLink
            isHidden={!hasFormat}
            passedRef={formatRef}
            variant="black"
            icon="format"
            tooltip="GENERAL_FORMAT"
            onClick={() => setMenu("format")}
            isSmall
        />
        <IconLink
            isHidden={!withEmoji}
            passedRef={emojiRef}
            variant="black"
            icon="emoji"
            tooltip="EMOJI_NAME"
            onClick={() => setMenu("emoji")}
            isSmall
        />
        <IconLink
            isHidden={!withVariable}
            passedRef={variableRef}
            variant="black"
            icon="variable"
            tooltip="GENERAL_VARIABLES"
            onClick={() => setMenu("variable")}
            isDisabled={isDisabled}
            isSmall
        />
        <IconLink
            isHidden={!withChannel}
            passedRef={channelRef}
            variant="black"
            icon="channel"
            tooltip="CHANNELS_SINGULAR"
            onClick={() => setMenu("channel")}
            isSmall
        />

        {hasFormat && <FormatMenu
            open={menu === "format"}
            targetRef={formatRef}
            message={value}
            direction="bottom left"
            onText={(text) => onChange(name, text)}
            onClose={() => setMenu("")}
        />}
        {withEmoji && <EmojiPopup
            open={menu === "emoji"}
            targetRef={emojiRef}
            direction="bottom left"
            gap={4}
            onClick={handleInsertClick}
            onClose={() => setMenu("")}
        />}
        {withVariable && <VariableMenu
            open={menu === "variable"}
            targetRef={variableRef}
            message={value}
            list={variables}
            direction="bottom left"
            onClick={handleInsertClick}
            onClose={() => setMenu("")}
        />}
        {withChannel && <ChannelMenu
            open={menu === "channel"}
            targetRef={channelRef}
            message={value}
            list={channelLinks}
            direction="bottom left"
            onClick={handleInsertClick}
            onClose={() => setMenu("")}
        />}
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
TextItem.propTypes = {
    hasFormat    : PropTypes.bool,
    withEmoji    : PropTypes.bool,
    variables    : PropTypes.array,
    channelLinks : PropTypes.array,
    isDisabled   : PropTypes.bool,
    inputRef     : PropTypes.object,
    value        : PropTypes.string,
    onChange     : PropTypes.func,
};

export default TextItem;
