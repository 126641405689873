import React                from "react";
import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useForm              from "Dashboard/Hooks/Form";
import Commons              from "Utils/Commons";

// Components
import WabaTemplateHeader   from "./View/WabaTemplateHeader";
import WabaTemplateEditor   from "./View/WabaTemplateEditor";
import WabaTemplatePreview  from "./View/WabaTemplatePreview";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";
import PageMain             from "Dashboard/Components/Page/PageMain";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The WABA Template Page
 * @returns {React.ReactElement}
 */
function WabaTemplatePage() {
    const { wabaTemplateID } = Navigate.useParams();

    const clientID = Commons.useClientID();
    const navigate = Navigate.useGoto();
    const parent   = Navigate.useParent();
    const goto     = parent.replace(NLS.baseUrl("CREATE"), "");
    const isEdit   = Boolean(wabaTemplateID && !parent.endsWith(NLS.baseUrl("CREATE")));

    const { charging, edition, elem, components, tongues, channels } = Store.useState("wabaTemplate");
    const { fetchElem, fetchEditData, editWabaTemplate, deleteWabaTemplate } = Store.useAction("wabaTemplate");


    // The Current State
    const [ tongueID,   setTongueID   ] = React.useState(0);
    const [ showDelete, setShowDelete ] = React.useState(false);

    // The Initial Data
    const initialData = {
        wabaTemplateID : 0,
        channelID      : 0,
        name           : "",
        category       : "",
        forIntegration : 0,
        components     : {},
        publish        : 0,
    };

    // Handles the Edit
    const handleEdit = async (data) => {
        await editWabaTemplate({
            ...data, clientID,
            components : JSON.stringify(data.components),
        });
    };

    // Handles the Close
    const handleClose = () => {
        navigate(goto);
    };

    // Handles the Delete
    const handleDelete = async () => {
        await deleteWabaTemplate(elem.id);
        navigate(goto);
    };

    // The Form State
    const {
        loading, data, errors, setElem, setData, setErrors, handleSubmit,
    } = useForm("wabaTemplate", initialData, handleEdit, handleClose, false);


    // Load the Data
    React.useEffect(() => {
        if (wabaTemplateID) {
            fetchElem(wabaTemplateID);
        } else {
            fetchEditData(clientID);
        }
    }, [ wabaTemplateID ]);

    // Store the Data
    React.useEffect(() => {
        if (elem.id) {
            const wabaTemplateID = isEdit ? elem.wabaTemplateID : 0;
            setElem({ ...elem, wabaTemplateID, components });
        } else if (channels.length) {
            setElem({ ...initialData, channelID : channels[0].key });
        } else {
            setElem({ ...initialData });
        }
    }, [ edition ]);

    // Set the initial tongue
    React.useEffect(() => {
        if (!charging && tongues.length) {
            setTongueID(tongues[0].key);
        }
    }, [ charging ]);


    // Returns the Value
    const getValue = (name, defaultValue = "") => {
        if (Utils.hasProp(initialData, name)) {
            return data[name] || defaultValue;
        }
        return data.components[tongueID]?.[name] || defaultValue;
    };

    // Returns the Error
    const getErrorKey = (name, forSection = false) => {
        if (forSection) {
            return name === "main" ? "main" : `${tongueID}-${name}`;
        }
        if (name === "form" || Utils.hasProp(initialData, name)) {
            return name;
        }
        return `${tongueID}-${name}`;
    };

    // Returns the Error
    const getError = (name, forSection = false, forMultiple = false) => {
        if (name === "form" && !errors.form) {
            return getLanguageError();
        }
        const key = getErrorKey(name, forSection);
        if (!forMultiple) {
            return errors[key] || "";
        }

        const result = {};
        for (const [ errorKey, errorText ] of Object.entries(errors)) {
            if (errorKey.startsWith(key)) {
                result[errorKey.replace(`${tongueID}-`, "")] = errorText;
            }
        }
        return result;
    };

    // Returns a Language Error
    const getLanguageError = () => {
        const languages = [];
        const sections  = [ "header", "body", "footer", "buttons" ];
        for (const { key, value } of tongues) {
            for (const section of sections) {
                if (Number(errors[`${key}-${section}`]) > 0) {
                    languages.push(value);
                    break;
                }
            }
        }
        if (languages.length > 0) {
            return NLS.pluralizeList("GENERAL_ERROR_LANGUAGE", languages);
        }
        return "";
    };


    // Handles the Change
    const handleChange = (section, name, value) => {
        let newData = { ...data };
        if (Utils.hasProp(initialData, name)) {
            newData = { ...data, [name] : value };
        } else {
            const elem       = { ...data.components[tongueID], [name] : value };
            const components = { ...data.components, [tongueID] : elem };
            newData = { ...data, components };
        }
        handleErrors(section, name);
        setData(newData);
    };

    // Handles the Value
    const handleData = (section, fields) => {
        const elem       = { ...data.components[tongueID], ...fields };
        const components = { ...data.components, [tongueID] : elem };
        const newData    = { ...data, components };
        handleErrors(section, ...Object.keys(fields));
        setData(newData);
    };

    // Removes a Global Error
    const handleErrors = (section, ...names) => {
        const newErrors = { ...errors };
        for (const name of names) {
            const key = getErrorKey(name);
            for (const errorKey of Object.keys(errors)) {
                if (errorKey.startsWith(key)) {
                    newErrors[errorKey] = "";
                }
            }
        }

        const sectionKey = getErrorKey(section, true);
        newErrors[sectionKey] = Number(errors[sectionKey]) - 1;
        setErrors(newErrors);
    };

    // Handles the Draft
    const handleDraft = () => {
        handleSubmit({ publish : 0 });
    };

    // Handles the Publish
    const handlePublish = () => {
        handleSubmit({ publish : 1 });
    };



    // Do the Render
    const showTongues = Boolean(tongues.length > 1);

    return <Main withNavigation>
        <WabaTemplateHeader
            href={goto}
            isEdit={isEdit}
            getValue={getValue}
        />

        <Content isLoading={loading || charging}>
            {showTongues && <TabList
                selected={tongueID}
                onClick={setTongueID}
            >
                {tongues.map(({ key, value }) => <TabItem
                    key={key}
                    message={value}
                    value={key}
                />)}
            </TabList>}

            <PageMain>
                <WabaTemplateEditor
                    loading={loading}
                    clientID={clientID}
                    hasTabs={showTongues}
                    getValue={getValue}
                    getError={getError}
                    onChange={handleChange}
                    setData={handleData}
                    onDraft={handleDraft}
                    onPublish={handlePublish}
                    onDelete={() => setShowDelete(true)}
                    onClose={handleClose}
                />
                <WabaTemplatePreview
                    clientID={clientID}
                    hasTabs={showTongues}
                    getValue={getValue}
                />
            </PageMain>
        </Content>

        <DeleteDialog
            open={showDelete}
            title="WABA_TEMPLATES_DELETE_TITLE"
            message="WABA_TEMPLATES_DELETE_TEXT"
            content={data.name}
            onSubmit={handleDelete}
            onClose={() => setShowDelete(false)}
        />
    </Main>;
}

export default WabaTemplatePage;
