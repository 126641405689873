import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";

// The API
import {
    CampaignExecution,
} from "Utils/API";



// The initial State
const initialState = {
    loading   : true,
    error     : false,
    edition   : 0,
    canCreate : false,
    canEdit   : false,
    canImport : false,
    canExport : false,
    list      : [],
    total     : 0,
    filters   : {
        search   : "",
        fromDate : "",
        toDate   : "",
    },
    sort      : {
        orderBy  : "createdTime",
        orderAsc : 0,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "CAMPAIGN_EXECUTION_LOADING" });
    },

    /**
     * Fetches the Campaign Execution List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  filters
     * @param {Object=}  sort
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, filters = {}, sort = {}) {
        const params = { ...filters, ...sort };
        Navigate.unsetParams(params);
        if (type === "CAMPAIGN") {
            params.campaignID = elemID;
        }
        const data = await CampaignExecution.getAll(params);
        data.filters = filters;
        data.sort    = sort;
        dispatch({ type : "CAMPAIGN_EXECUTION_LIST", data });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdDate = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.sendDate    = DateTime.formatDate(elem.sendTime,    "dashesTime");
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "CAMPAIGN_EXECUTION_LIST")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "CAMPAIGN_EXECUTION_LOADING":
        return {
            ...state,
            loading         : true,
        };

    case "CAMPAIGN_EXECUTION_LIST":
        return {
            ...state,
            loading         : false,
            error           : false,
            canCreate       : action.data.canCreate,
            canEdit         : action.data.canEdit,
            canImport       : action.data.canImport,
            canExport       : action.data.canExport,
            list            : Utils.parseList(action.data.list, parseElem),
            total           : action.data.total,
            filters         : action.data.filters,
            sort            : action.data.sort,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
