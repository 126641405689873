import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Conversations        from "Utils/Conversations";

// Dashboard
import Html                 from "Dashboard/Components/Common/Html";



// Styles
const Action = Styled(Html)`
    padding: 0 12px;
    text-align: center;
    color: var(--darkest-gray);
`;



/**
 * The Message Actions
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function MessageActions(props) {
    const { list } = props;


    // Do the Render
    return list.map((elem) => <Action
        key={elem.id}
        content={Conversations.getActionText(elem)}
    />);
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
MessageActions.propTypes = {
    list : PropTypes.array.isRequired,
};

export default MessageActions;
