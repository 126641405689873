import NLS                  from "Dashboard/Core/NLS";
import Commons              from "./Commons";



/**
 * Returns true if the Plan Type is Hotel
 * @param {String} planType
 * @returns {Boolean}
 */
function isHotel(planType) {
    return planType === "hotel";
}

/**
 * Returns true if the Plan Type is Contact
 * @param {String} planType
 * @returns {Boolean}
 */
function isContact(planType) {
    return planType === "contact";
}



/**
 * Returns the Amount Text
 * @param {Object} data
 * @returns {String}
 */
function getAmountText(data) {
    if (isHotel(data.planType)) {
        return String(data.roomAmount);
    }
    if (isContact(data.planType)) {
        return `${data.usedContactAmount} / ${data.contactAmount}`;
    }
    return "";
}

/**
 * Returns the Price Data for the Plans
 * @param {String} planType
 * @returns {Object}
 */
function getPriceData(planType) {
    return [
        {
            isHidden : !isContact(planType),
            priceKey : "planPrice",
            message  : "PLANS_PLAN_PRICE_IN",
        },
        {
            isHidden : !isHotel(planType),
            priceKey : "roomPrice",
            message  : "PLANS_ROOM_PRICE_IN",
        },
        {
            isHidden : !isContact(planType),
            priceKey : "contactPrice",
            message  : "PLANS_CONTACT_PRICE_IN",
        },
        {
            isHidden : false,
            priceKey : "setupPrice",
            message  : "PLANS_SETUP_PRICE_IN",
        },
    ];
}

/**
 * Returns a String for the Base Price
 * @param {String}   planType
 * @param {Object}   data
 * @param {Boolean=} withSetup
 * @returns {String}
 */
function getBasePrice(planType, data, withSetup = false) {
    let result = "";

    if (isHotel(planType)) {
        const roomPrice     = getSinglePrice(data.discountRoomPrice, data.roomPrice);
        const roomPriceText = Commons.formatPrice(roomPrice, data.currencySymbol, 2);
        result = NLS.format("PLANS_ROOM_PRICE_TEXT", roomPriceText);

    } else if (isContact(planType)) {
        const planPrice        = getSinglePrice(data.discountPlanPrice, data.planPrice);
        const contactPrice     = getSinglePrice(data.discountContactPrice, data.contactPrice);
        const planPriceText    = Commons.formatPrice(planPrice, data.currencySymbol);
        const contactPriceText = Commons.formatPrice(contactPrice, data.currencySymbol, 2);
        result = NLS.format("PLANS_CONTACT_PRICE_TEXT", planPriceText, contactPriceText);
    }

    if (withSetup) {
        const setupPrice     = getSinglePrice(data.discountSetupPrice, data.setupPrice);
        const setupPriceText = Commons.formatPrice(setupPrice, data.currencySymbol);
        result += ` + ${setupPriceText}`;
    }
    return result;
}

/**
 * Returns a String for the Base Price
 * @param {Number} discountedPrice
 * @param {Number} basePrice
 * @returns {Number}
 */
function getSinglePrice(discountedPrice, basePrice) {
    if (discountedPrice !== undefined) {
        return discountedPrice;
    }
    return basePrice;
}

/**
 * Returns a String for the Total Price
 * @param {String} planType
 * @param {Object} data
 * @param {Object} planPrices
 * @param {Object} addonPrices
 * @param {Object} connectorPrices
 * @returns {String}
 */
function getTotalPrice(planType, data, planPrices, addonPrices, connectorPrices) {
    const currencyID = Number(data.currencyID);
    const planID     = Number(data.planID);

    let prices = {};
    for (const planPrice of planPrices) {
        if (planPrice.currencyID === currencyID && planPrice.planID === planID) {
            prices = planPrice;
            break;
        }
    }

    // Calculate the total price for the addons
    let totalAddons = 0;
    for (const [ addonCode, addon ] of Object.entries(data.addons || {})) {
        if (!addon.isActive) {
            continue;
        }

        let price = 0;
        if (addon.priceFree) {
            price = 0;
        } else if (addon.price) {
            price = addon.price;
        } else {
            for (const addonPrice of addonPrices) {
                if (addonPrice.currencyID === data.currencyID && addonPrice.addonCode === addonCode) {
                    price = addonPrice.price;
                    break;
                }
            }
        }

        const quantity = Number(addon.quantity || 1);
        totalAddons += Number(price) * quantity;
    }

    // Calculate the total price for the connectors
    let totalConnectors = 0;
    for (const [ connectorCode, connector ] of Object.entries(data.connectors || {})) {
        if (!connector.isActive) {
            continue;
        }

        let price = 0;
        if (connector.priceFree) {
            price = 0;
        } else if (connector.price) {
            price = connector.price;
        } else {
            for (const connectorPrice of connectorPrices) {
                if (connectorPrice.currencyID === data.currencyID && connectorPrice.connectorCode === connectorCode) {
                    price = connectorPrice.price;
                    break;
                }
            }
        }

        const quantity = Number(connector.quantity || 1);
        totalConnectors += Number(price) * quantity;
    }

    // Calculate the total price
    const multiplier = data.discount ? (100 - Math.max(0, Math.min(100, data.discount))) / 100 : 1;
    const symbol     = prices.currencySymbol;
    const setupPrice = getPriceValue(data, prices, "setupPrice") * multiplier;

    totalAddons     *= multiplier;
    totalConnectors *= multiplier;

    if (isHotel(planType)) {
        const roomPrice  = getPriceValue(data, prices, "roomPrice") * multiplier;
        const roomAmount = Number(data.roomAmount);
        const total      = roomAmount * roomPrice + totalAddons + totalConnectors;
        return NLS.format(
            "CLIENTS_HOTEL_PRICE",
            Commons.formatPrice(roomPrice, symbol, 2),
            String(roomAmount),
            Commons.formatPrice(totalAddons, symbol),
            Commons.formatPrice(totalConnectors, symbol),
            Commons.formatPrice(setupPrice, symbol),
            Commons.formatPrice(total, symbol)
        );
    }

    if (isContact(planType)) {
        const planPrice    = getPriceValue(data, prices, "planPrice") * multiplier;
        const contactPrice = getPriceValue(data, prices, "contactPrice") * multiplier;
        const total        = planPrice + totalAddons + totalConnectors;
        return NLS.format(
            "CLIENTS_CONTACT_PRICE",
            Commons.formatPrice(planPrice, symbol),
            Commons.formatPrice(contactPrice, symbol),
            Commons.formatPrice(totalAddons, symbol),
            Commons.formatPrice(totalConnectors, symbol),
            Commons.formatPrice(setupPrice, symbol),
            Commons.formatPrice(total, symbol)
        );
    }

    const total = totalAddons + totalConnectors;
    return NLS.format(
        "CLIENTS_BASE_PRICE",
        Commons.formatPrice(totalAddons, symbol),
        Commons.formatPrice(totalConnectors, symbol),
        Commons.formatPrice(setupPrice, symbol),
        Commons.formatPrice(total, symbol)
    );
}

/**
 * Returns the Price value for the given Key
 * @param {Object} data
 * @param {Object} prices
 * @param {String} key
 * @returns {Number}
 */
function getPriceValue(data, prices, key) {
    if (Number(data[`${key}Free`]) === 1) {
        return 0;
    }
    return Number(data[key]) || Number(prices[key]) || 0;
}

/**
 * Returns the Amount
 * @param {Number} amount
 * @param {Number} minQuantity
 * @returns {Number}
 */
function getAmountQuantity(amount, minQuantity) {
    return Math.max(minQuantity, Math.ceil(amount / 1000));
}




// The public API
export default {
    isHotel,
    isContact,

    getAmountText,
    getPriceData,
    getBasePrice,
    getTotalPrice,
    getAmountQuantity,
};
