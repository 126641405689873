import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";

// Dashboard
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";



// Styles
const Tabs = Styled(TabList)`
    margin-top: var(--main-gap);
    padding-top: calc(var(--main-gap) * 2);
    padding-bottom: var(--main-gap);
    border-top: 1px solid var(--border-color-medium);
`;



/**
 * The Campaign Tongues
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CampaignTongues(props) {
    const { isHidden, tongueID, setTongueID } = props;

    const { selects : { tongues } } = Store.useState("campaign");


    // Do the Render
    return <Tabs
        isHidden={isHidden || tongues.length < 2}
        selected={tongueID}
        onClick={setTongueID}
    >
        {tongues.map(({ key, value }) => <TabItem
            key={key}
            message={value}
            value={key}
        />)}
    </Tabs>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CampaignTongues.propTypes = {
    isHidden    : PropTypes.bool,
    tongueID    : PropTypes.number.isRequired,
    setTongueID : PropTypes.func.isRequired,
};

export default CampaignTongues;
