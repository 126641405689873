import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import NLS                  from "Dashboard/Core/NLS";
import Utils                from "Dashboard/Utils/Utils";

// Components
import FlowInputs           from "./FlowInputs";
import FlowSimulation       from "../Simulation/FlowSimulation";

// Dashboard
import Details              from "Dashboard/Components/Details/Details";
import DetailList           from "Dashboard/Components/Details/DetailList";
import DetailItem           from "Dashboard/Components/Details/DetailItem";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";
import Form                 from "Dashboard/Components/Form/Form";
import InputField           from "Dashboard/Components/Form/InputField";
import Button               from "Dashboard/Components/Form/Button";
import ConversationItem     from "Components/Utils/Details/ConversationItem";
import HospitalityItem      from "Components/Utils/Details/HospitalityItem";
import QuotationItem        from "Components/Utils/Details/QuotationItem";
import OrderItem            from "Components/Utils/Details/OrderItem";
import ContactItem          from "Components/Utils/Details/ContactItem";
import AccountsItem         from "Components/Utils/Details/AccountsItem";



// Styles
const Container = Styled(Details).attrs(({ hasExternalTabs }) => ({ hasExternalTabs }))`
    height: ${(props) => props.hasExternalTabs ? "var(--page-height-tabs)" : "var(--page-height)"};

    @media (max-width: 1200px) {
        height: auto;
    }
`;

const Content = Styled(Form)`
    padding: 8px;
`;



/**
 * The Flow Editor Details
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function FlowEditorDetails(props) {
    const { hasTabs } = props;

    const { edition, nodes, publishErrors, languages } = Store.useState("flowEditor");
    const { editNode } = Store.useAction("flowEditor");

    const { hasChanges, inPublish, search, detailsTab, selectedNode } = Store.useState("flowState");
    const { setHasChanges, setAction, setDetailsTab, setSelectedNode } = Store.useAction("flowState");

    const { hasSimulation, conversation, contact, hospitality, quotation, order, accounts } = Store.useState("flowSimulation");


    // The Current State
    const [ results,  setResults  ] = React.useState([]);
    const [ data,     setData     ] = React.useState({ name : "" });
    const [ errors,   setErrors   ] = React.useState({ form : "" });
    const [ language, setLanguage ] = React.useState(languages > 0 ? languages[0].key : "es");

    // The Current Node
    const node = selectedNode ? Utils.getValue(nodes, "id", selectedNode) : {};


    // Updates the Data
    React.useEffect(() => {
        if (selectedNode) {
            const values = { name : node.name };
            for (const input of node.inputs) {
                if (input.type === "double") {
                    for (const field of input.fields) {
                        if (node.options && node.options[field.name] !== undefined) {
                            values[field.name] = node.options[field.name];
                        } else {
                            const initialValue = [ "toggle", "select" ].includes(field.type) ? 0 : "";
                            values[field.name] = field.startValue || initialValue;
                        }
                    }
                } else if (node.options && node.options[input.name] !== undefined) {
                    values[input.name] = node.options[input.name];
                } else {
                    const initialValue = [ "toggle", "select" ].includes(input.type) ? 0 : "";
                    values[input.name] = input.startValue || initialValue;
                }
            }

            const errors = Utils.getValue(publishErrors, "id", selectedNode, "errors");
            if (inPublish && errors) {
                setErrors({ ...errors, form : "" });
            } else {
                setErrors({ form : "" });
            }

            setData(values);
        }
    }, [ selectedNode ]);


    // Show the Publish Errors
    React.useEffect(() => {
        if (inPublish) {
            const errors = Utils.getValue(publishErrors, "id", selectedNode, "errors");
            if (errors) {
                setErrors({ ...errors, form : "" });
            }
        }
    }, [ inPublish ]);


    // Searches the Nodes
    React.useEffect(() => {
        if (search && search.length > 2) {
            const results = [];
            const value   = search.toLowerCase();
            for (const node of nodes) {
                if (node.name.toLowerCase().includes(value)) {
                    results.push(node);
                } else if (!node.name && NLS.get(node.message).toLowerCase().includes(value)) {
                    results.push(node);
                }
            }
            setResults(results);
        } else {
            setResults([]);
        }
    }, [ search, edition ]);


    // Handles the Select
    const handleSelect = (nodeID) => {
        if (hasChanges) {
            setAction("WARNING");
            return;
        }

        setDetailsTab("editor");
        setSelectedNode(nodeID);
        Utils.scrollIntoView(`.flow-node-${nodeID}`, "center", "center");
    };

    // Handles the Input Change
    const handleChange = (name, value, newChange = true) => {
        setData({ ...data, [name] : value });
        if (newChange) {
            setHasChanges(true);
        }
    };

    // Handles the Submit
    const handleSubmit = async () => {
        setErrors({ form : "" });
        try {
            const options = { ...data };
            delete options.name;
            await editNode(node.id, data.name, JSON.stringify(options));
            setHasChanges(false);
        } catch (errors) {
            setErrors(errors);
            window.setTimeout(() => Utils.scrollIntoView(".inputfield-error"), 200);
        }
    };

    // Returns the Error Data
    const getErrorData = (elem) => {
        const name   = elem.name || NLS.get(elem.message);
        const errors = [];
        if (elem.inputError) {
            errors.push(NLS.get("FLOWS_NODES_ERROR_INPUT"));
        }
        if (elem.cycleError) {
            errors.push(NLS.get("FLOWS_NODES_ERROR_CYCLE"));
        }
        if (elem.optionsError) {
            errors.push(NLS.pluralize("GENERAL_ERROR_OPTIONS", Utils.count(elem.errors)));
        }
        const error = NLS.format("GENERAL_ERROR_FORMAT", name, errors.join(", "));
        return { nodeID : elem.id, icon : elem.icon, error };
    };



    // Variables
    const inEditor        = detailsTab === "editor";
    const inSimulation    = detailsTab === "simulation";
    const inDetails       = detailsTab === "details";

    const hasErrors       = Boolean(inPublish && publishErrors.length > 0);
    const showErrors      = Boolean(hasErrors && inEditor);
    const hasResults      = Boolean(search && results.length > 0);
    const showResults     = Boolean(hasResults && inEditor);
    const hasEdit         = Boolean(selectedNode);
    const showEdit        = Boolean(hasEdit && inEditor);

    const showEditor      = Boolean(hasErrors || hasResults || hasEdit);
    const showContainer   = Boolean(showEditor || hasSimulation);
    const hasInternalTabs = Boolean(hasSimulation);
    const showSimulation  = Boolean(inSimulation || (!showEditor && inEditor && hasSimulation));
    const showDetails     = Boolean(hasInternalTabs && inDetails);
    const showLanguages   = Boolean(node.hasLanguages && languages.length > 1);
    const selectedTab     = showSimulation ? "simulation" : detailsTab;



    // Do the Render
    if (!showContainer) {
        return <React.Fragment />;
    }
    return <Container
        hasExternalTabs={hasTabs}
        hasTabs={hasInternalTabs}
        isInside
        isWide
    >
        {hasInternalTabs && <TabList
            selected={selectedTab}
            onClick={setDetailsTab}
            inDetails
        >
            <TabItem
                isHidden={!showEditor}
                message="GENERAL_EDITOR"
                value="editor"
            />
            <TabItem
                message="FLOWS_SIMULATION_NAME"
                value="simulation"
            />
            <TabItem
                message="GENERAL_DETAILS"
                value="details"
            />
        </TabList>}

        {showErrors && <DetailList
            icon="error"
            message="FLOWS_NODES_WITH_ERRORS"
            collapsible="flow-errors"
        >
            {publishErrors.map((elem) => {
                const { nodeID, error, icon } = getErrorData(elem);
                return <DetailItem
                    key={nodeID}
                    icon={icon}
                    message={error}
                    tooltip="GENERAL_ONE_ERROR"
                    isSelected={nodeID === selectedNode}
                    onClick={() => handleSelect(nodeID)}
                />;
            })}
        </DetailList>}

        {showResults && <DetailList
            icon="search"
            message="FLOWS_NODES_FOUND_NODES"
            collapsible="flow-search"
        >
            {results.map((elem) => <DetailItem
                key={elem.nodeID}
                icon={elem.icon}
                message={elem.name || elem.message}
                isSelected={elem.nodeID === selectedNode}
                onClick={() => handleSelect(elem.nodeID)}
            />)}
        </DetailList>}

        {showEdit && <DetailList
            icon={node.icon}
            message={node.message}
            collapsible="flow-edit"
        >
            <Content error={errors.form} onSubmit={handleSubmit} noAutoFocus>
                {showLanguages && <TabList
                    selected={language}
                    onClick={setLanguage}
                >
                    {languages.map(({ key, value }) => <TabItem
                        key={key}
                        message={value}
                        value={key}
                    />)}
                </TabList>}

                <InputField
                    name="name"
                    label="FLOWS_NODES_NAME"
                    value={data.name}
                    onChange={handleChange}
                />
                <FlowInputs
                    data={data}
                    errors={errors}
                    language={language}
                    onChange={handleChange}
                />
                <Button
                    variant="primary"
                    message="GENERAL_SAVE"
                    onClick={handleSubmit}
                    fullWidth
                />
            </Content>
        </DetailList>}

        <FlowSimulation
            open={showSimulation}
            hasExternalTabs={hasTabs}
            hasInternalTabs={hasInternalTabs}
            onEnd={() => setDetailsTab("editor")}
        />

        {showDetails && <>
            <ConversationItem
                collapsible="flow-conversation"
                elem={conversation}
            />
            <HospitalityItem
                collapsible="flow-hospitality"
                elem={hospitality}
            />
            <QuotationItem
                collapsible="flow-quotation"
                elem={quotation}
            />
            <OrderItem
                collapsible="flow-order"
                elem={order}
            />
            <ContactItem
                collapsible="flow-contact"
                action="CONTACT"
                elem={contact}
            />
            <AccountsItem
                collapsible="flow-accounts"
                list={accounts}
            />
        </>}
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
FlowEditorDetails.propTypes = {
    hasTabs : PropTypes.bool.isRequired,
};

export default FlowEditorDetails;
