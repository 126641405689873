// The Document Type IDs
const MAIN     = "main";
const PARTNER  = "partner";
const ADMIN    = "admin";
const RELEASES = "releases";



// The Document Types
const types = {
    MAIN,
    PARTNER,
    ADMIN,
    RELEASES,
};

export default types;
