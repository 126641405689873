import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";

// Components
import MediaContent         from "./MediaContent";

// Dashboard
import Dialog               from "Dashboard/Components/Dialog/Dialog";
import DialogHeader         from "Dashboard/Components/Dialog/DialogHeader";
import DialogBody           from "Dashboard/Components/Dialog/DialogBody";
import DialogFooter         from "Dashboard/Components/Dialog/DialogFooter";



/**
 * The Media Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function MediaDialog(props) {
    const {
        open, onClose, onSubmit,
        clientID, path, mediaType, initialSelect,
        multipleSelect, isRequired,
    } = props;

    const { canEdit } = Store.useState("media");


    // The Current State
    const [ selection, setSelection ] = React.useState(null);
    const [ create,    setCreate    ] = React.useState(false);



    // Do the Render
    return <Dialog
        open={open}
        onClose={onClose}
        zIndex={4000}
        isWide
        noTab
    >
        <DialogHeader
            message="MEDIA_SELECT_TITLE"
            icon="media"
        />
        <DialogBody withSpacing>
            <MediaContent
                clientID={clientID}
                path={path}
                mediaType={mediaType}
                create={create}
                endCreate={() => setCreate(false)}
                initialSelect={initialSelect}
                onSelect={setSelection}
                multipleSelect={multipleSelect}
                canSelect
                inDialog
            />
        </DialogBody>
        <DialogFooter
            primary="GENERAL_SELECT"
            secondary={canEdit ? "MEDIA_CREATE_TITLE" : ""}
            onSubmit={() => onSubmit(selection)}
            onSecondary={() => setCreate(true)}
            isDisabled={isRequired && !selection}
        />
    </Dialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
MediaDialog.propTypes = {
    open           : PropTypes.bool.isRequired,
    onClose        : PropTypes.func.isRequired,
    onSubmit       : PropTypes.func.isRequired,
    clientID       : PropTypes.number,
    path           : PropTypes.string,
    mediaType      : PropTypes.string,
    initialSelect  : PropTypes.string,
    multipleSelect : PropTypes.bool,
    isRequired     : PropTypes.bool,
};

export default MediaDialog;
