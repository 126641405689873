import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import { Channel }          from "Utils/API";



// The initial State
const initialState = {
    loading       : true,
    error         : false,
    edition       : 0,
    canCreate     : false,
    canEdit       : false,
    canImport     : false,
    canExport     : false,
    canProfile    : false,
    list          : [],
    total         : 0,
    elem          : {},
    profile       : {},
    verticals     : [],
    providerTypes : [],
    canAddPhone   : false,
    phoneNumbers  : [],
    flows         : [],
    statuses      : [],
    sort          : {
        orderBy  : "providerName",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "CHANNEL_LOADING" });
    },

    /**
     * Fetches the Channel List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, params = {}) {
        Navigate.unsetParams(params);
        if (type === "CLIENT") {
            params.clientID = elemID;
        }
        const data = await Channel.getAll(params);
        data.sort  = params;
        dispatch({ type : "CHANNEL_LIST", data });
    },

    /**
     * Fetches a single Channel
     * @param {Function} dispatch
     * @param {Number}   channelID
     * @returns {Promise}
     */
    async fetchElem(dispatch, channelID) {
        const data = await Channel.getOne({ channelID });
        dispatch({ type : "CHANNEL_ELEM", data });
    },

    /**
     * Fetches the Channel Edit data
     * @param {Function} dispatch
     * @param {Number}   clientID
     * @returns {Promise}
     */
    async fetchEditData(dispatch, clientID) {
        dispatch({ type : "CHANNEL_LOADING" });
        const data = await Channel.getEditData({ clientID });
        dispatch({ type : "CHANNEL_EDIT", data });
    },

    /**
     * Fetches the Channel Profile
     * @param {Function} dispatch
     * @param {Number}   channelID
     * @returns {Promise}
     */
    async fetchProfile(dispatch, channelID) {
        const data = await Channel.getProfile({ channelID });
        dispatch({ type : "CHANNEL_PROFILE", data });
    },

    /**
     * Adds a Channel
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    async addChannel(dispatch, data) {
        return Channel.add(data);
    },

    /**
     * Edits a Channel
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editChannel(dispatch, data) {
        return Channel.edit(data);
    },

    /**
     * Edits a Channel Profile
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editProfile(dispatch, data) {
        return Channel.editProfile(data);
    },

    /**
     * Deletes a Channel
     * @param {Function} dispatch
     * @param {Number}   channelID
     * @returns {Promise}
     */
    deleteChannel(dispatch, channelID) {
        return Channel.delete({ channelID });
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "CHANNEL_LIST", "CHANNEL_ELEM", "CHANNEL_PROFILE", "CHANNEL_EDIT")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "CHANNEL_LOADING":
        return {
            ...state,
            loading       : true,
        };

    case "CHANNEL_LIST":
        return {
            ...state,
            loading       : false,
            error         : false,
            canCreate     : action.data.canCreate,
            canEdit       : action.data.canEdit,
            canImport     : action.data.canImport,
            canExport     : action.data.canExport,
            list          : action.data.list,
            total         : action.data.total,
            sort          : action.data.sort,
        };

    case "CHANNEL_ELEM":
        return {
            ...state,
            error         : false,
            edition       : state.edition + 1,
            elem          : action.data.elem,
            flows         : action.data.flows,
            statuses      : action.data.statuses,
        };

    case "CHANNEL_PROFILE":
        return {
            ...state,
            error         : false,
            edition       : state.edition + 1,
            canProfile    : action.data.canProfile,
            profile       : action.data.profile,
            verticals     : action.data.verticals,
        };

    case "CHANNEL_EDIT":
        return {
            ...state,
            error         : false,
            loading       : false,
            edition       : state.edition + 1,
            providerTypes : action.data.providerTypes,
            canAddPhone   : action.data.canAddPhone,
            phoneNumbers  : action.data.phoneNumbers,
            flows         : action.data.flows,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
