import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import { Team }             from "Utils/API";



// The initial State
const initialState = {
    loading   : true,
    error     : false,
    edition   : 0,
    canCreate : false,
    canEdit   : false,
    canImport : false,
    canExport : false,
    list      : [],
    total     : 0,
    elem      : {},
    values    : [],
    teams     : [],
    users     : [],
    colors    : [],
    statuses  : [],
    sort      : {
        orderBy  : "name",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "TEAM_LOADING" });
    },

    /**
     * Fetches the Team List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, params = {}) {
        Navigate.unsetParams(params);
        if (type === "CLIENT") {
            params.clientID = elemID;
        }
        const data = await Team.getAll(params);
        data.sort  = params;
        dispatch({ type : "TEAM_LIST", data });
    },

    /**
     * Fetches a single Team
     * @param {Function} dispatch
     * @param {Number}   teamID
     * @returns {Promise}
     */
    async fetchElem(dispatch, teamID) {
        const data = await Team.getOne({ teamID });
        dispatch({ type : "TEAM_ELEM", data });
    },

    /**
     * Fetches the Team Edit data
     * @param {Function} dispatch
     * @param {Number}   clientID
     * @param {Number=}  userID
     * @returns {Promise}
     */
    async fetchEditData(dispatch, clientID, userID = 0) {
        const data = await Team.getEditData({ clientID, userID });
        dispatch({ type : "TEAM_EDIT", data });
    },

    /**
     * Creates an Team
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    createTeam(dispatch, data) {
        return Team.create(data);
    },

    /**
     * Edits the given Team
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editTeam(dispatch, data) {
        return Team.edit(data);
    },

    /**
     * Edits the Teams of the User
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editUser(dispatch, data) {
        return Team.editUser(data);
    },

    /**
     * Deletes a Team
     * @param {Function} dispatch
     * @param {Number}   teamID
     * @returns {Promise}
     */
    deleteTeam(dispatch, teamID) {
        return Team.delete({ teamID });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.usersText = NLS.pluralize("TEAMS_USERS", elem.userCount);
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "TEAM_LIST", "TEAM_ELEM", "TEAM_EDIT")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "TEAM_LOADING":
        return {
            ...state,
            loading   : true,
        };

    case "TEAM_LIST":
        return {
            ...state,
            loading   : false,
            error     : false,
            canCreate : action.data.canCreate,
            canEdit   : action.data.canEdit,
            canImport : action.data.canImport,
            canExport : action.data.canExport,
            list      : Utils.parseList(action.data.list, parseElem),
            total     : action.data.total,
            sort      : action.data.sort,
        };

    case "TEAM_ELEM":
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            elem      : parseElem(action.data.elem),
            users     : action.data.users,
            colors    : action.data.colors,
            statuses  : action.data.statuses,
        };

    case "TEAM_EDIT":
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            values    : action.data.values,
            teams     : action.data.teams,
            users     : action.data.users,
            colors    : action.data.colors,
            statuses  : action.data.statuses,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
