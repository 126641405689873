import Store                from "Dashboard/Core/Store";



// The initial State
const initialState = {
    notificationsSupported : false,
    notificationGranted    : false,
    notificationDenied     : false,

    showChatSupport        : false,
    chatSupportUnread      : 0,
};



// The Actions
const actions = {
    /**
     * Sets the Notifications Supported
     * @param {Function} dispatch
     * @returns {Void}
     */
    setNotificationsSupported(dispatch) {
        dispatch({ type : "GLOBAL_NOTIFICATIONS_SUPPORTED" });
    },

    /**
     * Sets the Notification Granted
     * @param {Function} dispatch
     * @param {Boolean}  granted
     * @returns {Void}
     */
    setNotificationsGranted(dispatch, granted) {
        dispatch({ type : "GLOBAL_NOTIFICATIONS_GRANTED", granted });
    },

    /**
     * Sets the Notification Denied
     * @param {Function} dispatch
     * @param {Boolean}  denied
     * @returns {Void}
     */
    setNotificationsDenied(dispatch, denied) {
        dispatch({ type : "GLOBAL_NOTIFICATIONS_DENIED", denied });
    },

    /**
     * Sets the Show Chat Support
     * @param {Function} dispatch
     * @param {Boolean}  show
     * @returns {Void}
     */
    setShowChatSupport(dispatch, show) {
        dispatch({ type : "GLOBAL_SHOW_CHAT_SUPPORT", show });
    },

    /**
     * Sets the Chat Support Unread
     * @param {Function} dispatch
     * @param {Number}   unread
     * @returns {Void}
     */
    setChatSupportUnread(dispatch, unread) {
        dispatch({ type : "GLOBAL_CHAT_SUPPORT_UNREAD", unread });
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
    case "GLOBAL_NOTIFICATIONS_SUPPORTED":
        return {
            ...state,
            notificationsSupported : true,
        };

    case "GLOBAL_NOTIFICATIONS_GRANTED":
        return {
            ...state,
            notificationsGranted   : action.granted,
        };

    case "GLOBAL_NOTIFICATIONS_DENIED":
        return {
            ...state,
            notificationsDenied    : action.denied,
        };

    case "GLOBAL_SHOW_CHAT_SUPPORT":
        return {
            ...state,
            showChatSupport        : action.show,
        };

    case "GLOBAL_CHAT_SUPPORT_UNREAD":
        return {
            ...state,
            chatSupportUnread      : action.unread,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
