import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import { Tongue }           from "Utils/API";



// The initial State
const initialState = {
    loading   : true,
    error     : false,
    edition   : 0,
    canCreate : false,
    canEdit   : false,
    canImport : false,
    canExport : false,
    list      : [],
    total     : 0,
    elem      : {},
    languages : [],
    colors    : [],
    sort      : {
        orderBy  : "position",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "TONGUE_LOADING" });
    },

    /**
     * Fetches the Tongue List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, params = {}) {
        Navigate.unsetParams(params);
        if (type === "CLIENT") {
            params.clientID = elemID;
        }
        const data = await Tongue.getAll(params);
        data.sort  = params;
        dispatch({ type : "TONGUE_LIST", data });
    },

    /**
     * Fetches a single Tongue
     * @param {Function} dispatch
     * @param {Number}   tongueID
     * @returns {Promise}
     */
    async fetchElem(dispatch, tongueID) {
        const data = await Tongue.getOne({ tongueID });
        dispatch({ type : "TONGUE_ELEM", data });
    },

    /**
     * Fetches the Tongue Edit data
     * @param {Function} dispatch
     * @returns {Promise}
     */
    async fetchEditData(dispatch) {
        const data = await Tongue.getEditData();
        dispatch({ type : "TONGUE_EDIT", data });
    },

    /**
     * Edits/Creates a Tongue
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editTongue(dispatch, data) {
        return Tongue.edit(data);
    },

    /**
     * Deletes a Tongue
     * @param {Function} dispatch
     * @param {Number}   tongueID
     * @returns {Promise}
     */
    deleteTongue(dispatch, tongueID) {
        return Tongue.delete({ tongueID });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.isDefaultText = Utils.toYesNo(elem.isDefault);
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "TONGUE_LIST", "TONGUE_ELEM", "TONGUE_EDIT")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "TONGUE_LOADING":
        return {
            ...state,
            loading   : true,
        };

    case "TONGUE_LIST":
        return {
            ...state,
            loading   : false,
            error     : false,
            canCreate : action.data.canCreate,
            canEdit   : action.data.canEdit,
            canImport : action.data.canImport,
            canExport : action.data.canExport,
            list      : Utils.parseList(action.data.list, parseElem),
            total     : action.data.total,
            sort      : action.data.sort,
        };

    case "TONGUE_ELEM":
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            elem      : parseElem(action.data.elem),
            languages : action.data.languages,
            colors    : action.data.colors,
        };

    case "TONGUE_EDIT":
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            languages : action.data.languages,
            colors    : action.data.colors,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
