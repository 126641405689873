import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import { Hotel }            from "Utils/API";



// The initial State
const initialState = {
    loading             : true,
    charging            : false,
    error               : false,
    edition             : 0,
    canCreate           : false,
    canEdit             : false,
    canImport           : false,
    canExport           : false,
    list                : [],
    total               : 0,
    elem                : {},
    canAddHospitality   : false,
    canAddBookingEngine : false,
    ftpHostname         : "",
    ftpPort             : "",
    pmsProviders        : [],
    bookingProviders    : [],
    defaultFields       : [],
    hospitalityFields   : [],
    contactFields       : [],
    statuses            : [],
    sort                : {
        orderBy  : "name",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "HOTEL_LOADING" });
    },

    /**
     * Fetches the Hotel List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, params = {}) {
        Navigate.unsetParams(params);
        if (type === "CLIENT") {
            params.clientID = elemID;
        }
        const data = await Hotel.getAll(params);
        data.sort  = params;
        dispatch({ type : "HOTEL_LIST", data });
    },

    /**
     * Fetches a single Hotel
     * @param {Function} dispatch
     * @param {Number}   hotelID
     * @returns {Promise}
     */
    async fetchElem(dispatch, hotelID) {
        dispatch({ type : "HOTEL_CHARGING" });
        const data = await Hotel.getOne({ hotelID });
        dispatch({ type : "HOTEL_ELEM", data });
    },

    /**
     * Fetches the Hotel Edit data
     * @param {Function} dispatch
     * @param {Number}   clientID
     * @returns {Promise}
     */
    async fetchEditData(dispatch, clientID) {
        dispatch({ type : "HOTEL_CHARGING" });
        const data = await Hotel.getEditData({ clientID });
        dispatch({ type : "HOTEL_EDIT", data });
    },

    /**
     * Edits/Creates a Hotel
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editHotel(dispatch, data) {
        return Hotel.edit(data);
    },

    /**
     * Deletes a Hotel
     * @param {Function} dispatch
     * @param {Number}   hotelID
     * @returns {Promise}
     */
    deleteHotel(dispatch, hotelID) {
        return Hotel.delete({ hotelID });
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "HOTEL_LIST", "HOTEL_ELEM", "HOTEL_EDIT")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "HOTEL_LOADING":
        return {
            ...state,
            loading             : true,
        };

    case "HOTEL_CHARGING":
        return {
            ...state,
            charging            : true,
        };

    case "HOTEL_LIST":
        return {
            ...state,
            loading             : false,
            error               : false,
            canCreate           : action.data.canCreate,
            canEdit             : action.data.canEdit,
            canImport           : action.data.canImport,
            canExport           : action.data.canExport,
            list                : action.data.list,
            total               : action.data.total,
            sort                : action.data.sort,
        };

    case "HOTEL_ELEM":
        return {
            ...state,
            charging            : false,
            error               : false,
            edition             : state.edition + 1,
            elem                : action.data.elem,
            canAddHospitality   : action.data.canAddHospitality,
            canAddBookingEngine : action.data.canAddBookingEngine,
            ftpHostname         : action.data.ftpHostname,
            ftpPort             : action.data.ftpPort,
            pmsProviders        : action.data.pmsProviders,
            bookingProviders    : action.data.bookingProviders,
            defaultFields       : action.data.defaultFields,
            hospitalityFields   : action.data.hospitalityFields,
            contactFields       : action.data.contactFields,
            statuses            : action.data.statuses,
        };

    case "HOTEL_EDIT":
        return {
            ...state,
            charging            : false,
            error               : false,
            edition             : state.edition + 1,
            canAddHospitality   : action.data.canAddHospitality,
            canAddBookingEngine : action.data.canAddBookingEngine,
            ftpHostname         : action.data.ftpHostname,
            ftpPort             : action.data.ftpPort,
            pmsProviders        : action.data.pmsProviders,
            bookingProviders    : action.data.bookingProviders,
            defaultFields       : action.data.defaultFields,
            hospitalityFields   : action.data.hospitalityFields,
            contactFields       : action.data.contactFields,
            statuses            : action.data.statuses,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
