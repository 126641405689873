import React                from "react";
import Store                from "Dashboard/Core/Store";

// Dashboard
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableCell            from "Dashboard/Components/Table/TableCell";



/**
 * The Dashboard Users
 * @returns {React.ReactElement}
 */
function DashboardUsers() {
    const { loading, list } = Store.useState("dashboard");


    // Do the Render
    return <Table
        isLoading={loading}
        none="USERS_NONE_AVAILABLE"
        hasTabs
        noSorting
    >
        <TableHead>
            <TableHeader field="name"                  message="USERS_SINGULAR"              />
            <TableHeader field="currentProgress"       message="DASHBOARD_TOTAL_PROGRESS"    align="center" maxWidth="80"  />
            <TableHeader field="currentFollowup"       message="DASHBOARD_TOTAL_FOLLOWUP"    align="center" maxWidth="100" />
            <TableHeader field="resolved"              message="DASHBOARD_TOTAL_RESOLVED"    align="center" maxWidth="70"  />
            <TableHeader field="avgFirstMessage"       message="DASHBOARD_AVG_FIRST_MESSAGE" align="center" maxWidth="170" />
            <TableHeader field="avgMessage"            message="DASHBOARD_AVG_MESSAGE"       align="center" maxWidth="130" />
            <TableHeader field="avgResolved"           message="DASHBOARD_AVG_RESOLVED"      align="center" maxWidth="130" />
            <TableHeader field="percentQualifications" message="DASHBOARD_QUALIFICATIONS"    align="center" maxWidth="100" />
        </TableHead>
        <TableBody>
            {list.map((elem) => <TableRow key={elem.id} elemID={elem.id}>
                <TableCell message={elem.name}                  />
                <TableCell message={elem.currentProgress}       />
                <TableCell message={elem.currentFollowup}       />
                <TableCell message={elem.resolved}              />
                <TableCell message={elem.avgFirstMessage}       />
                <TableCell message={elem.avgMessage}            />
                <TableCell message={elem.avgResolved}           />
                <TableCell message={elem.percentQualifications} />
            </TableRow>)}
        </TableBody>
    </Table>;
}

export default DashboardUsers;
