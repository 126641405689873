import React                from "react";
import PropTypes            from "prop-types";
import { Bar }              from "react-chartjs-2";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";



// Styles
const Container = Styled.div`
    box-sizing: border-box;
    height: var(--report-height);
    padding: var(--main-gap);
    border: var(--border-width) solid var(--border-color-light);
    border-radius: var(--border-radius);
`;



/**
 * The Stacked Bar
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function StackedBar(props) {
    const { labels, amounts, colors } = props;

    // Chart Data
    const options = {
        maintainAspectRatio : false,
        interaction : {
            mode      : "index",
            intersect : false,
        },
        scales : {
            y : {
                stacked : true,
                min     : 0,
                ticks   : {
                    precision : 0,
                },
            },
            x : {
                stacked : true,
            },
        },
        plugins : {
            tooltip : {
                cornerRadius  : 10,
                padding       : 8,
                boxPadding    : 4,
                usePointStyle : true,
            },
            legend : {
                labels   : {
                    usePointStyle : true,
                },
            },
        },
    };

    const dataset = {
        labels   : labels,
        datasets : Object.entries(amounts).map(([ label, data ], index) => {
            const color = colors[index] || "";
            return {
                label           : NLS.get(label),
                data            : data,
                borderWidth     : 1,
                borderRadius    : 6,
                borderColor     : color,
                backgroundColor : color.replace(")", ", 0.8)"),
            };
        }),
    };


    // Do the Render
    return <Container>
        <Bar
            // @ts-ignore
            options={options}
            data={dataset}
        />
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
StackedBar.propTypes = {
    labels  : PropTypes.array.isRequired,
    amounts : PropTypes.object.isRequired,
    colors  : PropTypes.array.isRequired,
};

export default StackedBar;
