import React                from "react";
import Action               from "Dashboard/Core/Action";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";
import Conversations        from "Utils/Conversations";
import Commons              from "Utils/Commons";

// Components
import OrderFilter          from "./OrderFilter";
import OrderEdit            from "./OrderEdit";
import ContactEdit          from "Components/App/Client/Contact/Dialogs/ContactEdit";

// Dashboard
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import Html                 from "Dashboard/Components/Common/Html";



/**
 * The Order List
 * @returns {React.ReactElement}
 */
function OrderList() {
    const { fetch, load, loadFilter } = useList("storeOrder", "STORE");

    const { storeID, storeSubID } = Navigate.useParams();
    const navigate                = Commons.useGoto();
    const conversationsNavigate   = Conversations.useGoto();


    const { elem : store } = Store.useState("store");

    const data = Store.useState("storeOrder");
    const { loading, canEdit, list, total, filters, sort, elem } = data;

    const { openDetails } = Store.useAction("core");
    const { fetchElem, removeElem } = Store.useAction("storeOrder");

    const { isFiltering, action : stateAction } = Store.useState("storeState");
    const { setAction, setFiltering, setOrder } = Store.useAction("storeState");

    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Store change
    React.useEffect(() => {
        if (storeID) {
            load();
        }
    }, [ storeID ]);

    // Starts an Action
    React.useEffect(() => {
        if (isFiltering) {
            startAction("FILTER");
        }
    }, [ isFiltering ]);

    // Starts an Action
    React.useEffect(() => {
        if (stateAction.isOrder || stateAction.isContact) {
            startAction(stateAction, elem.id);
        }
    }, [ stateAction.name ]);

    // Removes the Order on exit
    React.useEffect(() => {
        return () => {
            removeElem();
            setOrder(false);
        };
    }, []);

    // Handles the Order change
    React.useEffect(() => {
        if (storeSubID) {
            fetchElem(storeSubID);
            openDetails();
            setOrder(true);
        }
    }, [ storeSubID ]);


    // Handles the Action
    const handleAction = (action, elemID) => {
        if (action.isView) {
            if (elemID !== storeSubID) {
                navigate("STORES", storeID, "ORDERS", elemID);
            } else {
                openDetails();
            }
        } else if (action.isConversation) {
            const conversationNumber = Utils.getValue(list, "id", elemID, "conversationNumber");
            conversationsNavigate(conversationNumber);
        } else {
            startAction(action, elemID);
        }
    };

    // Handles the Filter Submit
    const handleFilter = (filters) => {
        loadFilter(filters);
        removeElem();
        handleClose();
    };

    // Handles the Edit Submit
    const handleEdit = () => {
        fetch();
        if (elemID === elem.id) {
            fetchElem(elem.id);
        }
        handleClose();
    };

    // Handles the Close
    const handleClose = () => {
        endAction();
        setFiltering(false);
        setAction();
    };


    // Do the Render
    const listElem = Utils.getValue(list, "orderID", elemID);
    const order    = listElem.id ? listElem : elem;

    return <>
        <Table
            fetch={load}
            sort={sort}
            none="ORDERS_NONE_AVAILABLE"
            isLoading={loading}
            hasTabs
        >
            <TableHead>
                <TableHeader field="createdTime"   message="GENERAL_CREATED"   isTitle minWidth="160" maxWidth="160"  />
                <TableHeader field="orderNumber"   message="ORDERS_SINGULAR"   maxWidth="80" />
                <TableHeader field="contactName"   message="CONTACTS_SINGULAR" />
                <TableHeader field="totalQuantity" message="GENERAL_QUANTITY"  align="center" maxWidth="80" />
                <TableHeader field="totalPrice"    message="GENERAL_TOTAL"     align="right" maxWidth="90" />
                <TableHeader field="statusName"    message="GENERAL_STATUS"    align="center" maxWidth="100" />
            </TableHead>
            <TableBody>
                {list.map((elem) => <TableRow
                    key={elem.orderID}
                    elemID={elem.orderID}
                    isSelected={elem.orderID === storeSubID}
                >
                    <TableCell message={elem.createdDateTime} />
                    <TableCell message={elem.orderNumberText} />
                    <TableCell message={elem.contactName}     />
                    <TableCell message={elem.totalQuantity}   />
                    <TableCell><Html>{elem.totalPriceText}</Html></TableCell>
                    <TableCell message={elem.statusName}      textColor={elem.statusColor} />
                </TableRow>)}
            </TableBody>
            <TablePaging total={total} />
            <TableActionList onAction={handleAction} canEdit={canEdit}>
                <TableAction action="VIEW"         message="ORDERS_VIEW_TITLE"        />
                <TableAction action="CONVERSATION" message="CONVERSATIONS_VIEW_TITLE" />
                <TableAction action="ORDER"        message="ORDERS_EDIT_TITLE"        />
                <TableAction action="CONTACT"      message="CONTACTS_EDIT_TITLE"      />
            </TableActionList>
        </Table>

        <OrderFilter
            open={action.isFilter}
            filters={filters}
            clientID={store.clientID}
            onSubmit={handleFilter}
            onClose={handleClose}
        />
        <OrderEdit
            open={action.isOrder}
            elemID={order.id}
            onSubmit={handleEdit}
            onClose={handleClose}
        />
        <ContactEdit
            open={action.isContact}
            elemID={order.contactID}
            clientID={order.clientID}
            onSubmit={handleEdit}
            onClose={handleClose}
        />
    </>;
}

export default OrderList;
