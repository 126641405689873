import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Order Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function OrderEdit(props) {
    const { open, elemID, conversationID, onClose, onSubmit } = props;

    const { elem } = Store.useState("store");
    const { editElem, statuses } = Store.useState("storeOrder");
    const { fetchEditData, editOrder } = Store.useAction("storeOrder");


    // The Initial Data
    const initialData = {
        orderID      : 0,
        status       : "",
        cancelReason : "",
    };

    // Handles the Set
    const handleSet = () => {
        if (editElem.id) {
            setElem({ ...editElem });
        } else {
            setElem({ ...initialData });
        }
    };

    // Handles the Edit
    const handleEdit = (data) => {
        return editOrder({ ...data, conversationID });
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("storeOrder", initialData, handleEdit, onSubmit);

    // Load the Data
    const { loading } = useDialog("storeOrder", open, elemID, { storeID : elem.storeID }, handleSet, fetchEditData);


    // Do the Render
    return <EditDialog
        open={open}
        icon="order"
        title={editElem.name || "ORDERS_EDIT_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <InputField
            type="radio"
            name="status"
            label="GENERAL_STATUS"
            options={statuses}
            value={data.status}
            error={errors.status}
            onChange={handleChange}
            isRequired
        />
        <InputField
            isHidden={data.status !== "Cancelled"}
            type="textarea"
            name="cancelReason"
            label="GENERAL_CANCEL_REASON"
            value={data.cancelReason}
            error={errors.cancelReason}
            onChange={handleChange}
            maxLength={255}
            isRequired
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
OrderEdit.propTypes = {
    open           : PropTypes.bool.isRequired,
    onClose        : PropTypes.func.isRequired,
    onSubmit       : PropTypes.func.isRequired,
    elemID         : PropTypes.number,
    conversationID : PropTypes.number,
};

/**
 * The Default Properties
 * @type {Object} defaultProps
 */
OrderEdit.defaultProps = {
    elemID         : 0,
    conversationID : 0,
};

export default OrderEdit;
