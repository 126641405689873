import React                from "react";
import PropTypes            from "prop-types";
import { useMsal }          from "@azure/msal-react";

// Components
import LoginButton          from "./LoginButton";



/**
 * The Microsoft Login
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function MicrosoftLogin(props) {
    const { onSuccess, isSignup } = props;
    const { instance } = useMsal();


    // Handles the Microsoft Login
    const handleLogin = () => {
        instance.loginPopup({
            scopes : [ "email" ],
        }).then(onSuccess).catch(() => {});
    };


    // Do the Render
    return <LoginButton
        onClick={handleLogin}
        logo="Microsoft"
        message={isSignup ? "JOIN_MICROSOFT" : "LOGIN_MICROSOFT"}
    />;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
MicrosoftLogin.propTypes = {
    onSuccess : PropTypes.func.isRequired,
    isSignup  : PropTypes.bool,
};

export default MicrosoftLogin;
