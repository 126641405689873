let isLoaded  = false;
let initAppID = "";


/**
 * Loads the Facebook script
 * @returns {Void}
 */
function load() {
    if (isLoaded) {
        return;
    }
    isLoaded = true;

    (function (id) {
        const fjs = document.getElementsByTagName("script")[0];
        const js  = document.createElement("script");
        if (document.getElementById(id)) {
            return;
        }
        js.id  = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
    }("facebook-jssdk"));
}

/**
 * Initializes a Facebook App
 * @param {String=} appID
 * @param {String=} version
 * @returns {Promise}
 */
function initialize(appID = process.env.REACT_APP_META_ID, version = "v16.0") {
    return new Promise((resolve) => {
        if (initAppID === appID) {
            resolve();
            return;
        }

        initAppID = appID;
        const params = {
            appId   : appID,
            cookie  : true,
            xfbml   : true,
            version : version,
        };

        if (window.FB) {
            window.FB.init(params);
            resolve();
            return;
        }

        window.fbAsyncInit = () => {
            window.FB.init(params);
            resolve();
        };
    });
}

/**
 * Returns the Login Status
 * @returns {Promise}
 */
function getLoginStatus() {
    return new Promise((resolve) => {
        window.FB.getLoginStatus((response) => {
            if (response.status === "connected" && response.authResponse.accessToken) {
                resolve(response.authResponse.accessToken);
            } else {
                resolve();
            }
        });
    });
}

/**
 * Shows the Login Dialog
 * @param {Object} options
 * @returns {Promise}
 */
function login(options) {
    return new Promise((resolve) => {
        window.FB.login((response) => {
            if (response.status === "connected" && response.authResponse.accessToken) {
                resolve(response.authResponse.accessToken);
            } else if (response.authResponse && response.authResponse.code) {
                resolve(response.authResponse.code);
            } else {
                resolve();
            }
        }, options);
    });
}

/**
 * Does a Logouts
 * @returns {Promise}
 */
function logout() {
    return new Promise((resolve) => {
        window.FB.logout((response) => {
            window.setTimeout(() => {
                resolve();
            }, 1000);
        });
        window.setTimeout(() => {
            resolve();
        }, 3000);
    });
}

/**
 * Does an API call
 * @param {String} route
 * @returns {Promise}
 */
function api(route) {
    return new Promise((resolve) => {
        window.FB.api(route, (response) => {
            resolve(response);
        });
    });
}




// The public API
export default {
    load,
    initialize,
    getLoginStatus,
    login,
    logout,
    api,
};
