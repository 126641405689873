import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Period               from "Dashboard/Utils/Period";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Components
import ChannelIconList      from "Components/Utils/Common/ChannelIconList";
import FieldsInputs         from "Components/Utils/Inputs/FieldsInputs";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import DialogMessage        from "Dashboard/Components/Dialog/DialogMessage";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";
import Button               from "Dashboard/Components/Form/Button";
import NoneAvailable        from "Dashboard/Components/Common/NoneAvailable";



// Styles
const Results = Styled(DialogMessage)`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 32px;
`;

const Title = Styled(DialogMessage)`
    margin: 0;
    color: var(--title-color);
    font-weight: bold;
`;

const Text = Styled(DialogMessage)`
    margin: 0 0 16px 0;
`;

const Contact = Styled.div`
    padding: 8px 12px;
    border-radius: var(--border-radius);
    background-color: var(--lighter-gray);
    cursor: pointer;

    .input-content {
        background: transparent;
    }
    div:empty {
        display: none;
    }
`;

const Label = Styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;



/**
 * The Contact Combine Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ContactCombine(props) {
    const { open, contact, onClose, onSubmit } = props;
    const { clientID, contactID } = contact;

    const { fields, contacts, hasInstagram, hasTelegram } = Store.useState("contactMerge");
    const { fetchCombineData } = Store.useAction("contactMerge");


    // The Current State
    const [ contactIDs,  setContactIDs  ] = React.useState([]);
    const [ showResults, setShowResults ] = React.useState(false);

    // The Initial Data
    const initialData = {
        period    : 0,
        fromDate  : "",
        toDate    : "",
        fullName  : "",
        email     : "",
        cellphone : "",
        instagram : "",
        telegram  : "",
        address   : "",
    };
    for (const field of fields) {
        initialData[field.customFieldID] = "";
    }

    // Handles the Set
    const handleSet = () => {
        setData({
            fullName  : contact.fullName  || "",
            email     : contact.email     || "",
            cellphone : contact.cellphone || "",
        });
        setShowResults(false);
        setContactIDs([]);
    };

    // Handles the Period Change
    const handlePeriod = (name, period) => {
        const fromDate = Period.getFromDate(period);
        const toDate   = Period.getToDate(period);
        setData({ period, fromDate, toDate });
    };

    // Handles the Filter
    const handleFilter = async () => {
        await fetchCombineData(clientID, contactID, data, contactIDs);
        setShowResults(true);
    };

    // Handles the Contact
    const handleContact = (contactID) => {
        let newContactIDs = [];
        if (contactIDs.includes(contactID)) {
            newContactIDs = contactIDs.filter((id) => id !== contactID);
        } else {
            newContactIDs = [ ...contactIDs, contactID ];
        }
        setContactIDs(newContactIDs);
    };

    // Handles the Contact
    const handleSubmit = () => {
        if (contactIDs.length) {
            onSubmit([ contactID, ...contactIDs ]);
        }
    };


    // The Form State
    const {
        data, errors, setData, handleChange,
    } = useForm("contactMerge", initialData);

    // Load the Data
    const { loading } = useDialog("contactMerge", open, 0, { clientID }, handleSet);


    // Do the Render
    const hasOne = (hasInstagram && !hasTelegram) || (!hasInstagram && hasTelegram);

    return <EditDialog
        open={open}
        icon="merge"
        title="CONTACTS_MERGE_TITLE"
        error={errors.form}
        primary="GENERAL_MERGE"
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isDisabled={!contactIDs.length}
    >
        <Columns amount="3">
            <InputField
                type="select"
                name="period"
                label="PERIOD_NAME"
                value={data.period}
                onChange={handlePeriod}
                options={Period.getSelect()}
            />
            <InputField
                type="date"
                name="fromDate"
                label="GENERAL_FROM"
                value={data.fromDate}
                onChange={handleChange}
            />
            <InputField
                type="date"
                name="toDate"
                label="GENERAL_TO"
                value={data.toDate}
                onChange={handleChange}
            />
        </Columns>

        <Columns>
            <InputField
                className={hasOne ? "columns-double" : ""}
                name="fullName"
                label="GENERAL_FULL_NAME"
                value={data.fullName}
                onChange={handleChange}
            />

            <InputField
                type="email"
                name="email"
                label="GENERAL_EMAIL"
                value={data.email}
                onChange={handleChange}
            />
            <InputField
                type="tel"
                name="cellphone"
                label="GENERAL_CELLPHONE"
                value={data.cellphone}
                onChange={handleChange}
            />

            <InputField
                isHidden={!hasInstagram}
                name="instagram"
                label="CONTACTS_INSTAGRAM"
                value={data.instagram}
                onChange={handleChange}
            />
            <InputField
                isHidden={!hasTelegram}
                name="telegram"
                label="CONTACTS_TELEGRAM"
                value={data.telegram}
                onChange={handleChange}
            />

            <InputField
                name="address"
                label="CONTACTS_ADDRESS"
                value={data.address}
                onChange={handleChange}
            />
            <FieldsInputs
                fields={fields}
                data={data}
                onChange={handleChange}
            />
        </Columns>

        <DialogMessage>
            <Button
                variant="primary"
                message="GENERAL_FILTER"
                onClick={handleFilter}
            />
        </DialogMessage>

        <Results isHidden={!showResults}>
            {!contacts.length ? <NoneAvailable
                message="CONTACTS_NONE_AVAILABLE"
            /> : <>
                <Title
                    variant="h3"
                    message="CONTACTS_COMBINE_TITLE"
                />
                <Text
                    variant="p"
                    message="CONTACTS_COMBINE_TEXT"
                />
                {contacts.map((elem) => <Contact key={elem.id}>
                    <InputField
                        type="checkbox"
                        name="contactID"
                        value={contactIDs.includes(elem.contactID) ? 1 : 0}
                        onChange={() => handleContact(elem.contactID)}
                    >
                        <Label>
                            <ChannelIconList items={elem.providerItems} />
                            <b>{elem.fullName}</b>
                            <div>{elem.email}</div>
                            <div>{elem.cellphone}</div>
                        </Label>
                    </InputField>
                </Contact>)}
            </>}
        </Results>
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ContactCombine.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    contact  : PropTypes.object.isRequired,
};

export default ContactCombine;
