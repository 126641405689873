import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import NLS                  from "Dashboard/Core/NLS";
import Utils                from "Dashboard/Utils/Utils";

// Dashboard
import Icon                 from "Dashboard/Components/Common/Icon";



// Styles
const Container = Styled.section.attrs(({ columns }) => ({ columns }))`
    box-sizing: border-box;
    position: absolute;
    top: 40px;
    left: 8px;
    max-width: calc(100vw - 44px);
    display: grid;
    grid-template-columns: repeat(${(props) => props.columns}, 1fr);
    grid-gap: 12px 6px;
    padding: 16px 8px;
    background-color: white;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    z-index: 5;

    @media screen and (max-width: 450px) {
        grid-template-columns: repeat(auto-fill, minmax(64px, 1fr));
    }
`;

const Item = Styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(var(--flow-nodes-size) + 24px);
    cursor: pointer;
`;

const NodeIcon = Styled(Icon)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--flow-nodes-size);
    height: var(--flow-nodes-size);
    background-color: var(--dark-gray);
    border-radius: var(--border-radius);
    margin-bottom: 4px;
    font-size: 24px;
    transition: all 0.2s;

    :hover {
        background-color: var(--darker-gray);
    }
`;

const Name = Styled.span`
    font-size: 12px;
    text-align: center;
`;



/**
 * The Flow Add Node
 * @returns {React.ReactElement}
 */
function FlowAddNode() {
    const { nodeTypes } = Store.useState("flowEditor");
    const { action } = Store.useState("flowState");
    const { setAction, setNodeCreating, setSelectedNodes } = Store.useAction("flowState");

    // The References
    const menuRef = React.useRef();


    // Add the Close handler
    React.useEffect(() => {
        if (open) {
            window.addEventListener("click", handleClose);
            return () => window.removeEventListener("click", handleClose);
        }
        return () => {};
    }, [ open ]);


    // Close the Menu
    const handleClose = (e) => {
        if (!menuRef.current) {
            return;
        }
        if (menuRef && Utils.inRef(e.clientX, e.clientY, menuRef)) {
            return;
        }
        setAction();
        e.stopPropagation();
        e.preventDefault();
    };

    // Handles the Create
    const handleCreate = (e, node) => {
        node.startX = e.clientX;
        node.startY = e.clientY;
        setSelectedNodes([]);
        setNodeCreating(node);
        setAction();
    };



    // Do the Render
    const nodeList = Object.values(nodeTypes).filter(({ isValid, hasInput }) => isValid && hasInput);
    const columns  = Math.ceil(nodeList.length / 3);

    if (!action.isCreate) {
        return <React.Fragment />;
    }
    return <Container ref={menuRef} columns={columns}>
        {nodeList.map((node) => <Item
            key={node.key}
            onClick={(e) => handleCreate(e, node)}
        >
            <NodeIcon icon={node.icon} />
            <Name>{NLS.get(node.message)}</Name>
        </Item>)}
    </Container>;
}

export default FlowAddNode;
