import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";

// Components
import MessageMedia         from "./MessageMedia";

// Dashboard
import Html                 from "Dashboard/Components/Common/Html";



// Styles
const Container = Styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-left: 4px solid;
    padding: 8px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: var(--border-radius);
    opacity: 0.6;

    .bubble-media {
        max-width: 100px;
        max-height: 64px;
    }
`;

const Text = Styled(Html)`
    max-height: 84px;
    overflow: hidden;
    text-overflow: ellipsis;
`;



/**
 * The Message Reply
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function MessageReply(props) {
    const { item } = props;

    const files = item.replyFiles || [];


    // Do the Render
    if (!item.replyToID) {
        return <React.Fragment />;
    }
    return <Container>
        {files.map((file) => <MessageMedia
            key={file.fileID}
            media={file}
        />)}
        <Text
            content={item.replyMessage}
            addBreaks
            formatText
        />
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
MessageReply.propTypes = {
    item : PropTypes.object,
};

/**
 * The Default Properties
 * @type {Object} defaultProps
 */
MessageReply.defaultProps = {
    item : {},
};

export default MessageReply;
