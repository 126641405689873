import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";

// Components
import ChannelIcon          from "./ChannelIcon";



// Styles
const List = Styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;



/**
 * The Channel Icon List
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ChannelIconList(props) {
    return <List>
        {props.items.map((item) => <ChannelIcon
            key={item.key}
            icon={item.icon}
            color={item.color}
            size={24}
        />)}
    </List>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ChannelIconList.propTypes = {
    items : PropTypes.array.isRequired,
};

export default ChannelIconList;
