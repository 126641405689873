import React                from "react";
import PropTypes            from "prop-types";
import Conversations        from "Utils/Conversations";

// Dashboard
import DetailList           from "Dashboard/Components/Details/DetailList";
import DetailItem           from "Dashboard/Components/Details/DetailItem";



/**
 * The Conversations Detail Item
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ConversationsItem(props) {
    const { list, collapsible } = props;

    const navigate = Conversations.useGoto();


    // Handles the Conversation click
    const handleConversation = (elem) => {
        const url = Conversations.statusToUrl(elem.status, false);
        navigate(url, elem.number);
    };


    // Do the Render
    return <DetailList
        isHidden={!list.length}
        message="CONVERSATIONS_NAME"
        collapsible={collapsible}
    >
        {list.map((elem) => <DetailItem
            key={elem.id}
            icon={elem.providerIcon}
            message={elem.message}
            tooltip="CONVERSATIONS_SINGULAR"
            onClick={() => handleConversation(elem)}
        />)}
    </DetailList>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ConversationsItem.propTypes = {
    list        : PropTypes.array.isRequired,
    collapsible : PropTypes.string,
};

export default ConversationsItem;
