import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Period               from "Dashboard/Utils/Period";
import Utils                from "Dashboard/Utils/Utils";
import useFilter            from "Dashboard/Hooks/Filter";

// Dashboard
import FilterDialog         from "Dashboard/Components/Dialogs/FilterDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Subscription Filter Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function SubscriptionFilter(props) {
    const { open, partnerID, forAdmin, forPartner, filters, onClose, onSubmit } = props;

    const { partners, clients, idsPerPartner } = Store.useState("subscription");


    // The Initial Data
    const initialData = {
        period     : 0,
        fromDate   : "",
        toDate     : "",
        partnerIDs : [],
        clientIDs  : [],
    };

    // The Filter State
    const {
        loading, data, handleChange, handlePeriod, handleSubmit,
    } = useFilter("subscription", open, initialData, filters, onSubmit, { partnerID });

    // Selects
    const getClients = Utils.useSelectList(loading, data.partnerIDs, clients, idsPerPartner);


    // Do the Render
    return <FilterDialog
        open={open}
        title="SUBSCRIPTIONS_FILTER_TITLE"
        initialData={initialData}
        data={data}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <Columns amount="3">
            <InputField
                type="select"
                name="period"
                label="PERIOD_NAME"
                value={data.period}
                onChange={handlePeriod}
                options={Period.getSelect()}
            />
            <InputField
                type="date"
                name="fromDate"
                label="GENERAL_FROM"
                value={data.fromDate}
                onChange={handleChange}
            />
            <InputField
                type="date"
                name="toDate"
                label="GENERAL_TO"
                value={data.toDate}
                onChange={handleChange}
            />
        </Columns>

        <InputField
            isHidden={!forAdmin}
            type="chooser"
            name="partnerIDs"
            label="PARTNERS_NAME"
            options={partners}
            value={data.partnerIDs}
            onChange={handleChange}
        />
        <InputField
            isHidden={!forAdmin && !forPartner}
            type="chooser"
            name="clientIDs"
            label="CLIENTS_NAME"
            options={getClients()}
            value={data.clientIDs}
            onChange={handleChange}
        />
    </FilterDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
SubscriptionFilter.propTypes = {
    open       : PropTypes.bool.isRequired,
    partnerID  : PropTypes.number.isRequired,
    forAdmin   : PropTypes.bool.isRequired,
    forPartner : PropTypes.bool.isRequired,
    onClose    : PropTypes.func.isRequired,
    onSubmit   : PropTypes.func.isRequired,
    filters    : PropTypes.object,
};

export default SubscriptionFilter;
