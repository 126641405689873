import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import { Media }            from "Utils/API";



// The initial State
const initialState = {
    loading   : false,
    error     : false,
    canCreate : false,
    canEdit   : false,
    list      : [],
    path      : "",
};



// The Actions
const actions = {
    /**
     * Fetches the Media List
     * @param {Function} dispatch
     * @param {Number=}  clientID
     * @param {String=}  mediaType
     * @param {String=}  path
     * @param {String=}  selected
     * @returns {Promise}
     */
    async fetchMedia(dispatch, clientID = 0, mediaType = "", path = "", selected = "") {
        dispatch({ type : "MEDIA_LOADING" });
        const params = { clientID, mediaType, path, selected };
        const data   = await Media.getAll(params);
        dispatch({ type : "MEDIA_LIST", data });
        return data;
    },

    /**
     * Create Directory
     * @param {Function} dispatch
     * @param {Number}   clientID
     * @param {String}   path
     * @param {String}   name
     * @returns {Promise}
     */
    createDirectory(dispatch, clientID, path, name) {
        return Media.create({ clientID, path, name });
    },

    /**
     * Uploads a Media
     * @param {Function} dispatch
     * @param {Number}   clientID
     * @param {String}   path
     * @param {Object}   file
     * @returns {Promise}
     */
    uploadMedia(dispatch, clientID, path, file) {
        return Media.upload({ clientID, path, file });
    },

    /**
     * Renames a Media
     * @param {Function} dispatch
     * @param {Number}   clientID
     * @param {String}   path
     * @param {String}   oldName
     * @param {String}   newName
     * @returns {Promise}
     */
    renameMedia(dispatch, clientID, path, oldName, newName) {
        return Media.rename({ clientID, path, oldName, newName });
    },

    /**
     * Moves a Media
     * @param {Function} dispatch
     * @param {Number}   clientID
     * @param {String}   oldPath
     * @param {String}   newPath
     * @param {String}   name
     * @returns {Promise}
     */
    moveMedia(dispatch, clientID, oldPath, newPath, name) {
        return Media.move({ clientID, oldPath, newPath, name });
    },

    /**
     * Deletes Media
     * @param {Function} dispatch
     * @param {Number}   clientID
     * @param {String}   path
     * @param {String}   name
     * @returns {Promise}
     */
    deleteMedia(dispatch, clientID, path, name) {
        return Media.delete({ clientID, path, name });
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "MEDIA_LIST")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "MEDIA_LOADING":
        return {
            ...state,
            loading   : true,
        };

    case "MEDIA_LIST":
        return {
            ...state,
            loading   : false,
            error     : false,
            canCreate : action.data.canCreate,
            canEdit   : action.data.canEdit,
            list      : action.data.list,
            path      : action.data.path,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
