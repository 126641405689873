import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Dashboard/Core/NLS";

// Components
import ProviderButton       from "Components/Utils/Common/ProviderButton";
import GoogleAuth           from "Components/Utils/Login/GoogleAuth";

// Dashboard
import Html                 from "Dashboard/Components/Common/Html";



/**
 * The Gmail Button
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function GmailButton(props) {
    const { isHidden, provider, onInstall } = props;


    // Do the Render
    if (isHidden) {
        return <React.Fragment />;
    }

    return <>
        <ProviderButton
            logo={provider.logo}
            color={provider.color}
            message={provider.fullName}
        />

        <section>
            <p>{NLS.get("CHANNELS_GMAIL_TEXT")}</p>
            <Html
                variant="p"
                message="CHANNELS_GMAIL_START"
            />
        </section>

        <GoogleAuth
            scope="https://www.googleapis.com/auth/gmail.modify"
            onChange={onInstall}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
GmailButton.propTypes = {
    isHidden  : PropTypes.bool.isRequired,
    provider  : PropTypes.object.isRequired,
    onInstall : PropTypes.func.isRequired,
};

export default GmailButton;
