import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";



// Constants
const ARROW_SIZE = 10;
const LINE_SIZE  = 10;
const EXTRA_SIZE = 30;



// Styles
const SVG = Styled.svg.attrs(({ isCreating, isSelected, isDark }) => ({ isCreating, isSelected, isDark }))`
    --edge-color: ${(props) => props.isSelected ? "var(--primary-color)" : (props.isDark ? "var(--black-color)" : "var(--dark-gray)")};

    position: absolute;
    top: 0;
    left: 0;
    transform-origin: top left;
    pointer-events: none;
    z-index: ${(props) => props.isCreating ? 4 : (props.isSelected || props.isDark ? 2 : 1)};
`;

const Path = Styled.path.attrs(({ isCreating }) => ({ isCreating }))`
    fill: none;
    stroke: var(--edge-color);
    stroke-width: 2;
    transition: stroke 0.1s;
    pointer-events: ${(props) => props.isCreating ? "none" : "all"};
    cursor: pointer;

    &:hover {
        stroke: var(--primary-color);
    }
    &:hover + polygon {
        fill: var(--primary-color);
    }
`;

const Polygon = Styled.polygon`
    fill: var(--edge-color);
    stroke: none;
    transition: fill 0.1s;
`;



/**
 * The Flow Edge
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function FlowEdge(props) {
    const { elem, startX, startY, endX, endY, isCreating } = props;

    const { selectedEdge, selectedNodes } = Store.useState("flowState");


    // Style Variables
    const xSize    = Math.abs(endX - startX);
    const ySize    = Math.abs(endY - startY);
    const isBig    = xSize > LINE_SIZE * 2 + ARROW_SIZE;
    const isHidden = xSize < LINE_SIZE * 2 && ySize < LINE_SIZE * 2;
    const toBottom = startY < endY;
    const toRight  = startX < endX && isBig;
    const width    = xSize + (toRight ? 0 : EXTRA_SIZE * 2);
    const height   = ySize + ARROW_SIZE * 2;
    const top      = toBottom ? startY : endY - ARROW_SIZE + 1;
    const left     = toRight ? startX : startX - width + EXTRA_SIZE;
    const bottom   = ySize + ARROW_SIZE + (toBottom ? 1 : 2);


    // Creates the Path
    const getPath = () => {
        const mult = toBottom ? 1 : -1;
        const AY   = toBottom ? 1 : bottom - 2;
        const ZY   = toBottom ? bottom - ARROW_SIZE : ARROW_SIZE;

        // Curve to the Right
        if (toRight) {
            const curve = ySize < LINE_SIZE * 2 ? (bottom - ARROW_SIZE) / 2 : LINE_SIZE;

            const AX = 0;
            const ZX = width - ARROW_SIZE;
            const BX = ZX / 2 - curve;
            const BY = AY;

            const CX = BX + curve;
            const CY = BY;
            const DX = CX;
            const DY = CY + curve * mult;

            const EX = DX;
            const EY = ZY - curve * mult;

            const FX = EX;
            const FY = ZY;
            const GX = FX + curve;
            const GY = ZY;

            return `
                M ${AX},${AY}
                L ${BX},${BY}
                Q ${CX},${CY} ${DX},${DY}
                L ${EX},${EY}
                Q ${FX},${FY} ${GX},${GY}
                L ${ZX},${ZY}
            `;
        }

        // Curve in an S shape
        const curve = ySize < LINE_SIZE * 4 ? (bottom - ARROW_SIZE) / 4 : LINE_SIZE;

        const AX = width - EXTRA_SIZE;
        const ZX = endX - left - ARROW_SIZE;
        const BX = AX + curve;
        const BY = AY;

        const CX = BX + curve;
        const CY = BY;
        const DX = CX;
        const DY = CY + curve * mult;

        const EX = DX;
        const EY = bottom / 2 - curve * mult;

        const FX = EX;
        const FY = EY + curve * mult;
        const GX = FX - curve;
        const GY = FY;

        const HX = EXTRA_SIZE - curve;
        const HY = GY;

        const IX = HX - curve;
        const IY = HY;
        const JX = IX;
        const JY = IY + curve * mult;

        const KX = JX;
        const KY = ZY - curve * mult;

        const LX = JX;
        const LY = ZY;
        const MX = LX + curve;
        const MY = ZY;

        return `
            M ${AX},${AY}
            L ${BX},${BY}
            Q ${CX},${CY} ${DX},${DY}
            L ${EX},${EY}
            Q ${FX},${FY} ${GX},${GY}
            L ${HX},${HY}
            Q ${IX},${IY} ${JX},${JY}
            L ${KX},${KY}
            Q ${LX},${LY} ${MX},${MY}
            L ${ZX},${ZY}
        `;
    };

    // Creates the Arrow Points
    const getArrow = () => {
        const AX = (toRight ? width : endX - left) - ARROW_SIZE;
        const AY = toBottom ? bottom - ARROW_SIZE : ARROW_SIZE;
        const HW = ARROW_SIZE / 2;
        return `${AX} ${AY - HW}, ${AX + ARROW_SIZE} ${AY}, ${AX} ${AY + HW}`;
    };



    // Do the Render
    if (isHidden) {
        return <React.Fragment />;
    }

    const isSelected    = Boolean(selectedEdge && elem && selectedEdge.id === elem.id);
    const isNotSelected = Boolean(selectedEdge && elem && selectedEdge.id !== elem.id);
    const isDark        = Boolean(!isNotSelected && (!elem || !selectedNodes.length || selectedNodes.includes(elem.fromNodeID) || selectedNodes.includes(elem.toNodeID)));

    const style         = {
        width     : `${width}px`,
        height    : `${height}px`,
        transform : `translate(${left}px, ${top}px)`,
    };

    return <SVG
        style={style}
        isCreating={isCreating}
        isSelected={isSelected}
        isDark={isDark}
    >
        <Path
            data-action="selectEdge"
            data-id={elem ? elem.id : 0}
            isCreating={isCreating}
            d={getPath()}
        />
        <Polygon points={getArrow()} />
    </SVG>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
FlowEdge.propTypes = {
    elem       : PropTypes.object,
    startX     : PropTypes.number,
    startY     : PropTypes.number,
    endX       : PropTypes.number,
    endY       : PropTypes.number,
    isCreating : PropTypes.bool,
};

export default FlowEdge;
