import React                from "react";
import Store                from "Dashboard/Core/Store";

// Components
import OrderItem            from "Components/Utils/Details/OrderItem";
import ContactItem          from "Components/Utils/Details/ContactItem";

// Dashboard
import ConversationItem     from "Components/Utils/Details/ConversationItem";



/**
 * The Order Details
 * @returns {React.ReactElement}
 */
function OrderDetails() {
    const { elem, contact, conversation } = Store.useState("storeOrder");
    const { setAction } = Store.useAction("storeState");


    // Do the Render
    const hasConversation = Boolean(conversation.id);

    return <>
        <OrderItem
            collapsible="order"
            onAction={setAction}
            action="ORDER"
            elem={elem}
            canEdit
        />
        <ContactItem
            collapsible="order"
            onAction={setAction}
            action="CONTACT"
            elem={contact}
            canEdit
        />
        {hasConversation && <ConversationItem
            collapsible="order"
            elem={conversation}
        />}
    </>;
}

export default OrderDetails;
