import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Components
import TextareaField        from "Components/Utils/Inputs/TextareaField";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Reply Assistant Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ReplyAssistant(props) {
    const { open, clientID, conversationID, forNote, onSubmit, onClose } = props;

    const { variables, channelLinks, assistants, messageLength } = Store.useState("conversation");
    const { generateConversation } = Store.useAction("conversation");


    // The Current State
    const [ generated, setGenerated ] = React.useState(false);

    // The Initial Data
    const initialData = {
        clientID        : 0,
        conversationID  : 0,
        assistantID     : 0,
        prompt          : "",
        includeMessages : 0,
        includeNotes    : 0,
        message         : "",
    };

    // Handles the Set
    const handleSet = (data) => {
        setElem({
            ...data,
            assistantID : assistants[0]?.key || 0,
        });
    };

    // Handles the Edit
    const handleEdit = async () => {
        const response = await generateConversation({ ...data, conversationID });
        if (response.result) {
            setGenerated(true);
            handleChange("message", response.result);
        }
    };

    // Handles the Use
    const handleUse = () => {
        onSubmit(data.message);
    };

    // The Form State
    const {
        loading, data, errors, setElem, handleChange, handleSubmit,
    } = useForm("conversation", initialData, handleEdit);

    // Load the Data
    useDialog("conversation", open, 0, {
        clientID, conversationID,
    }, handleSet);


    // Do the Render
    return <EditDialog
        open={open}
        icon="assistant"
        title={forNote ? "CONVERSATIONS_GENERATE_NOTE_TITLE" : "CONVERSATIONS_GENERATE_REPLY_TITLE"}
        error={errors.form}
        primary="CONVERSATIONS_GENERATE"
        secondary={data.message ? (forNote ? "CONVERSATIONS_USE_NOTE" : "CONVERSATIONS_USE_REPLY") : ""}
        onSubmit={handleSubmit}
        onSecondary={handleUse}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <InputField
            type="select"
            name="assistantID"
            label="ASSISTANTS_SINGULAR"
            options={assistants}
            value={data.assistantID}
            error={errors.assistantID}
            onChange={handleChange}
            isRequired
        />
        <TextareaField
            name="prompt"
            label={forNote ? "CONVERSATIONS_GENERATE_NOTE" : "CONVERSATIONS_GENERATE_REPLY"}
            value={data.prompt}
            error={errors.prompt}
            variables={variables}
            channelLinks={channelLinks}
            maxLength={messageLength}
            onChange={handleChange}
            withoutFormat
            withoutEmoji
            isRequired
        />
        <InputField
            type="toggle"
            name="includeMessages"
            label="CONVERSATIONS_GENERATE_INCLUDE_MESSAGES"
            value={data.includeMessages}
            onChange={handleChange}
            withBorder
        />
        <InputField
            type="toggle"
            name="includeNotes"
            label="CONVERSATIONS_GENERATE_INCLUDE_NOTES"
            value={data.includeNotes}
            onChange={handleChange}
            withBorder
        />
        <InputField
            isHidden={!generated}
            type="textarea"
            name="message"
            label={forNote ? "CONVERSATIONS_GENERATED_NOTE" : "CONVERSATIONS_GENERATED_REPLY"}
            value={data.message}
            onChange={handleChange}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ReplyAssistant.propTypes = {
    open           : PropTypes.bool.isRequired,
    clientID       : PropTypes.number.isRequired,
    conversationID : PropTypes.number.isRequired,
    forNote        : PropTypes.bool.isRequired,
    onSubmit       : PropTypes.func.isRequired,
    onClose        : PropTypes.func.isRequired,
};

export default ReplyAssistant;
