import React                from "react";
import PropTypes            from "prop-types";
import { useGoogleLogin }   from "@react-oauth/google";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";

// Dashboard
import HyperLink            from "Dashboard/Components/Link/HyperLink";



// Styles
const Container = Styled.div`
    border: 1px solid var(--input-border-color);
    border-radius: var(--border-radius);
    padding: 16px;
`;

const Button = Styled.div`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 210px;
    min-height: 40px;
    padding: 2px 12px 2px 2px;
    font-family: var(--google-font);
    font-weight: 500;
    letter-spacing: 0.60px;
    color: white;
    background-color: #1a73e8;
    border-radius: 4px;
    transition: all 0.218s;
    cursor: pointer;

    :hover {
        background-color: #5b94ee;
    }
`;

const Icon = Styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    background-color: white;
    margin-right: 14px;
`;

const Image = Styled.img`
    display: block;
    width: 18px;
`;

const Terms = Styled.p`
    margin: 16px 0 0 0;
    padding: 0;
    font-size: 0.8em;
    color: rgba(21, 28, 41, 0.48);
`;



/**
 * The Google Auth
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function GoogleAuth(props) {
    const { scope, onChange } = props;


    // Handles the Google Auth
    const handleAuth = useGoogleLogin({
        flow      : "auth-code",
        scope     : scope,
        onSuccess : (response) => {
            if (response.code) {
                onChange(response.code);
            }
        },
    });


    // Do the Render
    return <Container>
        <Button onClick={handleAuth}>
            <Icon>
                <Image
                    src={`${process.env.PUBLIC_URL}/logos/Google.svg`}
                    alt={NLS.get("CHANNELS_GMAIL_LOGIN")}
                />
            </Icon>
            {NLS.get("CHANNELS_GMAIL_LOGIN")}
        </Button>

        <Terms>
            {NLS.get("CHANNELS_GMAIL_TERMS_PRE")}
            <HyperLink
                href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
                target="_blank"
                message="CHANNELS_GMAIL_TERMS_LINK"
            />
            {NLS.get("CHANNELS_GMAIL_TERMS_POST")}
        </Terms>
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
GoogleAuth.propTypes = {
    scope    : PropTypes.string.isRequired,
    onChange : PropTypes.func.isRequired,
};

export default GoogleAuth;
