const urls = {

    // General Urls
    NONE                : "",
    ADD                 : "add",
    CREATE              : "create",
    EDIT                : "edit",
    REPORT              : "report",
    REPORTS             : "reports",

    // Auth Urls
    LOGIN               : "login",
    PASS_RECOVER        : "recover",
    PASS_CODE           : "code",
    PASS_RESET          : "reset",
    PASS_RESET_CODE     : "reset/:code",
    PASS_CHANGE         : "change",
    JOIN_ORGANIZATION   : "join/:code",

    // Profile Urls
    PROFILE             : "profile",
    ORGANIZATIONS       : "organizations",

    // Admin Urls
    CLIENTS             : "clients",
    CLIENT              : "clients/:clientID",
    CLIENT_STORES       : "clients/:clientID/stores",
    CLIENT_SETTINGS     : "clients/:clientID/settings",
    SUBSCRIPTIONS       : "subscriptions",

    // Partner Urls
    PARTNERS            : "partners",
    PARTNER             : "partners/:partnerID",
    PARTNER_CLIENT      : "partners/:partnerID/clients/:clientID",
    PARTNER_SETTINGS    : "partners/:partnerID/clients/:clientID/settings",

    // Config Urls
    TAGS                : "tags",
    CUSTOM_FIELDS       : "fields",
    PHONE_NUMBERS       : "phone-numbers",

    // Dashboard Urls
    DASHBOARD           : "dashboard",
    DASHBOARD_TAB       : "dashboard/:dashboardTab",

    // Conversation Urls
    CONVERSATIONS       : "conversations",
    CONVERSATION_TAB    : "conversations/:conversationTab",
    CONVERSATION        : "conversations/:conversationTab/:conversationID",
    QUEUE               : "queue",
    PROGRESS            : "progress",
    FOLLOWUP            : "followup",
    RESOLVED            : "resolved",
    IN_FLOW             : "flow",

    // Store Urls
    STORES              : "stores",
    STORE               : ":storeID",
    STORE_TAB           : ":storeID/:storeTab",
    STORE_SUB           : ":storeID/:storeTab/:storeSubID",
    LIST                : "list",
    ORDERS              : "orders",
    PRODUCTS            : "products",
    CATEGORIES          : "categories",
    BANNERS             : "banners",
    PERSONALIZE         : "personalize",

    // Contact Urls
    CONTACTS            : "contacts",
    CONTACT             : "contacts/:contactID",

    // Hospitality Urls
    HOSPITALITIES       : "hospitality",
    HOSPITALITY         : "hospitality/:hospitalityID",

    // Account Urls
    ACCOUNTS            : "accounts",
    ACCOUNT             : "accounts/:accountID",

    // Campaign Urls
    CAMPAIGNS           : "campaigns",
    CAMPAIGN            : "campaigns/:campaignID",
    CAMPAIGN_TAB        : "campaigns/:campaignID/:campaignTab",
    DISPATCHES          : "dispatches",

    // User Urls
    USERS               : "users",
    USER                : "users/:userID",

    // Team Urls
    TEAMS               : "teams",

    // Message Template Urls
    MSG_TEMPLATES       : "message-templates",
    MSG_TEMPLATE        : "message-templates/:msgTemplateID",
    MSG_TEMPLATE_ADD    : "message-templates/create",
    MSG_TEMPLATE_COPY   : "message-templates/create/:msgTemplateID",

    // Questionnaire Urls
    QUESTIONNAIRES      : "frequent-questions",
    QUESTIONNAIRE       : "frequent-questions/:questionnaireID",
    QUESTIONNAIRE_ADD   : "frequent-questions/create",
    QUESTIONNAIRE_COPY  : "frequent-questions/create/:questionnaireID",

    // Schedule Urls
    SCHEDULES           : "schedules",
    SCHEDULE            : "schedules/:scheduleID",
    SCHEDULE_ADD        : "schedules/create",
    SCHEDULE_COPY       : "schedules/create/:scheduleID",

    // Hotel Urls
    HOTELS              : "hotels",
    HOTEL               : "hotels/:hotelID",
    HOTELS_ADD          : "hotels/create",

    // Channel Urls
    CHANNELS            : "channels",
    CHANNELS_ADD        : "channels/add",

    // WABA Template Urls
    WABA_TEMPLATES      : "waba-templates",
    WABA_TEMPLATE       : "waba-templates/:wabaTemplateID",
    WABA_TEMPLATE_ADD   : "waba-templates/create",
    WABA_TEMPLATE_COPY  : "waba-templates/create/:wabaTemplateID",

    // Assistants AI Urls
    ASSISTANTS          : "assistants",
    ASSISTANT           : "assistants/:assistantID",
    ASSISTANT_ADD       : "assistants/create",
    ASSISTANTS_MODELS   : "assistants-models",

    // Widget Urls
    WIDGETS             : "widgets",
    WIDGET              : "widgets/:widgetID",
    WIDGET_TAB          : "widgets/:widgetID/:widgetTab",
    MAIN                : "main",
    CHAT                : "chat",
    PLATFORMS           : "platforms",
    ICON                : "icon",

    // Flow Urls
    FLOWS               : "flows",
    FLOW_ONE            : "flow",
    FLOW                : "flows/:flowID",
    FLOW_TAB            : "flows/:flowID/:flowTab",

    // Integration Urls
    INTEGRATIONS        : "integrations",
    INTEGRATION         : "integrations/:integrationID",
    INTEGRATION_TAB     : "integrations/:integrationID/:integrationTab",
    EXECUTIONS          : "executions",

    // Connector Urls
    CONNECTORS          : "connectors",
    CONNECTOR           : "connectors/:connectorID",
    CONNECTOR_ADD       : "connectors/create",

    // Ticket Urls
    TICKETS             : "tickets",
    TICKET              : "tickets/:ticketID",
    TICKET_TYPES        : "ticket-types",

    // Document Urls
    DOCUMENTS           : "documents",
    DOCUMENTS_TYPE      : "documents/:documentLanguage/:documentType",
    DOCUMENT            : "documents/:documentLanguage/:documentType/:documentSlug",

    // Setup Urls
    SETTINGS            : "settings",
    ADMINS              : "admins",
    PLANS               : "plans",
    ADDONS              : "addons",
    CURRENCIES          : "currencies",
    LANGUAGES           : "languages",
    COUNTRIES           : "countries",
    PROVINCES           : "provinces",
    MEDIA               : "files",

    // Monitoring Urls
    MONITORING          : "monitoring",

    // Email Urls
    EMAIL_TEMPLATES     : "emails-templates",
    EMAIL_QUEUE         : "emails-queue",
    EMAIL_WHITE_LIST    : "white-emails",

    // Log Urls
    LOGS                : "logs",
    LOG_NOTIFICATIONS   : "log-notifications",
    LOG_PROVIDERS       : "log-providers",
    LOG_FETCHES         : "log-fetches",
    LOG_ASSISTANTS      : "log-assistants",
    LOG_ACTIONS         : "log-actions",
    LOG_QUERIES         : "log-queries",
    LOG_ERRORS          : "log-errors",

};

export default urls;
