import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import NLS                  from "Dashboard/Core/NLS";
import Action               from "Dashboard/Core/Action";
import useDialog            from "Dashboard/Hooks/Dialog";

// Components
import TicketEdit           from "./TicketEdit";
import TicketAssign         from "./TicketAssign";
import TicketMessage        from "./TicketMessage";
import TicketReply          from "./TicketReply";

// Dashboard
import ViewDialog           from "Dashboard/Components/Dialogs/ViewDialog";
import PromptDialog         from "Dashboard/Components/Dialogs/PromptDialog";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";
import InfoList             from "Dashboard/Components/Info/InfoList";
import InfoItem             from "Dashboard/Components/Info/InfoItem";
import InfoAction           from "Dashboard/Components/Info/InfoAction";



// Styles
const None = Styled.div`
    padding: 32px;
    font-size: 16px;
    font-weight: bold;
`;

const Header = Styled(InfoList)`
    position: sticky;
    top: 0;
    padding-top: 0;
    background-color: white;
    border-bottom: 1px solid var(--border-color-light);
    z-index: 1;

    @media (max-width: 600px) {
        .actions {
            display: none;
        }
    }
`;



/**
 * The Ticket View Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function TicketView(props) {
    const { open, elemID, onClose } = props;

    const { messages, canModify, canOpen, canClose, canAssign } = Store.useState("ticket");
    const {
        fetchElem, openTicket, closeTicket,
        editMessage, deleteMessage, deleteImage,
    } = Store.useAction("ticket");


    // The References
    const contentRef = React.useRef(null);

    // The Current State
    const [ action,  setAction  ] = Action.useAction();
    const [ update,  setUpdate  ] = React.useState(false);
    const [ message, setMessage ] = React.useState(null);

    // Load the Data
    const { elem } = useDialog("ticket", open, elemID, null);


    // Handles the submit
    const handleSubmit = () => {
        setUpdate(true);
        window.setTimeout(() => scrollToBottom(), 100);
    };

    // Scrolls to the Bottom of the Chat
    const scrollToBottom = () => {
        const node = contentRef.current;
        if (node) {
            node.scrollTop = node.scrollHeight - node.offsetHeight;
        }
    };

    // Handles the Message Edit Submit
    const handleMessageEdit = async (text) => {
        if (text) {
            await editMessage(elem.ticketID, message.messageID, text);
            endAction(true);
        }
    };

    // Handles the Image Delete Submit
    const handleImageDelete = async () => {
        if (message) {
            await deleteImage(elem.ticketID, message.messageID);
            endAction(true);
        }
    };

    // Handles the Message Delete Submit
    const handleMessageDelete = async () => {
        if (message) {
            await deleteMessage(elem.ticketID, message.messageID);
            endAction(true);
        }
    };


    // Starts an Action
    const startAction = (action, message) => {
        setAction(action);
        setMessage(message);
    };

    // Ends an Action
    const endAction = (update) => {
        setAction();
        if (update !== undefined) {
            fetchElem(elemID);
            setUpdate(update);
        }
    };

    // Edits the Ticket
    const handleEdit = () => {
        endAction(true);
    };

    // Handles the Open Submit
    const handleOpen = async () => {
        if (canOpen) {
            await openTicket(elemID);
            endAction(true);
        }
    };

    // Handles the Close Submit
    const handleClose = async () => {
        if (canClose) {
            await closeTicket(elemID);
            endAction(true);
        }
    };


    // Do the Render
    const forMessage = Boolean(message && message.messageID);

    return <>
        <ViewDialog
            open={open}
            icon="ticket"
            title={elem.subject || "GENERAL_LOADING"}
            onClose={() => onClose(update)}
            contentRef={contentRef}
            hideFooter
            isWide
        >
            <Header variant="outlined" onAction={startAction}>
                <InfoItem label="GENERAL_ID"            message={elem.ticketNumber}   />
                <InfoItem label="GENERAL_STATUS"        message={elem.statusName}     />
                <InfoItem label="TICKET_TYPES_SINGULAR" message={elem.ticketTypeName} />
                <InfoItem label="TICKETS_ASSIGNED"      message={elem.adminName}      />

                <InfoAction action="EDIT"   isHidden={!canModify}              />
                <InfoAction action="ASSIGN" isHidden={canModify || !canAssign} />
                <InfoAction action="OPEN"   isHidden={!canOpen}                />
                <InfoAction action="CLOSE"  isHidden={!canClose}               />
            </Header>

            {!messages.length ? <None>{NLS.get("TICKETS_NONE_MESSAGES")}</None> : <div>
                {messages.map((elem) => <TicketMessage
                    key={elem.messageID}
                    elem={elem}
                    startAction={startAction}
                />)}
            </div>}

            <TicketReply
                elemID={elem.ticketID}
                onSubmit={handleSubmit}
            />
        </ViewDialog>

        <TicketEdit
            open={!forMessage && action.isCE}
            elemID={elem.ticketID}
            clientID={elem.clientID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <TicketAssign
            open={action.isAssign}
            elemID={elem.ticketID}
            onSubmit={handleEdit}
            onClose={endAction}
        />

        <ConfirmDialog
            open={action.isOpen}
            icon="status"
            title="TICKETS_OPEN_TITLE"
            message="TICKETS_OPEN_TEXT"
            content={elem.subject}
            onSubmit={handleOpen}
            onClose={endAction}
        />
        <ConfirmDialog
            open={action.isClose}
            icon="status"
            title="TICKETS_CLOSE_TITLE"
            message="TICKETS_CLOSE_TEXT"
            content={elem.subject}
            onSubmit={handleClose}
            onClose={endAction}
        />

        <PromptDialog
            open={forMessage && action.isEdit}
            icon="edit"
            title="TICKETS_EDIT_MESSAGE"
            inputType="textarea"
            placeholder="TICKETS_WRITE_MESSAGE"
            initialValue={forMessage ? message.message : ""}
            onSubmit={handleMessageEdit}
            onClose={endAction}
        />
        <DeleteDialog
            open={forMessage && action.isImage}
            title="TICKETS_DELETE_IMAGE"
            message="TICKETS_DELETE_IMAGE_TEXT"
            content={forMessage ? message.messageShort : ""}
            onSubmit={handleImageDelete}
            onClose={endAction}
        />
        <DeleteDialog
            open={forMessage && action.isDelete}
            title="TICKETS_DELETE_MESSAGE"
            message="TICKETS_DELETE_MESSAGE_TEXT"
            content={forMessage ? message.messageShort : ""}
            onSubmit={handleMessageDelete}
            onClose={endAction}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
TicketView.propTypes = {
    open    : PropTypes.bool.isRequired,
    onClose : PropTypes.func.isRequired,
    elemID  : PropTypes.number,
};

export default TicketView;
