import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import { Questionnaire }    from "Utils/API";



// The initial State
const initialState = {
    loading       : true,
    charging      : false,
    error         : false,
    edition       : 0,
    canCreate     : false,
    canEdit       : false,
    canImport     : false,
    canExport     : false,
    list          : [],
    total         : 0,
    elem          : {
        name      : "",
        questions : {},
    },
    tongues       : [],
    variables     : [],
    channelLinks  : [],
    maxQuestions  : 0,
    buttonLength  : 0,
    descLength    : 0,
    messageLength : 0,
    statuses      : [],
    sort          : {
        orderBy  : "name",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "QUESTIONNAIRE_LOADING" });
    },

    /**
     * Fetches the Questionnaire List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, params = {}) {
        Navigate.unsetParams(params);
        if (type === "CLIENT") {
            params.clientID = elemID;
        }
        const data = await Questionnaire.getAll(params);
        data.sort  = params;
        dispatch({ type : "QUESTIONNAIRE_LIST", data });
    },

    /**
     * Fetches a single Questionnaire
     * @param {Function} dispatch
     * @param {Number}   questionnaireID
     * @returns {Promise}
     */
    async fetchElem(dispatch, questionnaireID) {
        dispatch({ type : "QUESTIONNAIRE_CHARGING" });
        const data = await Questionnaire.getOne({ questionnaireID });
        dispatch({ type : "QUESTIONNAIRE_ELEM", data });
    },

    /**
     * Fetches the Questionnaire Edit data
     * @param {Function} dispatch
     * @param {Number}   clientID
     * @returns {Promise}
     */
    async fetchEditData(dispatch, clientID) {
        dispatch({ type : "QUESTIONNAIRE_CHARGING" });
        const data = await Questionnaire.getEditData({ clientID });
        dispatch({ type : "QUESTIONNAIRE_EDIT", data });
    },

    /**
     * Edits/Creates a Questionnaire
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editQuestionnaire(dispatch, data) {
        return Questionnaire.edit(data);
    },

    /**
     * Deletes a Questionnaire
     * @param {Function} dispatch
     * @param {Number}   questionnaireID
     * @returns {Promise}
     */
    deleteQuestionnaire(dispatch, questionnaireID) {
        return Questionnaire.delete({ questionnaireID });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.modifiedDateTime = DateTime.formatIf(elem.modifiedTime, "dashesTime");
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "QUESTIONNAIRE_LIST", "QUESTIONNAIRE_ELEM", "QUESTIONNAIRE_EDIT")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "QUESTIONNAIRE_LOADING":
        return {
            ...state,
            loading       : true,
        };
    case "QUESTIONNAIRE_CHARGING":
        return {
            ...state,
            charging      : true,
        };

    case "QUESTIONNAIRE_LIST":
        return {
            ...state,
            loading       : false,
            error         : false,
            canCreate     : action.data.canCreate,
            canEdit       : action.data.canEdit,
            canImport     : action.data.canImport,
            canExport     : action.data.canExport,
            list          : Utils.parseList(action.data.list, parseElem),
            total         : action.data.total,
            sort          : action.data.sort,
        };

    case "QUESTIONNAIRE_ELEM":
        return {
            ...state,
            charging      : false,
            error         : false,
            edition       : state.edition + 1,
            elem          : parseElem(action.data.elem),
            tongues       : action.data.tongues,
            variables     : action.data.variables,
            channelLinks  : action.data.channelLinks,
            maxQuestions  : action.data.maxQuestions,
            buttonLength  : action.data.buttonLength,
            descLength    : action.data.descLength,
            messageLength : action.data.messageLength,
            statuses      : action.data.statuses,
        };

    case "QUESTIONNAIRE_EDIT":
        return {
            ...state,
            charging      : false,
            error         : false,
            edition       : state.edition + 1,
            elem          : { ...initialState.elem },
            tongues       : action.data.tongues,
            variables     : action.data.variables,
            channelLinks  : action.data.channelLinks,
            maxQuestions  : action.data.maxQuestions,
            buttonLength  : action.data.buttonLength,
            descLength    : action.data.descLength,
            messageLength : action.data.messageLength,
            statuses      : action.data.statuses,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
