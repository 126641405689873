import React                from "react";
import Navigate             from "Dashboard/Core/Navigate";

// Components
import IntegrationTempDiags from "./IntegrationTempDialogs";
import IntegrationHeader    from "Components/App/Config/Integration/View/IntegrationHeader";
import IntegrationEditor    from "Components/App/Config/Integration/Editor/IntegrationEditor";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";



/**
 * The Integration Template Page
 * @returns {React.ReactElement}
 */
function IntegrationTempPage() {
    const { integrationID } = Navigate.useParams();


    // Do the Render
    return <>
        <Main withNavigation>
            <IntegrationHeader levels={1} />

            <Content>
                <IntegrationEditor
                    integrationID={integrationID}
                    hasTabs={false}
                />
            </Content>
        </Main>

        <IntegrationTempDiags />
    </>;
}

export default IntegrationTempPage;
