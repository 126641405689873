import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import { Integration }      from "Utils/API";



// The initial State
const initialState = {
    loading          : true,
    charging         : false,
    error            : false,
    edition          : 0,
    canCreate        : false,
    canEdit          : false,
    canImport        : false,
    canExport        : false,
    list             : [],
    total            : 0,
    elem             : {},
    integrationTemps : [],
    sort             : {
        orderBy  : "name",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "INTEGRATION_LOADING" });
    },

    /**
     * Fetches the Integration List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, params = {}) {
        Navigate.unsetParams(params);
        if (type === "CLIENT") {
            params.clientID = elemID;
        }
        const data = await Integration.getAll(params);
        data.sort  = params;
        dispatch({ type : "INTEGRATION_STATE_RESET" });
        dispatch({ type : "INTEGRATION_LIST", data });
    },

    /**
     * Fetches a single Integration
     * @param {Function} dispatch
     * @param {Number}   integrationID
     * @returns {Promise}
     */
    async fetchElem(dispatch, integrationID) {
        const data = await Integration.getOne({ integrationID });
        dispatch({ type : "INTEGRATION_ELEM", data });
        dispatch({ type : "FLOW_EDITOR_INTEGRATION", data });
        return data;
    },

    /**
     * Fetches the Integration Edit data
     * @param {Function} dispatch
     * @param {Number}   clientID
     * @returns {Promise}
     */
    async fetchEditData(dispatch, clientID) {
        const data = await Integration.getEditData({ clientID });
        dispatch({ type : "INTEGRATION_EDIT", data });
    },

    /**
     * Edits/Creates an Integration
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editIntegration(dispatch, data) {
        return Integration.edit(data);
    },

    /**
     * Copies an Integration
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    copyIntegration(dispatch, data) {
        return Integration.copy(data);
    },

    /**
     * Activates an Integration
     * @param {Function} dispatch
     * @param {Number}   integrationID
     * @returns {Promise}
     */
    activateIntegration(dispatch, integrationID) {
        return Integration.activate({ integrationID });
    },

    /**
     * Publishes an Integration
     * @param {Function} dispatch
     * @param {Number}   integrationID
     * @returns {Promise}
     */
    publishIntegration(dispatch, integrationID) {
        return Integration.publish({ integrationID });
    },

    /**
     * Clears an Integration
     * @param {Function} dispatch
     * @param {Number}   integrationID
     * @returns {Promise}
     */
    clearIntegration(dispatch, integrationID) {
        return Integration.clear({ integrationID });
    },

    /**
     * Pauses an Integration
     * @param {Function} dispatch
     * @param {Number}   integrationID
     * @returns {Promise}
     */
    pauseIntegration(dispatch, integrationID) {
        return Integration.pause({ integrationID });
    },

    /**
     * Deletes an Integration
     * @param {Function} dispatch
     * @param {Number}   integrationID
     * @returns {Promise}
     */
    deleteIntegration(dispatch, integrationID) {
        return Integration.delete({ integrationID });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.modifiedDateTime  = DateTime.formatIf(elem.modifiedTime, "dashesTime");
    elem.lastExecutionDate = DateTime.formatIf(elem.lastExecutionTime, "dashes");
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "INTEGRATION_LIST", "INTEGRATION_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "INTEGRATION_LOADING":
        return {
            ...state,
            loading          : true,
        };
    case "INTEGRATION_CHARGING":
        return {
            ...state,
            charging         : true,
        };

    case "INTEGRATION_LIST":
        return {
            ...state,
            loading          : false,
            error            : false,
            canCreate        : action.data.canCreate,
            canEdit          : action.data.canEdit,
            canImport        : action.data.canImport,
            canExport        : action.data.canExport,
            list             : Utils.parseList(action.data.list, parseElem),
            total            : action.data.total,
            sort             : action.data.sort,
        };

    case "INTEGRATION_DATA":
        return {
            ...state,
            charging         : false,
            error            : false,
            edition          : state.edition + 1,
            elem             : parseElem(action.data.elem),
        };

    case "INTEGRATION_ELEM":
        return {
            ...state,
            error            : false,
            edition          : state.edition + 1,
            canEdit          : action.data.canEdit,
            elem             : parseElem(action.data.elem),
            integrationTemps : action.data.integrationTemps,
        };

    case "INTEGRATION_EDIT":
        return {
            ...state,
            error            : false,
            edition          : state.edition + 1,
            integrationTemps : action.data.integrationTemps,
        };

    case "INTEGRATION_EDITOR_ELEM":
        return {
            ...state,
            error            : false,
            edition          : state.edition + 1,
            canEdit          : action.data.canEdit,
            elem             : parseElem(action.data.elem),
        };

    case "INTEGRATION_EDITOR_UPDATE":
        return {
            ...state,
            error            : false,
            canEdit          : action.data.canEdit,
            elem             : parseElem(action.data.elem),
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
