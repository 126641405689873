import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";
import { ContactMerge }     from "Utils/API";



// The initial State
const initialState = {
    loading      : true,
    error        : false,
    edition      : 0,
    contacts     : [],
    fields       : [],
    defaults     : [],
    merges       : [],
    hasInstagram : false,
    hasTelegram  : false,
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "CONTACT_MERGE_LOADING" });
    },

    /**
     * Fetches the Contact Edit data
     * @param {Function} dispatch
     * @param {Number}   clientID
     * @returns {Promise}
     */
    async fetchEditData(dispatch, clientID) {
        const data = await ContactMerge.getEditData({ clientID });
        dispatch({ type : "CONTACT_MERGE_EDIT", data });
    },

    /**
     * Fetches the Contact Combine data
     * @param {Function} dispatch
     * @param {Number}   clientID
     * @param {Number}   contactID
     * @param {Object}   params
     * @param {Number[]} contactIDs
     * @returns {Promise}
     */
    async fetchCombineData(dispatch, clientID, contactID, params, contactIDs) {
        const data = await ContactMerge.getCombineData({ clientID, contactID, ...params });
        dispatch({ type : "CONTACT_MERGE_COMBINE", data, contactIDs });
    },

    /**
     * Fetches the Contact Merge data
     * @param {Function} dispatch
     * @param {Number}   clientID
     * @param {Number[]} contactIDs
     * @returns {Promise}
     */
    async fetchMergeData(dispatch, clientID, contactIDs) {
        const data = await ContactMerge.getMergeData({ clientID, contactIDs });
        dispatch({ type : "CONTACT_MERGE_MERGE", data });
    },

    /**
     * Merge Contacts
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    mergeContacts(dispatch, data) {
        return ContactMerge.merge(data);
    },
};



/**
 * Parses a single Element
 * @param {Object[]} newContacts
 * @param {Object[]} oldContacts
 * @param {Number[]} contactIDs
 * @returns {Object}
 */
function parseContacts(newContacts, oldContacts, contactIDs) {
    const result = [];
    for (const contact of oldContacts) {
        if (contactIDs.includes(contact.contactID)) {
            result.push(contact);
        }
    }
    Utils.parseList(newContacts, (elem) => {
        if (!contactIDs.includes(elem.contactID)) {
            Commons.parseContact(elem);
            result.push(elem);
        }
    });
    return result;
}

/**
 * Parses a single Element
 * @param {Object[]} merges
 * @returns {Object}
 */
function parseMerges(merges) {
    return Utils.parseList(merges, (elem) => {
        Commons.parseFields(elem);
    });
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "CONTACT_MERGE_EDIT", "CONTACT_MERGE_DATA", "CONTACT_MERGE_MERGE")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "CONTACT_MERGE_LOADING":
        return {
            ...state,
            loading      : true,
        };

    case "CONTACT_MERGE_EDIT":
        return {
            ...state,
            error        : false,
            edition      : state.edition + 1,
            fields       : action.data.fields,
            hasInstagram : action.data.hasInstagram,
            hasTelegram  : action.data.hasTelegram,
        };

    case "CONTACT_MERGE_COMBINE":
        return {
            ...state,
            error        : false,
            contacts     : parseContacts(action.data.contacts, state.contacts, action.contactIDs),
        };

    case "CONTACT_MERGE_MERGE":
        return {
            ...state,
            error        : false,
            edition      : state.edition + 1,
            merges       : parseMerges(action.data.contacts),
            defaults     : action.data.defaults,
            fields       : action.data.fields,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
