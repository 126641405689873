import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The User Invite Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function UserInvite(props) {
    const { open, clientID, partnerID, onClose, onSubmit } = props;

    const { teams, colors, accesses } = Store.useState("user");
    const { inviteUser } = Store.useAction("user");


    // The Initial Data
    const initialData = {
        clientID     : 0,
        partnerID    : 0,
        email        : "",
        access       : "",
        color        : "#f2f2f2",
        observations : "",
    };

    // Restore the State
    React.useEffect(() => {
        if (open) {
            setElem({ ...initialData });
        }
    }, [ open ]);

    // Handles the Edit
    const handleEdit = (data) => {
        return inviteUser({ ...data, clientID, partnerID });
    };


    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("user", initialData, handleEdit, onSubmit, false, open);

    // Load the Data
    const { loading } = useDialog("user", open);



    // Do the Render
    return <EditDialog
        open={open}
        icon="user"
        title="USERS_INVITE_TITLE"
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            type="select"
            name="access"
            label="GENERAL_ACCESS"
            options={accesses}
            value={data.access}
            error={errors.access}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="email"
            name="email"
            label="GENERAL_EMAIL"
            value={data.email}
            error={errors.email}
            onChange={handleChange}
            isRequired
        />

        <InputField
            isHidden={!teams.length}
            type="multiple"
            name="teams"
            label="TEAMS_NAME"
            options={teams}
            value={data.teams}
            error={errors.teams}
            onChange={handleChange}
        />
        <InputField
            type="color"
            name="color"
            label="GENERAL_COLOR"
            options={colors}
            value={data.color}
            error={errors.color}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="textarea"
            name="observations"
            label="GENERAL_OBSERVATIONS"
            value={data.observations}
            onChange={handleChange}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
UserInvite.propTypes = {
    open      : PropTypes.bool.isRequired,
    onClose   : PropTypes.func.isRequired,
    onSubmit  : PropTypes.func.isRequired,
    clientID  : PropTypes.number,
    partnerID : PropTypes.number,
};

export default UserInvite;
