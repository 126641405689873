import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";

// Dashboard
import DialogMessage        from "Dashboard/Components/Dialog/DialogMessage";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";
import InputItem            from "Dashboard/Components/Form/InputItem";



// Styles
const Grid = Styled(DialogMessage)`
    display: flex;
    flex-direction: column;
    gap: var(--main-gap);
`;



/**
 * The Client Edit Addons
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ClientEditAddons(props) {
    const { isHidden, data, errors, onChange } = props;

    const { addons } = Store.useState("client");


    // Handles the Addon
    const handleAddon = (addonCode, name, value) => {
        const elem   = { ...data.addons[addonCode], [name] : value };
        const addons = { ...data.addons, [addonCode] : elem };
        onChange("addons", addons);
    };


    // Variables
    const hasMarketing    = Boolean(data.addons?.Marketing?.isActive);
    const hasIntegrations = Boolean(data.addons?.Integrations?.isActive);


    // Do the Render
    return <Grid isHidden={isHidden}>
        <Columns amount="4">
            <>
                {addons.map(({ key, value, hasQuantity }) => <React.Fragment key={key}>
                    <InputField
                        type="toggle"
                        name={`addonActive-${key}`}
                        label={value}
                        value={data.addons[key]?.isActive || 0}
                        onChange={(name, value) => handleAddon(key, "isActive", value)}
                        withBorder
                    />
                    <InputField
                        className="columns-double"
                        type="double"
                        label="GENERAL_PRICE"
                        error={errors[`addonPrice-${key}`]}
                    >
                        <InputItem
                            type="number"
                            name={`addonPrice-${key}`}
                            icon="money"
                            value={data.addons[key]?.price || ""}
                            minValue={0}
                            onChange={(name, value) => handleAddon(key, "price", value)}
                        />
                        <InputItem
                            type="checkbox"
                            name={`addonPriceFree-${key}`}
                            label="GENERAL_FREE"
                            value={data.addons[key]?.priceFree || 0}
                            onChange={(name, value) => handleAddon(key, "priceFree", value)}
                        />
                    </InputField>
                    {hasQuantity ? <InputField
                        type="number"
                        name={`addonQuantity-${key}`}
                        label="GENERAL_AMOUNT"
                        value={data.addons[key]?.quantity || 0}
                        error={errors[`addonQuantity-${key}`]}
                        onChange={(name, value) => handleAddon(key, "quantity", value)}
                        minValue={0}
                    /> : <div />}
                </React.Fragment>)}
            </>
        </Columns>

        <Columns topSpace="12">
            <InputField
                isHidden={!hasMarketing}
                type="number"
                name="campaignLimit"
                label="CLIENTS_CAMPAIGN_LIMIT"
                value={data.campaignLimit}
                error={errors.campaignLimit}
                onChange={onChange}
            />
            <InputField
                isHidden={!hasIntegrations}
                type="number"
                name="integrationLimit"
                label="CLIENTS_INTEGRATION_LIMIT"
                value={data.integrationLimit}
                error={errors.integrationLimit}
                onChange={onChange}
            />
        </Columns>
    </Grid>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ClientEditAddons.propTypes = {
    isHidden : PropTypes.bool.isRequired,
    data     : PropTypes.object.isRequired,
    errors   : PropTypes.object.isRequired,
    onChange : PropTypes.func.isRequired,
};

export default ClientEditAddons;
