import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";
import DialogMessage        from "Dashboard/Components/Dialog/DialogMessage";



/**
 * The Flow Simulation End Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function FlowSimulationEnd(props) {
    const { open, onClose, onSubmit } = props;

    const { simulationID } = Store.useState("flowSimulation");
    const { endSimulation } = Store.useAction("flowSimulation");


    // The Initial Data
    const initialData = {
        simulationID        : 0,
        resolveConversation : 1,
        deleteConversation  : 0,
        deleteContact       : 0,
    };


    // Initializes the Data
    React.useEffect(() => {
        setElem({ ...initialData, simulationID });
    }, [ open ]);

    // The Form State
    const {
        loading, data, errors, setElem, handleChange, handleSubmit,
    } = useForm("flow", initialData, endSimulation, onSubmit);



    // Do the Render
    return <EditDialog
        open={open}
        icon="simulate"
        title="FLOWS_SIMULATION_END_TITLE"
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <DialogMessage
            variant="h3"
            message="FLOWS_SIMULATION_END_TEXT"
            noSpace
        />
        <InputField
            type="toggle"
            name="resolveConversation"
            label="CONVERSATIONS_RESOLVE_TITLE"
            value={!!data.resolveConversation}
            onChange={handleChange}
            withBorder
        />
        <InputField
            type="toggle"
            name="deleteConversation"
            label="CONVERSATIONS_DELETE_TITLE"
            value={!!data.deleteConversation}
            onChange={handleChange}
            withBorder
        />
        <InputField
            type="toggle"
            name="deleteContact"
            label="CONTACTS_DELETE_TITLE"
            value={!!data.deleteContact}
            onChange={handleChange}
            withBorder
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
FlowSimulationEnd.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
};

export default FlowSimulationEnd;
