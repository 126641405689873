import React                from "react";
import Navigate             from "Dashboard/Core/Navigate";

// Components
import MediaContent         from "./MediaContent";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionItem           from "Dashboard/Components/Header/ActionItem";



/**
 * The Media Page
 * @returns {React.ReactElement}
 */
function MediaPage() {
    const { clientID } = Navigate.useParams();

    // The Current State
    const [ create, setCreate ] = React.useState(false);


    // Do the Render
    return <Main>
        <Header message="MEDIA_NAME" icon="media">
            <ActionList>
                <ActionItem
                    action="CREATE"
                    message="MEDIA_CREATE_TITLE"
                    onClick={() => setCreate(true)}
                />
            </ActionList>
        </Header>
        <Content>
            <MediaContent
                clientID={clientID}
                create={create}
                endCreate={() => setCreate(false)}
            />
        </Content>
    </Main>;
}

export default MediaPage;
