import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import { WidgetPlatform }   from "Utils/API";



// The initial State
const initialState = {
    loading   : true,
    error     : false,
    edition   : 0,
    elem      : {},
    channels  : [],
    providers : [],
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "WIDGET_PLATFORM_LOADING" });
    },

    /**
     * Fetches a single Widget Platform
     * @param {Function} dispatch
     * @param {Number}   platformID
     * @returns {Promise}
     */
    async fetchElem(dispatch, platformID) {
        const data = await WidgetPlatform.getOne({ platformID });
        dispatch({ type : "WIDGET_PLATFORM_ELEM", data });
    },

    /**
     * Fetches the Widget Platform Edit data
     * @param {Function} dispatch
     * @param {Number}   widgetID
     * @returns {Promise}
     */
    async fetchEditData(dispatch, widgetID) {
        const data = await WidgetPlatform.getEditData({ widgetID });
        dispatch({ type : "WIDGET_PLATFORM_EDIT", data });
    },

    /**
     * Edits/Creates a Widget Platform
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    async editPlatform(dispatch, params) {
        const data = await WidgetPlatform.edit(params);
        if (data.success) {
            dispatch({ type : "WIDGET_PLATFORMS", data });
        }
        return data;
    },

    /**
     * Sorts the Widget Platforms
     * @param {Function} dispatch
     * @param {Number}   widgetID
     * @param {Number[]} platformIDs
     * @returns {Promise}
     */
    async sortPlatforms(dispatch, widgetID, platformIDs) {
        const data = await WidgetPlatform.sort({ widgetID, platformIDs : JSON.stringify(platformIDs) });
        if (data.success) {
            dispatch({ type : "WIDGET_PLATFORMS", data });
        }
        return data;
    },

    /**
     * Deletes a Widget Platform
     * @param {Function} dispatch
     * @param {Number}   platformID
     * @returns {Promise}
     */
    async deletePlatform(dispatch, platformID) {
        const data = await WidgetPlatform.delete({ platformID });
        if (data.success) {
            dispatch({ type : "WIDGET_PLATFORMS", data });
        }
        return data;
    },
};



/**
 * Parses the Channels
 * @param {Object[]} channels
 * @returns {Object[]}
 */
function parseChannels(channels) {
    return Utils.parseList(channels, (elem) => {
        if (elem.value.endsWith("- ")) {
            elem.value = elem.value.substring(0, elem.value.length - 2);
        }
    });
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "WIDGET_PLATFORM_ELEM", "WIDGET_PLATFORM_EDIT")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "WIDGET_PLATFORM_LOADING":
        return {
            ...state,
            loading   : true,
        };

    case "WIDGET_PLATFORM_ELEM":
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            elem      : action.data.elem,
            channels  : parseChannels(action.data.channels),
            providers : action.data.providers,
        };

    case "WIDGET_PLATFORM_EDIT":
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            channels  : parseChannels(action.data.channels),
            providers : action.data.providers,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
