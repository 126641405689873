import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import NLS                  from "Dashboard/Core/NLS";
import Utils                from "Dashboard/Utils/Utils";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";
import Plans                from "Utils/Plans";
import Commons              from "Utils/Commons";

// Components
import ClientEditInfo       from "./Edit/ClientEditInfo";
import ClientEditPlan       from "./Edit/ClientEditPlan";
import ClientEditAddons     from "./Edit/ClientEditAddons";
import ClientEditConnectors from "./Edit/ClientEditConnectors";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import DialogMessage        from "Dashboard/Components/Dialog/DialogMessage";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";
import Alert                from "Dashboard/Components/Form/Alert";
import Html                 from "Dashboard/Components/Common/Html";



// Styles
const Dialog = Styled(EditDialog)`
    min-height: min(var(--dialog-body), 720px);
    padding-top: 0px;
    padding-bottom: 0px;
`;

const Inside = Styled(DialogMessage)`
    height: calc(min(var(--dialog-body), 720px) - var(--tabs-dialog) - var(--main-gap));
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: var(--main-gap);
`;

const Total = Styled(Html)`
    position: sticky;
    bottom: 0;
    padding: 8px;
    text-align: center;
    background-color: var(--lighter-gray);
    border-radius: var(--border-radius);
    color: var(--title-color);
    z-index: 2;
`;



/**
 * The Client Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ClientEdit(props) {
    const { open, elemID, partnerID, onClose, onSubmit } = props;

    const {
        defCurrencyID, defPlanID, plans, planPrices,
        addonPrices, connectorPrices,
    } = Store.useState("client");

    const { editClient } = Store.useAction("client");


    // The Fields
    const fields = {
        info       : [ "partnerID", "name", "email", "countryID", "provinceID", "language", "timeZone" ],
        plan       : [ "currencyID", "planID", "roomAmount", "contactAmount", "planPrice", "roomPrice", "contactPrice", "setupPrice", "discount", "chargeStartDate" ],
        addons     : [ "addonPrice", "addonQuantity" ],
        connectors : [ "connectorPrice", "connectorQuantity" ],
    };

    // The Current State
    const [ tab, setTab ] = React.useState("info");

    // The Initial Data
    const initialData = {
        clientID         : 0,
        partnerID        : 0,
        name             : "",
        socialReason     : "",
        taxID            : "",
        firstName        : "",
        lastName         : "",
        email            : "",
        phone            : "",
        countryID        : "",
        provinceID       : "",
        locality         : "",
        address          : "",
        zipCode          : "",
        timeZone         : -3,
        language         : "es",
        currencyID       : 0,
        planID           : 0,
        planPrice        : "",
        planPriceFree    : 0,
        roomAmount       : "",
        roomPrice        : "",
        roomPriceFree    : 0,
        contactAmount    : "",
        contactPrice     : "",
        contactPriceFree : 0,
        setupPrice       : "",
        setupPriceFree   : 0,
        discount         : "",
        dontCharge       : 0,
        chargeStartDate  : "",
        addons           : [],
        campaignLimit    : 0,
        integrationLimit : 0,
        connectors       : [],
        status           : "Active",
        observations     : "",
    };

    // Handles the Set
    const handleSet = (data) => {
        setTab("info");
        setElem({
            ...data,
            currencyID : data.currencyID || defCurrencyID,
            planID     : data.planID     || defPlanID,
        });
    };

    // Handles the Edit
    const handleEdit = (data) => {
        return editClient({
            ...data,
            addons     : JSON.stringify(data.addons),
            connectors : JSON.stringify(data.connectors),
        });
    };

    // Returns the Error Count in the Step
    const getErrorCount = (tab) => {
        return Commons.getErrorCount(fields, errors, tab);
    };


    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("client", initialData, handleEdit, onSubmit);

    // Load the Data
    const { loading } = useDialog("client", open, elemID, { partnerID }, handleSet);


    // Variables
    const errorCount = Object.values(errors).filter((error) => !!error).length;
    const planType   = Utils.getValue(plans, "key", data.planID, "planType");
    const totalPrice = Plans.getTotalPrice(planType, data, planPrices, addonPrices, connectorPrices);


    // Do the Render
    return <Dialog
        open={open}
        icon="client"
        title={elemID ? "CLIENTS_EDIT_TITLE" : "CLIENTS_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <Alert
            isHidden={errorCount === 0}
            variant="error"
            message={NLS.pluralize("CLIENTS_ERROR", errorCount)}
        />

        <TabList selected={tab} onClick={setTab} inDialog>
            <TabItem
                message="GENERAL_INFO"
                value="info"
                badge={getErrorCount("info")}
            />
            <TabItem
                message="PLANS_SINGULAR"
                value="plan"
                badge={getErrorCount("plan")}
            />
            <TabItem
                message="ADDONS_NAME"
                value="addons"
                badge={getErrorCount("addons")}
            />
            <TabItem
                message="CONNECTORS_NAME"
                value="connectors"
                badge={getErrorCount("connectors")}
            />
        </TabList>

        <Inside>
            <ClientEditInfo
                isHidden={tab !== "info"}
                partnerID={partnerID}
                data={data}
                errors={errors}
                onChange={handleChange}
            />
            <ClientEditPlan
                isHidden={tab !== "plan"}
                data={data}
                errors={errors}
                onChange={handleChange}
            />
            <ClientEditAddons
                isHidden={tab !== "addons"}
                data={data}
                errors={errors}
                onChange={handleChange}
            />
            <ClientEditConnectors
                isHidden={tab !== "connectors"}
                data={data}
                errors={errors}
                onChange={handleChange}
            />

            <Total>{totalPrice}</Total>
        </Inside>
    </Dialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ClientEdit.propTypes = {
    open      : PropTypes.bool.isRequired,
    onClose   : PropTypes.func.isRequired,
    onSubmit  : PropTypes.func.isRequired,
    elemID    : PropTypes.number,
    partnerID : PropTypes.number,
};

export default ClientEdit;
