import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Assistant Model Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function AssistantModelEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { models } = Store.useState("assistantModel");
    const { editAssistantModel } = Store.useAction("assistantModel");


    // The Initial Data
    const initialData = {
        assistantModelID : 0,
        name             : "",
        type             : "",
        externalID       : "",
        inputCost        : "",
        outputCost       : "",
        position         : 0,
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("assistantModel", initialData, editAssistantModel, onSubmit);

    // Load the Data
    const { loading } = useDialog("assistantModel", open, elemID, null, setElem);



    // Do the Render
    const isText  = data.type === "text";
    const isAudio = data.type === "audio";

    return <EditDialog
        open={open}
        icon="assistant-model"
        title={elemID ? "ASSISTANTS_MODELS_EDIT_TITLE" : "ASSISTANTS_MODELS_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="select"
            name="type"
            label="GENERAL_TYPE"
            options="SELECT_ASSISTANT_TYPES"
            value={data.type}
            error={errors.type}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="select"
            name="externalID"
            label="ASSISTANTS_MODELS_MODEL"
            options={models}
            value={data.externalID}
            error={errors.externalID}
            onChange={handleChange}
            isRequired
        />

        <InputField
            isHidden={!isText}
            type="number"
            icon="money"
            name="inputCost"
            label="ASSISTANTS_MODELS_INPUT_COST"
            value={data.inputCost}
            error={errors.inputCost}
            onChange={handleChange}
            isRequired
        />
        <InputField
            isHidden={!isText}
            type="number"
            icon="money"
            name="outputCost"
            label="ASSISTANTS_MODELS_OUTPUT_COST"
            value={data.outputCost}
            error={errors.outputCost}
            onChange={handleChange}
            isRequired
        />
        <InputField
            isHidden={!isAudio}
            type="number"
            icon="money"
            name="outputCost"
            label="ASSISTANTS_MODELS_MINUTE_COST"
            value={data.outputCost}
            error={errors.outputCost}
            onChange={handleChange}
            isRequired
        />

        <InputField
            type="number"
            name="position"
            label="GENERAL_POSITION"
            value={data.position}
            error={errors.position}
            onChange={handleChange}
            minValue={0}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
AssistantModelEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default AssistantModelEdit;
