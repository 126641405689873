import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import SortableList         from "Dashboard/Components/Common/SortableList";



/**
 * The Dashboard Personalize Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function DashboardPersonalize(props) {
    const { open, clientID, onClose } = props;

    const { reportTypes, sort } = Store.useState("dashboard");
    const { saveReports } = Store.useAction("dashboard");


    // The Current State
    const [ list, setList ] = React.useState([]);

    // Update the data on Open
    React.useEffect(() => {
        setList(reportTypes);
    }, [ open ]);

    // Handles the Visibility
    const handleVisibility = (index, isVisible) => {
        const newList  = [ ...list ];
        newList[index] = { ...list[index], isVisible };
        setList(newList);
    };

    // Handles the Filter Submit
    const handleSubmit = () => {
        saveReports({
            clientID    : clientID,
            filter      : sort.filter,
            reportTypes : JSON.stringify(list),
        });
        onClose();
    };


    // Do the Render
    return <EditDialog
        open={open}
        icon="personalize"
        title="DASHBOARD_PERSONALIZE_TITLE"
        onSubmit={handleSubmit}
        onClose={onClose}
        isNarrow
    >
        <SortableList
            list={list}
            onSort={setList}
            onVisibility={handleVisibility}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
DashboardPersonalize.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    clientID : PropTypes.number,
};

export default DashboardPersonalize;
