import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import { ProviderLog }      from "Utils/API";



// The initial State
const initialState = {
    loading   : true,
    error     : false,
    edition   : 0,
    canCreate : false,
    canEdit   : false,
    canImport : false,
    canExport : false,
    list      : [],
    total     : 0,
    elem      : {},
    filters   : {
        search   : "",
        fromDate : "",
        toDate   : "",
    },
    sort      : {
        orderBy  : "createdTime",
        orderAsc : 0,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "PROVIDER_LOG_LOADING" });
    },

    /**
     * Fetches the Provider Log
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  filters
     * @param {Object=}  sort
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, filters = {}, sort = {}) {
        const params = { ...filters, ...sort };
        let data = null;

        switch (type) {
        case "CLIENT":
            params.clientID = elemID;
            data = await ProviderLog.getAllForClient(params);
            break;
        case "USER":
            data = await ProviderLog.getAllForOwner(params);
            break;
        default:
            data = await ProviderLog.getAll(params);
            break;
        }
        data.filters = filters;
        data.sort    = sort;
        dispatch({ type : "PROVIDER_LOG_LIST", data });
    },

    /**
     * Fetches a single Provider Log
     * @param {Function} dispatch
     * @param {Number}   logID
     * @returns {Promise}
     */
    async fetchElem(dispatch, logID) {
        const data = await ProviderLog.getOne({ logID });
        dispatch({ type : "PROVIDER_LOG_ELEM", data });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdDate      = DateTime.formatDate(elem.createdTime, "dashesSeconds");
    elem.errorText        = elem.isError ? NLS.get("GENERAL_FAIL") : NLS.get("GENERAL_SUCCESS");
    elem.errorClass       = elem.isError ? "text-red" : "text-green";
    elem.conversationText = elem.channelID ? `${elem.conversationID} (#${elem.conversationNumber})` : "";
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "PROVIDER_LOG_LIST", "PROVIDER_LOG_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "PROVIDER_LOG_LOADING":
        return {
            ...state,
            loading   : true,
        };

    case "PROVIDER_LOG_LIST":
        return {
            ...state,
            loading   : false,
            error     : false,
            canCreate : action.data.canCreate,
            canEdit   : action.data.canEdit,
            canImport : action.data.canImport,
            canExport : action.data.canExport,
            list      : Utils.parseList(action.data.list, parseElem),
            total     : action.data.total,
            filters   : action.data.filters,
            sort      : action.data.sort,
        };

    case "PROVIDER_LOG_ELEM":
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            elem      : parseElem(action.data.elem),
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
