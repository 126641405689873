import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";

// Components
import MessageBubble        from "./MessageBubble";



// Styles
const Messages = Styled.section.attrs(({ isMine }) => ({ isMine }))`
    display: flex;
    flex-direction: column;
    align-items: ${(props) => props.isMine ? "flex-end" : "flex-start"};
`;

const User = Styled.h3`
    margin: 0;
    padding: 2px 24px;
    font-size: 14px;
`;



/**
 * The Message List
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function MessageList(props) {
    const {
        list, userName, isMine,
        canReplyTo, onReply,
        canForwardTo, onForward,
        canReactTo, onReaction,
    } = props;


    // Do the Render
    if (!list.length) {
        return <React.Fragment />;
    }
    return <Messages className="messages" isMine={isMine}>
        {!!userName && <User>{userName}</User>}
        {list.map((elem) => <MessageBubble
            key={elem.id}
            id={`conversation-${elem.id}`}
            isMine={isMine}
            item={elem}
            canReplyTo={canReplyTo}
            onReply={onReply}
            canForwardTo={canForwardTo}
            onForward={onForward}
            canReactTo={canReactTo}
            onReaction={onReaction}
        />)}
    </Messages>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
MessageList.propTypes = {
    list         : PropTypes.array.isRequired,
    userName     : PropTypes.string,
    isMine       : PropTypes.bool,
    canReplyTo   : PropTypes.bool,
    onReply      : PropTypes.func,
    canReactTo   : PropTypes.bool,
    onReaction   : PropTypes.func,
    canForwardTo : PropTypes.bool,
    onForward    : PropTypes.func,
};

export default MessageList;
