import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";

// Dashboard
import Icon                 from "Dashboard/Components/Common/Icon";



// Styles
const Container = Styled.div.attrs(({ size, padding }) => ({ size, padding }))`
    box-sizing: border-box;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${(props) => `${props.size}px`};
    height: ${(props) => `${props.size}px`};
    padding: ${(props) => props.padding ? `${props.padding}px` : "0px"};
    background: white;
    border-radius: var(--border-radius);
`;

const CustomIcon = Styled(Icon).attrs(({ size }) => ({ size }))`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: ${(props) => `${props.size}px`};
`;

const Image = Styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
`;



/**
 * The Provider Icon
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ProviderIcon(props) {
    const { logo, icon, color, size, padding, message } = props;


    // Do the Render
    if (!logo && !icon) {
        return <React.Fragment />;
    }
    return <Container size={size} padding={padding}>
        {!!icon && <CustomIcon
            icon={icon}
            color={color}
            size={size - (padding || 0)}
        />}
        {!!logo && <Image
            src={`${process.env.PUBLIC_URL}/logos/${logo}.svg`}
            alt={NLS.get(message)}
        />}
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ProviderIcon.propTypes = {
    logo    : PropTypes.string,
    icon    : PropTypes.string,
    color   : PropTypes.string,
    message : PropTypes.string,
    size    : PropTypes.number.isRequired,
    padding : PropTypes.number,
};

export default ProviderIcon;
