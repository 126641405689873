import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import MediaType            from "Dashboard/Core/MediaType";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// Components
import TextField            from "Components/Utils/Inputs/TextField";
import MediaField           from "Components/Utils/Inputs/MediaField";

// Dashboard
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";
import IconLink             from "Dashboard/Components/Link/IconLink";
import Button               from "Dashboard/Components/Form/Button";



// Styles
const Map = Styled.div`
    border: 1px solid var(--border-color-light);
    border-radius: var(--border-radius);
    overflow: hidden;

    iframe {
        display: block;
        width: 100%;
    }
`;



/**
 * The WABA Template Head
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function WabaTemplateHead(props) {
    const { clientID, isDisabled, getValue, getError, onChange, setData } = props;

    const { textLength, variables } = Store.useState("wabaTemplate");

    // The References
    const inputRef = React.useRef(null);


    // Handles the Change
    const handleChange = (name, value) => {
        onChange("header", name, value);
    };

    // Handles the Variable
    const handleType = (name, headerType) => {
        const fields = { headerType };
        fields.headerFile = "";
        if (headerType !== "TEXT") {
            fields.headerText     = "";
            fields.headerSample   = "";
            fields.headerVariable = "";
        }
        if (headerType !== "LOCATION") {
            fields.headerLatitude  = 0;
            fields.headerLongitude = 0;
            fields.headerLocation  = "";
            fields.headerAddress   = "";
        }
        setData("header", fields);
    };

    // Handles the Variable
    const handleVariable = () => {
        const headerText = Utils.insertText(inputRef, getValue("headerText"), "{{1}}");
        setData("header", { headerText });
        inputRef.current.focus();
    };

    // Handles the Text
    const handleText = (name, headerText) => {
        const headerVariable = getValue("headerVariable");
        if (headerText.includes("{{1}}")) {
            setData("header", { headerText, headerVariable });
        } else {
            setData("header", {
                headerText,
                headerSample   : "",
                headerVariable : "",
            });
        }
    };

    // Handles the My Location
    const handleLocation = () => {
        navigator.geolocation.getCurrentPosition((position) => {
            setData("header", {
                headerLatitud  : position.coords.latitude,
                headerLongitud : position.coords.longitude,
            });
        });
    };


    // Variables
    const headerType  = getValue("headerType", "NONE");
    const isText      = headerType === "TEXT";
    const isImage     = headerType === "IMAGE";
    const isVideo     = headerType === "VIDEO";
    const isDocument  = headerType === "DOCUMENT";
    const isLocation  = headerType === "LOCATION";
    const isMedia     = isImage || isVideo || isDocument;

    const latitude    = getValue("headerLatitude");
    const longitude   = getValue("headerLongitude");
    const mapSource   = Utils.getGoogleMapEmbed(latitude, longitude);
    const hasVariable = Boolean(getValue("headerVariable"));
    const showValue   = Boolean(hasVariable && !getValue("forIntegration"));


    // Do the Render
    return <Columns>
        <InputField
            className="columns-double"
            type="select"
            name="headerType"
            label="WABA_TEMPLATES_HEADER_TYPE"
            options="SELECT_WABA_TEMPLATES_HEADER_TYPES"
            value={getValue("headerType")}
            onChange={handleType}
            isDisabled={isDisabled}
            isRequired
        />

        <TextField
            isHidden={!isText}
            className="columns-double"
            passedRef={inputRef}
            name="headerText"
            label="WABA_TEMPLATES_HEADER_TITLE"
            value={getValue("headerText")}
            error={getError("headerText")}
            onChange={handleText}
            isDisabled={isDisabled}
            maxLength={textLength}
            withEmoji
            isRequired
        >
            <IconLink
                isHidden={hasVariable}
                variant="black"
                icon="variable"
                tooltip="GENERAL_VARIABLES"
                onClick={handleVariable}
                isSmall
            />
        </TextField>

        <InputField
            isHidden={!hasVariable}
            className={!showValue ? "columns-double" : ""}
            name="headerSample"
            label={NLS.format("WABA_TEMPLATES_SAMPLE", "1")}
            value={getValue("headerSample")}
            error={getError("headerSample")}
            onChange={handleChange}
            isDisabled={isDisabled}
            isRequired
        />
        <InputField
            isHidden={!hasVariable || !showValue}
            type="select"
            name="headerVariable"
            label={NLS.format("WABA_TEMPLATES_VALUE", "1")}
            options={variables}
            value={getValue("headerVariable")}
            error={getError("headerVariable")}
            onChange={handleChange}
            isRequired
        />

        <MediaField
            className="columns-double"
            isHidden={!isMedia}
            clientID={clientID}
            name="headerFile"
            label="WABA_TEMPLATES_HEADER_FILE"
            value={getValue("headerFile")}
            error={getError("headerFile")}
            onChange={handleChange}
            placeholder={isImage ? "GENERAL_SELECT_IMAGE" : (isVideo ? "GENERAL_SELECT_VIDEO" : "GENERAL_SELECT_PDF")}
            mediaType={isImage ? MediaType.IMAGE : (isVideo ? MediaType.VIDEO : MediaType.PDF)}
            isRequired
        />

        <InputField
            isHidden={!isLocation}
            name="headerLatitude"
            label="WABA_TEMPLATES_HEADER_LATITUDE"
            value={latitude}
            error={getError("headerLatitude")}
            onChange={handleChange}
            isRequired
        />
        <InputField
            isHidden={!isLocation}
            name="headerLongitude"
            label="WABA_TEMPLATES_HEADER_LONGITUDE"
            value={longitude}
            error={getError("headerLongitude")}
            onChange={handleChange}
            isRequired
        />
        <InputField
            isHidden={!isLocation}
            name="headerLocation"
            label="WABA_TEMPLATES_HEADER_LOCATION"
            value={getValue("headerLocation")}
            error={getError("headerLocation")}
            onChange={handleChange}
            isRequired
        />
        <InputField
            isHidden={!isLocation}
            name="headerAddress"
            label="WABA_TEMPLATES_HEADER_ADDRESS"
            value={getValue("headerAddress")}
            error={getError("headerAddress")}
            onChange={handleChange}
            isRequired
        />
        {isLocation && <>
            <Map className="columns-double">
                <iframe
                    title="Map"
                    width="500"
                    height="250"
                    src={mapSource}
                    frameBorder="0"
                    scrolling="no"
                />
            </Map>
            <div className="columns-double">
                <Button
                    isHidden={!isLocation}
                    variant="outlined"
                    message="WABA_TEMPLATES_HEADER_MY_LOCATION"
                    onClick={handleLocation}
                />
            </div>
        </>}
    </Columns>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
WabaTemplateHead.propTypes = {
    clientID   : PropTypes.number.isRequired,
    isDisabled : PropTypes.bool.isRequired,
    getValue   : PropTypes.func.isRequired,
    getError   : PropTypes.func.isRequired,
    onChange   : PropTypes.func.isRequired,
    setData    : PropTypes.func.isRequired,
};

export default WabaTemplateHead;
