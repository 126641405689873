import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";

// Components
import OrderDetails         from "../Order/OrderDetails";
import AssistantItem        from "Components/Utils/Details/AssistantItem";

// Dashboard
import Details              from "Dashboard/Components/Details/Details";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";



// Styles
const Container = Styled(Details)`
    height: var(--page-height-tabs);

    @media (max-width: 1200px) {
        height: auto;
    }
`;



/**
 * The Store Details
 * @returns {React.ReactElement}
 */
function StoreDetails() {
    const { canEdit, elem, module } = Store.useState("store");
    const { withDetails, withOrder, withAssistant, detailsTab } = Store.useState("storeState");

    const { setDetailsTab, setAction } = Store.useAction("storeState");


    // Variables
    const hasInternalTabs = withOrder && withAssistant;
    const inOrder         = detailsTab === "order";
    const inAssistant     = detailsTab === "assistant";


    // Do the Render
    return <Container
        isHidden={!withDetails}
        collapsible="order"
        hasTabs={hasInternalTabs}
        onAction={setAction}
        canEdit={canEdit}
        isInside
        isWide
    >
        {hasInternalTabs && <TabList
            selected={detailsTab}
            onClick={setDetailsTab}
            inDetails
        >
            <TabItem
                isHidden={!withOrder}
                message="ORDERS_SINGULAR"
                value="order"
            />
            <TabItem
                isHidden={!withAssistant}
                message="ASSISTANTS_SINGULAR"
                value="assistant"
            />
        </TabList>}

        {inOrder && <OrderDetails />}

        {inAssistant && <AssistantItem
            module={module}
            parentID={elem.id}
            assistantID={elem.assistantID}
            hasInternalTabs={hasInternalTabs}
            hasExternalTabs
        />}
    </Container>;
}

export default StoreDetails;
