import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";

// Components
import CampaignTrigger      from "./CampaignTrigger";

// Dashboard
import NoneAvailable        from "Dashboard/Components/Common/NoneAvailable";
import Alert                from "Dashboard/Components/Form/Alert";
import Button               from "Dashboard/Components/Form/Button";



// Styles
const Container = Styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;



/**
 * The Campaign Triggers
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CampaignTriggers(props) {
    const { getOptionError, getGlobalError } = props;

    const { triggersDraft } = Store.useState("campaign");


    // The Current State
    const [ isCreating, setCreating ] = React.useState(false);


    // Do the Render
    const showNone = Boolean(!isCreating && !triggersDraft.length);

    return <Container>
        <Alert variant="error" message={getOptionError("triggersForm")} />

        <NoneAvailable
            isHidden={!showNone}
            message="CAMPAIGNS_TRIGGERS_NONE_AVAILABLE"
        />

        {triggersDraft.map((item) => <CampaignTrigger
            key={item.triggerID}
            item={item}
            setCreating={setCreating}
            getGlobalError={getGlobalError}
        />)}

        {isCreating && <CampaignTrigger
            setCreating={setCreating}
            getGlobalError={getGlobalError}
        />}

        <div>
            <Button
                isHidden={isCreating}
                variant="outlined"
                message="CAMPAIGNS_TRIGGERS_ADD_TITLE"
                onClick={() => setCreating(true)}
            />
        </div>
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CampaignTriggers.propTypes = {
    getOptionError : PropTypes.func.isRequired,
    getGlobalError : PropTypes.func.isRequired,
};

export default CampaignTriggers;
