import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";

// Dashboard
import IconLink             from "Dashboard/Components/Link/IconLink";



// Styles
const Container = Styled.div`
    display: flex;
`;

const Link = Styled(IconLink)`
    font-size: 18px;
`;



/**
 * The Reply Actions
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ReplyActions(props) {
    const {
        msgTemplateMenuRef, formatMenuRef,
        emojiMenuRef, fileMenuRef,
        variableMenuRef, channelMenuRef,
        msgTemplates, onMenu, onDialog,
    } = props;

    const { variables, assistants } = Store.useState("conversation");


    // Do the Render
    return <Container>
        <Link
            isHidden={!msgTemplates.length}
            passedRef={msgTemplateMenuRef}
            variant="light"
            icon="template"
            tooltip="GENERAL_TEMPLATE"
            tooltipVariant="top"
            onClick={() => onMenu("msgTemplate")}
        />
        <Link
            passedRef={formatMenuRef}
            variant="light"
            icon="format"
            tooltip="GENERAL_FORMAT"
            tooltipVariant="top"
            onClick={() => onMenu("format")}
        />
        <Link
            passedRef={emojiMenuRef}
            variant="light"
            icon="emoji"
            tooltip="EMOJI_NAME"
            tooltipVariant="top"
            onClick={() => onMenu("emoji")}
        />
        <Link
            passedRef={fileMenuRef}
            variant="light"
            icon="attachment"
            tooltip="GENERAL_FILE"
            tooltipVariant="top"
            onClick={() => onMenu("file")}
        />
        <Link
            isHidden={!variables.length}
            passedRef={variableMenuRef}
            variant="light"
            icon="variable"
            tooltip="GENERAL_VARIABLES"
            tooltipVariant="top"
            onClick={() => onMenu("variable")}
        />
        <Link
            passedRef={channelMenuRef}
            variant="light"
            icon="channel"
            tooltip="CHANNELS_SINGULAR"
            tooltipVariant="top"
            onClick={() => onMenu("channel")}
        />
        <Link
            isHidden={!assistants.length}
            variant="light"
            icon="assistant"
            tooltip="ASSISTANTS_SINGULAR"
            tooltipVariant="top"
            onClick={() => onDialog("assistant")}
        />
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ReplyActions.propTypes = {
    msgTemplateMenuRef : PropTypes.any.isRequired,
    formatMenuRef      : PropTypes.any.isRequired,
    emojiMenuRef       : PropTypes.any.isRequired,
    fileMenuRef        : PropTypes.any.isRequired,
    variableMenuRef    : PropTypes.any.isRequired,
    channelMenuRef     : PropTypes.any.isRequired,
    msgTemplates       : PropTypes.array.isRequired,
    onMenu             : PropTypes.func.isRequired,
    onDialog           : PropTypes.func.isRequired,
};

export default ReplyActions;
