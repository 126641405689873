import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useFilter            from "Dashboard/Hooks/Filter";
import Period               from "Dashboard/Utils/Period";
import { Contact }          from "Utils/API";

// Dashboard
import FilterDialog         from "Dashboard/Components/Dialogs/FilterDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Order Filter Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function OrderFilter(props) {
    const { open, clientID, filters, onClose, onSubmit } = props;

    const { statuses } = Store.useState("storeOrder");


    // The Initial Data
    const initialData = {
        period      : 0,
        fromDate    : "",
        toDate      : "",
        contact     : 0,
        contactName : "",
        orderNumber : "",
        status      : "",
    };

    // The Filter State
    const {
        loading, data, handleChange, handlePeriod, handleSubmit,
    } = useFilter("storeOrder", open, initialData, filters, onSubmit);


    // Do the Render
    return <FilterDialog
        open={open}
        title="ORDERS_FILTER_TITLE"
        initialData={initialData}
        data={data}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <Columns amount="3">
            <InputField
                type="select"
                name="period"
                label="PERIOD_NAME"
                value={data.period}
                onChange={handlePeriod}
                options={Period.getSelect()}
            />
            <InputField
                type="date"
                name="fromDate"
                label="GENERAL_FROM"
                value={data.fromDate}
                onChange={handleChange}
            />
            <InputField
                type="date"
                name="toDate"
                label="GENERAL_TO"
                value={data.toDate}
                onChange={handleChange}
            />
        </Columns>

        <InputField
            type="suggest"
            name="contactName"
            label="CONTACTS_SINGULAR"
            suggestID="contact"
            suggestFetch={Contact.search}
            suggestParams={{ clientID }}
            value={data.contactName}
            onChange={handleChange}
        />

        <InputField
            type="number"
            name="orderNumber"
            label="ORDERS_NUMBER"
            value={data.orderNumber}
            onChange={handleChange}
        />
        <InputField
            type="chooser"
            name="status"
            label="GENERAL_STATUS"
            options={statuses}
            value={data.status}
            onChange={handleChange}
        />
    </FilterDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
OrderFilter.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    clientID : PropTypes.number,
    filters  : PropTypes.object,
};

export default OrderFilter;
