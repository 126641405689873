import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";

// Components
import ConnectorInput       from "./ConnectorInput";
import ProviderButton       from "Components/Utils/Common/ProviderButton";

// Dashboard
import InputField           from "Dashboard/Components/Form/InputField";
import Columns              from "Dashboard/Components/Form/Columns";



// Styles
const Container = Styled(Columns)`
    max-width: var(--provider-width);
    margin: 0 auto;
`;

const Title = Styled.h3`
    margin: var(--main-gap) 0;
    font-weight: 400;
`;



/**
 * The Connector Config
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ConnectorConfig(props) {
    const { connector, data, errors, onChange } = props;

    const { statuses } = Store.useState("connector");


    // Do the Render
    if (!connector.connectorCode) {
        return <React.Fragment />;
    }
    return <Container amount="1">
        <ProviderButton
            icon={connector.icon}
            color={connector.color}
            message={connector.fullName}
        />
        <Title>{NLS.get(connector.description)}</Title>

        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={onChange}
            isRequired
        />
        <>
            {connector.inputs.map((item) => <ConnectorInput
                {...item}
                key={item.name}
                type={item.type}
                value={data[item.name] ?? ""}
                error={errors[item.name] ?? ""}
                onChange={onChange}
            />)}
        </>
        <InputField
            type="select"
            name="status"
            label="GENERAL_STATUS"
            options={statuses}
            value={data.status}
            error={errors.status}
            onChange={onChange}
            isRequired
        />
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ConnectorConfig.propTypes = {
    connector : PropTypes.object.isRequired,
    data      : PropTypes.object.isRequired,
    errors    : PropTypes.object.isRequired,
    onChange  : PropTypes.func.isRequired,
};

export default ConnectorConfig;
