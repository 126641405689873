import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import NLS                  from "Dashboard/Core/NLS";

// Components
import ProviderButton       from "Components/Utils/Common/ProviderButton";



// Styles
const Title = Styled.h3`
    margin-top: 0;
    margin-bottom: var(--provider-gap);
    font-weight: 400;
    text-align: center;
`;

const Columns = Styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--provider-gap);
    max-width: var(--provider-width);
    margin: 0 auto;

    @media (max-width: 600px) {
        grid-template-columns: 1fr;
    }
`;



/**
 * The Channel Select
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ChannelSelect(props) {
    const { selected, onSelect } = props;

    const { hasPhoneNumbers } = Store.useState("permission");
    const { providerTypes } = Store.useState("channel");


    // Returns true if the Provider Type is hidden
    const isHidden = (providerType) => {
        switch(providerType) {
        case "Dialog360":
            return true;
        case "Twilio":
            return !hasPhoneNumbers;
        default:
            return false;
        }
    };


    // Do the Render
    return <>
        <Title>{NLS.get("CHANNELS_SELECT_TEXT")}</Title>
        <Columns>
            {providerTypes.map((elem) => <ProviderButton
                isHidden={isHidden(elem.providerType)}
                key={elem.providerType}
                logo={elem.logo}
                color={elem.color}
                message={elem.fullName}
                isSelected={selected === elem.providerType}
                onClick={() => onSelect(elem)}
            />)}
        </Columns>
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ChannelSelect.propTypes = {
    selected : PropTypes.string.isRequired,
    onSelect : PropTypes.func.isRequired,
};

export default ChannelSelect;
