import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";

// Dashboard
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";
import Button               from "Dashboard/Components/Form/Button";
import IconLink             from "Dashboard/Components/Link/IconLink";



// Styles
const Container = Styled.div.attrs(({ hasDelete }) => ({ hasDelete }))`
    position: relative;
    display: flex;
    align-items: center;
    gap: 12px;

    ${(elem) => !elem.hasDelete && `
        margin-right: calc(32px + 12px);
    `}
`;

const Content = Styled(Columns)`
    width: 100%;
`;

const Buttons = Styled.div`
    display: flex;
    gap: 12px;
`;



/**
 * The Campaign Condition
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CampaignCondition(props) {
    const { groupID, item, setCreating, getGlobalError } = props;

    const { elem, selects } = Store.useState("campaign");
    const { createCondition, editCondition, deleteCondition } = Store.useAction("campaign");


    // The References
    const timerRef = React.useRef(null);

    // The Initial Data
    const initialData = {
        campaignID  : elem.campaignID,
        groupID     : groupID,
        conditionID : 0,
        condition   : "",
        fieldID     : "",
        tongueID    : "",
        tagID       : "",
        teamID      : "",
        hotelID     : "",
        value       : "",
    };

    // The Initial Data
    const initialErrors = {
        condition : "",
        fieldID   : "",
        tongueID  : "",
        tagID     : "",
        teamID    : "",
        hotelID   : "",
        value     : "",
    };

    // The Current State
    const [ data,   setData   ] = React.useState({ ...initialData, ...item });
    const [ errors, setErrors ] = React.useState({ ...initialErrors });

    // Some Variables
    const isCreate = !item.id;


    // Returns true if the item is hidden
    const isHidden = (...options) => {
        return !options.includes(data.condition);
    };

    // Returns the Current Error
    const getError = (key) => {
        return getGlobalError(errors, key, "conditions", item.conditionID);
    };

    // Handles the Input Change
    const handleChange = (name, value) => {
        const newData = { ...data, [name] : value };
        setData(newData);
        setErrors({ ...errors, [name] : "" });

        if (isCreate) {
            return;
        }
        Utils.setTimeout(timerRef, () => handleEdit(newData), 1000);
    };

    // Handles the Create
    const handleCreate = async () => {
        try {
            setErrors({ ...initialData });
            await createCondition(data);
            setCreating(false);
        } catch (errors) {
            setErrors(errors);
        }
    };

    // Handles the Edit
    const handleEdit = async (newData) => {
        try {
            setErrors({ ...initialErrors });
            await editCondition(newData);
            timerRef.current = null;
        } catch (errors) {
            setErrors(errors);
        }
    };


    // Do the Render
    const fields = selects[`${data.condition}s`] ?? [];
    const { type, options } = Commons.getCustomFieldInput(data, fields);

    return <>
        <Container className="condition-square" hasDelete={!isCreate}>
            <Content amount="3">
                <InputField
                    type="select"
                    name="condition"
                    label="GENERAL_CONDITION"
                    options={selects.conditions}
                    value={data.condition}
                    error={getError("condition")}
                    onChange={handleChange}
                    isRequired
                />
                <InputField
                    isHidden={isHidden("contactField")}
                    type="select"
                    name="fieldID"
                    label="GENERAL_FIELD"
                    options={selects.contactFields}
                    value={data.fieldID}
                    error={getError("fieldID")}
                    onChange={handleChange}
                    isRequired
                />
                <InputField
                    isHidden={isHidden("contactTongue")}
                    type="select"
                    name="tongueID"
                    label="LANGUAGES_SINGULAR"
                    options={selects.tongues}
                    value={data.tongueID}
                    error={getError("tongueID")}
                    onChange={handleChange}
                    isRequired
                />
                <InputField
                    isHidden={isHidden("contactTag", "conversationTag")}
                    type="select"
                    name="tagID"
                    label="TAGS_SINGULAR"
                    options={selects.tags}
                    value={data.tagID}
                    error={getError("tagID")}
                    onChange={handleChange}
                    isRequired
                />
                <InputField
                    isHidden={isHidden("conversationField")}
                    type="select"
                    name="fieldID"
                    label="GENERAL_FIELD"
                    options={selects.conversationFields}
                    value={data.fieldID}
                    error={getError("fieldID")}
                    onChange={handleChange}
                    isRequired
                />
                <InputField
                    isHidden={isHidden("conversationTeam")}
                    type="select"
                    name="teamID"
                    label="TEAMS_SINGULAR"
                    options={selects.teams}
                    value={data.teamID}
                    error={getError("teamID")}
                    onChange={handleChange}
                    isRequired
                />
                <InputField
                    isHidden={isHidden("hospitalityField")}
                    type="select"
                    name="fieldID"
                    label="GENERAL_FIELD"
                    options={selects.hospitalityFields}
                    value={data.fieldID}
                    error={getError("fieldID")}
                    onChange={handleChange}
                    isRequired
                />
                <InputField
                    isHidden={isHidden("hospitalityBooking", "hospitalityIn", "hospitalityOut")}
                    type="select"
                    name="hotelID"
                    label="HOTELS_SINGULAR"
                    options={selects.hotels}
                    value={data.hotelID}
                    error={getError("hotelID")}
                    onChange={handleChange}
                    noneText="GENERAL_ANY"
                    shrinkLabel
                />
                <InputField
                    isHidden={isHidden("contactField", "conversationField", "hospitalityField")}
                    type={type}
                    name="value"
                    label="GENERAL_VALUE"
                    options={options}
                    value={data.value}
                    error={getError("value")}
                    onChange={handleChange}
                />
            </Content>

            <IconLink
                isHidden={isCreate}
                variant="black"
                icon="delete"
                onClick={() => deleteCondition(data.conditionID)}
            />
        </Container>

        {isCreate && <Buttons className="condition-square">
            <Button
                variant="outlined"
                message="GENERAL_SAVE"
                onClick={handleCreate}
            />
            <Button
                variant="outlined"
                message="GENERAL_CANCEL"
                onClick={() => setCreating(false)}
            />
        </Buttons>}
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CampaignCondition.propTypes = {
    groupID        : PropTypes.number,
    item           : PropTypes.object,
    setCreating    : PropTypes.func,
    getGlobalError : PropTypes.func.isRequired,
    forGroup       : PropTypes.bool,
};

/**
 * The Default Properties
 * @type {Object} defaultProps
 */
CampaignCondition.defaultProps = {
    groupID  : 0,
    item     : {},
    forGroup : false,
};

export default CampaignCondition;
