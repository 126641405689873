import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useFilter            from "Dashboard/Hooks/Filter";

// Dashboard
import FilterDialog         from "Dashboard/Components/Dialogs/FilterDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Client Filter Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ClientFilter(props) {
    const { open, forPartner, filters, onClose, onSubmit } = props;

    const { partners, countries, provinces, plans, statuses } = Store.useState("client");


    // The Initial Data
    const initialData = {
        partnerID    : 0,
        name         : "",
        socialReason : "",
        taxID        : "",
        countryID    : "",
        provinceID   : "",
        planID       : 0,
        status       : "",
    };

    // The Filter State
    const {
        loading, data, handleChange, handleSubmit,
    } = useFilter("client", open, initialData, filters, onSubmit);


    // Do the Render
    return <FilterDialog
        open={open}
        title="CLIENTS_FILTER_TITLE"
        initialData={initialData}
        data={data}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <Columns>
            <InputField
                isHidden={forPartner}
                type="select"
                name="partnerID"
                label="PARTNERS_SINGULAR"
                options={partners}
                value={data.partnerID}
                onChange={handleChange}
            />

            <InputField
                name="name"
                label="GENERAL_FANTASY_NAME"
                value={data.name}
                onChange={handleChange}
            />
            <InputField
                name="socialReason"
                label="GENERAL_SOCIAL_REASON"
                value={data.socialReason}
                onChange={handleChange}
            />
            <InputField
                name="taxID"
                label="GENERAL_TAX_ID"
                value={data.taxID}
                onChange={handleChange}
            />

            <InputField
                type="select"
                name="countryID"
                label="COUNTRIES_SINGULAR"
                options={countries}
                value={data.countryID}
                onChange={handleChange}
            />
            <InputField
                type="select"
                name="provinceID"
                label="GENERAL_PROVINCE"
                options={provinces[data.countryID] || []}
                value={data.provinceID}
                onChange={handleChange}
            />

            <InputField
                type="select"
                name="planID"
                label="CLIENTS_PLAN"
                options={plans}
                value={data.planID}
                onChange={handleChange}
            />
            <InputField
                type="select"
                name="status"
                label="GENERAL_STATUS"
                options={statuses}
                value={data.status}
                onChange={handleChange}
            />
        </Columns>
    </FilterDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ClientFilter.propTypes = {
    open       : PropTypes.bool.isRequired,
    forPartner : PropTypes.bool.isRequired,
    onClose    : PropTypes.func.isRequired,
    onSubmit   : PropTypes.func.isRequired,
    filters    : PropTypes.object,
};

export default ClientFilter;
