import React                from "react";
import PropTypes            from "prop-types";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import ViewDialog           from "Dashboard/Components/Dialogs/ViewDialog";
import ViewField            from "Dashboard/Components/Form/ViewField";
import Columns              from "Dashboard/Components/Form/Columns";



/**
 * The Assistant Run Log View Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function AssistantRunLogView(props) {
    const { open, elemID, isAdmin, isFlow, onClose } = props;

    // Load the Data
    const { loading, elem } = useDialog("assistantRun", open, elemID);


    // Formats the Request
    const formatRequest = (request) => {
        let result = request.replace(/ '/g, " \"<b>");
        result = result.replace(/' /g, "</b>\" ");
        result = result.replace(/'. /g, "\"</b>. ");
        result = result.replace(/\. /g, ".<br>");
        return result;
    };


    // Do the Render
    return <ViewDialog
        open={open}
        icon="assistant-log"
        title="ASSISTANTS_RUNS_VIEW_TITLE"
        onClose={onClose}
        isLoading={loading}
        withSpacing
    >
        <Columns lastDouble>
            <ViewField
                label="GENERAL_TIME"
                value={elem.createdDate}
            />
            <ViewField
                label="ASSISTANTS_RUNS_EXECUTION"
                value={elem.runTimeText}
            />

            <ViewField
                isHidden={!isAdmin}
                label="CLIENTS_SINGULAR"
                value={elem.clientName}
                showEmpty
            />
            <ViewField
                isHidden={isFlow}
                label="FLOWS_SINGULAR"
                value={elem.flowName}
                showEmpty
            />
            <ViewField
                label="CONVERSATIONS_SINGULAR"
                value={elem.conversationText}
                showEmpty
            />

            <ViewField
                label="ASSISTANTS_MODELS_SINGULAR"
                value={elem.model}
            />
            <ViewField
                isHidden={!elem.fileIDs}
                label="MEDIA_NAME"
                value={elem.fileIDs}
            />
            <ViewField
                label="GENERAL_DURATION"
                value={elem.durationText}
            />
            <ViewField
                isHidden={!elem.inputTokens}
                label="ASSISTANTS_RUNS_INPUT"
                value={elem.inputTokensText}
            />
            <ViewField
                label="ASSISTANTS_RUNS_OUTPUT"
                value={elem.outputTokensText}
            />
        </Columns>

        <ViewField
            label="GENERAL_REQUEST"
            value={formatRequest(elem.request ?? "")}
        />
        <ViewField
            label="ASSISTANTS_INSTRUCTIONS"
            value={formatRequest(elem.instructions ?? "")}
        />
        <ViewField
            label="GENERAL_RESPONSE"
            value={elem.response}
        />
    </ViewDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
AssistantRunLogView.propTypes = {
    open    : PropTypes.bool.isRequired,
    isAdmin : PropTypes.bool.isRequired,
    isFlow  : PropTypes.bool.isRequired,
    onClose : PropTypes.func.isRequired,
    elemID  : PropTypes.number,
};

export default AssistantRunLogView;
