import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Phone Number Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function PhoneNumberEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { editPhoneNumber } = Store.useAction("phoneNumber");


    // The Initial Data
    const initialData = {
        clientID      : 0,
        phoneNumberID : 0,
        name          : "",
        location      : "",
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("phoneNumber", initialData, editPhoneNumber, onSubmit);

    // Load the Data
    const { loading } = useDialog("phoneNumber", open, elemID, null, setElem);



    // Do the Render
    return <EditDialog
        open={open}
        icon="phone-number"
        title="PHONE_NUMBERS_EDIT_TITLE"
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            name="name"
            label="PHONE_NUMBERS_DESCRIPTIVE_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
        />
        <InputField
            name="location"
            label="PHONE_NUMBERS_LOCATION"
            value={data.location}
            error={errors.location}
            onChange={handleChange}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
PhoneNumberEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default PhoneNumberEdit;
