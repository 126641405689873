import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Tongue Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function TongueEdit(props) {
    const { open, elemID, clientID, onClose, onSubmit } = props;

    const { languages, colors } = Store.useState("tongue");
    const { editTongue } = Store.useAction("tongue");


    // The Initial Data
    const initialData = {
        clientID   : 0,
        tongueID   : 0,
        languageID : 0,
        color      : "#f2f2f2",
        isDefault  : 0,
        position   : 0,
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("tongue", initialData, editTongue, onSubmit);

    // Load the Data
    const { loading } = useDialog("tongue", open, elemID, { clientID }, setElem);



    // Do the Render
    return <EditDialog
        open={open}
        icon="language"
        title={elemID ? "LANGUAGES_EDIT_TITLE" : "LANGUAGES_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            type="select"
            name="languageID"
            label="LANGUAGES_SINGULAR"
            options={languages}
            value={data.languageID}
            error={errors.languageID}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="color"
            name="color"
            label="GENERAL_COLOR"
            options={colors}
            value={data.color}
            error={errors.color}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="toggle"
            name="isDefault"
            label="GENERAL_IS_DEFAULT"
            value={data.isDefault}
            onChange={handleChange}
            withBorder
        />
        <InputField
            type="number"
            name="position"
            label="GENERAL_POSITION"
            value={data.position}
            error={errors.position}
            onChange={handleChange}
            minValue={0}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
TongueEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
    clientID : PropTypes.number,
};

export default TongueEdit;
