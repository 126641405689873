import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import { WabaTemplate }     from "Utils/API";



// The initial State
const initialState = {
    loading       : true,
    charging      : false,
    error         : false,
    edition       : 0,
    canCreate     : false,
    canEdit       : false,
    canImport     : false,
    canExport     : false,
    list          : [],
    total         : 0,
    elem          : {
        name : "",
    },
    components    : {},
    tongues       : [],
    channels      : [],
    variables     : [],
    wabaTemplates : [],
    channelLinks  : [],
    bodyLength    : 0,
    textLength    : 0,
    maxButtons    : 0,
    buttonLength  : 0,
    sort          : {
        orderBy  : "name",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "WABA_TEMPLATE_LOADING" });
    },

    /**
     * Fetches the WABA Template List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, params = {}) {
        Navigate.unsetParams(params);
        if (type === "CLIENT") {
            params.clientID = elemID;
        }
        const data = await WabaTemplate.getAll(params);
        data.sort  = params;
        dispatch({ type : "WABA_TEMPLATE_LIST", data });
    },

    /**
     * Fetches a single WABA Template
     * @param {Function} dispatch
     * @param {Number}   wabaTemplateID
     * @returns {Promise}
     */
    async fetchElem(dispatch, wabaTemplateID) {
        dispatch({ type : "WABA_TEMPLATE_CHARGING" });
        const data = await WabaTemplate.getOne({ wabaTemplateID });
        dispatch({ type : "WABA_TEMPLATE_ELEM", data });
    },

    /**
     * Fetches the WABA Template Edit data
     * @param {Function} dispatch
     * @param {Number}   clientID
     * @returns {Promise}
     */
    async fetchEditData(dispatch, clientID) {
        dispatch({ type : "WABA_TEMPLATE_CHARGING" });
        const data = await WabaTemplate.getEditData({ clientID });
        dispatch({ type : "WABA_TEMPLATE_EDIT", data });
    },

    /**
     * Fetches the WABA Template Import data
     * @param {Function} dispatch
     * @param {Number}   clientID
     * @param {Number}   channelID
     * @param {Boolean=} isInitial
     * @returns {Promise}
     */
    async fetchImportData(dispatch, clientID, channelID, isInitial = true) {
        const data = await WabaTemplate.getImportData({ clientID, channelID });
        data.isInitial = isInitial;
        dispatch({ type : "WABA_TEMPLATE_IMPORT", data });
    },

    /**
     * Edits/Creates a WABA Template
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editWabaTemplate(dispatch, data) {
        return WabaTemplate.edit(data);
    },

    /**
     * Imports a WABA Template
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    importWabaTemplate(dispatch, data) {
        return WabaTemplate.import(data);
    },

    /**
     * Deletes a WABA Template
     * @param {Function} dispatch
     * @param {Number}   wabaTemplateID
     * @returns {Promise}
     */
    deleteWabaTemplate(dispatch, wabaTemplateID) {
        return WabaTemplate.delete({ wabaTemplateID });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.categoryName = NLS.get("SELECT_WABA_TEMPLATES_CATEGORIES", elem.category);
    elem.languageName = elem.components.map(({ languageName }) => languageName).join(" - ");
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "WABA_TEMPLATE_LIST", "WABA_TEMPLATE_ELEM", "WABA_TEMPLATE_EDIT", "WABA_TEMPLATE_IMPORT")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "WABA_TEMPLATE_LOADING":
        return {
            ...state,
            loading       : true,
        };
    case "WABA_TEMPLATE_CHARGING":
        return {
            ...state,
            charging      : true,
        };

    case "WABA_TEMPLATE_LIST":
        return {
            ...state,
            loading       : false,
            error         : false,
            canCreate     : action.data.canCreate,
            canEdit       : action.data.canEdit,
            canImport     : action.data.canImport,
            canExport     : action.data.canExport,
            list          : Utils.parseList(action.data.list, parseElem),
            total         : action.data.total,
            channels      : action.data.channels,
            sort          : action.data.sort,
        };

    case "WABA_TEMPLATE_ELEM":
        return {
            ...state,
            charging      : false,
            error         : false,
            edition       : state.edition + 1,
            elem          : parseElem(action.data.elem),
            components    : action.data.components,
            tongues       : action.data.tongues,
            channels      : action.data.channels,
            variables     : action.data.variables,
            channelLinks  : action.data.channelLinks,
            bodyLength    : action.data.bodyLength,
            textLength    : action.data.textLength,
            maxButtons    : action.data.maxButtons,
            buttonLength  : action.data.buttonLength,
        };

    case "WABA_TEMPLATE_EDIT":
        return {
            ...state,
            charging      : false,
            error         : false,
            edition       : state.edition + 1,
            elem          : { ...initialState.elem },
            components    : {},
            tongues       : action.data.tongues,
            channels      : action.data.channels,
            variables     : action.data.variables,
            channelLinks  : action.data.channelLinks,
            bodyLength    : action.data.bodyLength,
            textLength    : action.data.textLength,
            maxButtons    : action.data.maxButtons,
            buttonLength  : action.data.buttonLength,
        };

    case "WABA_TEMPLATE_IMPORT":
        return {
            ...state,
            error         : false,
            edition       : state.edition + (action.data.isInitial ? 1 : 0),
            channels      : action.data.channels,
            variables     : action.data.variables,
            channelLinks  : action.data.channelLinks,
            wabaTemplates : action.data.wabaTemplates,
            maxButtons    : action.data.maxButtons,
            buttonLength  : action.data.buttonLength,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
