import React                from "react";
import PropTypes            from "prop-types";
import Utils                from "Dashboard/Utils/Utils";
import NLS                  from "Dashboard/Core/NLS";
import Commons              from "Utils/Commons";

// Components
import FormatMenu           from "Components/Utils/Menus/FormatMenu";
import VariableMenu         from "Components/Utils/Menus/VariableMenu";
import ChannelMenu          from "Components/Utils/Menus/ChannelMenu";

// Dashboard
import InputField           from "Dashboard/Components/Form/InputField";
import IconLink             from "Dashboard/Components/Link/IconLink";
import EmojiPopup           from "Dashboard/Components/Form/EmojiPopup";



/**
 * The Textarea Field
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function TextareaField(props) {
    const {
        isHidden, name, label, value, error, helperText, maxLength,
        messageCost, withoutFormat, withoutEmoji, variables, channelLinks,
        onChange, isRequired, children,
    } = props;


    // The References
    const inputRef    = React.useRef(null);
    const formatRef   = React.useRef(null);
    const emojiRef    = React.useRef(null);
    const variableRef = React.useRef(null);
    const channelRef  = React.useRef(null);

    // The Current State
    const [ menu, setMenu ] = React.useState("");


    // Handles the Insert Click
    const handleInsertClick = (text) => {
        onChange(name, text);
        setMenu("");
    };

    // Handles the Emoji Click
    const handleEmojiClick = (emoji) => {
        const text = Utils.insertText(inputRef, value, emoji);
        onChange(name, text);
        setMenu("");
        inputRef.current.focus();
    };


    // Do the Render
    const withVariable    = variables.length > 0;
    const withChannel     = channelLinks.length > 0;
    const messageCostText = messageCost ? Commons.calculateSMSCost(value, messageCost) : "";

    if (isHidden) {
        return <React.Fragment />;
    }
    return <>
        <InputField
            passedRef={inputRef}
            type="textarea"
            name={name}
            label={label}
            value={value}
            error={error}
            helperText={helperText}
            maxLength={maxLength}
            onChange={onChange}
            isRequired={isRequired}
            rows="2"
        >
            {children}
            {!!messageCostText && <span>
                {NLS.format("CONVERSATIONS_SMS_COST", messageCostText)}
            </span>}
            <IconLink
                isHidden={withoutFormat}
                passedRef={formatRef}
                variant="black"
                icon="format"
                tooltip="GENERAL_FORMAT"
                onClick={() => setMenu("format")}
                isSmall
            />
            <IconLink
                isHidden={withoutEmoji}
                passedRef={emojiRef}
                variant="black"
                icon="emoji"
                tooltip="EMOJI_NAME"
                onClick={() => setMenu("emoji")}
                isSmall
            />
            <IconLink
                isHidden={!withVariable}
                passedRef={variableRef}
                variant="black"
                icon="variable"
                tooltip="GENERAL_VARIABLES"
                onClick={() => setMenu("variable")}
                isSmall
            />
            <IconLink
                isHidden={!withChannel}
                passedRef={channelRef}
                variant="black"
                icon="channel"
                tooltip="CHANNELS_SINGULAR"
                onClick={() => setMenu("channel")}
                isSmall
            />
        </InputField>

        <FormatMenu
            open={menu === "format"}
            targetRef={formatRef}
            inputRef={inputRef}
            message={value}
            direction="bottom left"
            onText={(text) => onChange(name, text)}
            onClose={() => setMenu("")}
        />
        <EmojiPopup
            open={menu === "emoji"}
            targetRef={emojiRef}
            direction="bottom left"
            gap={4}
            onClick={handleEmojiClick}
            onClose={() => setMenu("")}
        />
        <VariableMenu
            open={menu === "variable"}
            targetRef={variableRef}
            inputRef={inputRef}
            message={value}
            list={variables}
            direction="bottom left"
            onClick={handleInsertClick}
            onClose={() => setMenu("")}
        />
        <ChannelMenu
            open={menu === "channel"}
            targetRef={channelRef}
            inputRef={inputRef}
            message={value}
            list={channelLinks}
            direction="bottom left"
            onClick={handleInsertClick}
            onClose={() => setMenu("")}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
TextareaField.propTypes = {
    isHidden      : PropTypes.bool,
    name          : PropTypes.string.isRequired,
    label         : PropTypes.string.isRequired,
    value         : PropTypes.string.isRequired,
    error         : PropTypes.string,
    helperText    : PropTypes.string,
    maxLength     : PropTypes.number,
    messageCost   : PropTypes.number,
    withoutFormat : PropTypes.bool,
    withoutEmoji  : PropTypes.bool,
    variables     : PropTypes.array.isRequired,
    channelLinks  : PropTypes.array.isRequired,
    onChange      : PropTypes.func.isRequired,
    isRequired    : PropTypes.bool,
    children      : PropTypes.any,
};

export default TextareaField;
