import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";

// Dashboard
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionItem           from "Dashboard/Components/Header/ActionItem";
import ActionOption         from "Dashboard/Components/Header/ActionOption";
import MenuLine             from "Dashboard/Components/Menu/MenuLine";



/**
 * The Contact Header
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ContactHeader(props) {
    const { startAction, checked, isExporting } = props;

    const { hasAccount } = Store.useState("permission");
    const {
        canEdit, canFilter, canImport, canExport,
        total, elem, hospitality, hasFilters,
    } = Store.useState("contact");


    // Handles the Action
    const handleAction = (action) => {
        startAction(action, elem.id);
    };


    // Variables
    const hasEdit          = canEdit && !!elem.id;
    const hasExport        = canExport && total > 0;
    const canCombine       = canEdit && checked.length === 0 && !!elem.id;
    const canMerge         = canEdit && checked.length > 1;
    const canHospitality   = hasEdit && !!hospitality.id;
    const canAssignAccount = hasEdit && hasAccount;
    const canDoBlock       = hasEdit && !elem.isBlocked;
    const canUnblock       = hasEdit && elem.isBlocked;
    const canDelete        = canEdit && checked.length === 0 && !!elem.id;
    const canMultiDelete   = canEdit && checked.length > 0;
    const canConverse      = hasEdit && !elem.isBlocked;


    // Do the Render
    return <Header message="CONTACTS_NAME" icon="contact">
        <ActionList
            onAction={handleAction}
            withImport={false}
            withExport={false}
        >
            <ActionItem
                isHidden={!canFilter}
                action="FILTER"
                withMark={hasFilters}
            />
            <ActionItem
                isHidden={!canEdit}
                icon="more"
                message="GENERAL_ACTIONS"
                isLoading={isExporting}
            >
                <ActionOption
                    isHidden={!canEdit}
                    action="CREATE"
                    message="CONTACTS_CREATE_TITLE"
                />
                <ActionOption
                    isHidden={!hasEdit}
                    action="EDIT"
                    message="CONTACTS_EDIT_TITLE"
                />
                <ActionOption
                    isHidden={!canCombine}
                    action="COMBINE"
                    message="CONTACTS_MERGE_TITLE"
                />

                <ActionOption
                    isHidden={!canHospitality}
                    action="HOSPITALITY"
                    message="HOSPITALITY_EDIT_TITLE"
                />
                <ActionOption
                    isHidden={!canAssignAccount}
                    action="ASSIGN"
                    message="ACCOUNTS_ASSIGN_ACCOUNT_TITLE"
                />

                <ActionOption
                    isHidden={!canDoBlock}
                    action="BLOCK"
                    message="CONTACTS_BLOCK_TITLE"
                />
                <ActionOption
                    isHidden={!canUnblock}
                    action="BLOCK"
                    message="CONTACTS_UNBLOCK_TITLE"
                />
                <ActionOption
                    isHidden={!canDelete}
                    action="DELETE"
                    message="CONTACTS_DELETE_TITLE"
                />

                <ActionOption
                    isHidden={!canConverse}
                    action="CONVERSATION"
                    message="CONVERSATIONS_CREATE_TITLE"
                />

                <MenuLine />
                <ActionOption
                    isHidden={!canImport}
                    action="IMPORT"
                    message="CONTACTS_IMPORT_TITLE"
                />
                <ActionOption
                    isHidden={!hasExport}
                    action="EXPORT"
                    message="CONTACTS_EXPORT_TITLE"
                />

                <ActionOption
                    isHidden={!canMerge}
                    action="MERGE"
                    message="CONTACTS_MERGE_TITLE"
                />
                <ActionOption
                    isHidden={!canMultiDelete}
                    action="MULTIDEL"
                    message="CONTACTS_MULTI_DELETE_TITLE"
                />
            </ActionItem>
        </ActionList>
    </Header>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ContactHeader.propTypes = {
    startAction : PropTypes.func.isRequired,
    checked     : PropTypes.array.isRequired,
    isExporting : PropTypes.bool.isRequired,
};

export default ContactHeader;
