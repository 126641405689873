import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import KeyCode              from "Dashboard/Utils/KeyCode";
import Utils                from "Dashboard/Utils/Utils";

// Dashboard
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";
import InputField           from "Dashboard/Components/Form/InputField";



// Styles
const SearchField = Styled(InputField)`
    margin-bottom: var(--main-gap);

    .input {
        border-color: var(--light-gray);
        border-width: 2px
    }
`;

const Tabs = Styled(TabList)`
    width: 100%;
    .tabs-content {
        width: 100%;
        gap: 8px;
    }
    .tab-item {
        margin: 0;
        width: 50%;
    }
`;



/**
 * The List Search
 * @returns {React.ReactElement}
 */
function ListSearch() {
    const { clientID, showSearch, searchTab, search } = Store.useState("conversation");
    const { searchList, setSearchTab, endSearch } = Store.useAction("conversation");

    // The References
    const timerRef = React.useRef(0);

    // The Current State
    const [ searchText, setSearchText ] = React.useState("");


    // Handles the Clear
    const handleClear = () => {
        setSearchText("");
        endSearch();
    };

    // Handles the Input
    const handleInput = (name, text) => {
        Utils.clearTimeout(timerRef);
        if (text.length < 3) {
            return;
        }
        Utils.setTimeout(timerRef, () => handleSearch(text), 1000);
    };

    // Handles the KeyDown
    const handleKeyDown = (e) => {
        if (e.keyCode !== KeyCode.DOM_VK_RETURN) {
            return;
        }
        Utils.clearTimeout(timerRef);
        handleSearch(searchText);
        e.preventDefault();
    };

    // Handles the Search
    const handleSearch = async (text) => {
        const result = await searchList(clientID, text);
        if (result) {
            if (searchTab === "contacts" && !result.contactsTotal && result.conversationsTotal) {
                setSearchTab("conversations");
            } else if (searchTab === "conversations" && result.contactsTotal && !result.conversationsTotal) {
                setSearchTab("contacts");
            }
        }
    };

    // Handles the Tab
    const handleTab = (action, elemID) => {
        setSearchTab(elemID);
    };



    // Do the Render
    if (!showSearch) {
        return <React.Fragment />;
    }
    return <>
        <SearchField
            name="search"
            label="GENERAL_SEARCH"
            value={searchText}
            onChange={(name, value) => setSearchText(value)}
            onInput={handleInput}
            onClear={handleClear}
            onKeyDown={handleKeyDown}
            hasClear
            autoFocus
        />

        <Tabs
            selected={searchTab}
            onAction={handleTab}
        >
            <TabItem
                value="contacts"
                message="CONTACTS_NAME"
                amount={search.contactsTotal}
            />
            <TabItem
                value="conversations"
                message="CONVERSATIONS_NAME"
                amount={search.conversationsTotal}
            />
        </Tabs>
    </>;
}

export default ListSearch;
