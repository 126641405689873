import React                from "react";
import PropTypes            from "prop-types";
import Utils                from "Dashboard/Utils/Utils";

// Dashboard
import Menu                 from "Dashboard/Components/Menu/Menu";
import MenuItem             from "Dashboard/Components/Menu/MenuItem";



/**
 * The Channel Menu
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ChannelMenu(props) {
    const {
        open, targetRef, inputRef, message,
        list, direction, onClick, onClose,
    } = props;

    // Handles the Click
    const handleClick = (elem) => {
        if (!message || !inputRef) {
            onClick(elem);
        } else {
            const result = Utils.insertText(inputRef, message, elem);
            onClick(result);
            inputRef.current.focus();
        }
    };


    // Do the Render
    return <Menu
        open={open}
        targetRef={targetRef}
        direction={direction}
        gap={4}
        onClose={onClose}
    >
        {list.map(({ key, value, extra }) => <MenuItem
            key={key}
            title={value}
            message={extra}
            onClick={() => handleClick(key)}
            isSmall
        />)}
    </Menu>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ChannelMenu.propTypes = {
    open      : PropTypes.bool.isRequired,
    targetRef : PropTypes.any,
    inputRef  : PropTypes.any,
    message   : PropTypes.string,
    list      : PropTypes.array.isRequired,
    direction : PropTypes.string.isRequired,
    onClick   : PropTypes.func.isRequired,
    onClose   : PropTypes.func.isRequired,
};

export default ChannelMenu;
