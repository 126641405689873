import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";

// Components
import FlowNodeIO           from "./FlowNodeIO";



// Styles
const Output = Styled(FlowNodeIO)`
    flex-direction: row-reverse;
    margin-right: -6px;
`;



/**
 * The Flow Node Output
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function FlowNodeOutput(props) {
    const { elem } = props;

    const { selectedEdge } = Store.useState("flowState");


    // Returns true if the Edge is Selected
    const isSelected = (nodeOut) => {
        return (
            selectedEdge && selectedEdge.fromNodeID === elem.id &&
            selectedEdge.fromNodeOut === String(nodeOut)
        );
    };



    // Do the Render
    return <div>
        {elem.fromNodeOuts.map(({ key, value }) => <Output
            key={key}
            message={value}
            action="grabEdge"
            id={elem.id}
            type={key}
            isSelected={isSelected(key)}
        />)}
    </div>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
FlowNodeOutput.propTypes = {
    elem : PropTypes.object.isRequired,
};

export default FlowNodeOutput;
