import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";
import Conversations        from "Utils/Conversations";
import { StoreOrder }       from "Utils/API";



// The initial State
const initialState = {
    loading      : true,
    error        : false,
    edition      : 0,
    canCreate    : false,
    canEdit      : false,
    canImport    : false,
    canExport    : false,
    list         : [],
    total        : 0,
    elem         : { id : 0 },
    contact      : {},
    conversation : {},
    editElem     : {},
    statuses     : [],
    filters      : {},
    hasFilters   : false,
    sort         : {
        orderBy  : "createdTime",
        orderAsc : 0,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "STORE_ORDER_LOADING" });
    },

    /**
     * Fetches the Store Order List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  filters
     * @param {Object=}  sort
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, filters = {}, sort = {}) {
        const params = { ...filters, ...sort };
        Navigate.unsetParams(params);
        if (type === "STORE") {
            params.storeID = elemID;
        }
        const data = await StoreOrder.getAll(params);
        data.filters = filters;
        data.sort    = sort;
        dispatch({ type : "STORE_ORDER_LIST", data });
    },

    /**
     * Fetches a single Store Order
     * @param {Function} dispatch
     * @param {Number}   orderID
     * @returns {Promise}
     */
    async fetchElem(dispatch, orderID) {
        const data = await StoreOrder.getOne({ orderID });
        dispatch({ type : "STORE_ORDER_ELEM", data });
        return data;
    },

    /**
     * Removes the single Order
     * @param {Function} dispatch
     * @returns {Promise}
     */
    async removeElem(dispatch) {
        dispatch({ type : "STORE_ORDER_REMOVE" });
    },

    /**
     * Fetches the Order Edit data
     * @param {Function} dispatch
     * @param {Number}   orderID
     * @param {Number}   storeID
     * @returns {Promise}
     */
    async fetchEditData(dispatch, orderID, storeID) {
        const data = await StoreOrder.getEditData({ orderID, storeID });
        dispatch({ type : "STORE_ORDER_EDIT", data });
    },

    /**
     * Edits/Creates a Store Order
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editOrder(dispatch, data) {
        return StoreOrder.edit(data);
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "STORE_ORDER_LIST", "STORE_ORDER_ELEM", "STORE_ORDER_EDIT")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "STORE_ORDER_LOADING":
        return {
            ...state,
            loading      : true,
        };

    case "STORE_ORDER_LIST":
        return {
            ...state,
            loading      : false,
            error        : false,
            canCreate    : action.data.canCreate,
            canEdit      : action.data.canEdit,
            canImport    : action.data.canImport,
            canExport    : action.data.canExport,
            list         : Utils.parseList(action.data.list, Commons.parseOrder),
            total        : action.data.total,
            filters      : action.data.filters,
            hasFilters   : !Utils.isEmpty(action.data.filters),
            sort         : action.data.sort,
        };

    case "STORE_ORDER_ELEM":
        return {
            ...state,
            error        : false,
            edition      : state.edition + 1,
            elem         : Commons.parseOrder(action.data.elem),
            contact      : Commons.parseContact(action.data.contact),
            conversation : Conversations.parseElem(action.data.conversation),
        };

    case "STORE_ORDER_EDIT":
        return {
            ...state,
            error        : false,
            edition      : state.edition + 1,
            editElem     : action.data.elem ? Commons.parseOrder(action.data.elem) : {},
            statuses     : action.data.statuses,
        };

    case "STORE_ORDER_REMOVE":
        return {
            ...state,
            error        : false,
            edition      : state.edition + 1,
            elem         : { ...initialState.elem },
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
