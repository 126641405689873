import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import DialogMessage        from "Dashboard/Components/Dialog/DialogMessage";
import InputField           from "Dashboard/Components/Form/InputField";



// Styles
const Title = Styled(DialogMessage)`
    margin: 24px 0 0 0;
    color: var(--title-color);
    font-weight: bold;
`;

const Phone = Styled.div`
    padding: 8px 12px;
    border-radius: var(--border-radius);
    background-color: var(--lighter-gray);
    cursor: pointer;

    .input-content {
        background: transparent;
    }
    div:empty {
        display: none;
    }
`;

const Label = Styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;



/**
 * The Phone Number Add Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function PhoneNumberAdd(props) {
    const { open, clientID, onClose, onSubmit } = props;

    const { numbers } = Store.useState("phoneNumber");
    const { addPhoneNumber } = Store.useAction("phoneNumber");


    // The Initial Data
    const initialData = {
        clientID     : 0,
        name         : "",
        phoneNumber  : "",
        friendlyName : "",
        location     : "",
    };


    // Handles the Phone
    const handlePhone = (elem) => {
        setData({
            phoneNumber  : elem.phoneNumber,
            friendlyName : elem.friendlyName,
            location     : [ elem.locality, elem.region, elem.isoCountry ].join(", "),
        });
    };

    // The Form State
    const {
        data, errors, setElem, setData, handleChange, handleSubmit,
    } = useForm("phoneNumber", initialData, addPhoneNumber, onSubmit);

    // Load the Data
    const { loading } = useDialog("phoneNumber", open, 0, { clientID }, setElem);



    // Do the Render
    return <EditDialog
        open={open}
        icon="phone-number"
        title="PHONE_NUMBERS_ADD_TITLE"
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isDisabled={!numbers.length}
    >
        <InputField
            name="name"
            label="PHONE_NUMBERS_DESCRIPTIVE_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
        />

        <Title
            variant="h3"
            message="PHONE_NUMBERS_SELECT_PHONE"
        />
        <>
            {numbers.map((elem) => <Phone key={elem.phoneNumber}>
                <InputField
                    type="checkbox"
                    name="contactID"
                    value={elem.phoneNumber === data.phoneNumber ? 1 : 0}
                    onChange={() => handlePhone(elem)}
                >
                    <Label>
                        <b>{elem.friendlyName}</b>
                        <div>{elem.locality}</div>
                        <div>{elem.region}</div>
                        <div>{elem.isoCountry}</div>
                    </Label>
                </InputField>
            </Phone>)}
        </>
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
PhoneNumberAdd.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    clientID : PropTypes.number.isRequired,
};

export default PhoneNumberAdd;
