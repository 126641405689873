import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";

// Components
import TextField            from "Components/Utils/Inputs/TextField";
import TextItem             from "Components/Utils/Inputs/TextItem";
import TextareaField        from "Components/Utils/Inputs/TextareaField";
import MediaField           from "Components/Utils/Inputs/MediaField";

// Dashboard
import InputField           from "Dashboard/Components/Form/InputField";
import InputItem            from "Dashboard/Components/Form/InputItem";



/**
 * The Flow Input
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function FlowInput(props) {
    const { clientID, item, data, errors, language, onChange } = props;

    const { selects, variables, channelLinks } = Store.useState("flowEditor");


    // Handles the Change
    const handleChange = (name, value) => {
        if (!item.useLanguage) {
            onChange(name, value);
            return;
        }

        if (!data[name] || !Utils.isObject(data[name])) {
            data[name] = {};
        }
        data[name][language] = value;
        onChange(name, data[name]);
    };


    // Returns true if it should hide the Field
    const hideField = (item, data) => {
        if (!item.hide) {
            return false;
        }
        return eval(item.hide)(data, selects, language);
    };

    // Returns the Value for the given field
    const getValue = (name = item.name, defaultValue = "") => {
        if (!item.useLanguage) {
            return data[name] || defaultValue;
        }
        if (!data[name] || !Utils.isObject(data[name])) {
            return defaultValue;
        }
        return data[name][language] || defaultValue;
    };


    // Variables
    const isHidden   = item.hide ? eval(item.hide)(data, selects) : false;
    const isMedia    = item.type === "media";
    const isText     = item.type === "text";
    const isTextarea = item.type === "textarea";
    const isDouble   = item.type === "double";
    const isInput    = !isMedia && !isText && !isTextarea;
    const items      = item.fields ? item.fields : [];
    const value      = getValue();


    // Do the Render
    if (isHidden) {
        return <React.Fragment />;
    }
    return <>
        {isMedia && <MediaField
            {...item}
            clientID={clientID}
            value={value}
            error={errors[item.name] || ""}
            onChange={handleChange}
        />}

        {isText && <TextField
            {...item}
            value={value}
            error={errors[item.name] || ""}
            variables={variables}
            onChange={handleChange}
            withEmoji={item.withEmoji ?? true}
            withVariable={item.withVariable ?? true}
        />}

        {isTextarea && <TextareaField
            {...item}
            value={value}
            error={errors[item.name] || ""}
            variables={variables}
            channelLinks={channelLinks}
            onChange={handleChange}
        />}

        {isInput && <InputField
            {...item}
            className={`flow-input-${item.name}`}
            value={value}
            error={errors[item.name] || ""}
            errors={errors}
            options={Commons.getInputSelect(item, data, selects)}
            onChange={handleChange}
        >
            {items.map((field) => <InputItem
                {...field}
                key={field.key || field.name}
                subKey={field.key || field.name}
                value={isDouble ? getValue(field.name) : undefined}
                hide={(elem) => hideField(field, elem)}
                getType={(elem) => Commons.getInputType(field, elem, selects)}
                getOptions={(elem) => Commons.getInputSelect(field, elem, selects)}
                withBorder
            >
                {field.withChildren && <TextItem
                    variables={variables}
                />}
            </InputItem>)}
        </InputField>}
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
FlowInput.propTypes = {
    clientID : PropTypes.number.isRequired,
    item     : PropTypes.object.isRequired,
    data     : PropTypes.object.isRequired,
    errors   : PropTypes.object.isRequired,
    language : PropTypes.string.isRequired,
    onChange : PropTypes.func.isRequired,
};

export default FlowInput;
