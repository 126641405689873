import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import DialogMessage        from "Dashboard/Components/Dialog/DialogMessage";
import InputField           from "Dashboard/Components/Form/InputField";
import Icon                 from "Dashboard/Components/Common/Icon";



// Styles
const Actions = Styled(DialogMessage)`
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-height: 320px;
    overflow: auto;
`;

const Action = Styled.div.attrs(({ isSelected }) => ({ isSelected }))`
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 8px 16px;
    border: 1px solid var(--input-border-color);
    border-radius: var(--border-radius);
    cursor: pointer;

    .icon {
        font-size: 20px;
    }

    :hover {
        border-color: var(--input-border-hover);
    }

    ${(props) => props.isSelected && `
        background-color: var(--lighter-gray);
    `}
`;

const ActionIcon = Styled(Icon).attrs(({ color }) => ({ color }))`
    color: ${(props) => props.color || "var(--primary-color)"};
`;



/**
 * The Integration Create Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function IntegrationCreate(props) {
    const { open, integrationID, position, forTrigger, onClose, onSubmit } = props;

    const filterRef = React.useRef(null);

    const { actionTypes } = Store.useState("integrationEditor");
    const { createAction } = Store.useAction("integrationEditor");


    // The Initial Data
    const initialData = {
        integrationID : 0,
        actionType    : "",
        filter        : "",
    };


    // Initializes the Data
    React.useEffect(() => {
        if (open) {
            setElem({ ...initialData });
            filterRef.current.focus();
        }
    }, [ open ]);

    // Handles the Edit
    const handleEdit = async (data) => {
        await createAction(integrationID, data.actionType, data.name, position, forTrigger ? 1 : 0);
        onSubmit();
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("integration", initialData, handleEdit);



    // Generate the Actions List
    const list = React.useMemo(() => {
        const result = [];
        for (const actionType of Object.values(actionTypes)) {
            const { isAllowed, isTrigger, message } = actionType;
            if (isAllowed &&
                ((forTrigger && isTrigger) || (!forTrigger && !isTrigger)) &&
                NLS.get(message).toLocaleLowerCase().includes(data.filter.toLocaleLowerCase())
            ) {
                result.push(actionType);
            }
        }
        return result;
    }, [ JSON.stringify(actionTypes), forTrigger, data.filter ]);


    // Do the Render
    return <EditDialog
        open={open}
        icon="action"
        title={forTrigger ? "INTEGRATIONS_ACTIONS_CREATE_TRIGGER" : "INTEGRATIONS_ACTIONS_CREATE_ACTION"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isDisabled={!data.actionType}
        isNarrow
    >
        <DialogMessage message="INTEGRATIONS_ACTIONS_CREATE_TEXT" />

        <InputField
            passedRef={filterRef}
            name="filter"
            label="GENERAL_FILTER"
            value={data.filter}
            error={errors.filter}
            onChange={handleChange}
        />
        <Actions className="no-scrollbars">
            <>
                {list.map(({ key, icon, color, message, description }) => <Action
                    key={key}
                    isSelected={data.actionType === key}
                    onClick={() => handleChange("actionType", key)}
                >
                    <ActionIcon icon={icon} color={color} />
                    <div>
                        <b>{NLS.get(message)}: </b>
                        {NLS.get(description)}
                    </div>
                </Action>)}
            </>
        </Actions>
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
IntegrationCreate.propTypes = {
    open          : PropTypes.bool.isRequired,
    integrationID : PropTypes.number.isRequired,
    position      : PropTypes.number.isRequired,
    forTrigger    : PropTypes.bool.isRequired,
    onClose       : PropTypes.func.isRequired,
    onSubmit      : PropTypes.func.isRequired,
};

export default IntegrationCreate;
