import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";

// Dashboard
import Html                 from "Dashboard/Components/Common/Html";



// Styles
const Container = Styled.div`
    position: relative;
    margin: 0 16px;

    :hover .note-actions {
        display: block;
    }
`;

const Title = Styled.h4`
    margin: 0;
    padding: 2px 12px;
    font-weight: bold;
`;

const Content = Styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px 16px;
    border: 1px solid var(--border-color-light);
    border-radius: var(--border-radius);
`;

const Date = Styled.p`
    text-align: right;
    margin: -4px -10px -2px 0;
    font-size: 11px;
    color: rgba(0, 0, 0, 0.5);
`;



/**
 * The Chat Summary
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ChatSummary(props) {
    const { elem } = props;


    // Do the Render
    if (!elem.summary) {
        return <React.Fragment />;
    }
    return <Container>
        <Title>{NLS.get("CONVERSATIONS_SUMMARY")}</Title>
        <Content>
            <Html
                content={elem.summary}
                addBreaks
                formatText
            />
            <Date>{elem.summaryTimeFull}</Date>
        </Content>
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ChatSummary.propTypes = {
    elem : PropTypes.object.isRequired,
};

export default ChatSummary;
