import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// Components
import Zoom                 from "Components/Utils/Common/Zoom";

// Dashboard
import Button               from "Dashboard/Components/Form/Button";



// Styles
const Container = Styled.header`
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 8px;
    background-color: var(--lighter-gray);

    @media screen and (max-width: 450px) {
        .btn {
            padding: 4px 8px;
        }
        .btn-content {
            display: none;
        }
    }
`;

const Actions = Styled.section`
    display: flex;
    gap: 8px;
`;



/**
 * The Flow Actions
 * @returns {React.ReactElement}
 */
function FlowActions() {
    const { flowID, nodes } = Store.useState("flowEditor");
    const { hasChanges, zoom, selectedNode, selectedNodes, selectedEdge } = Store.useState("flowState");

    const { copyNodes, deleteEdge } = Store.useAction("flowEditor");
    const { setAction, setZoom, setEdgeEditing } = Store.useAction("flowState");


    // Toggles the Nodes Box
    const toggleNodes = () => {
        if (hasChanges) {
            setAction("WARNING");
        } else {
            setAction("CREATE");
        }
    };

    // Handles the Copy
    const handleCopy = () => {
        if (hasChanges) {
            setAction("WARNING");
        } else {
            copyNodes(flowID, JSON.stringify(selectedNodes));
        }
    };

    // Handles the Delete
    const handleDelete = () => {
        if (selectedNodes.length) {
            setAction("REMOVE");
        }
        if (selectedEdge) {
            deleteEdge(selectedEdge.id);
        }
    };

    // Handles the Zoom Minus
    const handleZoomMinus = (e) => {
        setZoom(flowID, zoom - 10);
        e.preventDefault();
    };

    // Handles the Zoom Plus
    const handleZoomPlus = (e) => {
        setZoom(flowID, zoom + 10);
        e.preventDefault();
    };

    // Handles the Zoom Reset
    const handleZoomReset = (e) => {
        setZoom(flowID, 100);
        e.preventDefault();
    };



    // Do the Render
    const isInitial = selectedNode ? Utils.getValue(nodes, "id", selectedNode, "isInitial") : false;
    const canEdit   = selectedNodes.length > 1 || (selectedNodes.length === 1 && !isInitial);
    const canDelete = Boolean(canEdit || selectedEdge);

    return <Container>
        <Actions>
            <Button
                variant="primary"
                message="FLOWS_NODES_SINGULAR"
                icon="add"
                onClick={toggleNodes}
                isSmall
            />
            <Button
                isHidden={!canEdit}
                variant="white"
                message="GENERAL_COPY"
                icon="copy"
                onClick={handleCopy}
                isSmall
            />
            <Button
                isHidden={!canEdit}
                variant="white"
                message="GENERAL_MOVE"
                icon="move"
                onClick={() => setAction("MOVE")}
                isSmall
            />
            <Button
                isHidden={!selectedEdge}
                variant="white"
                message="GENERAL_EDIT"
                icon="edit"
                onClick={() => setEdgeEditing(true)}
                isSmall
            />
            <Button
                isHidden={!canDelete}
                variant="white"
                message="GENERAL_DELETE"
                icon="delete"
                onClick={handleDelete}
                isSmall
            />
        </Actions>
        <Zoom
            zoom={zoom}
            onMinus={handleZoomMinus}
            onPlus={handleZoomPlus}
            onReset={handleZoomReset}
        />
    </Container>;
}

export default FlowActions;
