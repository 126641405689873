import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";

// Dashboard
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Personalize Main
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function PersonalizeMain(props) {
    const { onChange, onClear, hasClear } = props;

    const { data } = Store.useState("storeOption");


    // Do the Render
    return <>
        <InputField
            type="select"
            name="fontFamily"
            label="PERSONALIZE_FONT_FAMILY"
            options="SELECT_FONT_FAMILIES"
            value={data.fontFamily}
            onChange={onChange}
            onClear={onClear}
            hasClear={hasClear("fontFamily")}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
PersonalizeMain.propTypes = {
    onChange : PropTypes.func.isRequired,
    onClear  : PropTypes.func.isRequired,
    hasClear : PropTypes.func.isRequired,
};

export default PersonalizeMain;
