import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Team Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function TeamEdit(props) {
    const { open, elemID, clientID, onClose, onSubmit } = props;

    const { users, colors, statuses } = Store.useState("team");
    const { editTeam } = Store.useAction("team");


    // The Initial Data
    const initialData = {
        clientID : 0,
        teamID   : 0,
        name     : "",
        users    : [],
        color    : "#f2f2f2",
        status   : "Active",
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("team", initialData, editTeam, onSubmit);

    // Load the Data
    const { loading } = useDialog("team", open, elemID, { clientID }, setElem);



    // Do the Render
    return <EditDialog
        open={open}
        icon="team"
        title={elemID ? "TEAMS_EDIT_TITLE" : "TEAMS_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="multiple"
            name="users"
            label="TEAMS_USERS"
            options={users}
            value={data.users}
            error={errors.users}
            onChange={handleChange}
            isDisabled={!users.length}
        />
        <InputField
            type="color"
            name="color"
            label="GENERAL_COLOR"
            options={colors}
            value={data.color}
            error={errors.color}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="select"
            name="status"
            label="GENERAL_STATUS"
            options={statuses}
            value={data.status}
            error={errors.status}
            onChange={handleChange}
            isRequired
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
TeamEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
    clientID : PropTypes.number,
};

export default TeamEdit;
