import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import NLS                  from "Dashboard/Core/NLS";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Team User Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function TeamUser(props) {
    const { open, elemID : userID, elemName, clientID, onClose, onSubmit } = props;

    const { teams, values } = Store.useState("team");
    const { editUser } = Store.useAction("team");


    // The Initial Data
    const initialData = {
        teams : values,
    };

    // Handles the Edit
    const handleEdit = (data) => {
        return editUser({ ...data, userID, clientID });
    };


    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("team", initialData, handleEdit, onSubmit);

    // Load the Data
    const { loading } = useDialog("team", open, 0, { clientID, userID }, setElem);



    // Do the Render
    return <EditDialog
        open={open}
        icon="team"
        title="TEAMS_USER_TITLE"
        message={NLS.format("TEAMS_USER_TEXT", elemName)}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            type="multiple"
            name="teams"
            label="TEAMS_NAME"
            options={teams}
            value={data.teams}
            error={errors.teams}
            onChange={handleChange}
            isDisabled={!teams.length}
            isRequired
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
TeamUser.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
    elemName : PropTypes.string,
    clientID : PropTypes.number,
};

export default TeamUser;
