import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Province Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ProvinceEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { countries    } = Store.useState("province");
    const { editProvince } = Store.useAction("province");


    // The Initial Data
    const initialData = {
        provinceID : 0,
        countryID  : 0,
        name       : "",
        isoCode    : "",
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("province", initialData, editProvince, onSubmit);

    // Load the Data
    const { loading } = useDialog("province", open, elemID, null, setElem);



    // Does the Render
    return <EditDialog
        open={open}
        icon="province"
        title="PROVINCES_EDIT_TITLE"
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            type="select"
            name="countryID"
            label="COUNTRIES_SINGULAR"
            value={data.countryID}
            error={errors.countryID}
            onChange={handleChange}
            options={countries}
            isRequired
        />
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
            isRequired
        />
        <InputField
            name="isoCode"
            label="GENERAL_ISO_CODE"
            value={data.isoCode}
            error={errors.isoCode}
            onChange={handleChange}
            isRequired
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ProvinceEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default ProvinceEdit;
