import Action               from "Dashboard/Core/Action";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";



// The initial State
const initialState = {
    action        : Action.get(),
    hasChanges    : false,
    inPublish     : false,
    zoom          : 100,
    search        : "",
    detailsTab    : "editor",

    nodeCreating  : null,
    toSelectNodes : [],
    selectedNode  : 0,
    selectedNodes : [],
    isEditingEdge : false,
    selectedEdge  : null,
};



// The Actions
const actions = {
    /**
     * Sets the Flow action
     * @param {Function} dispatch
     * @param {String}   action
     * @returns {Void}
     */
    setAction(dispatch, action) {
        dispatch({ type : "FLOW_ACTION", action });
    },

    /**
     * Sets the Flow has changes
     * @param {Function} dispatch
     * @param {Boolean}  hasChanges
     * @returns {Void}
     */
    setHasChanges(dispatch, hasChanges) {
        dispatch({ type : "FLOW_HAS_CHANGES", hasChanges });
    },

    /**
     * Sets the Flow in Publish mode
     * @param {Function} dispatch
     * @param {Boolean}  inPublish
     * @returns {Void}
     */
    setInPublish(dispatch, inPublish) {
        dispatch({ type : "FLOW_IN_PUBLISH", inPublish });
    },

    /**
     * Inits the Flow zoom
     * @param {Function} dispatch
     * @param {Number}   flowID
     * @returns {Void}
     */
    initZoom(dispatch, flowID) {
        const zoom = Utils.restoreItem(`conversana-flow-${flowID}`);
        if (zoom) {
            dispatch({ type : "FLOW_ZOOM", zoom : Number(zoom) });
        }
    },

    /**
     * Sets the Flow zoom
     * @param {Function} dispatch
     * @param {Number}   flowID
     * @param {Number}   newZoom
     * @returns {Void}
     */
    setZoom(dispatch, flowID, newZoom) {
        const zoom = Utils.clamp(Number(newZoom), 50, 150);
        Utils.storeItem(`conversana-flow-${flowID}`, String(zoom));
        dispatch({ type : "FLOW_ZOOM", zoom });
    },

    /**
     * Sets the Flow Search
     * @param {Function} dispatch
     * @param {String}   search
     * @returns {Void}
     */
    setSearch(dispatch, search) {
        dispatch({ type : "FLOW_SEARCH", search });
    },

    /**
     * Sets the Flow Details Tab
     * @param {Function} dispatch
     * @param {String}   detailsTab
     * @returns {Void}
     */
    setDetailsTab(dispatch, detailsTab) {
        dispatch({ type : "FLOW_DETAILS_TAB", detailsTab });
    },


    /**
     * Sets the Flow Node creating
     * @param {Function} dispatch
     * @param {Object}   node
     * @returns {Void}
     */
    setNodeCreating(dispatch, node) {
        dispatch({ type : "FLOW_NODE_CREATING", node });
    },

    /**
     * Sets the to select Flow Nodes
     * @param {Function} dispatch
     * @param {Number[]} toSelectNodes
     * @returns {Void}
     */
    setToSelectNodes(dispatch, toSelectNodes) {
        dispatch({ type : "FLOW_SELECT_NODES", toSelectNodes });
    },

    /**
     * Sets the selected Flow Node
     * @param {Function} dispatch
     * @param {Number}   selectedNode
     * @returns {Void}
     */
    setSelectedNode(dispatch, selectedNode) {
        dispatch({ type : "FLOW_SELECTED_NODE", selectedNode });
    },

    /**
     * Sets the selected Flow Nodes
     * @param {Function} dispatch
     * @param {Number[]} selectedNodes
     * @returns {Void}
     */
    setSelectedNodes(dispatch, selectedNodes) {
        dispatch({ type : "FLOW_SELECTED_NODES", selectedNodes });
    },


    /**
     * Sets the Flow Edge editing
     * @param {Function} dispatch
     * @param {Boolean}  isEditingEdge
     * @returns {Void}
     */
    setEdgeEditing(dispatch, isEditingEdge) {
        dispatch({ type : "FLOW_EDGE_EDITING", isEditingEdge });
    },

    /**
     * Sets the selected Flow Edge
     * @param {Function} dispatch
     * @param {Object}   selectedEdge
     * @returns {Void}
     */
    setSelectedEdge(dispatch, selectedEdge) {
        dispatch({ type : "FLOW_SELECTED_EDGE", selectedEdge });
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
    case "FLOW_ACTION":
        return {
            ...state,
            action        : Action.get(action.action),
        };

    case "FLOW_HAS_CHANGES":
        return {
            ...state,
            hasChanges    : action.hasChanges,
        };

    case "FLOW_IN_PUBLISH":
        return {
            ...state,
            inPublish     : action.inPublish,
            action        : action.inPublish ? Action.get("ERROR") : Action.get(),
        };

    case "FLOW_ZOOM":
        return {
            ...state,
            zoom          : action.zoom,
        };

    case "FLOW_SEARCH":
        return {
            ...state,
            search        : action.search,
        };

    case "FLOW_DETAILS_TAB":
        return {
            ...state,
            detailsTab    : action.detailsTab,
        };


    case "FLOW_NODE_CREATING":
        return {
            ...state,
            nodeCreating  : action.node,
        };

    case "FLOW_SELECT_NODES":
        return {
            ...state,
            toSelectNodes : action.toSelectNodes || [],
        };

    case "FLOW_SELECTED_NODE":
        return {
            ...state,
            selectedNode  : action.selectedNode || 0,
            selectedNodes : !action.selectedNode ? [] : [ action.selectedNode ],
            selectedEdge  : null,
        };

    case "FLOW_SELECTED_NODES":
        return {
            ...state,
            selectedNode  : action.selectedNodes.length === 1 ? action.selectedNodes[0] : 0,
            selectedNodes : action.selectedNodes || [],
            selectedEdge  : null,
        };


    case "FLOW_EDGE_EDITING":
        return {
            ...state,
            isEditingEdge : action.isEditingEdge,
        };

    case "FLOW_SELECTED_EDGE":
        return {
            ...state,
            selectedEdge  : action.selectedEdge,
        };

    case "FLOW_STATE_RESET":
        return {
            ...state,
            selectedNode  : 0,
            selectedNodes : [],
            selectedEdge  : null,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
