import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";

// Dashboard
import DetailList           from "Dashboard/Components/Details/DetailList";
import DetailItem           from "Dashboard/Components/Details/DetailItem";



/**
 * The Quotation Detail Item
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function QuotationItem(props) {
    const { elem, collapsible, action, onAction, canEdit } = props;
    const { hasBookingEngine } = Store.useState("permission");


    // Do the Render
    if (!hasBookingEngine) {
        return <React.Fragment />;
    }

    return <DetailList
        isHidden={!elem.id}
        message="QUOTATIONS_SINGULAR"
        collapsible={collapsible}
        action={action}
        onAction={onAction}
        canEdit={canEdit}
    >
        <DetailItem
            icon="room"
            message={elem.name}
            tooltip="HOSPITALITY_ROOM"
        />
        <DetailItem
            icon="time"
            message={elem.arrivalDate}
            tooltip="QUOTATIONS_ARRIVAL_DATE"
            withTip
        />
        <DetailItem
            icon="time"
            message={elem.departureDate}
            tooltip="QUOTATIONS_DEPARTURE_DATE"
            withTip
        />
        <DetailItem
            icon="user"
            message={elem.guestsAmount}
            tooltip="QUOTATIONS_GUEST_AMOUNT"
            withTip
        />
        <DetailItem
            icon="money"
            message={elem.offerPriceText}
            tooltip="QUOTATIONS_OFFER_PRICE"
            withTip
        />
        <DetailItem
            icon="money"
            message={elem.regularPriceText}
            tooltip="QUOTATIONS_REGULAR_PRICE"
            withTip
        />
        <DetailItem
            icon="time"
            message={elem.createdDate}
            tooltip="GENERAL_CREATED_TIME"
        />
    </DetailList>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
QuotationItem.propTypes = {
    elem        : PropTypes.object.isRequired,
    collapsible : PropTypes.string,
    action      : PropTypes.string,
    canEdit     : PropTypes.bool,
    onAction    : PropTypes.func,
};

export default QuotationItem;
