import React                from "react";
import PropTypes            from "prop-types";
import Commons              from "Utils/Commons";

// Dashboard
import InputField           from "Dashboard/Components/Form/InputField";
import InputItem            from "Dashboard/Components/Form/InputItem";



/**
 * The Fields Inputs
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function FieldsInputs(props) {
    const {
        fields, data, errors, tongueID,
        onError, onChange, withRequired, skipFiles,
    } = props;


    // Variables
    const visibleFields = fields.filter(({ isHidden, inputType }) => {
        return !isHidden && ((skipFiles && inputType !== "file") || !skipFiles);
    });
    const amount = visibleFields.length;


    // Handles the Clear
    const onClear = (name) => {
        onChange(name, "");
    };

    // Returns the value of the Custom Field
    const getValue = (field) => {
        if (field.inputType === "file") {
            return data[field.customFieldID]?.name ?? (data[field.customFieldID] ?? "");
        }
        const key = Commons.getCustomFieldKey(field, tongueID);
        return data[key];
    };



    // Do the Render
    return <>
        {visibleFields.map((elem, index) => <InputField
            className={(amount % 2 === 1 && index === amount - 1) ? "columns-double" : ""}
            columns={2}
            key={elem.customFieldID}
            type={elem.inputType}
            name={Commons.getCustomFieldKey(elem, tongueID)}
            label={elem.name}
            options={elem.select}
            value={getValue(elem)}
            error={errors[elem.customFieldID]}
            onChange={onChange}
            onClear={onClear}
            onError={onError}
            isRequired={withRequired && elem.isRequired}
            withBorder={elem.inputType !== "fields"}
            maxSize={3}
        >
            <InputItem name="key" />
            <InputItem name="value" />
        </InputField>)}
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
FieldsInputs.propTypes = {
    fields       : PropTypes.array.isRequired,
    data         : PropTypes.object.isRequired,
    tongueID     : PropTypes.number,
    errors       : PropTypes.object,
    onChange     : PropTypes.func.isRequired,
    onError      : PropTypes.func,
    withRequired : PropTypes.bool,
    skipFiles    : PropTypes.bool,
};

/**
 * The Default Properties
 * @typedef {Object} defaultProps
 */
FieldsInputs.defaultProps = {
    errors       : {},
    withRequired : false,
    skipFiles    : false,
};

export default FieldsInputs;
