import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";

// Components
import ListHeader           from "./ListHeader";
import ListSearch           from "./ListSearch";
import ListContent          from "./ListContent";
import ListProviders        from "./ListProviders";



// Styles
const Container = Styled.section`
    display: flex;
    flex-direction: column;
    height: var(--page-height);
`;



/**
 * The List Container
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ListContainer(props) {
    const { fetch } = props;


    // Do the Render
    return <Container>
        <ListHeader />
        <ListSearch />
        <ListContent fetch={fetch} />
        <ListProviders />
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ListContainer.propTypes = {
    fetch : PropTypes.func.isRequired,
};

export default ListContainer;
