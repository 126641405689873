import React                from "react";
import PropTypes            from "prop-types";
import Utils                from "Dashboard/Utils/Utils";

// Dashboard
import Menu                 from "Dashboard/Components/Menu/Menu";
import MenuItem             from "Dashboard/Components/Menu/MenuItem";



/**
 * The Format Menu
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function FormatMenu(props) {
    const {
        open, targetRef, inputRef, message,
        direction, onText, onClose,
    } = props;

    // Handles the Click
    const handleFormat = (character) => {
        const text = Utils.formatText(inputRef, message, character);
        onText(text);
        inputRef.current.focus();
    };


    // Do the Render
    return <Menu
        open={open}
        targetRef={targetRef}
        direction={direction}
        gap={4}
        onClose={onClose}
    >
        <MenuItem
            icon="bold"
            message="GENERAL_FORMAT_BOLD"
            onClick={() => handleFormat("**")}
            isSmall
        />
        <MenuItem
            icon="italic"
            message="GENERAL_FORMAT_ITALIC"
            onClick={() => handleFormat("__")}
            isSmall
        />
        <MenuItem
            icon="strikethrough"
            message="GENERAL_FORMAT_STRIKETHROUGH"
            onClick={() => handleFormat("~~")}
            isSmall
        />
        <MenuItem
            icon="code"
            message="GENERAL_FORMAT_CODE"
            onClick={() => handleFormat("```")}
            isSmall
        />
    </Menu>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
FormatMenu.propTypes = {
    open      : PropTypes.bool.isRequired,
    targetRef : PropTypes.any,
    inputRef  : PropTypes.any,
    message   : PropTypes.string,
    direction : PropTypes.string.isRequired,
    onText    : PropTypes.func.isRequired,
    onClose   : PropTypes.func.isRequired,
};

export default FormatMenu;
