import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// Components
import IntegrationActions   from "../Canvas/IntegrationActions";
import IntegrationCanvas    from "../Canvas/IntegrationCanvas";
import IntegrationDetails   from "./IntegrationDetails";
import IntegrationDialogs   from "./IntegrationDialogs";

// Dashboard
import CircularLoader       from "Dashboard/Components/Loader/CircularLoader";



// Styles
const Container = Styled.section`
    display: flex;
    gap: var(--main-gap);
`;

const Content = Styled.main`
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    border: var(--border-width) solid var(--border-color-light);
    border-radius: var(--border-radius);
    overflow: hidden;
`;

const Canvas = Styled.section.attrs(({ hasTabs }) => ({ hasTabs }))`
    height: ${(props) => props.hasTabs ? "var(--flow-height-tabs)" : "var(--flow-height)"};
    overflow: auto;
    background-image: radial-gradient(var(--grid-color) 1px, transparent 0);
    background-size: var(--grid-size) var(--grid-size);
`;



/**
 * The Integration Editor
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function IntegrationEditor(props) {
    const { integrationID, hasTabs } = props;

    const { readying } = Store.useState("integrationEditor");
    const { fetchEditor, fetchUpdate } = Store.useAction("integrationEditor");
    const { initZoom, setHasChanges, setSelectedAction } = Store.useAction("integrationState");


    // The References
    const timerRef = React.useRef(0);

    // The Current State
    const [ update, setUpdate ] = React.useState(0);


    // Remove the Auto-update
    React.useEffect(() => {
        return () => {
            Utils.clearTimeout(timerRef);
        };
    }, []);

    // Load the Data
    React.useEffect(() => {
        if (integrationID) {
            fetchEditor(integrationID);
            initZoom(integrationID);
            setHasChanges(false);
            setAutoUpdate();
        }
    }, [ integrationID ]);

    // Handle the Update
    React.useEffect(() => {
        fetchUpdate(integrationID);
        setAutoUpdate();
    }, [ update ]);


    // Sets an auto-update
    const setAutoUpdate = () => {
        Utils.setTimeout(timerRef, () => {
            setUpdate(update + 1);
        }, 5 * 1000);
    };


    // Do the Render
    if (readying) {
        return <CircularLoader />;
    }
    return <Container>
        <Content>
            <IntegrationActions />
            <Canvas
                hasTabs={hasTabs}
                onClick={() => setSelectedAction(0)}
            >
                <IntegrationCanvas />
            </Canvas>
        </Content>

        <IntegrationDetails hasExternalTabs={hasTabs} />
        <IntegrationDialogs />
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
IntegrationEditor.propTypes = {
    integrationID : PropTypes.number,
    hasTabs       : PropTypes.bool.isRequired,
};

export default IntegrationEditor;
