import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Action               from "Dashboard/Core/Action";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";
import Commons              from "Utils/Commons";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Message Template List
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function MsgTemplateList(props) {
    const { type } = props;
    const { fetch, load } = useList("msgTemplate", type);

    const navigate = Commons.useGoto();

    const data = Store.useState("msgTemplate");
    const { loading, canEdit, list, total, sort } = data;

    const { hasHotel } = Store.useState("permission");
    const { deleteMsgTemplate } = Store.useAction("msgTemplate");

    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Action
    const handleAction = (action, elemID) => {
        if (action.isCreate) {
            navigate("SETTINGS", "MSG_TEMPLATES", "CREATE");
        } else if (action.isCopy) {
            navigate("SETTINGS", "MSG_TEMPLATES", "CREATE", elemID);
        } else {
            startAction(action, elemID);
        }
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        endAction();
        const result = await deleteMsgTemplate(elemID);
        if (result.success) {
            fetch();
        }
    };


    // Do the Render
    const elemName = Utils.getValue(list, "msgTemplateID", elemID, "name");

    return <Main>
        <Header message="MSG_TEMPLATES_NAME" icon="template">
            <ActionList data={data} onAction={handleAction} />
        </Header>
        <Content>
            <Table
                fetch={load}
                sort={sort}
                none="MSG_TEMPLATES_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="name"       message="GENERAL_NAME"     isTitle bigMobile />
                    <TableHeader field="teamCount"  message="TEAMS_NAME"       maxWidth="100" align="center" />
                    <TableHeader field="hotelCount" message="HOTELS_NAME"      maxWidth="100" align="center" isHidden={!hasHotel} />
                    <TableHeader field="status"     message="GENERAL_STATUS"   maxWidth="70"  />
                    <TableHeader field="position"   message="GENERAL_POSITION" maxWidth="80"  align="center" hideMobile />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.msgTemplateID} elemID={elem.msgTemplateID}>
                        <TableCell message={elem.name}       />
                        <TableCell message={elem.teamsText}  />
                        <TableCell message={elem.hotelsText} />
                        <TableCell message={elem.statusName} textColor={elem.statusColor} />
                        <TableCell message={elem.position}   />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={handleAction} canEdit={canEdit}>
                    <TableAction action="EDIT"   message="MSG_TEMPLATES_EDIT_TITLE"   navigate />
                    <TableAction action="COPY"   message="MSG_TEMPLATES_COPY_TITLE"   />
                    <TableAction action="DELETE" message="MSG_TEMPLATES_DELETE_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <DeleteDialog
            open={action.isDelete}
            title="MSG_TEMPLATES_DELETE_TITLE"
            message="MSG_TEMPLATES_DELETE_TEXT"
            content={elemName}
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </Main>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
MsgTemplateList.propTypes = {
    type : PropTypes.string.isRequired,
};

export default MsgTemplateList;
