import React                from "react";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import Action               from "Dashboard/Core/Action";
import Utils                from "Dashboard/Utils/Utils";
import Conversations        from "Utils/Conversations";
import Commons              from "Utils/Commons";

// Components
import ConversationCreate   from "./ConversationCreate";

// Dashboard
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionItem           from "Dashboard/Components/Header/ActionItem";



/**
 * The Conversation Header
 * @returns {React.ReactElement}
 */
function ConversationHeader() {
    const { conversationTab, conversationID } = Navigate.useParams();

    const clientID = Commons.useClientID();
    const parent   = Navigate.useParent();
    const navigate = Conversations.useGoto();

    const data = Store.useState("conversation");
    const { canEdit, contact, providers } = data;

    // The Current State
    const [ action, setAction ] = Action.useAction();


    // Handles the Conversation Create
    const handleCreate = (response) => {
        setAction();
        if (response.number !== conversationID) {
            navigate(conversationTab, response.number);
        }
    };



    // Do the Render
    const isMobile = Utils.isMobile();
    const goBack   = isMobile && conversationID;

    return <>
        <Header
            message="CONVERSATIONS_NAME"
            icon="conversation"
            href={goBack ? parent : null}
        >
            <ActionList onAction={setAction}>
                <ActionItem
                    isHidden={!canEdit || !providers.length}
                    action="CREATE"
                />
            </ActionList>
        </Header>

        <ConversationCreate
            open={action.isCreate}
            clientID={clientID}
            contact={contact.is && !contact.isBlocked ? contact : {}}
            onSubmit={handleCreate}
            onClose={setAction}
        />
    </>;
}

export default ConversationHeader;
