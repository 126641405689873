import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";
import Conversations        from "Utils/Conversations";

// Dashboard
import Icon                 from "Dashboard/Components/Common/Icon";



// Styles
const Values = Styled.div`
    flex-grow: 2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    flex-direction: column;
    justify-content: space-around;
`;

const Value = Styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12px;
    gap: 16px;
    border: 1px solid var(--border-color-light);
    border-radius: var(--border-radius);
    transition: all 0.2s;
    cursor: pointer;

    :hover {
        background-color: var(--lightest-gray);
    }
`;

const Content = Styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

const Title = Styled.h3`
    margin: 0;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
`;

const Iconography = Styled(Icon)`
    color: var(--primary-color);
    font-size: 32px;
`;

const Amount = Styled.div`
    font-size: 28px;
`;

const Unread = Styled.div`
    position: absolute;
    top: 4px;
    right: 4px;
    padding: 4px 8px;
    font-size: 16px;
    color: white;
    background-color: rgb(255, 0, 51);
    border-radius: var(--border-radius);
`;



/**
 * The Dashboard Values
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function DashboardValues(props) {
    const navigate = Conversations.useGoto();

    const { labels, dataset, extras, urls } = props;

    // The Icons
    const icons = {
        "DASHBOARD_TOTAL_QUEUE_NOW"    : "queue",
        "DASHBOARD_TOTAL_PROGRESS_NOW" : "progress",
        "DASHBOARD_TOTAL_FOLLOWUP_NOW" : "followup",
        "DASHBOARD_TOTAL_RESOLVED_NOW" : "resolved",
    };


    // Do the Render
    return <Values>
        {labels.map((label, index) => {
            const unread = extras[index];
            return <Value
                key={label}
                onClick={() => navigate(urls[index])}
            >
                <Title>{NLS.get(label)}</Title>
                <Content>
                    <Iconography icon={icons[label]} />
                    <Amount>{dataset[index] || 0}</Amount>
                    {!!unread && <Unread>{unread}</Unread>}
                </Content>
            </Value>;
        })}
    </Values>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
DashboardValues.propTypes = {
    labels  : PropTypes.array.isRequired,
    dataset : PropTypes.array.isRequired,
    extras  : PropTypes.array.isRequired,
    urls    : PropTypes.array.isRequired,
};

export default DashboardValues;
