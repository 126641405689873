import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// Components
import IntegrationInput     from "./IntegrationInput";



/**
 * The Integration Inputs
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function IntegrationInputs(props) {
    const { data, errors, language, onChange, onMedia } = props;

    const { actions } = Store.useState("integrationEditor");
    const { selectedAction } = Store.useState("integrationState");


    // Do the Render
    const action    = selectedAction ? Utils.getValue(actions, "id", selectedAction) : {};
    const hasInputs = Boolean(selectedAction && action.inputs.length);

    return <>
        {hasInputs && action.inputs.map((item) => <IntegrationInput
            key={`${selectedAction}-${item.key || item.name}`}
            action={action}
            item={item}
            data={data}
            errors={errors}
            language={language}
            onChange={onChange}
            onMedia={onMedia}
        />)}
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
IntegrationInputs.propTypes = {
    data     : PropTypes.object.isRequired,
    errors   : PropTypes.object.isRequired,
    language : PropTypes.string.isRequired,
    onChange : PropTypes.func.isRequired,
    onMedia  : PropTypes.func.isRequired,
};

export default IntegrationInputs;
