import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";

// Dashboard
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionItem           from "Dashboard/Components/Header/ActionItem";
import ActionOption         from "Dashboard/Components/Header/ActionOption";
import MenuLine             from "Dashboard/Components/Menu/MenuLine";



/**
 * The User Header
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function UserHeader(props) {
    const { startAction, forPartner, isExporting } = props;

    const { isAnyAdmin } = Store.useState("auth");
    const {
        canCreate, canEdit, canExport, hasFilters, elem, teams,
    } = Store.useState("user");


    // Handles the Action
    const handleAction = (action) => {
        startAction(action, elem.id);
    };


    // Do the Render
    const hasEdit    = canEdit && !!elem.id;
    const canResend  = hasEdit && elem.isPending;
    const canTeam    = hasEdit && !forPartner && teams.length > 0;
    const canLoginAs = isAnyAdmin && hasEdit && !elem.isPending;

    return <Header message="USERS_NAME" icon="user">
        <ActionList onAction={startAction}>
            <ActionItem
                action="FILTER"
                withMark={hasFilters}
            />
            <ActionItem
                icon="more"
                message="GENERAL_ACTIONS"
                onAction={startAction}
                isLoading={isExporting}
            >
                <ActionOption
                    isHidden={!canCreate}
                    action="CREATE"
                    message="USERS_CREATE_TITLE"
                />
                <ActionOption
                    isHidden={!canEdit}
                    action="INVITE"
                    message="USERS_INVITE_TITLE"
                />
                <ActionOption
                    isHidden={!hasEdit}
                    action="EDIT"
                    message="USERS_EDIT_TITLE"
                    onAction={handleAction}
                />
                <ActionOption
                    isHidden={!canResend}
                    action="RESEND"
                    message="USERS_RESEND_TITLE"
                    onAction={handleAction}
                />
                <ActionOption
                    isHidden={!canTeam}
                    action="TEAM"
                    message="TEAMS_USER_TITLE"
                    onAction={handleAction}
                />
                <ActionOption
                    isHidden={!canLoginAs}
                    action="LOGIN"
                    message="GENERAL_LOGIN_AS"
                    onAction={handleAction}
                />
                <ActionOption
                    isHidden={!hasEdit}
                    action="DELETE"
                    message="USERS_DELETE_TITLE"
                    onAction={handleAction}
                />

                <MenuLine />
                <ActionOption
                    isHidden={!canExport}
                    action="EXPORT"
                    message="USERS_EXPORT_TITLE"
                />
            </ActionItem>
        </ActionList>
    </Header>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
UserHeader.propTypes = {
    startAction : PropTypes.func.isRequired,
    forPartner  : PropTypes.bool.isRequired,
    isExporting : PropTypes.bool.isRequired,
};

export default UserHeader;
