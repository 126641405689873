import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Plans                from "Utils/Plans";
import { Plan }             from "Utils/API";



// The initial State
const initialState = {
    loading    : true,
    error      : false,
    edition    : 0,
    canCreate  : false,
    canEdit    : false,
    canImport  : false,
    canExport  : false,
    list       : [],
    total      : 0,
    elem       : {},
    currencies : [],
    sort       : {
        orderBy  : "position",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "PLAN_LOADING" });
    },

    /**
     * Fetches the Plan List
     * @param {Function} dispatch
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, params = {}) {
        const data = await Plan.getAll(params);
        data.sort = params;
        dispatch({ type : "PLAN_LIST", data });
    },

    /**
     * Fetches a single Plan
     * @param {Function} dispatch
     * @param {Number}   planID
     * @returns {Promise}
     */
    async fetchElem(dispatch, planID) {
        const data = await Plan.getOne({ planID });
        dispatch({ type : "PLAN_ELEM", data });
    },

    /**
     * Fetches the Plan Edit data
     * @param {Function} dispatch
     * @returns {Promise}
     */
    async fetchEditData(dispatch) {
        const data = await Plan.getEditData();
        dispatch({ type : "PLAN_EDIT", data });
    },

    /**
     * Edits/Creates a Plan
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editPlan(dispatch, data) {
        return Plan.edit(data);
    },

    /**
     * Deletes a Plan
     * @param {Function} dispatch
     * @param {Number}   planID
     * @returns {Promise}
     */
    deletePlan(dispatch, planID) {
        return Plan.delete({ planID });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.planTypeName = NLS.get("SELECT_PLAN_TYPES", elem.planType);
    elem.pricesText   = {};

    for (const price of Object.values(elem.prices || {})) {
        elem.pricesText[price.currencyID] = Plans.getBasePrice(elem.planType, price, true);
    }
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "PLAN_LIST", "PLAN_ELEM", "PLAN_EDIT")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "PLAN_LOADING":
        return {
            ...state,
            loading    : true,
        };

    case "PLAN_LIST":
        return {
            ...state,
            loading    : false,
            error      : false,
            canCreate  : action.data.canCreate,
            canEdit    : action.data.canEdit,
            canImport  : action.data.canImport,
            canExport  : action.data.canExport,
            list       : Utils.parseList(action.data.list, parseElem),
            total      : action.data.total,
            sort       : action.data.sort,
            currencies : action.data.currencies,
        };

    case "PLAN_ELEM":
        return {
            ...state,
            error      : false,
            edition    : state.edition + 1,
            elem       : parseElem(action.data.elem),
            currencies : action.data.currencies,
        };

    case "PLAN_EDIT":
        return {
            ...state,
            error      : false,
            edition    : state.edition + 1,
            currencies : action.data.currencies,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
