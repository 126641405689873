import Action               from "Dashboard/Core/Action";
import Store                from "Dashboard/Core/Store";



// The Initial Data
const initialData = {
    name               : "",
    description        : "",
    startTime          : "",
    endTime            : "",
    emailActive        : 0,
    emailPriority      : 0,
    emailChannelID     : 0,
    smsActive          : 0,
    smsPriority        : 0,
    smsChannelID       : 0,
    whatsAppActive     : 0,
    whatsAppPriority   : 0,
    whatsAppChannelID  : 0,
    whatsAppTemplateID : 0,
    options            : {},
};
const initialErrors = {
    form               : "",
    name               : "",
    description        : "",
    startTime          : "",
    endTime            : "",
    emailActive        : "",
    emailPriority      : "",
    emailChannelID     : "",
    smsActive          : "",
    smsPriority        : "",
    smsChannelID       : "",
    whatsAppActive     : "",
    whatsAppPriority   : "",
    whatsAppChannelID  : "",
    whatsAppTemplateID : "",
};

// The initial State
const initialState = {
    step       : "",
    channel    : "",
    action     : Action.get(),
    inActivate : false,
    data       : { ...initialData },
    errors     : { ...initialErrors },
};



// The Actions
const actions = {
    /**
     * Sets the Campaign initial data
     * @param {Function} dispatch
     * @param {String}   name
     * @param {Object}   optionsDraft
     * @param {Boolean=} isDraft
     * @returns {Void}
     */
    setInitialData(dispatch, name, optionsDraft, isDraft) {
        dispatch({ type : "CAMPAIGN_INITIAL_DATA", name, optionsDraft, isDraft });
    },

    /**
     * Sets the Campaign action
     * @param {Function} dispatch
     * @param {String}   action
     * @returns {Void}
     */
    setAction(dispatch, action) {
        dispatch({ type : "CAMPAIGN_ACTION", action });
    },

    /**
     * Sets the Campaign step
     * @param {Function} dispatch
     * @param {Number}   step
     * @returns {Void}
     */
    setStep(dispatch, step) {
        dispatch({ type : "CAMPAIGN_STEP", step });
    },

    /**
     * Sets the Campaign channel
     * @param {Function} dispatch
     * @param {Number}   channel
     * @returns {Void}
     */
    setChannel(dispatch, channel) {
        dispatch({ type : "CAMPAIGN_CHANNEL", channel });
    },

    /**
     * Sets the Campaign in Activate mode
     * @param {Function} dispatch
     * @param {Boolean}  inActivate
     * @returns {Void}
     */
    setInActivate(dispatch, inActivate) {
        dispatch({ type : "CAMPAIGN_IN_ACTIVATE", inActivate });
    },

    /**
     * Sets the Campaign data
     * @param {Function} dispatch
     * @param {String}   name
     * @param {*}        value
     * @param {Number=}  tongueID
     * @returns {Void}
     */
    setData(dispatch, name, value, tongueID = 0) {
        dispatch({ type : "CAMPAIGN_DATA", name, value, tongueID });
    },

    /**
     * Sets the Campaign errors
     * @param {Function} dispatch
     * @param {Object}   errors
     * @returns {Void}
     */
    setErrors(dispatch, errors) {
        dispatch({ type : "CAMPAIGN_SET_ERRORS", errors });
    },

    /**
     * Removes the Campaign errors
     * @param {Function} dispatch
     * @returns {Void}
     */
    removeErrors(dispatch) {
        dispatch({ type : "CAMPAIGN_REMOVE_ERRORS" });
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
    case "CAMPAIGN_INITIAL_DATA":
        return {
            ...state,
            step        : action.isDraft ? "main" : "",
            channel     : "",
            data        : {
                ...initialData,
                ...action.optionsDraft[0],
                name    : action.name,
                options : action.optionsDraft,
            },
            errors      : { ...initialErrors },
        };

    case "CAMPAIGN_ACTION":
        return {
            ...state,
            action      : Action.get(action.action),
        };

    case "CAMPAIGN_STEP":
        return {
            ...state,
            step        : action.step,
        };

    case "CAMPAIGN_CHANNEL":
        return {
            ...state,
            channel     : action.channel,
        };

    case "CAMPAIGN_IN_ACTIVATE":
        return {
            ...state,
            inActivate  : action.inActivate,
            action      : action.inActivate ? Action.get("ERROR") : Action.get(),
        };

    case "CAMPAIGN_DATA": {
        let newData;
        if (action.tongueID) {
            const elem    = { ...state.data.options[action.tongueID], [action.name] : action.value };
            const options = { ...state.data.options, [action.tongueID] : elem };
            newData = { ...state.data, options };
        } else {
            newData = { ...state.data, [action.name] : action.value };
        }
        return {
            ...state,
            data        : newData,
        };
    }

    case "CAMPAIGN_SET_ERRORS":
        return {
            ...state,
            errors      : { ...action.errors },
        };

    case "CAMPAIGN_REMOVE_ERRORS":
        return {
            ...state,
            errors      : { ...initialErrors },
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
