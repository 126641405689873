import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";



// Styles
const Button = Styled.div`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    min-height: 38px;
    margin: 0 auto;
    color: rgb(60, 64, 67);
    border: 1px solid var(--border-color-medium);
    border-radius: 4px;
    transition: all 0.218s;
    cursor: pointer;

    :hover {
        border-color: #d2e3fc;
        background: rgba(66, 133, 244, 0.04);
    }
`;

const Image = Styled.img`
    display: block;
    width: 21px;
`;



/**
 * The Login Button
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function LoginButton(props) {
    const { message, logo, onClick } = props;


    // Do the Render
    return <Button onClick={onClick}>
        <Image
            src={`${process.env.PUBLIC_URL}/logos/${logo}.svg`}
            alt={NLS.get(message)}
        />
        {NLS.get(message)}
    </Button>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
LoginButton.propTypes = {
    message : PropTypes.string.isRequired,
    logo    : PropTypes.string.isRequired,
    onClick : PropTypes.func.isRequired,
};

export default LoginButton;
