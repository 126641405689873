import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Action               from "Dashboard/Core/Action";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";
import Commons              from "Utils/Commons";

// Components
import PhoneNumberAdd       from "./PhoneNumberAdd";
import PhoneNumberImport    from "./PhoneNumberImport";
import PhoneNumberEdit      from "./PhoneNumberEdit";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Phone Number List
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function PhoneNumberList(props) {
    const { type } = props;
    const clientID = Commons.useClientID();

    const { fetch, load } = useList("phoneNumber", type);

    const data = Store.useState("phoneNumber");
    const { loading, canEdit, canDelete, list, total, sort } = data;

    const { deletePhoneNumber } = Store.useAction("phoneNumber");

    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Edit Submit
    const handleEdit = () => {
        endAction();
        fetch();
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        endAction();
        const result = await deletePhoneNumber(elemID);
        if (result.success) {
            fetch();
        }
    };


    // Do the Render
    const elem     = Utils.getValue(list, "phoneNumberID", elemID);
    const elemName = elem.name || elem.friendlyName;

    return <Main>
        <Header message="PHONE_NUMBERS_NAME" icon="phone-number">
            <ActionList data={data} onAction={startAction} useAdd />
        </Header>
        <Content>
            <Table
                fetch={load}
                sort={sort}
                none="PHONE_NUMBERS_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="phoneNumber"  message="PHONE_NUMBERS_SINGULAR"         isTitle />
                    <TableHeader field="friendlyName" message="PHONE_NUMBERS_FRIENDLY_NAME"    />
                    <TableHeader field="name"         message="PHONE_NUMBERS_DESCRIPTIVE_NAME" />
                    <TableHeader field="location"     message="PHONE_NUMBERS_LOCATION"         />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.phoneNumberID} elemID={elem.phoneNumberID}>
                        <TableCell message={elem.phoneNumber}  />
                        <TableCell message={elem.friendlyName} />
                        <TableCell message={elem.name}         />
                        <TableCell message={elem.location}     />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={startAction} canEdit={canEdit}>
                    <TableAction action="EDIT"   message="PHONE_NUMBERS_EDIT_TITLE"   />
                    <TableAction action="DELETE" message="PHONE_NUMBERS_DELETE_TITLE" isHidden={!canDelete} />
                </TableActionList>
            </Table>
        </Content>

        <PhoneNumberAdd
            open={action.isCreate}
            clientID={clientID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <PhoneNumberImport
            open={action.isImport}
            clientID={clientID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <PhoneNumberEdit
            open={action.isVE}
            elemID={elemID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="PHONE_NUMBERS_DELETE_TITLE"
            message="PHONE_NUMBERS_DELETE_TEXT"
            content={elemName}
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </Main>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
PhoneNumberList.propTypes = {
    type : PropTypes.string.isRequired,
};

export default PhoneNumberList;
