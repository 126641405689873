import React                from "react";
import PropTypes            from "prop-types";
import useDialog            from "Dashboard/Hooks/Dialog";
import Commons              from "Utils/Commons";

// Dashboard
import ViewDialog           from "Dashboard/Components/Dialogs/ViewDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import ViewField            from "Dashboard/Components/Form/ViewField";



/**
 * The Fetch Log View Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function FetchLogView(props) {
    const { open, isAdmin, isFlow, isIntegration, elemID, onClose } = props;

    // Load the Data
    const { loading, elem } = useDialog("fetchLog", open, elemID);


    // Do the Render
    const doubleColumns = isAdmin || isFlow || isIntegration;

    return <ViewDialog
        open={open}
        icon="fetch"
        title="FETCHES_LOG_VIEW_TITLE"
        onClose={onClose}
        isLoading={loading}
        withSpacing
    >
        <Columns lastDouble>
            <ViewField
                label="GENERAL_TIME"
                value={elem.createdDate}
            />
            <ViewField
                label="GENERAL_RESULT"
                value={elem.errorText}
                viewClass={elem.errorClass}
            />
            <ViewField
                isHidden={!isAdmin}
                label="CLIENTS_SINGULAR"
                value={elem.clientName}
                showEmpty
            />
            <ViewField
                isHidden={isFlow || !elem.flowID}
                label="FLOWS_SINGULAR"
                value={elem.flowName}
            />
            <ViewField
                isHidden={isIntegration || !elem.integrationID}
                label="INTEGRATIONS_SINGULAR"
                value={elem.integrationName}
            />
            <ViewField
                isHidden={!!elem.integrationID}
                className={doubleColumns ? "columns-double" : ""}
                label="CONVERSATIONS_SINGULAR"
                value={elem.conversationText}
                showEmpty
            />
        </Columns>

        <ViewField
            label="GENERAL_URL"
            value={elem.url}
        />
        <ViewField
            label="GENERAL_FETCH_METHOD"
            value={elem.method}
        />
        <ViewField
            label="GENERAL_FETCH_USER_PASS"
            value={elem.userPass}
        />
        <ViewField
            label="FETCHES_LOG_HEADERS"
            value={Commons.jsonToHtml(elem.headers)}
        />
        <ViewField
            label="FETCHES_LOG_PARAMS"
            value={Commons.jsonToHtml(elem.params)}
        />
        <ViewField
            label="GENERAL_RESPONSE"
            value={Commons.jsonToHtml(elem.response)}
        />
    </ViewDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
FetchLogView.propTypes = {
    open          : PropTypes.bool.isRequired,
    isAdmin       : PropTypes.bool.isRequired,
    isFlow        : PropTypes.bool.isRequired,
    isIntegration : PropTypes.bool.isRequired,
    onClose       : PropTypes.func.isRequired,
    elemID        : PropTypes.number,
};

export default FetchLogView;
