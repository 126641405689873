import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";
import ViewField            from "Dashboard/Components/Form/ViewField";



/**
 * The Phone Number Import Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function PhoneNumberImport(props) {
    const { open, clientID, onClose, onSubmit } = props;

    const { numbers } = Store.useState("phoneNumber");
    const { fetchImportData, importPhoneNumber } = Store.useAction("phoneNumber");


    // The Initial Data
    const initialData = {
        clientID     : 0,
        name         : "",
        providerID   : "",
        phoneNumber  : "",
        friendlyName : "",
        location     : "",
    };


    // Handles the Phone
    const handlePhone = (name, value) => {
        const elem = Utils.getValue(numbers, "phoneNumber", value);
        setData({
            providerID   : elem.providerID,
            phoneNumber  : elem.phoneNumber,
            friendlyName : elem.friendlyName,
        });
    };

    // The Form State
    const {
        data, errors, setElem, setData, handleChange, handleSubmit,
    } = useForm("phoneNumber", initialData, importPhoneNumber, onSubmit);

    // Load the Data
    const { loading } = useDialog("phoneNumber", open, 0, { clientID }, setElem, fetchImportData);



    // Do the Render
    const showError    = Boolean(!numbers.length);
    const numberSelect = numbers.map((elem) => ({ key : elem.phoneNumber, value : elem.friendlyName }));

    return <EditDialog
        open={open}
        icon="import"
        title="PHONE_NUMBERS_IMPORT_TITLE"
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isDisabled={!numbers.length}
    >
        <ViewField
            isHidden={!showError}
            label="PHONE_NUMBERS_SINGULAR"
            message="PHONE_NUMBERS_EMPTY"
        />
        <InputField
            isHidden={showError}
            type="select"
            name="phoneNumber"
            label="PHONE_NUMBERS_SINGULAR"
            options={numberSelect}
            value={data.phoneNumber}
            error={errors.phoneNumber}
            onChange={handlePhone}
            isRequired
        />
        <InputField
            isHidden={showError}
            name="name"
            label="PHONE_NUMBERS_DESCRIPTIVE_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
        />
        <InputField
            isHidden={showError}
            name="location"
            label="PHONE_NUMBERS_LOCATION"
            value={data.location}
            error={errors.location}
            onChange={handleChange}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
PhoneNumberImport.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    clientID : PropTypes.number,
};

export default PhoneNumberImport;
