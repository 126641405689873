import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";
import { ConnectorType }    from "Utils/API";



// The initial State
const initialState = {
    loading    : true,
    error      : false,
    edition    : 0,
    canCreate  : false,
    canEdit    : false,
    canImport  : false,
    canExport  : false,
    list       : [],
    total      : 0,
    elem       : {},
    currencies : [],
    sort       : {
        orderBy  : "position",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "CONNECTOR_TYPE_LOADING" });
    },

    /**
     * Fetches the Connector Type List
     * @param {Function} dispatch
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, params = {}) {
        const data = await ConnectorType.getAll(params);
        data.sort = params;
        dispatch({ type : "CONNECTOR_TYPE_LIST", data });
    },

    /**
     * Fetches a single Connector Type
     * @param {Function} dispatch
     * @param {Number}   connectorCode
     * @returns {Promise}
     */
    async fetchElem(dispatch, connectorCode) {
        const data = await ConnectorType.getOne({ connectorCode });
        dispatch({ type : "CONNECTOR_TYPE_ELEM", data });
    },

    /**
     * Edits a Connector Type
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editConnector(dispatch, data) {
        return ConnectorType.edit(data);
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.hasQuantityText = Utils.toYesNo(elem.hasQuantity);

    elem.pricesText      = {};
    for (const { currencyID, currencySymbol, price } of Object.values(elem.prices || {})) {
        elem.pricesText[currencyID] = Commons.formatPrice(price, currencySymbol);
    }
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "CONNECTOR_TYPE_LIST", "CONNECTOR_TYPE_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "CONNECTOR_TYPE_LOADING":
        return {
            ...state,
            loading    : true,
        };

    case "CONNECTOR_TYPE_LIST":
        return {
            ...state,
            loading    : false,
            error      : false,
            canCreate  : action.data.canCreate,
            canEdit    : action.data.canEdit,
            canImport  : action.data.canImport,
            canExport  : action.data.canExport,
            list       : Utils.parseList(action.data.list, parseElem),
            total      : action.data.total,
            sort       : action.data.sort,
            currencies : action.data.currencies,
        };

    case "CONNECTOR_TYPE_ELEM":
        return {
            ...state,
            error      : false,
            edition    : state.edition + 1,
            elem       : parseElem(action.data.elem),
            currencies : action.data.currencies,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
