import NLS                  from "Dashboard/Core/NLS";
import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import { Assistant }        from "Utils/API";



// The initial State
const initialState = {
    loading   : true,
    charging  : false,
    error     : false,
    edition   : 0,
    canCreate : false,
    canEdit   : false,
    canImport : false,
    canExport : false,
    list      : [],
    total     : 0,
    elem      : {},
    module    : "",
    models    : [],
    maxFiles  : 0,
    statuses  : [],
    sort      : {
        orderBy  : "name",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "ASSISTANT_LOADING" });
    },

    /**
     * Fetches the Assistant List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, params = {}) {
        Navigate.unsetParams(params);
        if (type === "CLIENT") {
            params.clientID = elemID;
        }
        const data = await Assistant.getAll(params);
        data.sort  = params;
        dispatch({ type : "ASSISTANT_LIST", data });
    },

    /**
     * Fetches a single Assistant
     * @param {Function} dispatch
     * @param {Number}   assistantID
     * @returns {Promise}
     */
    async fetchElem(dispatch, assistantID) {
        dispatch({ type : "ASSISTANT_CHARGING" });
        const data = await Assistant.getOne({ assistantID });
        dispatch({ type : "ASSISTANT_ELEM", data });
    },

    /**
     * Fetches the Assistant Edit data
     * @param {Function} dispatch
     * @param {Number}   clientID
     * @returns {Promise}
     */
    async fetchEditData(dispatch, clientID) {
        dispatch({ type : "ASSISTANT_CHARGING" });
        const data = await Assistant.getEditData({ clientID });
        dispatch({ type : "ASSISTANT_EDIT", data });
    },

    /**
     * Edits/Creates an Assistant
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editAssistant(dispatch, data) {
        return Assistant.edit(data);
    },

    /**
     * Deletes an Assistant
     * @param {Function} dispatch
     * @param {Number}   assistantID
     * @returns {Promise}
     */
    deleteAssistant(dispatch, assistantID) {
        return Assistant.delete({ assistantID });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.useByDefaultText = Utils.toYesNo(elem.useByDefault);
    elem.filesText        = NLS.pluralize("ASSISTANTS_FILES", elem.fileCount);
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "ASSISTANT_LIST", "ASSISTANT_ELEM", "ASSISTANT_EDIT")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "ASSISTANT_LOADING":
        return {
            ...state,
            loading   : true,
        };

    case "ASSISTANT_CHARGING":
        return {
            ...state,
            charging  : true,
        };

    case "ASSISTANT_LIST":
        return {
            ...state,
            loading   : false,
            error     : false,
            canCreate : action.data.canCreate,
            canEdit   : action.data.canEdit,
            canImport : action.data.canImport,
            canExport : action.data.canExport,
            list      : Utils.parseList(action.data.list, parseElem),
            total     : action.data.total,
            sort      : action.data.sort,
        };

    case "ASSISTANT_ELEM":
        return {
            ...state,
            charging  : false,
            error     : false,
            edition   : state.edition + 1,
            elem      : parseElem(action.data.elem),
            module    : action.data.module,
            models    : action.data.models,
            maxFiles  : action.data.maxFiles,
            statuses  : action.data.statuses,
        };

    case "ASSISTANT_EDIT":
        return {
            ...state,
            charging  : false,
            error     : false,
            edition   : state.edition + 1,
            module    : action.data.module,
            models    : action.data.models,
            maxFiles  : action.data.maxFiles,
            statuses  : action.data.statuses,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
