import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";

// Dashboard
import Header               from "Dashboard/Components/Header/Header";



/**
 * The WABA Template Header
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function WabaTemplateHeader(props) {
    const { href, isEdit, getValue } = props;

    const { charging, elem } = Store.useState("wabaTemplate");


    // Generates the Title
    const title = React.useMemo(() => {
        if (charging) {
            return "GENERAL_LOADING_DOTS";
        }
        if (isEdit) {
            return elem.name;
        }
        return "WABA_TEMPLATES_CREATE_TITLE";
    }, [ charging, isEdit, elem.name ]);


    // Do the Render
    return <Header
        message={title}
        icon="whatsapp"
        href={href}
        subTitle={isEdit ? getValue("statusName") : ""}
        subCircle={isEdit ? getValue("statusColor") : ""}
    />;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
WabaTemplateHeader.propTypes = {
    href     : PropTypes.string.isRequired,
    isEdit   : PropTypes.bool.isRequired,
    getValue : PropTypes.func.isRequired,
};

export default WabaTemplateHeader;
