import React                from "react";
import Store                from "Dashboard/Core/Store";
import Action               from "Dashboard/Core/Action";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";
import { Partner }          from "Utils/API";

// Components
import PartnerFilter        from "./PartnerFilter";
import PartnerEdit          from "./PartnerEdit";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Partner List
 * @returns {React.ReactElement}
 */
function PartnerList() {
    const { fetch, load, loadFilter } = useList("partner");

    const data = Store.useState("partner");
    const { loading, canEdit, list, total, filters, sort } = data;

    const { deletePartner } = Store.useAction("partner");

    // The Current State
    const [ exporting, setExporting ] = React.useState(false);
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Action
    const handleAction = async (action, elemID) => {
        if (action.isExport) {
            setExporting(true);
            endAction();
            const source = Partner.export(filters);
            await Utils.download(source, "partners.xlsx");
            setExporting(false);
        } else {
            startAction(action, elemID);
        }
    };

    // Handles the Filter Submit
    const handleFilter = (filters) => {
        endAction();
        loadFilter(filters);
    };

    // Handles the Edit Submit
    const handleEdit = () => {
        endAction();
        fetch();
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        endAction();
        const result = await deletePartner(elemID);
        if (result.success) {
            fetch();
        }
    };


    // Do the Render
    const elemName = Utils.getValue(list, "partnerID", elemID, "name");

    return <Main withNavigation={false}>
        <Header message="PARTNERS_NAME" icon="partner">
            <ActionList data={data} onAction={handleAction} isExporting={exporting} />
        </Header>
        <Content isLoading={loading}>
            <Table fetch={load} sort={sort} none="PARTNERS_NONE_AVAILABLE">
                <TableHead>
                    <TableHeader field="name"         message="GENERAL_NAME"          grow="2" isTitle />
                    <TableHeader field="socialReason" message="GENERAL_SOCIAL_REASON" grow="2" />
                    <TableHeader field="clientsCount" message="CLIENTS_NAME"          />
                    <TableHeader field="commission"   message="PARTNERS_COMMISSION"   />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.partnerID} elemID={elem.partnerID}>
                        <TableCell message={elem.name}           circle={elem.statusColor} />
                        <TableCell message={elem.contact}        />
                        <TableCell message={elem.clientsText}    />
                        <TableCell message={elem.commissionText} />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={handleAction} canEdit={canEdit}>
                    <TableAction action="VIEW"   message="PARTNERS_VIEW_TITLE"   navigate />
                    <TableAction action="EDIT"   message="PARTNERS_EDIT_TITLE"   />
                    <TableAction action="DELETE" message="PARTNERS_DELETE_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <PartnerFilter
            open={action.isFilter}
            filters={filters}
            onSubmit={handleFilter}
            onClose={endAction}
        />
        <PartnerEdit
            open={action.isVCE}
            elemID={elemID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="PARTNERS_DELETE_TITLE"
            message="PARTNERS_DELETE_TEXT"
            content={elemName}
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </Main>;
}

export default PartnerList;
