import React                from "react";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";

// Components
import SettingsTitle        from "./SettingsTitle";

// Dashboard
import Button               from "Dashboard/Components/Form/Button";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Settings Delete
 * @returns {React.ReactElement}
 */
function SettingsDelete() {
    const { credentialID, isAnyAdmin } = Store.useState("auth");
    const { deleteProfile } = Store.useAction("profile");

    const navigate = Navigate.useGoto();

    // The Current State
    const [ showDelete, setShowDelete ] = React.useState(false);

    // Handles the Delete
    const handleDelete = async () => {
        await deleteProfile(credentialID);
        navigate("LOGIN");
    };


    // Do the Render
    if (isAnyAdmin) {
        return <React.Fragment />;
    }
    return <section>
        <SettingsTitle
            icon="delete"
            title="ACCOUNT_DELETE_TITLE"
            isError
        />

        <p>{NLS.get("ACCOUNT_DELETE_TEXT")}</p>
        <Button
            variant="error"
            message="ACCOUNT_DELETE_TITLE"
            onClick={() => setShowDelete(true)}
        />

        <DeleteDialog
            open={showDelete}
            title="ACCOUNT_DELETE_TITLE"
            message="ACCOUNT_DELETE_TEXT"
            onSubmit={handleDelete}
            onClose={() => setShowDelete(false)}
        />
    </section>;
}

export default SettingsDelete;
