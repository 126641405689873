import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";

// Dashboard
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionItem           from "Dashboard/Components/Header/ActionItem";



/**
 * The Assistant Header
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function AssistantHeader(props) {
    const { data, href, isEdit, showAssistant, setShowAssistant } = props;

    const { charging, elem } = Store.useState("assistant");


    // Generates the Title
    const title = React.useMemo(() => {
        if (charging) {
            return "GENERAL_LOADING_DOTS";
        }
        if (isEdit) {
            return data.name;
        }
        return "ASSISTANTS_CREATE_TITLE";
    }, [ charging, isEdit, data.name ]);


    // Do the Render
    return <Header
        message={title}
        icon="assistant"
        href={href}
        subTitle={elem.statusName}
        subCircle={elem.statusColor}
    >
        <ActionList>
            <ActionItem
                isHidden={!isEdit}
                variant={showAssistant ? "primary" : "outlined"}
                action="ASSISTANT"
                message="ASSISTANTS_THREADS_NAME"
                onClick={() => setShowAssistant(!showAssistant)}
            />
        </ActionList>
    </Header>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
AssistantHeader.propTypes = {
    data             : PropTypes.object.isRequired,
    href             : PropTypes.string.isRequired,
    isEdit           : PropTypes.bool.isRequired,
    showAssistant    : PropTypes.bool.isRequired,
    setShowAssistant : PropTypes.func.isRequired,
};

export default AssistantHeader;
