import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Components
import TextareaField        from "Components/Utils/Inputs/TextareaField";
import MediaField           from "Components/Utils/Inputs/MediaField";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Note Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function NoteEdit(props) {
    const { open, elemID, clientID, conversationID, onClose } = props;

    const { variables, channelLinks } = Store.useState("conversation");
    const { lastUpdate } = Store.useState("conversationMessage");
    const { elem } = Store.useState("conversationNote");
    const { editNote, deleteNote } = Store.useAction("conversationNote");


    // The Current State
    const [ showDelete, setShowDelete ] = React.useState(false);

    // The Initial Data
    const initialData = {
        noteID         : 0,
        conversationID : 0,
        clientID       : 0,
        message        : "",
        isNewFile      : 0,
        newFile        : {},
        newFileName    : "",
        oldFileName    : "",
        lastUpdate     : 0,
    };


    // Handles the Set
    const handleSet = (elem) => {
        if (!elem) {
            setElem({ ...initialData, clientID, conversationID });
        } else {
            setElem({
                clientID       : elem.clientID,
                conversationID : elem.conversationID,
                noteID         : elem.noteID,
                message        : elem.message,
                isNewFile      : elem.fileName ? 1 : 0,
                newFileName    : elem.fileName,
                oldFileName    : elem.file,
                lastUpdate     : lastUpdate,
            });
        }
    };

    // Deletes the Conversation Note
    const handleDelete = async () => {
        const response = await deleteNote(elemID, lastUpdate);
        if (response.success) {
            setShowDelete(false);
            onClose();
        }
    };


    // The Form State
    const {
        data, errors, setElem, setError, handleChange, handleSubmit,
    } = useForm("conversationNote", initialData, editNote, onClose);

    // Load the Data
    const { loading } = useDialog("conversationNote", open, elemID, null, handleSet);



    // Do the Render
    return <>
        <EditDialog
            open={open}
            icon="note"
            title={!elemID ? "CONVERSATIONS_NOTES_CREATE_TITLE" : "CONVERSATIONS_NOTES_EDIT_TITLE"}
            secondary={elemID ? "CONVERSATIONS_NOTES_DELETE_TITLE" : ""}
            onSecondary={() => setShowDelete(true)}
            error={errors.form}
            onSubmit={handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <TextareaField
                name="message"
                label="CONVERSATIONS_NOTES_MESSAGE"
                value={data.message}
                error={errors.message}
                variables={variables}
                channelLinks={channelLinks}
                onChange={handleChange}
                isRequired
            />

            <Columns>
                <InputField
                    type="toggle"
                    name="isNewFile"
                    label="CONVERSATIONS_NOTES_USE_FILE"
                    value={data.isNewFile}
                    onChange={handleChange}
                    withBorder
                />
                <MediaField
                    isHidden={!!data.isNewFile}
                    name="oldFileName"
                    label="CONVERSATIONS_OLD_FILE"
                    value={data.oldFileName}
                    error={errors.oldFileName}
                    clientID={elem.clientID}
                    onChange={handleChange}
                />
                <InputField
                    isHidden={!data.isNewFile}
                    type="file"
                    name="newFile"
                    label="CONVERSATIONS_NEW_FILE"
                    value={data.newFileName}
                    error={errors.file}
                    maxSize={3}
                    onChange={handleChange}
                    onError={setError}
                />
            </Columns>
        </EditDialog>

        <DeleteDialog
            open={open && showDelete}
            title="CONVERSATIONS_NOTES_DELETE_TITLE"
            message="CONVERSATIONS_NOTES_DELETE_TEXT"
            content={elem.name}
            onSubmit={handleDelete}
            onClose={() => setShowDelete(false)}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
NoteEdit.propTypes = {
    open           : PropTypes.bool.isRequired,
    elemID         : PropTypes.number,
    clientID       : PropTypes.number,
    conversationID : PropTypes.number,
    onClose        : PropTypes.func.isRequired,
};

export default NoteEdit;
