import React                from "react";
import Store                from "Dashboard/Core/Store";
import Action               from "Dashboard/Core/Action";
import useList              from "Dashboard/Hooks/List";

// Components
import ProvinceEdit         from "./ProvinceEdit";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";



/**
 * The Province List
 * @returns {React.ReactElement}
 */
function ProvinceList() {
    const { fetch, load } = useList("province");

    const data = Store.useState("province");
    const { loading, canEdit, list, total, sort } = data;

    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Edit Submit
    const handleEdit = () => {
        endAction();
        fetch();
    };


    // Do the Render
    return <Main>
        <Header message="PROVINCES_NAME" icon="province">
            <ActionList data={data} onAction={startAction} />
        </Header>
        <Content>
            <Table
                fetch={load}
                sort={sort}
                none="PROVINCES_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="countryName" message="COUNTRIES_SINGULAR" isTitle />
                    <TableHeader field="name"        message="PROVINCES_SINGULAR" />
                    <TableHeader field="isoCode"     message="GENERAL_ISO_CODE"   maxWidth="110" align="center" hideMobile />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.provinceID} elemID={elem.provinceID}>
                        <TableCell message={elem.countryName} />
                        <TableCell message={elem.name}        />
                        <TableCell message={elem.isoCode}     />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={startAction} canEdit={canEdit}>
                    <TableAction action="EDIT" message="PROVINCES_EDIT_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <ProvinceEdit
            open={action.isVCE}
            elemID={elemID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
    </Main>;
}

export default ProvinceList;
