import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Components
import ContactCombine       from "./ContactCombine";
import ContactMerge         from "./ContactMerge";
import TagEdit              from "Components/App/Config/Tag/TagEdit";
import FieldsInputs         from "Components/Utils/Inputs/FieldsInputs";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";
import InputItem            from "Dashboard/Components/Form/InputItem";



/**
 * The Contact Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ContactEdit(props) {
    const { open, elemID, clientID, conversationID, hospitalityID, onClose, onSubmit } = props;

    const { elem, editElem, contactOrigins, countries, tongues, customFields, tags } = Store.useState("contact");
    const { fetchEditData, editContact } = Store.useAction("contact");
    const { hasMarketing } = Store.useState("permission");


    // The Current State
    const [ openCombine,  setOpenCombine  ] = React.useState(false);
    const [ openMerge,    setOpenMerge    ] = React.useState(false);
    const [ openTag,      setOpenTag      ] = React.useState(false);
    const [ contactIDs,   setContactIDs   ] = React.useState([]);
    const [ update,       setUpdate       ] = React.useState(false);
    const [ sameContact,  setSameContact  ] = React.useState(false);
    const [ newContactID, setNewContactID ] = React.useState(0);

    // The Initial Data
    const initialData = {
        contactID        : 0,
        clientID         : 0,
        countryID        : 0,
        tongueID         : 0,
        firstName        : "",
        lastName         : "",
        emails           : "[{}]",
        cellphones       : "[{}]",
        origin           : "",
        address          : "",
        externalID       : "",
        observations     : "",
        dontSendCampaign : "",
        tags             : [],
    };
    for (const customField of customFields) {
        initialData[customField.id] = "";
    }


    // Handles the Edit
    const handleEdit = (data) => {
        return editContact({ ...data, clientID, conversationID, hospitalityID });
    };

    // Handles the Set
    const handleSet = () => {
        const emailValues     = Utils.getValues(editElem.values, "defaultField", "email");
        const cellphoneValues = Utils.getValues(editElem.values, "defaultField", "cellphone");
        const tagIDs          = Object.keys(editElem.tags || {});

        const emails     = JSON.stringify(emailValues.length     ? emailValues     : [{}]);
        const cellphones = JSON.stringify(cellphoneValues.length ? cellphoneValues : [{}]);
        const tags       = tagIDs.length ? tagIDs : [];

        setElem({ ...editElem, emails, cellphones, tags });

        if (!newContactID) {
            setSameContact(!elem.id || elem.id === editElem.id);
        }
    };

    // Handles the Tag Create Action
    const handleTagCreate = async ({ tagID, name }) => {
        if (tagID) {
            const newTags = [ ...data.tags || [], tagID ];
            handleChange("tags", newTags);
            tags.push({ key : tagID, value : name });
        }
        setOpenTag(false);
    };

    // Handles the Combine
    const handleCombine = (newContactIDs) => {
        setContactIDs(newContactIDs);
        setOpenCombine(false);
        setOpenMerge(true);
    };

    // Handles the Merge
    const handleMerge = (response) => {
        setContactIDs([]);
        setOpenCombine(false);
        setOpenMerge(false);
        setUpdate(true);
        fetchEditData(response.contactID);
        setNewContactID(response.contactID);
    };


    // Sets the Update to false
    React.useEffect(() => {
        if (open) {
            setUpdate(false);
        }
    }, [ open ]);

    // Handles the Final Submit
    const handleFinalSubmit = (response) => {
        let contactID = 0;

        // Contact created
        if (!editElem.id) {
            contactID = response.contactID;
        // Details contact combined
        } else if (sameContact && newContactID) {
            contactID = newContactID;
        // Details contact edited
        } else if (sameContact) {
            contactID = response.contactID;
        }
        onSubmit(contactID, response);
    };

    // Handles the Close
    const handleClose = () => {
        onClose(update, sameContact ? newContactID : 0);
    };


    // The Form State
    const {
        data, errors, setElem, setError, handleChange, handleSubmit,
    } = useForm("contact", initialData, handleEdit, handleFinalSubmit);

    // Load the Data
    const { loading } = useDialog("contact", open, elemID, { elemID, clientID }, handleSet, fetchEditData);



    // Do the Render
    const hasName = Boolean(data.firstName || data.lastName);

    return <>
        <EditDialog
            open={open}
            icon="contact"
            title={elemID ? "CONTACTS_EDIT_TITLE" : "CONTACTS_CREATE_TITLE"}
            error={errors.form}
            secondary={elemID ? "CONTACTS_MERGE_TITLE" : ""}
            onSecondary={() => setOpenCombine(true)}
            onSubmit={handleSubmit}
            onClose={handleClose}
            isLoading={loading}
        >
            <Columns>
                <InputField
                    name="firstName"
                    label="GENERAL_FIRST_NAME"
                    value={data.firstName}
                    error={errors.firstName}
                    onChange={handleChange}
                    isRequired={!hasName}
                />
                <InputField
                    name="lastName"
                    label="GENERAL_LAST_NAME"
                    value={data.lastName}
                    error={errors.lastName}
                    onChange={handleChange}
                    isRequired={!hasName}
                />

                <InputField
                    type="fields"
                    name="emails"
                    label="GENERAL_EMAIL"
                    addButton="CONTACTS_ADD_EMAIL"
                    value={data.emails}
                    errors={errors}
                    onChange={handleChange}
                >
                    <InputItem
                        type="email"
                        name="value"
                    />
                </InputField>
                <InputField
                    type="fields"
                    name="cellphones"
                    label="GENERAL_CELLPHONE"
                    addButton="CONTACTS_ADD_CELLPHONE"
                    columns="2"
                    value={data.cellphones}
                    errors={errors}
                    onChange={handleChange}
                >
                    <InputItem
                        type="select"
                        name="countryID"
                        placeholder="GENERAL_CODE"
                        options={countries}
                    />
                    <InputItem
                        type="tel"
                        name="value"
                        placeholder="GENERAL_CELLPHONE"
                    />
                </InputField>

                <InputField
                    type="select"
                    name="tongueID"
                    label="LANGUAGES_SINGULAR"
                    options={tongues}
                    value={data.tongueID}
                    error={errors.tongueID}
                    onChange={handleChange}
                />
                <InputField
                    type="select"
                    name="origin"
                    label="GENERAL_ORIGIN"
                    options={contactOrigins}
                    value={data.origin}
                    error={errors.origin}
                    onChange={handleChange}
                />

                <InputField
                    name="address"
                    label="CONTACTS_ADDRESS"
                    value={data.address}
                    error={errors.address}
                    onChange={handleChange}
                />
                <InputField
                    name="externalID"
                    label="GENERAL_EXTERNAL_ID"
                    value={data.externalID}
                    error={errors.externalID}
                    onChange={handleChange}
                />

                <FieldsInputs
                    fields={customFields}
                    data={data}
                    errors={errors}
                    onError={setError}
                    onChange={handleChange}
                    withRequired
                />
            </Columns>

            <InputField
                type="chooser"
                name="tags"
                label="TAGS_NAME"
                inputType="select"
                addButton="TAGS_ADD_TITLE"
                value={data.tags}
                error={errors.tags}
                options={tags}
                onChange={handleChange}
                button="GENERAL_CREATE"
                onClick={() => setOpenTag(true)}
                isDisabled={!tags.length}
            />
            <InputField
                type="textarea"
                name="observations"
                label="GENERAL_OBSERVATIONS"
                value={data.observations}
                error={errors.observations}
                onChange={handleChange}
            />
            <InputField
                isHidden={!hasMarketing}
                type="toggle"
                name="dontSendCampaign"
                label="CONTACTS_DONT_SEND_CAMPAIGNS"
                value={data.dontSendCampaign}
                error={errors.dontSendCampaign}
                onChange={handleChange}
                withBorder
            />
        </EditDialog>

        <ContactCombine
            open={openCombine}
            contact={editElem}
            onSubmit={handleCombine}
            onClose={() => setOpenCombine(false)}
        />
        <ContactMerge
            open={openMerge}
            clientID={editElem.clientID}
            contactIDs={contactIDs}
            onSubmit={handleMerge}
            onClose={() => setOpenMerge(false)}
        />
        <TagEdit
            open={openTag}
            clientID={data.clientID}
            onSubmit={handleTagCreate}
            onClose={() => setOpenTag(false)}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ContactEdit.propTypes = {
    open           : PropTypes.bool.isRequired,
    onClose        : PropTypes.func.isRequired,
    onSubmit       : PropTypes.func.isRequired,
    elemID         : PropTypes.number,
    clientID       : PropTypes.number,
    conversationID : PropTypes.number,
    hospitalityID  : PropTypes.number,
};

/**
 * The Default Properties
 * @typedef {Object} defaultProps
 */
ContactEdit.defaultProps = {
    conversationID : 0,
    hospitalityID  : 0,
};

export default ContactEdit;
