import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";

// Dashboard
import DetailList           from "Dashboard/Components/Details/DetailList";
import DetailItem           from "Dashboard/Components/Details/DetailItem";
import IconLink             from "Dashboard/Components/Link/IconLink";



// Styles
const Text = Styled.div.attrs(({ hasAction }) => ({ hasAction }))`
    flex-grow: 2;
    ${(props) => props.hasAction && "cursor: pointer;"}
`;



/**
 * The Accounts Detail Item
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function AccountsItem(props) {
    const { list, collapsible, onAction, onItemAction, forContact } = props;


    // Handles the Action
    const handleAction = (action, id) => {
        if (onItemAction) {
            onItemAction(action, id);
        }
    };


    // Do the Render
    const hasAction = !!onItemAction;

    return <DetailList
        isHidden={!list.length}
        message={forContact ? "CONTACTS_NAME" : "ACCOUNTS_NAME"}
        collapsible={collapsible}
        onAction={onAction}
        action="ASSIGN"
        editIcon="add"
        canEdit
    >
        {list.map((elem) => <DetailItem
            key={elem.id}
            icon={forContact ? "contact" : "account"}
        >
            {forContact ? <Text
                hasAction={hasAction}
                onClick={() => handleAction("CONTACT", elem.contactID)}
            >
                {elem.contactName}
            </Text> : <Text
                hasAction={hasAction}
                onClick={() => handleAction("ACCOUNT", elem.accountID)}
            >
                {elem.accountName}
            </Text>}
            <IconLink
                isHidden={!hasAction}
                onClick={() => handleAction("UNASSIGN", elem.id)}
                variant="black"
                icon="delete"
                isSmall
            />
        </DetailItem>)}
    </DetailList>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
AccountsItem.propTypes = {
    list         : PropTypes.array.isRequired,
    onItemAction : PropTypes.func,
    onAction     : PropTypes.func,
    collapsible  : PropTypes.string,
    forContact   : PropTypes.bool,
};

export default AccountsItem;
