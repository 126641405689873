import React                from "react";
import Store                from "Dashboard/Core/Store";
import Action               from "Dashboard/Core/Action";
import useList              from "Dashboard/Hooks/List";

// Components
import ConnectorTypeEdit    from "./ConnectorTypeEdit";
import ProviderIcon         from "Components/Utils/Common/ProviderIcon";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";



/**
 * The Connector Type List
 * @returns {React.ReactElement}
 */
function ConnectorTypeList() {
    const { fetch, load } = useList("connectorType");

    const data = Store.useState("connectorType");
    const { canEdit, list, total, sort, loading, currencies } = data;

    // The Current State
    const [ action, elemCode, startAction, endAction ] = Action.useActionCode();


    // Handles the Edit Submit
    const handleEdit = () => {
        endAction();
        fetch();
    };


    // Do the Render
    return <Main>
        <Header message="CONNECTORS_NAME" icon="connector">
            <ActionList data={data} onAction={startAction} />
        </Header>
        <Content>
            <Table
                fetch={load}
                sort={sort}
                none="CONNECTORS_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="name"     message="GENERAL_NAME"     isTitle isFlex smallSpace />
                    <>{currencies.map(({ key, value }) => <TableHeader key={key} field={`price-${key}`} message={value} noSorting />)}</>
                    <TableHeader field="position" message="GENERAL_POSITION" maxWidth="80" align="center" hideMobile />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.connectorCode} elemID={elem.connectorCode}>
                        <TableCell>
                            <ProviderIcon
                                icon={elem.icon}
                                color={elem.color}
                                message={elem.connectorCode}
                                size={24}
                            />
                            {elem.name}
                        </TableCell>
                        <>{currencies.map(({ key }) => <TableCell key={key} message={elem.pricesText[key] || ""} />)}</>
                        <TableCell message={elem.position} />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={startAction} canEdit={canEdit}>
                    <TableAction action="EDIT" message="CONNECTORS_EDIT_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <ConnectorTypeEdit
            open={action.isVCE}
            elemCode={elemCode}
            onSubmit={handleEdit}
            onClose={endAction}
        />
    </Main>;
}

export default ConnectorTypeList;
