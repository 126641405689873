import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import MediaType            from "Dashboard/Core/MediaType";
import Navigate             from "Dashboard/Core/Navigate";
import Action               from "Dashboard/Core/Action";
import Utils                from "Dashboard/Utils/Utils";

// Dashboard
import DropZone             from "Dashboard/Components/Media/DropZone";
import MediaList            from "Dashboard/Components/Media/MediaList";
import PromptDialog         from "Dashboard/Components/Dialogs/PromptDialog";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Media Content
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function MediaContent(props) {
    const {
        clientID, mediaType, path, initialSelect,
        canSelect, onSelect, multipleSelect,
        inDialog, create, endCreate,
    } = props;

    const data = Store.useState("media");
    const { loading, canCreate, canEdit, list } = data;

    const { fetchMedia, createDirectory, uploadMedia, renameMedia, moveMedia, deleteMedia } = Store.useAction("media");
    const { showResult } = Store.useAction("core");


    // The Initial Data
    const initialElem = {
        name  : "",
        isDir : false,
    };

    // The Current State
    const [ edition,       setEdition       ] = React.useState(0);
    const [ elem,          setElem          ] = React.useState({ ...initialElem });
    const [ selectedPaths, setSelectedPaths ] = React.useState([ initialSelect ]);
    const [ action,        setAction        ] = Action.useAction();


    // Load the Data
    React.useEffect(() => {
        fetch(path, initialSelect);
    }, []);

    // Open the Create Dialog
    React.useEffect(() => {
        if (create) {
            startAction("CREATE");
        }
    }, [ create ]);


    // Fetches the content
    const fetch = (path = data.path, selected = "") => {
        return fetchMedia(clientID, mediaType, path, selected);
    };

    // Handles the Upload Submit
    const handleUpload = async (files) => {
        setAction();
        for (const file of files) {
            await uploadMedia(clientID, data.path, file);
        }
        const newData = await fetch();
        const file    = Utils.getValue(newData.list, "name", files[0].name);
        handleAction(Action.get("SELECT"), file);
    };

    // Handles the Upload Error
    const handleUploadError = (files) => {
        showResult("error", "MEDIA_ERROR_UPLOAD_SIZE" + (files > 1 ? "S" : ""));
    };

    // Handles the Create Submit
    const handleCreate = async (name) => {
        handleCloseCreate();
        const result = await createDirectory(clientID, data.path, name);
        if (result.success) {
            fetch();
        }
    };

    // Handles the Close Create
    const handleCloseCreate = () => {
        setAction();
        if (endCreate) {
            endCreate();
        }
    };

    // Handles the Edit Submit
    const handleEdit = async (newName) => {
        const oldName = elem.name;
        setAction();
        const result = await renameMedia(clientID, data.path, oldName, newName);
        if (result.success) {
            fetch();
        }
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        const name = elem.name;
        setAction();
        const result = await deleteMedia(clientID, data.path, name);
        if (result.success) {
            fetch();
        }
    };

    // Handles the Move Submit
    const handleMove = async (elem, directory) => {
        const oldPath = elem.mvPath.replace(elem.name, "");
        const result  = await moveMedia(clientID, oldPath || "/", directory.mvPath, elem.name);
        if (result.success) {
            handleUnselect();
            fetch();
        }
    };


    // Starts an Action
    const startAction = (action, elem = { ...initialElem }) => {
        setAction(action);
        setElem(elem);
    };

    // Handles the Action
    const handleAction = (action, elem, e) => {
        if (action.isSelect) {
            if (elem.isDir || elem.isBack) {
                fetch(elem.path);
            } else if (canSelect) {
                let newPaths = selectedPaths;
                if (multipleSelect && (e.ctrlKey || e.shiftKey || e.metaKey)) {
                    const index = selectedPaths.indexOf(elem.path);
                    if (index >= 0) {
                        newPaths.splice(index, 1);
                    } else {
                        newPaths.push(elem.path);
                    }
                    Utils.unselectAll();
                    e.preventDefault();
                } else {
                    newPaths = [ elem.path ];
                }
                setSelectedPaths(newPaths);
                setEdition(edition + 1);
                selectMedia(newPaths);
            }
        } else if (action.isView && elem.isDir) {
            fetch(elem.path);
        } else if (action.isView) {
            Navigate.gotoUrl(elem.url, true);
        } else {
            setAction(action);
            setElem(elem);
            handleUnselect();
        }
    };

    // Handles the Menu Close
    const handleUnselect = () => {
        setSelectedPaths([]);
        selectMedia();
    };

    // Sends the Selection
    const selectMedia = (files) => {
        if (!onSelect) {
            return;
        }
        if (multipleSelect) {
            onSelect(files.length ? files : null);
        } else {
            onSelect(files?.[0] ?? "");
        }
    };


    // Do the Render
    return <>
        <DropZone
            isHidden={!canCreate}
            open={action.isUpload}
            onStart={startAction}
            onEnd={setAction}
            onDrop={handleUpload}
            onError={handleUploadError}
            onlyImages={MediaType.onlyImages(mediaType)}
            maxSize={3}
        />
        <MediaList
            onAction={handleAction}
            onDrop={handleMove}
            isLoading={loading}
            canEdit={canEdit}
            canDrag={canEdit}
            canSelect={canSelect}
            inDialog={inDialog}
            selectedPaths={selectedPaths}
            path={data.path || "/"}
            items={list}
            withSpace={canCreate}
        />

        <PromptDialog
            open={action.isCreate}
            icon="directory"
            title="MEDIA_CREATE_TITLE"
            inputLabel="GENERAL_NAME"
            onSubmit={handleCreate}
            onClose={handleCloseCreate}
        />
        <PromptDialog
            open={action.isEdit}
            icon="edit"
            title={elem.isDir ? "MEDIA_EDIT_DIR_TITLE" : "MEDIA_EDIT_FILE_TITLE"}
            inputLabel="GENERAL_NAME"
            initialValue={elem.name}
            spellCheck="false"
            onSubmit={handleEdit}
            onClose={setAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title={elem.isDir ? "MEDIA_DELETE_DIR_TITLE" : "MEDIA_DELETE_FILE_TITLE"}
            message={elem.isDir ? "MEDIA_DELETE_DIR_TEXT" : "MEDIA_DELETE_FILE_TEXT"}
            content={elem.name}
            onSubmit={handleDelete}
            onClose={setAction}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
MediaContent.propTypes = {
    clientID       : PropTypes.number,
    path           : PropTypes.string,
    mediaType      : PropTypes.string,
    onSelect       : PropTypes.func,
    canSelect      : PropTypes.bool,
    multipleSelect : PropTypes.bool,
    initialSelect  : PropTypes.string,
    inDialog       : PropTypes.bool,
    create         : PropTypes.bool,
    endCreate      : PropTypes.func,
};

/**
 * The Default Properties
 * @type {Object} defaultProps
 */
MediaContent.defaultProps = {
    mediaType     : MediaType.ANY,
    path          : "",
    initialSelect : "",
    create        : false,
};

export default MediaContent;
