import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";

// Components
import MessageBubble        from "Components/App/Client/Conversation/Message/MessageBubble";

// Dashboard
import Details              from "Dashboard/Components/Details/Details";



// Styles
const Container = Styled(Details).attrs(({ hasExternalTabs }) => ({ hasExternalTabs }))`
    height: ${(props) => props.hasExternalTabs ? "var(--page-height-tabs)" : "var(--page-height)"};

    @media (max-width: 1200px) {
        height: auto;
    }
`;

const Title = Styled.h3`
    margin-top: 0;
    margin-bottom: 0;
`;

const Bubble = Styled(MessageBubble)`
    --bubble-padding: 0px !important;
    width: calc(100% + 12px);

    .bubble-content {
        margin-right: -12px;
    }
    .bubble.bubble::after {
        background: var(--lighter-gray);
    }
`;



/**
 * The Questionnaire Preview
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function QuestionnairePreview(props) {
    const { clientID, hasTabs, step, getValue } = props;


    // Variables
    const questions = getValue("questions");

    let message = "";
    let media   = null;
    let options = [];

    if (!step || step === "main") {
        message = getValue("name");
        options = questions.map((elem, payload) => ({ payload, message : elem.title || "" }));
    } else if (questions[step]) {
        const question = questions[step];
        if (question.question) {
            message += `**${question.question}**\n\n`;
        }
        if (question.answer) {
            message += question.answer;
        }
        if (question.file) {
            media = {
                fileID   : 1,
                fileText : question.file,
                fileUrl  : `${process.env.REACT_APP_FILES}${clientID}/${question.file}`,
            };
        }
    }


    // Do the Render
    return <Container hasExternalTabs={hasTabs} isInside>
        <Title>{NLS.get("GENERAL_PREVIEW")}</Title>
        <Bubble
            message={message}
            file={media}
            options={options}
            time="6:45"
            isDelivered
            isMine
        />
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
QuestionnairePreview.propTypes = {
    clientID : PropTypes.number.isRequired,
    hasTabs  : PropTypes.bool.isRequired,
    step     : PropTypes.string.isRequired,
    getValue : PropTypes.func.isRequired,
};

export default QuestionnairePreview;
