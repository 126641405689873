import React                from "react";
import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useForm              from "Dashboard/Hooks/Form";
import Commons              from "Utils/Commons";

// Components
import ConnectorHeader      from "./View/ConnectorHeader";
import ConnectorSelect      from "./View/ConnectorSelect";
import ConnectorConfig      from "./View/ConnectorConfig";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import PageContainer        from "Dashboard/Components/Page/PageContainer";
import PageAccordion        from "Dashboard/Components/Page/PageAccordion";
import PageButtons          from "Dashboard/Components/Page/PageButtons";
import AccordionItem        from "Dashboard/Components/Accordion/AccordionItem";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Connector Page
 * @returns {React.ReactElement}
 */
function ConnectorPage() {
    const { connectorID } = Navigate.useParams();

    const clientID = Commons.useClientID();
    const navigate = Navigate.useGoto();
    const parent   = Navigate.useParent();
    const goto     = parent.replace(NLS.baseUrl("CREATE"), "");
    const isEdit   = Boolean(connectorID && !parent.endsWith(NLS.baseUrl("CREATE")));

    const { charging, edition, elem, connectors } = Store.useState("connector");
    const { fetchElem, fetchEditData, editConnector, deleteConnector } = Store.useAction("connector");


    // The Initial Data
    const initialData = {
        connectorID   : 0,
        clientID      : clientID,
        connectorCode : "",
        name          : "",
        status        : "Active",
    };

    // The Current State
    const [ step,       setStep       ] = React.useState(!isEdit ? "select" : "config");
    const [ connector,  setConnector  ] = React.useState({ connectorCode : "" });
    const [ showDelete, setShowDelete ] = React.useState(false);

    // Handles the Edit
    const handleEdit = (response) => {
        if (response.success) {
            navigate(parent);
        }
    };

    // The Form State
    const {
        loading, data, errors, setData,
        resetData, resetErrors, handleChange, handleSubmit,
    } = useForm("connector", initialData, editConnector, handleEdit);


    // Load the Data
    React.useEffect(() => {
        if (connectorID) {
            fetchElem(connectorID);
        } else {
            fetchEditData(clientID);
        }
    }, []);

    // Store the Data
    React.useEffect(() => {
        if (isEdit) {
            const connector = Utils.getValue(connectors, "connectorCode", elem.connectorCode);
            if (connector.connectorCode) {
                setConnector(connector);
            } else {
                setConnector({ connectorCode : "" });
            }
            setData({ ...elem, ...elem.providerData });
        } else {
            resetErrors();
            resetData();
        }
    }, [ edition ]);


    // Handles the Select
    const handleSelect = (connector) => {
        setData({ connectorCode : connector.connectorCode });
        setConnector(connector);
        setStep("config");
    };

    // Handles the Delete
    const handleDelete = async () => {
        setShowDelete(false);
        const response = await deleteConnector(elem.id);
        if (response.success) {
            navigate(goto);
        }
    };

    // Handles the Close
    const handleClose = () => {
        navigate(parent);
    };



    // Do the Render
    return <Main withNavigation>
        <ConnectorHeader
            data={data}
            href={goto}
            isEdit={isEdit}
        />

        <Content isLoading={loading || charging}>
            <PageContainer error={errors.form}>
                <PageAccordion
                    selected={step}
                    onChange={setStep}
                    noClose
                >
                    <AccordionItem
                        isHidden={isEdit}
                        value="select"
                        message="CONNECTORS_SINGULAR"
                        description="CONNECTORS_PROVIDER_TEXT"
                    >
                        <ConnectorSelect
                            selected={connector.connectorCode}
                            onSelect={handleSelect}
                        />
                    </AccordionItem>

                    <AccordionItem
                        value="config"
                        message="CONNECTORS_CONFIG_TITLE"
                        description="CONNECTORS_CONFIG_TEXT"
                        isDisabled={!data.connectorCode}
                    >
                        <ConnectorConfig
                            connector={connector}
                            data={data}
                            errors={errors}
                            onChange={handleChange}
                        />
                    </AccordionItem>
                </PageAccordion>

                <PageButtons
                    isEdit={isEdit}
                    isDisabled={!data.connectorCode}
                    onSubmit={handleSubmit}
                    onDelete={() => setShowDelete(true)}
                    onClose={handleClose}
                />
            </PageContainer>
        </Content>

        <DeleteDialog
            open={showDelete}
            title="CONNECTORS_DELETE_TITLE"
            message="CONNECTORS_DELETE_TEXT"
            content={data.name}
            onSubmit={handleDelete}
            onClose={() => setShowDelete(false)}
        />
    </Main>;
}

export default ConnectorPage;
