import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import { ConversationNote } from "Utils/API";



// The initial State
const initialState = {
    loading : true,
    error   : false,
    edition : 0,
    elem    : {},
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "CONVERSATION_NOTE_LOADING" });
    },

    /**
     * Fetches a single Conversation Note
     * @param {Function} dispatch
     * @param {Number}   noteID
     * @returns {Promise}
     */
    async fetchElem(dispatch, noteID) {
        const data = await ConversationNote.getOne({ noteID });
        dispatch({ type : "CONVERSATION_NOTE_ELEM", data });
    },

    /**
     * Creates a Conversation Note
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    async createNote(dispatch, params) {
        const data = await ConversationNote.create(params);
        if (!data.error) {
            dispatch({ type : "CONVERSATION_NOTE_UPDATE", data });
        }
        return data;
    },

    /**
     * Edits a Conversation Note
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    async editNote(dispatch, params) {
        const data = await ConversationNote.edit(params);
        if (!data.error) {
            dispatch({ type : "CONVERSATION_NOTE_UPDATE", data });
        }
        return data;
    },

    /**
     * Deletes a Conversation Note
     * @param {Function} dispatch
     * @param {Number}   noteID
     * @param {Number}   lastUpdate
     * @returns {Promise}
     */
    async deleteNote(dispatch, noteID, lastUpdate) {
        const data = await ConversationNote.delete({ noteID, lastUpdate });
        if (!data.error) {
            dispatch({ type : "CONVERSATION_NOTE_UPDATE", data });
        }
        return data;
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "CONVERSATION_NOTE_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "CONVERSATION_NOTE_LOADING":
        return {
            ...state,
            loading   : true,
        };

    case "CONVERSATION_NOTE_ELEM":
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            elem      : action.data.elem,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
