import React                from "react";
import PropTypes            from "prop-types";
import Facebook             from "Utils/Facebook";

// Components
import LoginButton          from "./LoginButton";



/**
 * The Facebook Login
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function FacebookLogin(props) {
    const { onSuccess, isSignup } = props;


    // Loads Facebook
    React.useEffect(() => {
        Facebook.load();
        Facebook.initialize();
    }, []);

    // Handles the Facebook Login
    const handleLogin = async () => {
        const accessToken = await Facebook.login({ scope : "email" });
        onSuccess(accessToken);
    };


    // Do the Render
    return <LoginButton
        onClick={() => handleLogin()}
        logo="Facebook"
        message={isSignup ? "JOIN_FACEBOOK" : "LOGIN_FACEBOOK"}
    />;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
FacebookLogin.propTypes = {
    onSuccess : PropTypes.func.isRequired,
    isSignup  : PropTypes.bool,
};

export default FacebookLogin;
