import Action               from "Dashboard/Core/Action";
import Store                from "Dashboard/Core/Store";



// The initial State
const initialState = {
    action        : Action.get(),
    isFiltering   : false,
    isCreating    : false,
    withDetails   : false,
    withOrder     : false,
    withAssistant : false,
    detailsTab    : "",
    tongueID      : 0,
};



// The Actions
const actions = {
    /**
     * Sets the Store state Action
     * @param {Function} dispatch
     * @param {String}   action
     * @returns {Void}
     */
    setAction(dispatch, action) {
        dispatch({ type : "STORE_STATE_ACTION", action });
    },

    /**
     * Sets the Store state Filtering
     * @param {Function} dispatch
     * @param {Boolean}  isFiltering
     * @returns {Void}
     */
    setFiltering(dispatch, isFiltering) {
        dispatch({ type : "STORE_STATE_FILTERING", isFiltering });
    },

    /**
     * Sets the Store state Creating
     * @param {Function} dispatch
     * @param {Boolean}  isCreating
     * @returns {Void}
     */
    setCreating(dispatch, isCreating) {
        dispatch({ type : "STORE_STATE_CREATING", isCreating });
    },

    /**
     * Sets the Store state Order
     * @param {Function} dispatch
     * @param {Boolean}  withOrder
     * @returns {Void}
     */
    setOrder(dispatch, withOrder) {
        dispatch({ type : "STORE_STATE_ORDER", withOrder });
    },

    /**
     * Sets the Store state Assistant
     * @param {Function} dispatch
     * @param {Boolean}  withAssistant
     * @returns {Void}
     */
    setAssistant(dispatch, withAssistant) {
        dispatch({ type : "STORE_STATE_ASSISTANT", withAssistant });
    },

    /**
     * Sets the Store Details Tab
     * @param {Function} dispatch
     * @param {String}   detailsTab
     * @returns {Void}
     */
    setDetailsTab(dispatch, detailsTab) {
        dispatch({ type : "STORE_STATE_DETAILS_TAB", detailsTab });
    },

    /**
     * Sets the Widget tongue
     * @param {Function} dispatch
     * @param {Number}   tongueID
     * @returns {Void}
     */
    setTongueID(dispatch, tongueID) {
        dispatch({ type : "STORE_STATE_TONGUE", tongueID });
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
    case "STORE_STATE_ACTION":
        return {
            ...state,
            action        : Action.get(action.action),
        };

    case "STORE_STATE_FILTERING":
        return {
            ...state,
            isFiltering   : action.isFiltering,
        };

    case "STORE_STATE_CREATING":
        return {
            ...state,
            isCreating    : action.isCreating,
        };

    case "STORE_STATE_ORDER":
        return {
            ...state,
            withOrder     : action.withOrder,
            withDetails   : action.withOrder || state.withAssistant,
            detailsTab    : action.withOrder ? "order" : "assistant",
        };

    case "STORE_STATE_ASSISTANT":
        return {
            ...state,
            withAssistant : action.withAssistant,
            withDetails   : state.withOrder || action.withAssistant,
            detailsTab    : action.withAssistant ? "assistant" : "order",
        };

    case "STORE_STATE_DETAILS_TAB":
        return {
            ...state,
            detailsTab    : action.detailsTab,
        };

    case "STORE_STATE_TONGUE":
        return {
            ...state,
            tongueID      : action.tongueID,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
