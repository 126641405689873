import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";

// Dashboard
import UserRoute            from "Dashboard/Components/Route/UserRoute";



/**
 * Only Authenticated Partners can access the given Component
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function PartnerRoute(props) {
    const { isPartner } = Store.useState("auth");

    return <UserRoute
        {...props}
        isValid={isPartner}
    />;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
PartnerRoute.propTypes = {
    isHidden  : PropTypes.bool,
    url       : PropTypes.string.isRequired,
    component : PropTypes.oneOfType([ PropTypes.func, PropTypes.object ]).isRequired,
    exact     : PropTypes.bool,
    type      : PropTypes.string,
    path      : PropTypes.string,
};

export default PartnerRoute;
