import React                from "react";
import PropTypes            from "prop-types";
import { useGoogleLogin }   from "@react-oauth/google";

// Components
import LoginButton          from "./LoginButton";



/**
 * The Google Login
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function GoogleLogin(props) {
    const { onSuccess, isSignup } = props;


    // Handles the Google Login
    const handleLogin = useGoogleLogin({
        onSuccess : onSuccess,
    });


    // Do the Render
    return <LoginButton
        onClick={() => handleLogin()}
        logo="Google"
        message={isSignup ? "JOIN_GOOGLE" : "LOGIN_GOOGLE"}
    />;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
GoogleLogin.propTypes = {
    onSuccess : PropTypes.func.isRequired,
    isSignup  : PropTypes.bool,
};

export default GoogleLogin;
