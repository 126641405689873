import React                from "react";
import Store                from "Dashboard/Core/Store";

// Components
import ProfileContainer     from "Components/App/Profile/ProfileContainer";

// Dashboard
import Router               from "Dashboard/Components/Route/Router";
import UserRoute            from "Dashboard/Components/Route/UserRoute";



/**
 * The Credential Router
 * @returns {React.ReactElement}
 */
function CredentialRouter() {
    const { isOnlyCredential } = Store.useState("auth");


    // Do the Render
    if (!isOnlyCredential) {
        return <React.Fragment />;
    }
    return <Router type="USER">
        <UserRoute
            url="PROFILE"
            component={ProfileContainer}
        />
    </Router>;
}

export default CredentialRouter;
