import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Dashboard/Core/NLS";

// Dashboard
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableCell            from "Dashboard/Components/Table/TableCell";



/**
 * The Subscription AI View
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function SubscriptionAI(props) {
    const { isHidden, elem } = props;


    // Do the Render
    if (isHidden || !elem.id || !elem.assistants.length) {
        return <React.Fragment />;
    }
    return <Table noSorting notFixed rightSpace>
        <TableHead>
            <TableHeader field="assistantName"    message="ASSISTANTS_SINGULAR"         isTitle />
            <TableHeader field="runs"             message="SUBSCRIPTIONS_TOTAL_RUNS"    align="right" />
            <TableHeader field="inputCostFormat"  message="SUBSCRIPTIONS_INPUT_TOKENS"  align="right" />
            <TableHeader field="outputCostFormat" message="SUBSCRIPTIONS_OUTPUT_TOKENS" align="right" rightSpace />
        </TableHead>
        <TableBody>
            {elem.assistants.map((assistant) => <TableRow key={assistant.channelID} elemID={assistant.channelID}>
                <TableCell message={`${assistant.assistantName} (${assistant.model})`}                                           />
                <TableCell message={assistant.runs}                                                                              />
                <TableCell message={NLS.format("SUBSCRIPTIONS_TOKENS_COST", assistant.inputCostFormat, assistant.inputTokens)}   />
                <TableCell message={NLS.format("SUBSCRIPTIONS_TOKENS_COST", assistant.outputCostFormat, assistant.outputTokens)} />
            </TableRow>)}
        </TableBody>
    </Table>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
SubscriptionAI.propTypes = {
    isHidden : PropTypes.bool.isRequired,
    elem     : PropTypes.object.isRequired,
};

export default SubscriptionAI;
