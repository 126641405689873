import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";

// Dashboard
import InputField           from "Dashboard/Components/Form/InputField";
import Columns              from "Dashboard/Components/Form/Columns";



// Styles
const Container = Styled(Columns)`
    max-width: var(--provider-width);
    margin: 0 auto;
`;



/**
 * The Channel Config
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ChannelConfig(props) {
    const { data, errors, onChange } = props;

    const { flows } = Store.useState("channel");


    // Do the Render
    return <Container amount="1">
        <InputField
            name="name"
            label="GENERAL_NAME"
            helperText="CHANNELS_HELP_NAME"
            value={data.name}
            error={errors.name}
            onChange={onChange}
        />
        <InputField
            type="select"
            name="flowID"
            label="FLOWS_SINGULAR"
            options={flows}
            value={data.flowID}
            error={errors.flowID}
            onChange={onChange}
        />
        <InputField
            type="select"
            name="showNames"
            label="CHANNELS_ADD_USER_NAME"
            options="SELECT_SHOW_NAMES"
            value={data.showNames}
            onChange={onChange}
        />
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ChannelConfig.propTypes = {
    data     : PropTypes.object.isRequired,
    errors   : PropTypes.object.isRequired,
    onChange : PropTypes.func.isRequired,
};

export default ChannelConfig;
