import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import DialogMessage        from "Dashboard/Components/Dialog/DialogMessage";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Chat Followup Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ChatFollowup(props) {
    const { open, clientID, conversationID, onClose } = props;

    const { followupConversation } = Store.useAction("conversation");


    // The Initial Data
    const initialData = {
        hours          : "",
        reason         : "",
        followupStatus : "Queue",
        followupNotify : "none",
    };

    // Handles the Edit
    const handleEdit = () => {
        return followupConversation({
            ...data,
            conversationID,
        });
    };

    // The Form State
    const {
        loading, data, errors, setElem, handleChange, handleSubmit,
    } = useForm("conversation", initialData, handleEdit, onClose);

    // Load the Data
    useDialog("conversation", open, 0, {
        clientID, conversationID,
    }, setElem);


    // Do the Render
    return <EditDialog
        open={open}
        icon="followup"
        title="CONVERSATIONS_FOLLOWUP_TITLE"
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <DialogMessage
            variant="h3"
            message="CONVERSATIONS_FOLLOWUP_TEXT"
            noSpace
        />
        <InputField
            type="number"
            name="hours"
            label="CONVERSATIONS_FOLLOWUP_HOURS"
            value={data.hours}
            error={errors.hours}
            onChange={handleChange}
            isRequired
        />

        <InputField
            name="reason"
            label="CONVERSATIONS_FOLLOWUP_REASON"
            value={data.reason}
            error={errors.reason}
            onChange={handleChange}
        />
        <InputField
            type="radio"
            name="followupStatus"
            label="GENERAL_TYPE"
            options="SELECT_CONVERSATION_FOLLOWUPS"
            value={data.followupStatus}
            error={errors.followupStatus}
            onChange={handleChange}
        />
        <InputField
            type="radio"
            name="followupNotify"
            label="NOTIFICATIONS_SINGULAR"
            options="SELECT_CONVERSATION_NOTIFICATIONS"
            value={data.followupNotify}
            error={errors.followupNotify}
            onChange={handleChange}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ChatFollowup.propTypes = {
    open           : PropTypes.bool.isRequired,
    clientID       : PropTypes.number.isRequired,
    conversationID : PropTypes.number.isRequired,
    onClose        : PropTypes.func.isRequired,
};

export default ChatFollowup;
