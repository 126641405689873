import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";

// Components
import CampaignEmpty        from "../Utils/CampaignEmpty";

// Dashboard
import DialogMessage        from "Dashboard/Components/Dialog/DialogMessage";
import InputField           from "Dashboard/Components/Form/InputField";
import Columns              from "Dashboard/Components/Form/Columns";
import ViewField            from "Dashboard/Components/Form/ViewField";



/**
 * The Campaign WhatsApp
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CampaignWhatsApp(props) {
    const { data, getOptionError, onChange } = props;

    const {
        hasWhatsApp, selects : { whatsAppChannels, wabaTemplates },
    } = Store.useState("campaign");


    // Do the Render
    if (!hasWhatsApp) {
        return <CampaignEmpty
            waitMessage="CAMPAIGNS_WHATSAPP_WAIT"
            configureMessage="CAMPAIGNS_WHATSAPP_CONFIGURE"
        />;
    }

    const whatsAppActive   = Utils.isActive(data.whatsAppActive);
    const showTemplates    = Boolean(whatsAppActive && data.whatsAppChannelID);
    const wabaTemplateList = Commons.getWabaTemplates(wabaTemplates, data.whatsAppChannelID);
    const wabaTemplate     = Utils.getValue(wabaTemplates, "id", data.whatsAppTemplateID);
    const wabaMessage      = data.whatsAppTemplateID ? Commons.getWabaMessage(wabaTemplate) : "";

    return <>
        <DialogMessage
            variant="h3"
            message="CAMPAIGNS_WHATSAPP_TEXT"
        />
        <InputField
            type="toggle"
            name="whatsAppActive"
            label="CAMPAIGNS_WHATSAPP_ACTIVATE"
            value={whatsAppActive}
            error={getOptionError("whatsAppActive")}
            onChange={onChange}
            withBorder
        />

        <Columns isHidden={!whatsAppActive}>
            <InputField
                type="select"
                name="whatsAppPriority"
                label="CAMPAIGNS_PRIORITY"
                options="SELECT_CAMPAIGN_PRIORITIES"
                value={data.whatsAppPriority}
                error={getOptionError("whatsAppPriority")}
                onChange={onChange}
                isRequired
            />
            <InputField
                type="select"
                name="whatsAppChannelID"
                label="GENERAL_WHATSAPP_CHANNEL"
                options={whatsAppChannels}
                value={data.whatsAppChannelID}
                error={getOptionError("whatsAppChannelID")}
                onChange={onChange}
                isRequired
            />
        </Columns>

        <ViewField
            isHidden={!showTemplates || !!wabaTemplateList.length}
            label="WABA_TEMPLATES_SINGULAR"
            message="WABA_TEMPLATES_EMPTY_SEND"
        />
        <InputField
            isHidden={!showTemplates || !wabaTemplateList.length}
            type="select"
            name="whatsAppTemplateID"
            label="WABA_TEMPLATES_SINGULAR"
            options={wabaTemplateList}
            value={data.whatsAppTemplateID}
            error={getOptionError("whatsAppTemplateID")}
            onChange={onChange}
            isRequired
        />

        <ViewField
            isHidden={!showTemplates || !data.whatsAppTemplateID}
            label="GENERAL_MESSAGE"
            value={wabaMessage}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CampaignWhatsApp.propTypes = {
    data           : PropTypes.object.isRequired,
    getOptionError : PropTypes.func.isRequired,
    onChange       : PropTypes.func.isRequired,
};

export default CampaignWhatsApp;
