import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";



// Styles
const InputOutput = Styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 4px;
    height: 16px;
`;

const Text = Styled.div`
    max-width: calc(100% - 16px - 4px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

const Square = Styled.div.attrs(({ isSelected }) => ({ isSelected }))`
    flex-shrink: 0;
    width: 12px;
    height: 12px;
    background-color: ${(props) => props.isSelected ? "var(--primary-color)" : "var(--node-square)"};
    border-radius: 3px;
    cursor: pointer;
`;



/**
 * The Flow Node IO
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function FlowNodeIO(props) {
    const { className, action, type, id, isSelected, message } = props;


    // Do the Render
    return <InputOutput className={className}>
        <Square
            data-action={action}
            data-type={type}
            data-id={id}
            isSelected={isSelected}
        />
        <Text>{NLS.get(message)}</Text>
    </InputOutput>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
FlowNodeIO.propTypes = {
    className  : PropTypes.string,
    action     : PropTypes.string,
    type       : PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    id         : PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    isSelected : PropTypes.bool,
    message    : PropTypes.string,
};

export default FlowNodeIO;
