import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import NLS                  from "Dashboard/Core/NLS";

// Components
import ProviderButton       from "Components/Utils/Common/ProviderButton";



// Styles
const Title = Styled.h3`
    margin-top: 0;
    margin-bottom: var(--provider-gap);
    font-weight: 400;
    text-align: center;
`;

const Columns = Styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--provider-gap);
    max-width: var(--provider-width);
    margin: 0 auto;

    @media (max-width: 600px) {
        grid-template-columns: 1fr;
    }
`;



/**
 * The Connector Select
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ConnectorSelect(props) {
    const { selected, onSelect } = props;

    const { connectors } = Store.useState("connector");


    // Do the Render
    return <>
        <Title>{NLS.get("CONNECTORS_SELECT_TEXT")}</Title>
        <Columns>
            {connectors.map((elem) => <ProviderButton
                key={elem.connectorCode}
                icon={elem.icon}
                color={elem.color}
                message={elem.fullName}
                isSelected={selected === elem.connectorCode}
                onClick={() => onSelect(elem)}
            />)}
        </Columns>
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ConnectorSelect.propTypes = {
    selected : PropTypes.string.isRequired,
    onSelect : PropTypes.func.isRequired,
};

export default ConnectorSelect;
