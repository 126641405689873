import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import NLS                  from "Dashboard/Core/NLS";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Partner Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function PartnerEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { countries, provinces, statuses } = Store.useState("partner");
    const { editPartner } = Store.useAction("partner");


    // The Initial Data
    const initialData = {
        partnerID      : 0,
        name           : "",
        commission     : 0,
        socialReason   : "",
        taxID          : "",
        firstName      : "",
        lastName       : "",
        email          : "",
        phone          : "",
        countryID      : "",
        provinceID     : "",
        locality       : "",
        address        : "",
        zipCode        : "",
        language       : "es",
        timeZone       : -3,
        premiumSupport : 0,
        showTickets    : 1,
        status         : "Active",
        observations   : "",
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("partner", initialData, editPartner, onSubmit);

    // Load the Data
    const { loading } = useDialog("partner", open, elemID, null, setElem);



    // Do the Render
    return <EditDialog
        open={open}
        icon="partner"
        title={elemID ? "PARTNERS_EDIT_TITLE" : "PARTNERS_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <Columns>
            <InputField
                name="name"
                label="GENERAL_FANTASY_NAME"
                value={data.name}
                error={errors.name}
                onChange={handleChange}
                isRequired
            />
            <InputField
                type="number"
                name="commission"
                label="PARTNERS_COMMISSION"
                value={data.commission}
                error={errors.commission}
                onChange={handleChange}
            />

            <InputField
                name="socialReason"
                label="GENERAL_SOCIAL_REASON"
                value={data.socialReason}
                error={errors.socialReason}
                onChange={handleChange}
            />
            <InputField
                name="taxID"
                label="GENERAL_TAX_ID"
                value={data.taxID}
                error={errors.taxID}
                onChange={handleChange}
            />

            <InputField
                name="firstName"
                label="PARTNERS_FIRST_NAME"
                value={data.firstName}
                error={errors.firstName}
                onChange={handleChange}
            />
            <InputField
                name="lastName"
                label="PARTNERS_LAST_NAME"
                value={data.lastName}
                error={errors.lastName}
                onChange={handleChange}
            />

            <InputField
                type="email"
                name="email"
                label="PARTNERS_EMAIL"
                value={data.email}
                error={errors.email}
                onChange={handleChange}
            />
            <InputField
                type="tel"
                name="phone"
                label="PARTNERS_PHONE"
                value={data.phone}
                error={errors.phone}
                onChange={handleChange}
            />

            <InputField
                type="select"
                name="countryID"
                label="COUNTRIES_SINGULAR"
                options={countries}
                value={data.countryID}
                error={errors.countryID}
                onChange={handleChange}
            />
            <InputField
                type="select"
                name="provinceID"
                label="GENERAL_PROVINCE"
                options={provinces[data.countryID] || []}
                value={data.provinceID}
                error={errors.provinceID}
                onChange={handleChange}
            />
            <InputField
                name="locality"
                label="GENERAL_LOCALITY"
                value={data.locality}
                error={errors.locality}
                onChange={handleChange}
            />
            <InputField
                name="address"
                label="GENERAL_ADDRESS"
                value={data.address}
                error={errors.address}
                onChange={handleChange}
            />
            <InputField
                name="zipCode"
                label="GENERAL_ZIP_CODE"
                value={data.zipCode}
                error={errors.zipCode}
                onChange={handleChange}
            />
            <InputField
                type="select"
                name="language"
                label="LANGUAGES_SINGULAR"
                options={NLS.select("LANGUAGES")}
                value={data.language}
                error={errors.language}
                onChange={handleChange}
                isRequired
            />
            <InputField
                type="select"
                name="timeZone"
                label="GENERAL_TIME_ZONE"
                options={NLS.get("DATE_TIME_ZONES")}
                value={data.timeZone}
                error={errors.timeZone}
                onChange={handleChange}
                isRequired
            />

            <InputField
                type="toggle"
                name="premiumSupport"
                label="PARTNERS_PREMIUM_SUPPORT"
                value={!!data.premiumSupport}
                onChange={handleChange}
                withBorder
            />
            <InputField
                type="toggle"
                name="showTickets"
                label="PARTNERS_HIDE_TICKETS"
                value={!!data.showTickets}
                onChange={handleChange}
                withBorder
            />
            <InputField
                type="select"
                name="status"
                label="GENERAL_STATUS"
                options={statuses}
                value={data.status}
                error={errors.status}
                onChange={handleChange}
                isRequired
            />

            <InputField
                className="columns-double"
                type="textarea"
                name="observations"
                label="GENERAL_OBSERVATIONS"
                value={data.observations}
                onChange={handleChange}
            />
        </Columns>
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
PartnerEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default PartnerEdit;
