import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import { Widget }           from "Utils/API";



// The initial State
const initialState = {
    loading        : true,
    charging       : false,
    error          : false,
    edition        : 0,
    canCreate      : false,
    canEdit        : false,
    canImport      : false,
    canExport      : false,
    list           : [],
    total          : 0,
    elem           : {},
    options        : {},
    optionsDraft   : {},
    platforms      : [],
    platformsDraft : [],
    hasPlatforms   : false,
    flows          : [],
    tongues        : [],
    statuses       : [],
    sort           : {
        orderBy  : "name",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "WIDGET_LOADING" });
    },

    /**
     * Fetches the Widget List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, params = {}) {
        Navigate.unsetParams(params);
        if (type === "CLIENT") {
            params.clientID = elemID;
        }
        const data = await Widget.getAll(params);
        data.sort  = params;
        dispatch({ type : "WIDGET_LIST", data });
    },

    /**
     * Fetches a single Widget
     * @param {Function} dispatch
     * @param {Number}   widgetID
     * @returns {Promise}
     */
    async fetchElem(dispatch, widgetID) {
        const data = await Widget.getOne({ widgetID });
        dispatch({ type : "WIDGET_ELEM", data });
        return data;
    },

    /**
     * Fetches a single Widget
     * @param {Function} dispatch
     * @param {Number}   widgetID
     * @returns {Promise}
     */
    async fetchData(dispatch, widgetID) {
        dispatch({ type : "WIDGET_CHARGING" });
        const data = await Widget.getOne({ widgetID });
        dispatch({ type : "WIDGET_ELEM", data });
        return data;
    },

    /**
     * Creates a Widget
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    createWidget(dispatch, data) {
        return Widget.create(data);
    },

    /**
     * Copies a Widget
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    copyWidget(dispatch, data) {
        return Widget.copy(data);
    },

    /**
     * Edits a Widget Option
     * @param {Function} dispatch
     * @param {Number}   widgetID
     * @param {String}   option
     * @param {*}        value
     * @param {Number}   tongueID
     * @returns {Promise}
     */
    async editOption(dispatch, widgetID, option, value, tongueID) {
        const data = await Widget.editOption({ widgetID, option, value, tongueID });
        if (!data.errors) {
            dispatch({ type : "WIDGET_EDITED", data });
        }
        return data;
    },

    /**
     * Deletes a Widget
     * @param {Function} dispatch
     * @param {Number}   widgetID
     * @returns {Promise}
     */
    deleteWidget(dispatch, widgetID) {
        return Widget.delete({ widgetID });
    },

    /**
     * Publishes a Widget
     * @param {Function} dispatch
     * @param {Number}   widgetID
     * @returns {Promise}
     */
    publishWidget(dispatch, widgetID) {
        return Widget.publish({ widgetID });
    },

    /**
     * Clears a Widget
     * @param {Function} dispatch
     * @param {Number}   widgetID
     * @returns {Promise}
     */
    clearWidget(dispatch, widgetID) {
        return Widget.clear({ widgetID });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.modifiedDateTime = DateTime.formatIf(elem.modifiedTime, "dashesTime");
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "WIDGET_LIST", "WIDGET_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "WIDGET_LOADING":
        return {
            ...state,
            loading        : true,
        };
    case "WIDGET_CHARGING":
        return {
            ...state,
            charging       : true,
        };

    case "WIDGET_LIST":
        return {
            ...state,
            loading        : false,
            error          : false,
            canCreate      : action.data.canCreate,
            canEdit        : action.data.canEdit,
            canImport      : action.data.canImport,
            canExport      : action.data.canExport,
            list           : Utils.parseList(action.data.list, parseElem),
            total          : action.data.total,
            sort           : action.data.sort,
            statuses       : action.data.statuses,
        };

    case "WIDGET_ELEM":
        return {
            ...state,
            charging       : false,
            error          : false,
            edition        : state.edition + 1,
            elem           : parseElem(action.data.elem),
            options        : action.data.options,
            optionsDraft   : action.data.optionsDraft,
            platforms      : action.data.platforms,
            platformsDraft : action.data.platformsDraft,
            hasPlatforms   : action.data.platformsDraft.length > 0,
            flows          : action.data.flows,
            tongues        : action.data.tongues,
            statuses       : action.data.statuses,
        };

    case "WIDGET_EDITED":
        return {
            ...state,
            elem           : parseElem(action.data.elem),
        };

    case "WIDGET_PLATFORMS":
        return {
            ...state,
            elem           : parseElem(action.data.elem),
            platformsDraft : action.data.platformsDraft,
            hasPlatforms   : action.data.platformsDraft.length > 0,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
