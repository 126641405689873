import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Period               from "Dashboard/Utils/Period";
import Utils                from "Dashboard/Utils/Utils";
import useFilter            from "Dashboard/Hooks/Filter";

// Dashboard
import FilterDialog         from "Dashboard/Components/Dialogs/FilterDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Hospitality Filter Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function HospitalityFilter(props) {
    const { open, filters, clientID, onClose, onSubmit } = props;

    const { hotels, tongues, hasInstagram, hasTelegram } = Store.useState("hospitality");


    // The Initial Data
    const initialData = {
        hotelIDs   : [],
        period     : 0,
        fromDate   : "",
        toDate     : "",
        externalID : "",
        roomNumber : "",
        fullName   : "",
        email      : "",
        cellphone  : "",
        instagram  : "",
        telegram   : "",
        origin     : [],
        tongueIDs  : [],
    };

    // The Filter State
    const {
        loading, data, handleChange, handlePeriod, handleSubmit,
    } = useFilter("hospitality", open, initialData, filters, onSubmit, { hospitalityID : 0, clientID });


    // Do the Render
    const hasOne = (hasInstagram && !hasTelegram) || (!hasInstagram && hasTelegram);

    return <FilterDialog
        open={open}
        title="HOSPITALITY_FILTER_TITLE"
        initialData={Utils.clone(initialData)}
        data={data}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <Columns amount="3">
            <InputField
                type="select"
                name="period"
                label="PERIOD_NAME"
                value={data.period}
                onChange={handlePeriod}
                options={Period.getSelect()}
            />
            <InputField
                type="date"
                name="fromDate"
                label="GENERAL_FROM"
                value={data.fromDate}
                onChange={handleChange}
            />
            <InputField
                type="date"
                name="toDate"
                label="GENERAL_TO"
                value={data.toDate}
                onChange={handleChange}
            />
        </Columns>

        <InputField
            isHidden={hotels.length < 2}
            type="chooser"
            name="hotelIDs"
            label="HOTELS_NAME"
            options={hotels}
            value={data.hotelIDs}
            onChange={handleChange}
        />

        <Columns>
            <InputField
                name="externalID"
                label="HOSPITALITY_EXTERNAL_ID"
                value={data.externalID}
                onChange={handleChange}
            />
            <InputField
                name="roomNumber"
                label="HOSPITALITY_ROOM_NUMBER"
                value={data.roomNumber}
                onChange={handleChange}
            />

            <InputField
                className={!hasOne ? "columns-double" : ""}
                name="fullName"
                label="GENERAL_FULL_NAME"
                value={data.fullName}
                onChange={handleChange}
            />
            <InputField
                type="email"
                name="email"
                label="GENERAL_EMAIL"
                value={data.email}
                onChange={handleChange}
            />
            <InputField
                type="tel"
                name="cellphone"
                label="GENERAL_CELLPHONE"
                value={data.cellphone}
                onChange={handleChange}
            />

            <InputField
                isHidden={!hasInstagram}
                name="instagram"
                label="CONTACTS_INSTAGRAM"
                value={data.instagram}
                onChange={handleChange}
            />
            <InputField
                isHidden={!hasTelegram}
                name="telegram"
                label="CONTACTS_TELEGRAM"
                value={data.telegram}
                onChange={handleChange}
            />
        </Columns>

        <InputField
            type="chooser"
            name="tongueIDs"
            label="LANGUAGES_NAME"
            options={tongues}
            value={data.tongueIDs}
            onChange={handleChange}
        />
    </FilterDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
HospitalityFilter.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    filters  : PropTypes.object,
    clientID : PropTypes.number,
};

export default HospitalityFilter;
