import React                from "react";
import PropTypes            from "prop-types";
import MediaType            from "Dashboard/Core/MediaType";
import Store                from "Dashboard/Core/Store";

// Components
import MediaField           from "Components/Utils/Inputs/MediaField";

// Dashboard
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Personalize Header
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function PersonalizeHeader(props) {
    const { onChange, onClear, hasClear, getText } = props;

    const { elem : store } = Store.useState("store");
    const { data, errors } = Store.useState("storeOption");


    // Do the Render
    return <>
        <InputField
            type="toggle"
            name="headerLeftAlign"
            label="PERSONALIZE_HEADER_LEFT_ALIGN"
            value={Number(data.headerLeftAlign)}
            onChange={onChange}
            withBorder
        />
        <MediaField
            name="logoFile"
            label="PERSONALIZE_HEADER_LOGO_FILE"
            mediaType={MediaType.IMAGE}
            value={data.logoFile}
            error={errors.logoFile}
            clientID={store.clientID}
            onChange={onChange}
        />
        <InputField
            type="number"
            name="logoHeight"
            label="PERSONALIZE_HEADER_LOGO_HEIGHT"
            value={Number(data.logoHeight)}
            onChange={onChange}
            onClear={onClear}
            hasClear={hasClear("logoHeight")}
        />
        <InputField
            name="headerText"
            label="PERSONALIZE_HEADER_TEXT"
            value={getText("headerText")}
            error={errors.headerText}
            onInput={onChange}
            onClear={onClear}
            hasClear={hasClear("headerText")}
        />

        <InputField
            type="color"
            name="headerBgColor"
            label="PERSONALIZE_HEADER_BG_COLOR"
            value={data.headerBgColor}
            error={errors.headerBgColor}
            onChange={onChange}
            onClear={onClear}
            hasClear={hasClear("headerBgColor")}
        />
        <InputField
            type="color"
            name="headerTextColor"
            label="PERSONALIZE_HEADER_TEXT_COLOR"
            value={data.headerTextColor}
            error={errors.headerTextColor}
            onChange={onChange}
            onClear={onClear}
            hasClear={hasClear("headerTextColor")}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
PersonalizeHeader.propTypes = {
    onChange : PropTypes.func.isRequired,
    onClear  : PropTypes.func.isRequired,
    hasClear : PropTypes.func.isRequired,
    getText  : PropTypes.func.isRequired,
};

export default PersonalizeHeader;
