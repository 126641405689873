import React                from "react";
import Action               from "Dashboard/Core/Action";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import Commons              from "Utils/Commons";

// Components
import ClientEdit           from "Components/App/Admin/Client/ClientEdit";
import SubscriptionList     from "Components/App/Admin/Subscription/SubscriptionList";
import DashboardPage        from "Components/App/Client/Dashboard/DashboardPage";
import ConversationPage     from "Components/App/Client/Conversation/ConversationPage";
import ContactList          from "Components/App/Client/Contact/ContactList";
import HospitalityList      from "Components/App/Client/Hospitality/HospitalityList";
import AccountList          from "Components/App/Client/Account/AccountList";
import CampaignList         from "Components/App/Client/Campaign/CampaignList";
import CampaignPage         from "Components/App/Client/Campaign/CampaignPage";
import UserList             from "Components/App/Client/User/UserList";
import TeamList             from "Components/App/Client/Team/TeamList";
import TicketList           from "Components/App/Support/Ticket/TicketList";

// Dashboard
import Router               from "Dashboard/Components/Route/Router";
import UserRoute            from "Dashboard/Components/Route/UserRoute";
import SecondaryNav         from "Dashboard/Components/Navigation/SecondaryNav";
import NavigationList       from "Dashboard/Components/Navigation/NavigationList";
import NavigationItem       from "Dashboard/Components/Navigation/NavigationItem";



/**
 * The Client Page
 * @returns {React.ReactElement}
 */
function ClientPage() {
    const { clientID } = Navigate.useParams();

    const partnerID = Commons.usePartnerID();

    const { closeMenu } = Store.useAction("core");
    const {
        hasSubscriptions, hasHospitality, hasStore,
        hasAccount, hasMarketing, hasClientTickets,
    } = Store.useState("permission");

    const { elem } = Store.useState("client");
    const { fetchElem } = Store.useAction("client");

    // The Current State
    const [ action, setAction ] = Action.useAction();


    // Load the Data
    React.useEffect(() => {
        fetchElem(clientID);
    }, [ clientID ]);

    // Handles the Action
    const handleAction = (action) => {
        if (action.isCE) {
            closeMenu();
            setAction(action);
        }
    };

    // Handles the Edit Submit
    const handleEdit = () => {
        fetchElem(clientID);
        setAction();
    };


    // Do the Render
    return <>
        <SecondaryNav
            message={elem.name}
            fallback="CLIENTS_SINGULAR"
            href={Navigate.useFrom()}
            onAction={handleAction}
            canEdit
        >
            <NavigationList>
                <NavigationItem
                    message="DASHBOARD_NAME"
                    url="DASHBOARD"
                    icon="dashboard"
                />
                <NavigationItem
                    message="CONVERSATIONS_NAME"
                    url="CONVERSATIONS"
                    icon="conversation"
                />
                <NavigationItem
                    isHidden={!hasStore}
                    message="STORES_NAME"
                    url="STORES"
                    icon="store"
                />
                <NavigationItem
                    message="CONTACTS_NAME"
                    url="CONTACTS"
                    icon="contact"
                />
                <NavigationItem
                    isHidden={!hasHospitality}
                    message="HOSPITALITY_NAME"
                    url="HOSPITALITIES"
                    icon="hospitality"
                />
                <NavigationItem
                    isHidden={!hasAccount}
                    message="ACCOUNTS_NAME"
                    url="ACCOUNTS"
                    icon="account"
                />
                <NavigationItem
                    isHidden={!hasMarketing}
                    message="CAMPAIGNS_NAME"
                    url="CAMPAIGNS"
                    icon="campaign"
                />
                <NavigationItem
                    message="USERS_NAME"
                    url="USERS"
                    icon="user"
                />
                <NavigationItem
                    message="TEAMS_NAME"
                    url="TEAMS"
                    icon="team"
                />
            </NavigationList>

            <NavigationList>
                <NavigationItem
                    message="GENERAL_SETTINGS"
                    url="SETTINGS"
                    icon="settings"
                    noClose
                />
                <NavigationItem
                    isHidden={!hasSubscriptions}
                    message="SUBSCRIPTIONS_NAME"
                    url="SUBSCRIPTIONS"
                    icon="subscription"
                />
                <NavigationItem
                    isHidden={!hasClientTickets}
                    message="TICKETS_NAME"
                    url="TICKETS"
                    icon="ticket"
                />
            </NavigationList>
        </SecondaryNav>

        <Router type="CLIENT">
            <UserRoute
                url="DASHBOARD"
                component={DashboardPage}
            />
            <UserRoute
                url="DASHBOARD_TAB"
                component={DashboardPage}
            />

            <UserRoute
                url="CONVERSATIONS"
                component={ConversationPage}
            />
            <UserRoute
                url="CONVERSATION_TAB"
                component={ConversationPage}
            />
            <UserRoute
                url="CONVERSATION"
                component={ConversationPage}
            />

            <UserRoute
                url="CONTACTS"
                component={ContactList}
            />
            <UserRoute
                url="CONTACT"
                component={ContactList}
            />

            <UserRoute
                isHidden={!hasHospitality}
                url="HOSPITALITIES"
                component={HospitalityList}
            />
            <UserRoute
                isHidden={!hasHospitality}
                url="HOSPITALITY"
                component={HospitalityList}
            />

            <UserRoute
                isHidden={!hasAccount}
                url="ACCOUNTS"
                component={AccountList}
            />
            <UserRoute
                isHidden={!hasAccount}
                url="ACCOUNT"
                component={AccountList}
            />

            <UserRoute
                isHidden={!hasMarketing}
                url="CAMPAIGNS"
                component={CampaignList}
            />
            <UserRoute
                isHidden={!hasMarketing}
                url="CAMPAIGN"
                component={CampaignPage}
            />
            <UserRoute
                isHidden={!hasMarketing}
                url="CAMPAIGN_TAB"
                component={CampaignPage}
            />

            <UserRoute
                url="USERS"
                component={UserList}
            />
            <UserRoute
                url="USER"
                component={UserList}
            />

            <UserRoute
                url="TEAMS"
                component={TeamList}
            />
            <UserRoute
                isHidden={!hasSubscriptions}
                url="SUBSCRIPTIONS"
                component={SubscriptionList}
            />
            <UserRoute
                isHidden={!hasClientTickets}
                url="TICKETS"
                component={TicketList}
            />
        </Router>

        <ClientEdit
            open={action.isEdit}
            elemID={clientID}
            partnerID={partnerID}
            onSubmit={handleEdit}
            onClose={setAction}
        />
    </>;
}

export default ClientPage;
