import React                from "react";
import PropTypes            from "prop-types";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import ViewDialog           from "Dashboard/Components/Dialogs/ViewDialog";
import ViewField            from "Dashboard/Components/Form/ViewField";



/**
 * The Email Template View Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function EmailTemplateView(props) {
    const { open, elemID, onClose } = props;

    // Load the Data
    const { loading, elem } = useDialog("emailTemplate", open, elemID);


    // Do the Render
    return <ViewDialog
        open={open}
        icon="email"
        title="EMAIL_TEMPLATES_VIEW_TITLE"
        onClose={onClose}
        isLoading={loading}
        withSpacing
    >
        <ViewField
            label="LANGUAGES_SINGULAR"
            value={elem.languageName}
        />
        <ViewField
            label="GENERAL_DESCRIPTION"
            value={elem.description}
        />
        <ViewField
            label="GENERAL_SUBJECT"
            value={elem.subject}
        />
        <ViewField
            label="GENERAL_MESSAGE"
            value={elem.messageHtml}
        />
    </ViewDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
EmailTemplateView.propTypes = {
    open    : PropTypes.bool.isRequired,
    onClose : PropTypes.func.isRequired,
    elemID  : PropTypes.number,
};

export default EmailTemplateView;
