import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";

// Dashboard
import Icon                 from "Dashboard/Components/Common/Icon";



// Styles
const ItemIcon = Styled(Icon).attrs(({ color, size }) => ({ color, size }))`
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${(props) => `${props.size}px`};
    height: ${(props) => `${props.size}px`};
    font-size: ${(props) => `${props.size / 2}px`};
    background-color: ${(props) => props.color};
    border-radius: 50%;
    padding: 0;
    color: white;
`;



/**
 * The Channel Icon
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ChannelIcon(props) {
    const { icon, color, size } = props;


    // Do the Render
    if (!icon || !color) {
        return <React.Fragment />;
    }
    return <ItemIcon
        icon={icon}
        color={color}
        size={size}
    />;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ChannelIcon.propTypes = {
    icon  : PropTypes.string,
    color : PropTypes.string,
    size  : PropTypes.number.isRequired,
};

export default ChannelIcon;
